export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/performance/worker_wage',
      method: 'GET'
    },
    {
      path: '/performance/wage_level',
      method: 'GET'
    },
    {
      path: '/performance/worker',
      method: 'GET'
    }]
  },
  batch: {
    funKey: 'batch',
    name: '批量调薪',
    apis: [{
      path: '/performance/worker_wage/batch',
      method: 'POST'
    },
    {
      path: '/performance/worker_wage/batch',
      method: 'PUT'
    }]
  },
  batch_up: {
    funKey: 'batch_up',
    name: '智能升薪',
    apis: [{
      path: '/performance/worker_wage/batch',
      method: 'POST'
    },
    {
      path: '/performance/worker_wage/batch',
      method: 'PUT'
    }]
  }
}