<template>
  <div>
    <h2>Select组件(fm-select)</h2>
    <div class="flex">
      <fm-select placeholder="单选 v-model" v-model="select3">
        <fm-option :value="1" label="很好喝"></fm-option>
        <fm-option :value="2" label="哈哈哈"></fm-option>
        <fm-option :value="3" disabled label="hhhh"></fm-option>
      </fm-select>

      <fm-select placeholder="单选">
        <fm-option value="1" label="很好喝"></fm-option>
        <fm-option value="2" label="哈哈哈"></fm-option>
        <fm-option value="3" disabled label="hhhh"></fm-option>
      </fm-select>

      <fm-select placeholder="文本" text>
        <fm-option value="1" label="很好喝"></fm-option>
        <fm-option value="2" label="哈哈哈"></fm-option>
        <fm-option value="3" disabled label="hhhh"></fm-option>
      </fm-select>

      <fm-select placeholder="无选项"></fm-select>

      <fm-select placeholder="自定义显示">
        <fm-option value="1" label="NB">
          <span class="fmico fmico-gou">大奖</span>
          <span style="color: #c5c8ce;float:right;">qwi</span>
        </fm-option>
        <fm-option value="2" label="QW">
          <span class="fmico fmico-gou">进去</span>
          <span style="color: #c5c8ce;float:right;">www</span>
        </fm-option>
      </fm-select>

      <fm-select placeholder="多选" v-model="select" multiple style="width: 300px;">
        <fm-option value="1" label="很好喝"></fm-option>
        <fm-option value="2" label="哈哈哈"></fm-option>
        <fm-option value="3" disabled label="hhhh"></fm-option>
        <fm-option value="4" label="88777"></fm-option>
        <fm-option value="5" label="00123-321"></fm-option>
        <fm-option value="6" label="lwqisia"></fm-option>
        <fm-option value="8" label="qoqow12"></fm-option>
        <fm-option value="9" label="wqewqoe"></fm-option>
        <fm-option value="10" label="jdisadhq"></fm-option>
        <fm-option value="11" label="jdisadhq2"></fm-option>
        <fm-option value="12" label="jdisadhq3"></fm-option>
      </fm-select>

      <fm-select placeholder="筛选" filterable block v-model="selectData.value4">
        <fm-option value="数据1" label="数据1"></fm-option>
        <fm-option value="数据2" label="数据2"></fm-option>
        <fm-option value="数据3" label="数据3"></fm-option>
        <fm-option value="数据4" label="数据4"></fm-option>
        <fm-option value="数据5" label="数据5"></fm-option>
      </fm-select>
    </div>
    <br />
    <div class="flex">
      <fm-select placeholder="初始值多选" v-model="select1" multiple style="width: 300px;">
        <fm-option value="很好喝" label="很好喝"></fm-option>
        <fm-option value="哈哈哈" label="哈哈哈"></fm-option>
        <fm-option value="3" disabled label="hhhh"></fm-option>
        <fm-option value="88777" label="88777"></fm-option>
        <fm-option value="5" label="00123-321"></fm-option>
        <fm-option value="6" label="lwqisia"></fm-option>
        <fm-option value="8" label="qoqow12"></fm-option>
        <fm-option value="9" label="wqewqoe"></fm-option>
        <fm-option value="10" label="jdisadhq"></fm-option>
        <fm-option value="11" label="jdisadhq2"></fm-option>
        <fm-option value="12" label="jdisadhq3"></fm-option>
      </fm-select>

      <fm-select placeholder="change事件并返回label" v-model="selectData.value1" label @change="onSelectChange" style="width: 250px;">
        <fm-option value="A" label="A"></fm-option>
        <fm-option value="B" label="B"></fm-option>
      </fm-select>

      <fm-select placeholder="mini text" size="mini" text filterable>
        <fm-option value="1" label="很好喝"></fm-option>
        <fm-option value="2" label="哈哈哈"></fm-option>
        <fm-option value="3" disabled label="hhhh"></fm-option>
      </fm-select>

      <fm-select placeholder="配合按钮" v-model="selectData.value2" label style="width: 250px;">
        <fm-option value="A" label="A"></fm-option>
        <fm-option value="B" label="B"></fm-option>
      </fm-select>

      <fm-btn @click="selectData.value2 = 'A'">set A</fm-btn>
      <fm-btn @click="selectData.value2 = 'B'">set B</fm-btn>
    </div>
    <br />
    <div class="flex">
      <fm-select placeholder="可清除" filterable clearable v-model="selectData.value4" style="width: 250px;">
        <fm-option value="A" label="A"></fm-option>
        <fm-option value="B" label="B"></fm-option>
      </fm-select>
      <fm-select placeholder="多选可清除" clearable v-model="selectData.value5" multiple style="width: 250px;">
        <fm-option value="A" label="A"></fm-option>
        <fm-option value="B" label="B"></fm-option>
      </fm-select>
      <fm-select placeholder="value change" :value="selectData.value7" @change="(v) => selectData.value7 = v">
        <fm-option value="1" label="很好喝"></fm-option>
        <fm-option value="2" label="哈哈哈"></fm-option>
        <fm-option value="3" disabled label="hhhh"></fm-option>
      </fm-select>
      <fm-select placeholder="不可用" disabled>
        <fm-option value="1" label="很好喝"></fm-option>
        <fm-option value="2" label="哈哈哈"></fm-option>
        <fm-option value="3" label="hhhh"></fm-option>
      </fm-select>
      <fm-select placeholder="不可用" disabled filterable>
        <fm-option value="1" label="很好喝"></fm-option>
        <fm-option value="2" label="哈哈哈"></fm-option>
        <fm-option value="3" label="hhhh"></fm-option>
      </fm-select>
      <fm-select required v-verifier placeholder="非空验证" v-model="selectData.value8" filterable>
        <fm-option value="1" label="很好喝"></fm-option>
        <fm-option value="2" label="哈哈哈"></fm-option>
        <fm-option value="3" label="hhhh"></fm-option>
        <fm-option :value="0" label="0"></fm-option>
      </fm-select>
    </div>
    <br />
    <div class="flex">
      <fm-select placeholder="多选+筛选" clearable multiple filterable style="width: 250px;">
        <fm-option value="A" label="A"></fm-option>
        <fm-option value="B" label="B"></fm-option>
        <fm-option value="C" label="C"></fm-option>
        <fm-option value="D" label="D"></fm-option>
      </fm-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmSelectDemo',
  components: {},
  data() {
    return {
      selectData: {
        value1: null,
        value2: 'A',
        value4: null,
        value5: null,
        value6: null,
        value7: null,
        value8: null
      },
      select: [],
      select1: ['很好喝', '哈哈哈'],
      select3: 2,
    }
  },
  props: {},
  computed: {},
  methods: {
    onSelectChange (item) {
      console.log(item)
      this.$notice.info('select change', `值: ${item.value} 文本: ${item.label}`, 2000)
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped></style>