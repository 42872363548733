export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/performance/worker_wage',
      method: 'GET'
    },
    {
      path: '/performance/wage_level',
      method: 'GET'
    },
    {
      path: '/performance/wage_job_title',
      method: 'GET'
    },
    {
      path: '/performance/worker',
      method: 'GET'
    }]
  },
  batch: {
    funKey: 'batch',
    name: '批量维护',
    apis: [{
      path: '/performance/worker_wage/batch',
      method: 'POST'
    },
    {
      path: '/performance/worker_wage/batch',
      method: 'PUT'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/performance/worker_wage',
      method: 'POST'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/performance/worker_wage',
      method: 'POST'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/performance/worker_wage/{id}',
      method: 'DELETE'
    }]
  }
}