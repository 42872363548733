import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'

import pages from './pages'

import {
  localstorageHelper
} from '@/fmlib'

Vue.use(Router)

const fmRouter = new Router({routes: pages})

let testNeedAuth = function (router) {
  // 进入的页面是否需要登录
  return !router.path.includes('login')
}

let testAuthPass = function () {
  // 用户是否已经登录
  // 可以加上用户是否有权限访问
  return store.getters.authPass
}

fmRouter.beforeEach((to, from, next) => {
  let toData = to
  if (testNeedAuth(to)) {
    if (testAuthPass()) {
      if (store.getters.clientType === 'wap' && to.name === 'app') {
        toData = {
          name: 'wap.menu'
        }
        next(toData)
      } else if (from.name === 'count.count' && to.name === 'app') {
        toData = {
          name: 'count.home'
        }
        next(toData)
      } else {
        next()
      }
    } else {
      let localstorageJumpPageParm = localstorageHelper.getData('jumpPageParm')
      if (localstorageJumpPageParm) {
        store.dispatch('setJumpPageParm', localstorageJumpPageParm)
        localstorageHelper.updateData('jumpPageParm', null)
      } else if (to.name !== 'home') {
        store.dispatch('setJumpPageParm', to)
      }
      store.dispatch('authInit')
      toData = null
      next(false)
    }
  } else {
    next()
  }
  if (toData) {
    store.dispatch('routerUpdate', toData)
  }
})

export default fmRouter
