var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fm-modal",class:{
  ['theme-' + (_vm.theme || '')]: _vm.theme ? true : false,
  'fm-modal-inner': _vm.inner,
  'fm-modal-open': _vm.value
}},[_c('transition',{attrs:{"name":"fm-modal-mask-transition","mode":"in-out"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value && _vm.isMask),expression:"value && isMask"}],staticClass:"fm-modal-mask",style:({ zIndex: _vm.zIndex })})]),_c('transition',{attrs:{"name":"fm-modal-transition","mode":"in-out"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],staticClass:"fm-modal-wrap",style:({ zIndex: _vm.zIndex }),on:{"click":function($event){$event.stopPropagation();if($event.target !== $event.currentTarget)return null;return (() => { if (_vm.maskClosable === true || _vm.maskClosable === undefined) _vm.event('cancel')}).apply(null, arguments)}}},[_c('div',{staticClass:"fm-modal-content",style:({
        height: isNaN(_vm.height) ? _vm.height : (_vm.height + 'px'),
        width: isNaN(_vm.width) ? _vm.width : (_vm.width + 'px')
      })},[(_vm.isClosable)?_c('div',{staticClass:"fm-modal-close",on:{"click":function($event){$event.stopPropagation();return _vm.event('cancel')}}},[(!_vm.$slots.close)?_c('a',{staticClass:"fmico fmico-cha"}):_vm._e(),_vm._t("close")],2):_vm._e(),(_vm.$slots.header || _vm.title)?_c('div',{staticClass:"fm-modal-header"},[(_vm.$slots.header)?_vm._t("header"):[_vm._v(_vm._s(_vm.title))]],2):_vm._e(),_c('div',{staticClass:"fm-modal-body"},[_vm._t("default")],2),(_vm.$slots.footer)?_c('div',{staticClass:"fm-modal-footer"},[_vm._t("footer")],2):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }