<template>
  <div class="fm-date-picker-cell" :class="cls" @mouseenter="followMe" @click="choose">
    <div class="fm-date-picker-cell-text" ref="text" :class="cellCls"  v-html="cellText"></div>
  </div>
</template>

<script>
import { XDate } from './lib'

const today = new XDate()

export default {
  name: '',
  components: {},
  data() {
    return {
      selected: false
    }
  },
  inject: ['picker'],
  props: {
    cell: {
      type: Object
    },
    range: {
      type: Object
    },
    type: {
      type: String,
      default: 'date'
    },
    disabledDate: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    cellText () {
      if (typeof this.picker.template === 'function') {
        let tpl = this.picker.template(this.type, this.cell, this.$refs.text)
        if (tpl) {
          return tpl
        }
      }

      if (this.type === 'date') {
        return this.cell.date.getDate()
      } else if (this.type === 'year') {
        return this.cell.date.getFullYear()
      } else if (this.type === 'month') {
        return this.cell.date.getMonth() + 1 + '月'
      } else {
        return ''
      }
    },
    inCandidate () {
      if (this.range) {
        let start = this.cell.date.compare(this.range.start, 'd')
        let candidate = this.cell.date.compare(this.range.candidate, 'd')
        let end = this.cell.date.compare(this.range.end, 'd')
        return start === 1 && (end === undefined ? candidate === -1 : end === -1)
      } else {
        return false
      }
    },
    isCandidate () {
      if (this.range) {
        return this.cell.date.sameDate(this.range.start) || this.cell.date.sameDate(this.range.end)
      } else {
        return false
      }
    },
    isDisabled () {
      return typeof this.disabledDate === 'function' ? this.disabledDate(this.cell.date, this.type) : false
    },
    cls () {
      return {
        'fm-date-picker-candidate': this.inCandidate,
        'fm-date-picker-disabled': this.isDisabled
      }
    },
    cellCls () {
      return {
        'fm-date-picker-today': this.isToday,
        'fm-date-picker-selected': this.selected || this.isCandidate,
        'fm-date-picker-pre': this.cell.pre,
        'fm-date-picker-next': this.cell.next
      }
    },
    isToday () {
      return (this.type === 'date' && today.sameDate(this.cell.date) || (this.type === 'year' && today.sameYear(this.cell.date)) || (this.type === 'month' && today.sameMonth(this.cell.date)))
    },
    isRange () {
      return this.range && Object.keys(this.range).length === 3
    }
  },
  methods: {
    choose () {
      !this.isDisabled && this.picker.choose(this.cell)
    },
    followMe () {
      if (this.isRange && this.range && this.range.start && !this.range.end) {
        this.picker.choose(Object.assign({flag: 'candidate'}, this.cell))
      }
      if (this.picker.$refs.follow && !this.isDisabled) {
        this.picker.$refs.follow.me(this)
      }
    },
    hasValue () {
      this.selected = this.picker.checkSelected(this.cell.date)
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.picker.$on('update', this.hasValue)
    this.hasValue()
  },
  beforeUpdate() {},
  updated() {
    this.hasValue()
  },
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
@import '../styles/datapicker.less';
</style>