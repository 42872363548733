<style lang="less">
  @import url('../styles/values.less');
  .fm-table-filter {
    position: relative;
    margin-left: 5px;
    .fmico.fmico-filter {
      font-size: 12px;
      transition: all .3s;
      cursor: pointer;
      color: @color-border;
      &:hover {
        color: @color-primary;
      }
      &.fm-table-filter-active {
        color: @color-primary;
      }
    }
    .fm-table-filter-wrap {
      background-color: #FFF;
      max-width: 250px;
      text-align: left;
      border-radius: 5px;
      padding: 5px;
      border: 1px solid @color-border;
    }
  }

  .fm-table-filter-input {
    position: relative;
    .fm-table-filter-number {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .fm-table-filter-action {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    border-top: 1px solid #EEE;
    button {
      flex: 1;
      text-align: center;
      word-break: keep-all;
      white-space: nowrap;
      background: none;
      border: none;
      cursor: pointer;
      background-color: #FFF;
      &:active, &:focus {outline: none;}
    }
  }
  .fm-table-filter-cancel-btn, .fm-table-filter-confirm-btn {
    transition: all .3s;
  }
  .fm-table-filter-cancel-btn {
    color: @color-disabled-text;
    &:hover {
      color: @color-fail;
    }
  }
  .fm-table-filter-confirm-btn {
    color: @color-content-text;
    &:hover {
      color: @color-primary;
    }
  }

  .fm-table-filter-tags, .fm-table-filter-section {
    margin-top: 5px;
    .fm-tag {
      margin: 2px 4px 2px 0px;
      & ~ & {
        margin-left: 0;
      }
    }
  }

  .fm-table-filter-options {
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .fm-checkbox-group.fm-checkbox-group-vertical {
      .fm-checkbox {
        margin-top: 0;
        width: 100%;
      }
    }
  }

  .fm-table-filter-option {
    color: @color-component-text;
    font-size: @size-font-small;
    display: flex;
    align-items: center;
    padding: 0 5px;
    height: @size-height-small;
    transition: all .3s;
    cursor: pointer;
    &:hover, &.fm-table-filter-option-active {
      background-color: @color-background-base;
      color: @color-primary;
    }
    &:first-child {
      margin-top: 5px;
    }
  }
  .fm-table-filter-section-gap {
    padding: 0 5px;
    color: @color-divider;
  }
  .fm-table-filter-input .fm-input-new input {
    width: 100%;
  }
</style>

<template>
  <div class="fm-table-filter" @click.stop="() => null" @mouseenter="onMouseenter" @mouseleave="hover = false">
    <i class="fmico fmico-filter" :class="{'fm-table-filter-active': hasFilter}" @click.stop="toggle"></i>
    <fm-poper v-model="show">
      <div class="fm-table-filter-wrap">
        <div class="fm-table-filter-input" v-if="column.search">
          <div class="fm-table-filter-number" v-if="column.config.dataType === Number">
            <fm-input-new size="small" @enten="onAddSection" ref="start" style="width: 0; min-width: 80px; flex: 1;"></fm-input-new>
            <span class="fm-table-filter-section-gap">-</span>
            <fm-input-new size="small" @enten="onAddSection" ref="end" style="width: 0; min-width: 80px; flex: 1;"></fm-input-new>
          </div>
          <fm-input-new style="flex: 1" v-else @enten="onAddTag" ref="input" size="small" placeholder="筛选内容"></fm-input-new>
          <fm-btn v-if="filterMultiple" block type="dashed" size="small" @click="column.config.dataType === Number ? onAddSection() : onAddTag($refs.input.data)" style="margin-top: 8px;"><i class="fmico fmico-add"></i>新增</fm-btn>
        </div>
        <template v-if="filterMultiple">
          <div class="fm-table-filter-section" v-if="column.config.dataType === Number">
            <fm-tag v-for="section in sections" :key="section.join('-')" size="mini" @close="onRemoveSection(section)" closable>
              {{sectionFormat(section)}}
            </fm-tag>
          </div>
          <div class="fm-table-filter-tags" v-else>
            <fm-tag v-for="tag in tags" :key="tag" size="small" @close="onRemoveTag(tag)" closable>{{tag}}</fm-tag>
          </div>
        </template>
        <div class="fm-table-filter-options" v-if="column.config.filters && column.config.filters.length">
          <template v-if="filterMultiple">
            <fm-checkbox-group vertical @change="setOption" :value="options">
              <fm-checkbox v-for="(option, key) in column.config.filters" size="small" :label="option.value" :key="'multiple' + key">
                <div v-html="option.label"></div>
              </fm-checkbox>
            </fm-checkbox-group>
          </template>
          <template v-else>
            <div class="fm-table-filter-option" :class="{
              'fm-table-filter-option-active': options.includes(option.value)
            }" @click="setOption([option.value])" v-for="(option, key) in column.config.filters" :key="'single' + key" v-html="option.label"></div>
          </template>
        </div>
        <div class="fm-table-filter-action">
          <button class="fm-table-filter-cancel-btn" @click="clear">{{hasFilter ? '清除' : '取消'}}</button>
          <button class="fm-table-filter-confirm-btn" @click="apply">确定</button>
        </div>
      </div>
    </fm-poper>
    <fm-poper v-model="hover" @click.stop="toggle" :key="tagKey">
      <template v-if="column.config.dataType === Number">
        <fm-tag size="small" v-for="section in sections" :key="'sections-' + section.join('-')">
          {{sectionFormat(section)}}
        </fm-tag>
      </template>
      <template v-else>
        <fm-tag size="small" v-for="tag in tags" :key="'tags-' + tag">{{tag}}</fm-tag>
      </template>
      <fm-tag size="small" v-for="option in options" :key="'options-' + option" v-html="column.config.filters.find(v => v.value === option).label"></fm-tag>
    </fm-poper>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tags: [],
      options: [],
      sections: [],
      effect: {
        tags: [],
        options: [],
        sections: []
      },
      show: false,
      hover: false,
      tagKey: 0
    }
  },
  inject: ['table'],
  props: {
    column: {
      type: Object, default () {
        return {}
      }
    },
    groupIndex: {
      type: Number, default: -1
    },
    columnIndex: {
      type: Number, default: -1
    }
  },
  computed: {
    filterMultiple () {
      return this.column.config.filterMultiple !== false
    },
    hasFilter () {
      return this.tags.length || this.options.length || this.sections.length
    }
  },
  methods: {
    sectionFormat (section) {
      if (isNaN(section[0])) {
        return `小于等于${section[1]}`
      } else if (isNaN(section[1])) {
        return `大于等于${section[0]}`
      } else {
        return `大于等于${section[0]}且小于等于${section[1]}`
      }
    },
    onMouseenter () {
      if (!this.show && this.hasFilter) {
        this.hover = true
      }
    },
    onAddSection () {
      let range = [parseFloat(this.$refs.start.data), parseFloat(this.$refs.end.data)]
      if (isNaN(range[0]) === true && isNaN(range[1]) === true) {return}

      if (this.filterMultiple) {
        if (this.sections.findIndex(section => section.join('-') === range.join('-')) === -1) {
          this.sections.push(range)
          this.$refs.start.clearData()
          this.$refs.end.clearData()
        }
      } else {
        this.sections = [range]
      }
    },
    onRemoveSection (range) {
      const index = this.sections.findIndex(section => section.join('-') === range.join('-'))
      if (index !== 1) {
        this.sections.splice(index, 1)
      }
    },
    onAddTag (v) {
      if (this.filterMultiple && !this.tags.includes(v)) {
        this.tags.push(v)
        this.$refs.input.clearData()
      } else if (!this.filterMultiple) {
        this.tags = [v]
        this.show = false
        this.apply()
      }
    },
    onRemoveTag (v) {
      let index = this.tags.findIndex(tag => v === tag)
      if (index >= 0) {
        this.tags.splice(index, 1)
      }
    },
    toggle () {
      if (this.show) {
        this.close()
      } else {
        this.table.$emit('open-filter')
        this.show = true
      }
    },
    apply () {
      if (!this.filterMultiple && this.column.config.dataType === Number) {
        this.onAddSection()
      }
      this.effect.tags = [...this.tags]
      this.effect.options = [...this.options]
      this.effect.sections = [...this.sections]
      this.table.$emit('column-filter-set', {field: this.column.config.field, tags: [...this.tags], options: [...this.options], sections: [...this.sections]})
      this.close()
      this.tagKey += 1
    },
    close () {
      this.show = false
      this.hover = false
      this.tags = [...this.effect.tags]
      this.options = [...this.effect.options]
      this.sections = [...this.effect.sections]
    },
    clear () {
      if (!this.filterMultiple || !this.hasFilter) {
        this.close()
      }
      if (this.column.config.dataType === Number) {
        this.$refs.start.clearData()
        this.$refs.end.clearData()
      } else if (this.$refs.input) {
        this.$refs.input.clearData()
      }
      this.effect.tags = this.tags = []
      this.effect.options = this.options = []
      this.effect.sections = this.sections = []
      this.table.$emit('column-filter-set', {field: this.column.config.field, tags: [], options: [], sections: []})
    },
    setOption (v) {
      this.options = v
    },
    updateFilterConfig (config) {
      if (!Object.keys(config).includes(this.column.config.field)) {
        if (this.column.config.dataType === Number) {
          this.$refs.start.clearData()
          this.$refs.end.clearData()
        } else if (this.$refs.input) {
          this.$refs.input.clearData()
        }
        this.effect.tags = this.tags = []
        this.effect.options = this.options = []
        this.effect.sections = this.sections = []
      }
    }
  },
  mounted () {
    this.table.$on('open-filter', this.close)
    this.table.$on('update-filter-config', this.updateFilterConfig)
  },
  destroyed () {
    this.table.$off('open-filter', this.close)
    this.table.$off('update-filter-config', this.updateFilterConfig)
  }
}
</script>
