<style scoped>
  @import './demo.less';
</style>

<template>
  <div>
    <h2>数组输入框（fm-input-number）支持方向上下键输入</h2>
    <div class="flex">
      <fm-input-number size="large" placeholder="large"></fm-input-number>
      <fm-input-number size="norm" placeholder="norm"></fm-input-number>
      <fm-input-number size="small" placeholder="small"></fm-input-number>
      <fm-input-number size="mini" placeholder="mini"></fm-input-number>
    </div>
    <h2>增长步数（step）</h2>
    <div class="flex">
      <fm-input-number :step="0.1" placeholder="0.1"></fm-input-number>
      <fm-input-number :step="2" placeholder="2"></fm-input-number>
    </div>
    <h2>范围限定和保留小数点（<fm-tag>max</fm-tag><fm-tag>min</fm-tag><fm-tag>precision</fm-tag>）</h2>
    <div class="flex">
      <fm-input-number :max="10" :min="1" placeholder="1-10"></fm-input-number>
      <fm-input-number :max="10" :min="1" :precision="2" :step="0.1" placeholder="保留两位小数点"></fm-input-number>
    </div>
    <h2>不可用和只读（<fm-tag>disabled</fm-tag><fm-tag>readonly</fm-tag>）</h2>
    <div class="flex">
      <fm-input-number disabled placeholder="disabled"></fm-input-number>
      <fm-input-number readonly placeholder="readonly"></fm-input-number>
    </div>
    <h2>自定义显示格式（<fm-tag>formatter</fm-tag><fm-tag>parser</fm-tag>）</h2>
    <div class="flex">
      <fm-input-number :formatter="(v) => v + ' %'" :parser="(v) => Number(String(v).replace(/[^\d\.\-]/g, ''))"></fm-input-number>
      <fm-input-number :formatter="(v) => '¥ ' + v" :parser="(v) => Number(String(v).replace(/[^\d\.\-]/g, ''))"></fm-input-number>
    </div>
    <h2>可用性测试</h2>
    <div class="flex">
      <fm-input-number v-model="v1"></fm-input-number>
      <span>v1: {{v1}}</span>
      <fm-input-number v-model="v2"></fm-input-number>
      <span>v2: {{v2}}</span>
      <fm-input-number v-model="v3"></fm-input-number>
      <span>v3: {{v3}}</span>
      <fm-btn @click="v3 = 1">set 1</fm-btn>
      <fm-btn @click="v3 = 2">set 2</fm-btn>
      <fm-input-number :value="v4"></fm-input-number>
      <span>v4: {{v4}}</span>
      <fm-btn @click="v4 = 1">set 1</fm-btn>
      <fm-btn @click="v4 = 2">set 2</fm-btn>
    </div> 
  </div>
</template>

<script>
export default {
  name: 'FmInputNumberDemo',
  data () {
    return {
      v1: 1,
      v2: null,
      v3: null,
      v4: null
    }
  }
}
</script>
