<template>
  <transition name="fm-notice-transition" mode="in-out" @after-leave="remove">
    <div class="fm-notice" v-show="show" :class="noticeCls">
      <div class="fm-notice-title">
        <i v-if="type !== 'norm'" class="fmico" :class="type !== 'norm' ? [
        iconTypes[type] + (isEmptyDesc ? '-solid' : '')
        ] : []"></i>
        <slot v-if="$slots.title"></slot>
        <span v-else v-html="title"></span>
      </div>
      <a class="fm-notice-close" @click.stop="show = false">
        <i class="fmico fmico-cha"></i>
      </a>
      <div class="fm-notice-desc" v-if="desc || $slots.default">
        <slot v-if="$slots.default"></slot>
        <span v-else v-html="desc"></span>
      </div>
    </div>
  </transition>
</template>

<script>
import { iconTypes } from '../basic'

export default {
  name: 'FmNotice',
  components: {},
  data() {
    return {
      show: false,
      timer: null
    }
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    desc: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'norm'
    },
    duration: {
      type: Number,
      default: 4500
    }
  },
  computed: {
    iconTypes () {
      return iconTypes
    },
    noticeCls () {
      let cls = {
        'fm-notice-empty': this.isEmptyDesc,
        'fm-notice-with-icon': this.type !== 'norm',
        [`fm-notice-${this.type}`]: true
      }

      return cls
    },
    isEmptyDesc () {
      return !this.$slots.default && !this.desc
    }
  },
  methods: {
    remove () {
      this.$emit('close')
      this.$el.remove()
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    if (this.duration >= 1000) {
      this.time = setTimeout(() => {
        this.show = false
      }, this.duration)
    }

    if (this.duration > 0 && this.duration < 1000) {
      console.error('FmNotice.duration不能小于1000毫秒')
    }
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
  @import './styles/values.less';
  @notice-width: 335px;
  .fm-notice-box {
    width: @notice-width;
    margin-right: 20px;
    position: fixed;
    top: 20px;
    right: 0px;
    z-index: 1001;
  }
  .fm-notice {
    & + .fm-notice {
      margin-top: 10px;
    }
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgba(0,0,0,.2);
    background: #fff;
    line-height: 1;
    position: relative;
    overflow: hidden;
    .fm-notice-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: @size-font-norm;
      font-weight: 500;
      color: @color-sub-text;
      padding-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.fm-notice-empty.fm-notice-with-icon {
      .fm-notice-title {
        color: @color-gray;
        .fmico {
          margin-right: 5px;
          font-size: 1.5rem;
        }
      }
    }
    &.fm-notice-with-icon {
      .fm-notice-title {
        .fmico {
          margin-right: 10px;
          font-size: 1.5rem;
        }
      }
    }
    .fm-notice-desc {
      font-size: @size-font-small;
      color: @color-gray;
      text-align: justify;
      line-height: 1.5;
      margin-top: 10px;
    }
    .fm-notice-close {
      position: absolute;
      right: 8px;
      top: 15px;
      color: #999;
      outline: 0;
      cursor: pointer;
      .fmico-cha {
        font-size: 1rem;
        color: #999;
        transition: color .2s ease;
        position: relative;
        top: -3px;
        &:hover {
          color: #444;
        }
      }
    }

    // 颜色设置
    &.fm-notice-info {
      border: 1px solid @color-info;
      background-image: linear-gradient(0, @color-info-shadow, @color-info-shadow);
      .fm-notice-close, .fm-notice-close .fmico-cha, .fmico-info, .fmico-info-solid {
        color: @color-info;
      }
    }
    &.fm-notice-success {
      border: 1px solid @color-success;
      background-image: linear-gradient(0, @color-success-shadow, @color-success-shadow);
      .fm-notice-close, .fm-notice-close .fmico-cha, .fmico-success, .fmico-success-solid {
        color: @color-success;
      }
    }
    &.fm-notice-warning {
      border: 1px solid @color-warning;
      background-image: linear-gradient(0, @color-warning-shadow, @color-warning-shadow);
      .fm-notice-close, .fm-notice-close .fmico-cha, .fmico-warning, .fmico-warning-solid {
        color: @color-warning;
      }
    }
    &.fm-notice-error {
      border: 1px solid @color-danger;
      background-image: linear-gradient(0, @color-danger-shadow, @color-danger-shadow);
      .fm-notice-close, .fm-notice-close .fmico-cha, .fmico-error, .fmico-error-solid {
        color: @color-danger;
      }
    }
  }
  // 动画
  .fm-notice-transition-enter-active, .fm-notice-transition-leave-active {
    transition: all .5s;
    // opacity: 1;
    transform: translateX(0);
  }
  .fm-notice-transition-enter, .fm-notice-transition-leave-to {
    // opacity: 0;
    transform: translateX(@notice-width);
  }
</style>