<template>
  <div>
    <div class="flex" style="align-items: flex-start;margin-bottom: 10px;">
      <FmDatePicker placeholder="v-model双绑定" v-model="date.date1" required v-verifier @change="dateChange1" format="M月D日"></FmDatePicker>
      <FmDatePicker placeholder="多选+格式化方法" multiple :formatter="dateFormatter" @change="dateChange2"></FmDatePicker>
      <FmDatePicker placeholder="格式化参数" format="Y/m/d"></FmDatePicker>
      <FmDatePicker placeholder="年选择" type="year" v-model="date.date2" multiple @change="dateChange3"></FmDatePicker>
      <FmDatePicker placeholder="月选择" type="month"></FmDatePicker>
    </div>

    <div class="flex" style="align-items: flex-start;margin-bottom: 10px;">
      <FmDatePicker placeholder="带时间" type="datetime"></FmDatePicker>
      <FmDatePicker placeholder="时间选择" type="time"></FmDatePicker>
      <FmDatePicker placeholder="带确认 和 非空验证" confirm required v-verifier></FmDatePicker>
      <FmDatePicker placeholder="text" text></FmDatePicker>
      <FmDatePicker placeholder="readonly" text readonly></FmDatePicker>
    </div>

    <div class="flex" style="align-items: flex-start;margin-bottom: 10px;">
      <FmDatePicker placeholder="日期范围选择" style="width: 400px;" type="daterange"></FmDatePicker>
      <FmDatePicker placeholder="日期时间范围选择" style="width: 400px;" type="datetimerange"></FmDatePicker>
      <FmDatePicker placeholder="value绑定" style="width: 400px;" :value="date.date3" type="datetimerange"></FmDatePicker>
    </div>

    <div class="flex" style="align-items: flex-start;margin-bottom: 10px;">
      <FmDatePicker placeholder="不可选日期" style="width: 400px;" type="date" :disabledDate="disabledDate"></FmDatePicker>
      <FmDatePicker placeholder="快捷操作(单选)" style="width: 400px;" type="date" :shortcut="shortcut1"></FmDatePicker>
      <FmDatePicker placeholder="快捷操作(范围)" style="width: 400px;" type="daterange" :shortcut="shortcut2"></FmDatePicker>
    </div>

    <div class="flex" style="align-items: flex-start;margin-bottom: 10px;">
      <FmDatePicker :value="date.strDate" @change="(d) => date.strDate = d" placeholder="值字符串" style="width: 400px;" type="date"></FmDatePicker>
      <FmDatePicker placeholder="自定义日历渲染内容" style="width: 400px;" type="datetime" :template="template"></FmDatePicker>
      <FmDatePicker placeholder="可清空" clearable></FmDatePicker>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmDatePickerDemo',
  components: {},
  data() {
    return {
      date: {
        date1: new Date(1996, 3, 10),
        date2: null,
        date3: [new Date(1996, 3, 10), new Date(1996, 4, 11)],
        strDate: '2012-12-12'
      },
      shortcut1: [
        { 
          text: '昨天',
          value (XDate) {
            return new XDate().compute('d', -1)
          }
        },
        {
          text: '明天',
          value (XDate) {
            return new XDate().compute('d', 1)
          }
        },
        {
          text: '一周前',
          value (XDate) {
            return new XDate().compute('d', -7)
          }
        },
        {
          text: '点击事件',
          value (XDate) {
            return new XDate().compute('d', -5)
          },
          onClick: () => {
            this.$notice.info('点击了')
          }
        },
        {
          text: '异步点击',
          value (XDate) {
            return new XDate().compute('d', -1)
          },
          onClick: () => {
            return new Promise(resolve => {
              setTimeout(() => {
                this.$notice.info('resolve false取消选中')
                resolve(false) // 返回false即可取消选中
              }, 1500)
            })
          }
        }
      ],
      shortcut2: [
        { 
          text: '一周内',
          value (XDate) {
            return [new XDate().compute('d', -7), new XDate()]
          }
        }
      ]
    }
  },
  props: {},
  computed: {},
  methods: {
    template (type, cell, el) {
      // type 为 year month date
      // 可对指定的类型自定义渲染
      // 返回任意空值则使用默认的渲染方式
      if (type === 'date') {
        const data = (Math.random() * 10).toFixed(1)
        return `
          <div style="line-height: 1;">
            <span>${cell.date.format('D')}</span><br/>
            <span style="color: ${data < 5 ? 'blue' : 'red'};">${data}%</span>
          </div>
        `
      }
    },
    dateFormatter (date) {
      // Y 年 m月 d日 H时 i分 s秒
      return date ? ('第' + date.format('d') + '天') : ''
    },
    dateChange1 (d) {
      this.$notice.info({
        title: '双向绑定 变化 格式化',
        desc: '通过v-model绑定获取选中时间: ' + this.date.date1.format('Y/m/d') + '<br />通过format格式化选择结果: ' + d
      })
    },
    dateChange2 (date) {
      date && this.$notice.info('日期控件多选', date.join('和'))
    },
    dateChange3 (date) {
      this.date.date2 && this.$notice.info({
        title: 'format格式化',
        desc: '手动格式化:' + this.date.date2.map(v => v.format('Y') + '年').join(',') + '<br />自动识别format结果:' + date
      })
    },
    disabledDate (date) {
      return [1, 5, 10, 20, 25].includes(parseInt(date.format('d')))
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped></style>