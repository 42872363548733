<template>
  <div class="fm-split" :class="{[`fm-split-${mode}`]: true}" @mousemove="onMousemove">
    <div class="fm-split-pane fm-split-left" :style="leftStyle"><slot name="left"></slot></div>
    <div class="fm-split-line" @mousedown.stop="startMoving" :style="lineStyle">
      <i v-for="i in 5" :key="i"></i>
    </div>
    <div class="fm-split-pane fm-split-right" :style="rightStyle"><slot name="right"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'FmSplit',
  data () {
    return {
      data: this.value,
      isMoving: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    min: {
      type: Number,
      default: 0.1,
      validator (val) {
        return val >= 0 && val <= 1
      }
    },
    max: {
      type: Number,
      default: 0.9,
      validator (val) {
        return val >= 0 && val <= 1
      }
    },
    value: {
      type: Number,
      default: 0.5
    },
    mode: {
      type: String,
      default: 'horizontal',
      validator: function(size) {
        return ['horizontal', 'vertical'].includes(size)
      }
    }
  },
  computed: {
    ratio () {
      return this.data === undefined ? 50 : (this.data * 100)
    },
    leftStyle () {
      return this.mode === 'horizontal' ? {
        right: (100 - this.ratio) + '%'
      } : {
        bottom: (100 - this.ratio) + '%'
      }
    },
    lineStyle () {
      return this.rightStyle
    },
    rightStyle () {
      return this.mode === 'horizontal' ? {
        left: this.ratio + '%'
      } : {
        top: this.ratio + '%'
      }
    }
  },
  methods: {
    onMousemove ({movementX, movementY}) {
      if (!this.isMoving) return
      if (this.mode === 'horizontal') {
        this.data = Math.max(Math.min(this.max, this.data + movementX / this.$el.offsetWidth), this.min)
      } else {
        this.data = Math.max(Math.min(this.max, this.data + movementY / this.$el.offsetHeight), this.min)
      }
      this.$emit('input', this.data)
    },
    startMoving () {
      this.isMoving = true
    },
    stopMoving () {
      this.isMoving = false
    }
  },
  watch: {
    value (val) {
      this.data = val
    }
  },
  mounted () {
    document.addEventListener('mouseup', this.stopMoving)
    document.addEventListener('mouseleave', this.stopMoving)
  },
  destroyed () {
    document.removeEventListener('mouseup', this.stopMoving)
    document.removeEventListener('mouseleave', this.stopMoving)
  }
}
</script>

<style lang="less">
  @import './styles/values.less';
  @fm-split-width: 6px;
  .fm-split {
    position: relative;
    display: flex;
    border: 1px solid @color-border;
    width: 100%;
    height: 100%;
    .fm-split-pane {
      background-color: #FFF;
    }
    .fm-split-pane, .fm-split-line {
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      user-select: none;
    }
    .fm-split-line {
      justify-content: center;
      align-items: center;
      background-color: @color-background-base;
      border: 1px solid @color-border;
      display: flex;
      z-index: 1;
      i {
        display: block;
        background-color: @color-border;
      }
    }
    &.fm-split-horizontal {
      flex-direction: row;
      .fm-split-line {
        cursor: col-resize;
        flex-direction: column;
        position: relative;
        transform: translateX(-50%);
        width: @fm-split-width;
        height: 100%;
        i {
          width: 100%;
          height: 1px;
          & + i {
            margin-top: 3px;
            margin-left: unset;
          }
        }
      }
      .fm-split-left {
        left: 0;
      }
      .fm-split-right {
        right: 0;
      }
    }
    &.fm-split-vertical {
      flex-direction: column;
      .fm-split-line {
        position: relative;
        cursor: row-resize;
        flex-direction: row;
        height: @fm-split-width;
        width: 100%;
        transform: translateY(-50%);
        i {
          width: 1px;
          height: 100%;
          & + i {
            margin-left: 3px;
            margin-top: unset;
          }
        }
      }
      .fm-split-left {
        top: 0;
      }
      .fm-split-right {
        bottom: 0;
      }
    }
  }
</style>