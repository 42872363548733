<template>
  <div>
    <h2>消息提示($notice)</h2>
    <div class="flex">
      <fm-btn @click="$notice.open('信息框')">普通消息</fm-btn>
      <fm-btn @click="$notice.info('信息 4.5秒自动关闭')"><i class="fmico fmico-info"></i>信息 4.5秒自动关闭</fm-btn>
      <fm-btn @click="$notice.info({title: '信息', desc: '带描述信息 不关闭', duration: 0})"><i class="fmico fmico-info"></i>信息 带描述信息 不关闭</fm-btn>
      <fm-btn @click="$notice.success('成功 不关闭', 0)"><i class="fmico fmico-success"></i>成功 不关闭</fm-btn>
      <fm-btn @click="$notice.warning({title: '警告', desc: '带描述信息'})"><i class="fmico fmico-warning"></i>警告 带描述信息</fm-btn>
      <fm-btn @click="$notice.error({title: '失败 关闭回调', onClose})"><i class="fmico fmico-error"></i>失败 带关闭回调</fm-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FmNoticeDemo",
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {
    onClose () {
      this.$dialog.info('notice的关闭回调')
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
};
</script>

<style lang="less" scoped></style>