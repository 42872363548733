import { fileRequest } from '@/api'

export function getExtension (name) {
  return name ? (name.split('.').pop()).toLowerCase() : null
}

export function isImg (name) {
  return ['png', 'jpg', 'jpeg', 'gif'].includes(getExtension(name))
}
export function isText (name) {
  return ['md', 'txt'].includes(getExtension(name))
}

export function isExcel (name) {
  return ['xls', 'xlsx'].includes(getExtension(name))
}

export function isPdf (name) {
  return getExtension(name) === 'pdf'
}

const loadFlag = {}
export function loadScript (url) {
	return new Promise(resolve => {
    if (!loadFlag[url]) {
      let script = document.createElement("script");
      script.onload = function () {
        loadFlag[url] = true
        resolve()
      }
      script.src = url;
      document.body.appendChild(script);
    } else {
      resolve()
    }
	})
}

const resourcesCache = {}
export async function loadResourcesByCache ({ path, type, refresh }) {
  type = type || 'blob'
  let key = type + ':' + path
  if (!resourcesCache[key] || refresh) {
    resourcesCache[key] = new Promise(resolve => {
      if (isImg(path)) {
        if (type === 'uri') {
          loadImgAsDataUri(path).then(res => {
            resolve(res)
          }).catch((err) => {
            console.log(err)
            resolve(null)
          })
        } else {
          loadImgAsBlobUri(path).then(res => {
            resolve(res)
          }).catch((err) => {
            console.log(err)
            resolve(null)
          })
        }
      } else if (isPdf(path)) {
        if (type === 'uri') {
          loadPdfAsDataUri(path).then(res => {
            resolve(res)
          }).catch((err) => {
            console.log(err)
            resolve(null)
          })
        } else {
          loadPdfAsBlobUrl(path).then(res => {
            resolve(res)
          }).catch((err) => {
            console.log(err)
            resolve(null)
          })
        }
      } else {
        resolve(null)
      }
    })
  }
  return await resourcesCache[key]
}

export async function loadImgAsBlobUri (path) {
  const response = await fileRequest.download({ path: path })
  return window.URL.createObjectURL(new Blob([response], { type: 'image/' + path.split('.').pop() }))
}

export async function loadImgAsDataUri (path) {
  const response = await fileRequest.download({ path: path })
  return await new Promise(resolve => {
    let reader = new FileReader()
    reader.readAsDataURL(response)
    reader.onload = () => resolve(reader.result)
  })
}

export async function loadPdfCanvas (path) {
  await loadScript('/static/pdfjs-2.12.313-dist/build/pdf.js')
  const pdfjsBase = '/static/pdfjs-2.12.313-dist';
  window.pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsBase + '/build/pdf.worker.js';

  const response = await fileRequest.download({ path: path })
  const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf '}))
  const res = await window.pdfjsLib.getDocument({
      url: url,
      cMapUrl: pdfjsBase + '/web/cmaps/',
      cMapPacked: true
  }).promise

  const canvasList = []
  for (let i = 1; i <= res.numPages; i++) {
    const page = await res.getPage(i)
    const viewport = page.getViewport({ scale: 1.5 })

    const outputScale = window.devicePixelRatio || 1;

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    canvas.width = viewport.width * outputScale
    canvas.height = viewport.height * outputScale
    canvas.style.width = viewport.width + 'px'
    canvas.style.height = viewport.height + 'px'

    var transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

    await page.render({ canvasContext: context, transform: transform, viewport: viewport }).promise;
    canvasList.push(canvas)
  }

  return canvasList
}

export async function loadPdfAsBlobUrl (path) {
  try {
    const res = await loadPdfCanvas(path)
    return await Promise.all(res.map(v => {
      return new Promise(resolve => {
        v.toBlob(blob => {
          resolve(window.URL.createObjectURL(blob))
        }, 'image/png')
      })
    }))
  } catch (error) {
    return []
  }
}

export async function loadPdfAsDataUri (path) {
  try {
    const res = await loadPdfCanvas(path)
    return await Promise.all(res.map(v => {
      return v.toDataURL('image/png')
    }))
  } catch (error) {
    return []
  }
}

export function urlImgRotate2Canvas (src, rotate) {
  return new Promise(resolve => {
    let img = new Image()
    img.src = src
    img.crossOrigin = 'anonymous'
    img.onload = function() {
      rotate = Number(rotate)

      const isDiagonal = ![0, 180, 90, 270].includes(Math.abs(rotate))
      let canvas = document.createElement('canvas')

      if (!isDiagonal) {
        if (Math.abs(rotate) === 90 || Math.abs(rotate) === 270) {
          canvas.height = this.width
          canvas.width = this.height
        } else {
          canvas.height = this.height
          canvas.width = this.width
        }
      } else {
        // TODO 待完善目前是用对角线来做宽高
        const diagonal = Math.sqrt(Math.pow(this.width, 2) + Math.pow(this.height, 2))
        canvas.height = diagonal
        canvas.width = diagonal
      }

      let context = canvas.getContext('2d')
      context.translate(canvas.width / 2, canvas.height / 2)
      context.rotate(rotate * Math.PI / 180)
      context.drawImage(this, (-this.width / 2), (-this.height / 2))

      resolve(canvas)
    }
  })
}