export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/official_document_send/handle/my_org',
      method: 'GET'
    },
    {
      path: '/basis/official_document_send',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/ucenter/user',
      method: 'GET'
    }]
  },
  assign: {
    funKey: 'assign',
    name: '下发',
    apis: [{
      path: '/basis/official_document_send/assign/org/{id}',
      method: 'PUT'
    }]
  },
  signin: {
    funKey: 'signin',
    name: '签收',
    apis: [{
      path: '/basis/official_document_send/handle/{id}',
      method: 'POST'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '文件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取文件'
  },
}