// import {
//   dateOperating
// } from 'fmlib'
import {XDate} from '../FmDatePicker/lib'

// 最佳入库比例
const optimalStorageRatio = 0.8

// 每天工作时长
const workHours = 13

export const hourLong = 1000 * 60 * 60

export const calculateArriveTimeByVolume = (hourStatus, canChoosePeriod, volume) => {
  volume = volume || 1
  let arriveTime = null
  // 可用小时状态列表
  let canChooseHourStatus = getCanChooseHourStatus(hourStatus, canChoosePeriod)
  if (canChooseHourStatus.length === 0) {
    return arriveTime
  }
  // 待选到达时间
  let minRate = null
  canChooseHourStatus.forEach((item) => {
    let rate = volume / item.emptyInventory - optimalStorageRatio
    rate = rate * rate
    minRate = minRate || (rate + 1)
    if (!(rate > minRate)) {
      minRate = rate
      arriveTime = item.currentTime
    }
  })
  return arriveTime
}

export const calculateVolumeByArriveTime = (hourStatus, arriveTime) => {
  arriveTime = new Date(arriveTime).getTime()
  // 默认为0
  let emptyInventory = 0
  let lastArriveTime = 0
  hourStatus.forEach((item) => {
    let currentTimeTimeNum = item.currentTime.getTime()
    if (lastArriveTime < arriveTime && (currentTimeTimeNum > arriveTime || currentTimeTimeNum === arriveTime)) {
      emptyInventory = item.emptyInventory
    }
    lastArriveTime = currentTimeTimeNum
  })
  return parseInt(emptyInventory * optimalStorageRatio)
}

export const reCalculationHourStatus = (hourStatus, removeOrderIds, stopPumpCapacity, validCapacity) => {
  let nextHourData = null
  let hasRemove = false
  hourStatus.forEach((item) => {
    let hourHasRemove = false
    let newOrder = []
    let newInVolume = 0
    item.inOrder.forEach((itemOrder) => {
      let key = itemOrder.id
      if (removeOrderIds.includes(key)) {
        hourHasRemove = true
      } else {
        newOrder.push(itemOrder)
        newInVolume += itemOrder.volume
      }
    })
    hasRemove = hourHasRemove || hasRemove
    if (hourHasRemove) {
      item.inOrder = newOrder
      item.inVolume = newInVolume
    }
    if (item.inventory === null) {
      hasRemove = false
    }
    if (nextHourData && nextHourData.inventory && hasRemove) {
      item.inventory = nextHourData.inventory + item.inVolume - item.outVolume
      item.inventory = item.inventory > stopPumpCapacity ? item.inventory : stopPumpCapacity
      item.emptyInventory = validCapacity - item.inventory
    }
    nextHourData = item
  })
  return hourStatus
}

export const reCalculationHourStatusByReal = (hourStatus, removeOrderIds, stopPumpCapacity, validCapacity) => {
  hourStatus = reCalculationHourStatus(hourStatus, removeOrderIds, stopPumpCapacity, validCapacity)
  let nextHourData = null
  hourStatus.forEach((item, index) => {
    if (item.realCurrentInventory) {
      item.currentInventory = null
    }
    item.pCurrentInventory = null
    if (item.realCurrentInventory && index < (hourStatus.length - 1) && !hourStatus[index + 1].realCurrentInventory) {
      item.currentInventory = item.realCurrentInventory
    }
    if (nextHourData && !item.realCurrentInventory) {
      item.currentInventory = nextHourData + item.inVolume - item.outVolume
      item.currentInventory = item.currentInventory > stopPumpCapacity ? item.currentInventory : stopPumpCapacity
      item.emptyInventory = validCapacity - item.currentInventory
    }
    nextHourData = item.realCurrentInventory || item.currentInventory
  })
  return hourStatus
}

// 参数说明： 开始库存 有效容积 停泵库存 订单 预测销量 小时销售百分比 开始时间(Date) 结束时间(Date)
export const getHourStatus = ({startInventory, validCapacity, stopPumpCapacity, order, preSales, hourSalesRate, dataStartTime, dataEndTime}) => {
  let hourStatus = []
  // 开始时间小时数
  let startHour = dataStartTime.getHours()
  // 开始时间时间戳
  let startTime = dataStartTime.getTime()
  // 结束时间时间戳
  let endTime = dataEndTime.getTime()
  // 获取从开始时间到结束时间的小时列表 时间戳列表
  let hourList = getTimePointList(startTime, endTime, [], hourLong)
  // 前一节点库存量
  let lastInventory = startInventory
  hourList.forEach((item, index) => {
    // 前一节点时间戳
    let lastTime = index > 0 ? hourList[index - 1] : item
    // 出库升数 !!!周期不是一个小时以后需要调整修改
    let outVolume = index !== 0 ? parseInt(preSales * hourSalesRate[(startHour + index + 23) % 24] / 100) : 0
    // 入库升数
    let inVolume = 0
    let inOrder = []
    order.forEach((orderItem) => {
      let arriveTime = orderItem.arriveTime.getTime()
      // 如果订单到达时间大于上一时间点 小于或等于当前时间点 加入入库升数
      if (arriveTime > lastTime && (arriveTime < item || arriveTime === item)) {
        inVolume += orderItem.volume
        inOrder.push(orderItem)
      }
    })
    // 当前库存量 = 上一节点库存量 - 出库升数 + 入库升数
    let currentInventory = parseInt(lastInventory - outVolume + inVolume)
    // 库存最小为stopPumpCapacity
    currentInventory = currentInventory > stopPumpCapacity ? currentInventory : stopPumpCapacity
    // 空容 = 有效容积 - 当前库存
    let emptyInventory = validCapacity - currentInventory
    // 最大空容
    let maxEmptyInventory = validCapacity - stopPumpCapacity
    emptyInventory = emptyInventory < 0 ? 0 : (emptyInventory > maxEmptyInventory ? maxEmptyInventory : emptyInventory)
    let status = currentInventory < stopPumpCapacity ? 'low' : (currentInventory > validCapacity ? 'high' : 'normal')
    hourStatus.push({
      currentTime: new Date(item),
      inVolume,
      outVolume,
      emptyInventory,
      currentInventory,
      status,
      inOrder: inOrder
    })
    lastInventory = currentInventory
  })
  return hourStatus
}
// 参数说明： 最大有效容积、每日状态数据、小时销量分布，小时入库量分布
export const getHourStatusManyTank = ({dayStatus, planVolumeHourInFb, saleHourFb, realKc}) => {
  let dayStatusMap = {}
  dayStatus.forEach((item) => {
    dayStatusMap[item.day] = item
  })
  let hourStatus = []
  let lastInventory = null
  realKc.forEach((item) => {
    let inVolume = null
    let outVolume = null
    let isEndInventory = 0
    let currentInventory = null
    let pCurrentInventory = null
    let realCurrentInventory = item.realInventory || null

    let hourNum = Number(item['hourTime'].slice(11, 13))

    let i = -2
    while (i < 1) {
      // let dayStatusMapItem = dayStatusMap[dateOperating.computeDay({date: item['hourTime'].slice(0, 10), days: i, format: 'yy-mm-dd'})]
      let dayStatusMapItem = dayStatusMap[new XDate(item['hourTime'].slice(0, 10)).compute(i).format('Y-M-D')]
      if (dayStatusMapItem) {
        inVolume += (dayStatusMapItem['planVolume'] || 0) * (planVolumeHourInFb[hourNum - i * 24] || 0)
        if (i === 0) {
          outVolume = dayStatusMapItem.saleVolume * saleHourFb[hourNum]
          if (dayStatusMapItem.startData && dayStatusMapItem.startTime === item['hourTime']) {
            pCurrentInventory = dayStatusMapItem.startVolume
          }
        }
      }
      i = i + 1
    }
    if (lastInventory !== null) {
      currentInventory = lastInventory - (outVolume || 0) + (inVolume || 0)
      currentInventory = currentInventory < 0 ? 0 : currentInventory
    }
    if (pCurrentInventory !== null) {
      let zhData = pCurrentInventory
      pCurrentInventory = currentInventory
      currentInventory = zhData
      isEndInventory = 1
    }

    let dataItem = {
      currentTime: item['hourTime'],
      inVolume: inVolume !== null ? Number(inVolume.toFixed(1)) : null,
      outVolume: outVolume !== null ? Number(outVolume.toFixed(1)) : null,
      isEndInventory,
      pCurrentInventory: pCurrentInventory !== null ? Number(pCurrentInventory.toFixed(1)) : null,
      currentInventory: currentInventory !== null ? Number(currentInventory.toFixed(1)) : null,
      status,
      realCurrentInventory: realCurrentInventory !== null ? Number(realCurrentInventory.toFixed(1)) : null,
      inOrder: []
    }
    hourStatus.push(dataItem)
    lastInventory = currentInventory
  })
  return hourStatus
}

export const dealMark = (minValue, maxValue, startTime, endTime) => {
  // 上下分割线
  let intervalLine = []
  let areaList = []
  if (!isNaN(minValue)) {
    intervalLine.push({
      yAxis: minValue,
      label: {
        normal: {
          color: '#828c95',
          show: true,
          formatter: '工作库存\n' + minValue + ' L'
        },
        emphasis: {
          color: '#828c95',
          show: true,
          formatter: '工作库存\n' + minValue + ' L'
        }
      }
    })
  }
  if (!isNaN(maxValue)) {
    intervalLine.push({
      yAxis: maxValue,
      label: {
        normal: {
          color: '#828c95',
          show: true,
          formatter: '有效容积\n' + maxValue + ' L'
        },
        emphasis: {
          color: '#828c95',
          show: true,
          formatter: '有效容积\n' + maxValue + ' L'
        }
      }
    })
  }
  if (startTime && endTime && !isNaN(maxValue)) {
    areaList.push([{
      coord: [startTime, maxValue],
      itemStyle: {
        color: 'rgba(0, 0, 0, 0.2)',
        borderColor: 'rgba(0, 0, 0, 0.2)'
      }
    },
    {
      coord: [endTime, 0]
    }])
  }
  return {lineList: intervalLine, areaList: areaList}
}

export const dealData = (hourStatus, maxValue) => {
  // 预测线
  let preLineData = {
    data: [],
    name: '预测库存走势',
    lineStyle: {
      normal: {
        color: '#67C23A'
      }
    }
  }

  // 实际线
  let realLineData = {
    data: [],
    name: '实际库存走势',
    lineStyle: {
      normal: {
        color: '#0075c7'
      }
    }
  }

  let maxLineValue = maxValue || 0
  let beforeData = null
  hourStatus.forEach((item) => {
    maxLineValue = item.inventory > maxLineValue ? item.inventory : maxLineValue
    if (item.type === 'stock') {
      realLineData.data.push({name: item.time, value: [item.time, item.inventory], data: item})
    } else {
      if (beforeData && ['daily', 'stock'].includes(beforeData.type)) {
        preLineData.data.push({name: beforeData.time, value: [beforeData.time, beforeData.inventory]})
      }
      if (beforeData && ['daily', 'stock'].includes(item.type)) {
        preLineData.data.push({name: item.time, value: null})
      }
      preLineData.data.push({name: item.time, value: [item.time, item.inventory], data: item})
    }
    beforeData = item
  })
  return {lineList: [preLineData, realLineData], maxLineValue}
}

export const getTimePointList = (currentTime, endTime, pointList, cycle) => {
  if (!(currentTime > endTime)) {
    pointList.push(currentTime)
    return getTimePointList(currentTime + cycle, endTime, pointList, cycle)
  } else {
    return pointList
  }
}

export const dealOrder = ({piOrder, distributionOrder, routerOrder}) => {
  let orders = []
  piOrder.forEach((item) => {
    orders.push({
      // arriveTime: dateOperating.formatDate({date: item.arriveTime}),
      arriveTime: new XDate(item.arriveTime).getTime(),
      volume: item.volume,
      type: 'pi'
    })
  })
  distributionOrder.forEach((item) => {
    orders.push({
      // arriveTime: dateOperating.formatDate({date: item.arriveTime}),
      arriveTime: new XDate(item.arriveTime).getTime(),
      volume: item.volume,
      type: 'way'
    })
  })
  routerOrder.forEach((item) => {
    orders.push({
      // arriveTime: dateOperating.formatDate({date: item.arriveTime}),
      arriveTime: new XDate(item.arriveTime).getTime(),
      volume: item.volume,
      type: 'way'
    })
  })
  return orders
}

// 检查数据是否完整
export const checkGasTankData = (data) => {
  let checkResult = {
    pass: true,
    msg: ''
  }
  if (data.validCapacity < 0 || data.validCapacity === 0) {
    checkResult.pass = false
    checkResult.msg += '有效容积小于等于0，'
  }
  if (data.stopPumpCapacity < 0) {
    checkResult.pass = false
    checkResult.msg += '停泵库存小于0，'
  }
  if (data.preSales < 0) {
    checkResult.pass = false
    checkResult.msg += '平均销量小于0，'
  }
  if (data.startInventory < 0) {
    checkResult.pass = false
    checkResult.msg += '期初库存小于0，'
  }
  if (data.wayHour < 0) {
    checkResult.pass = false
    checkResult.msg += '在途时间小于0，'
  }
  if (!data.collectTime) {
    checkResult.pass = false
    checkResult.msg += '采集时间为空，'
  }
  checkResult.meg = checkResult.msg.slice(0, -1)
  return checkResult
}

export const getDataPeriod = (gasDailyCollectTime, piDate, wayHour) => {
  // 取整点数据
  // let dataStartTime = dateOperating.formatDate({date: dateOperating.formatDate({date: gasDailyCollectTime, format: 'yy-mm-dd hh:mm'}).slice(0, 14) + '00'})
  let dataStartTime = new XDate(new XDate(gasDailyCollectTime).format('Y-M-D H:I:00')).getTime()
  // 在途时间 + 配送时间 + 一天
  // let dataEndTime = dateOperating.computeDay({date: piDate, days: parseInt(wayHour / workHours) + 3})
  let dataEndTime = new XDate(piDate).compute('D', parseInt(wayHour / workHours) + 3).getTime()
  return {dataStartTime, dataEndTime}
}

// 获取可选择送达时间段
export const getCanPeratePeriod = (piDate, wayHour) => {
  // 配送时间是明天的8点到23那到达时间
  // 在途时间 + 配送时间 + 一天
  // 最早到达日期
  // let perateStartDay = dateOperating.computeDay({date: piDate, days: parseInt(wayHour / workHours)})
  let perateStartDay = new XDate(piDate).compute('D', parseInt(wayHour / workHours)).getTime()
  // 到达那天还要开几个小时
  let startDayWorkHours = wayHour % workHours
  // 最早到达时间
  let perateStartTime = new Date(perateStartDay.getTime() + (8 + startDayWorkHours) * hourLong)
  // 最晚到达时间
  // let perateEndTime = dateOperating.computeDay({date: perateStartTime, days: 1})
  let perateEndTime = new XDate(perateStartTime).compute('D', 1).getTime()
  return {perateStartTime, perateEndTime}
}

export const getCanChooseHourStatus = (hourStatus, canChoosePeriod) => {
  // 可用小时状态列表
  let canChooseHourStatus = []
  let waitCanChooseHourStatus = null
  hourStatus.forEach((item) => {
    if (item.currentTime.getTime() > canChoosePeriod.canChooseStartTime.getTime() && item.currentTime.getTime() < canChoosePeriod.canChooseEndTime.getTime()) {
      canChooseHourStatus.push(item)
    }
    if (item.currentTime.getTime() < canChoosePeriod.canChooseEndTime.getTime()) {
      waitCanChooseHourStatus = item
    }
  })
  canChooseHourStatus = canChooseHourStatus.length === 0 && waitCanChooseHourStatus ? [waitCanChooseHourStatus] : canChooseHourStatus
  return canChooseHourStatus
}

// 获取piorder可选时间 orderList 必须安时间排序
export const getCanChoosePeriod = (canPeratePeriod, orderList, orderIndex) => {
  let canChooseStartTime = canPeratePeriod.perateStartTime
  let canChooseEndTime = canPeratePeriod.perateEndTime
  if (orderList[orderIndex - 1]) {
    canChooseStartTime = new Date(orderList[orderIndex - 1].arriveTime)
  }
  if (orderList[orderIndex + 1]) {
    canChooseEndTime = new Date(orderList[orderIndex + 1].arriveTime)
  }
  return {canChooseStartTime, canChooseEndTime}
}
