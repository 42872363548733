import roles from '../roles'
const allRoles = roles.map(v => v.code)

export default [
  {
    'icon': 'icon-gongwen',
    'clientType': 'app',
    'roles': allRoles.map(v => {
      return { [v]: ['getMyDoc', 'getDetail', 'saveDetails', 'putDetails'] }
    }),
    'name': '我的公文',
    'url': '/pages/doc/index'
  },
  {
    'icon': 'icon-gongwenguanli',
    'clientType': 'app',
    'roles': [
      { 'rs': ['manage'] }
    ],
    'name': '公文管理',
    'url': '/pages/doc/manage'
  },
  {
    'icon': 'icon-gongwenchuli',
    'clientType': 'app',
    'roles': [],
    'name': '发文管理',
    'url': '/pages/doc/issued/index?type=manage'
  },
  {
    'icon': 'icon-fagongwen',
    'clientType': 'app',
    'roles': [],
    'name': '发文申请与审批',
    'url': '/pages/doc/issued/index?type=approve'
  },
  {
    'icon': 'icon-shougongwen',
    'clientType': 'app',
    'roles': [],
    'name': '发文签收',
    'url': '/pages/doc/issued/index?type=sign'
  },
  {
    'icon': 'icon-huiyi',
    'clientType': 'app',
    'roles': allRoles.map(v => {
      return { [v]: ['getMyDoc', 'getDetail', 'saveDetails', 'putDetails'] }
    }),
    'name': '会议文件处理',
    'url': '/pages/meeting/file/index'
  },
  {
    'icon': 'icon-huiyiguanli',
    'clientType': 'app',
    'roles': [
      { 'rs': ['manage'] }
    ],
    'name': '会议文件管理',
    'url': '/pages/meeting/file/manage'
  },
  {
    'icon': 'icon-qingjia',
    'clientType': 'app',
    'roles': allRoles.map(v => {
      return { [v]: ['upload', 'delFile', 'file', 'my'] }
    }),
    'name': '我的请假',
    'url': '/pages/vacate/index?type=my'
  },
  {
    'icon': 'icon-renwufenpei',
    'clientType': 'app',
    'roles': [
      { 'rs': ['distribution', 'file'] }
    ],
    'name': '请假条分配',
    'url': '/pages/vacate/index?type=distribution'
  },
  {
    'icon': 'icon-xiaojiashenpi',
    'clientType': 'app',
    'roles': [
      { 'rs': ['terminate', 'file'] }
    ],
    'name': '员工销假',
    'url': '/pages/vacate/record?type=terminate'
  },
  {
    'icon': 'icon-qingjiashenpi',
    'clientType': 'app',
    'roles': ['yz', 'fyz', 'kz', 'zr'].map(v => {
      return { [v]: ['approval', 'file'] }
    }),
    'name': '请假条审批',
    'url': '/pages/vacate/index?type=approval'
  },
  {
    'icon': 'icon-jiashiyuanfenxi',
    'clientType': 'app',
    'name': '个人信息',
    'url': '/pages/worker/my'
  },
  {
    'icon': 'icon-kaoheguanli',
    'clientType': 'app',
    'name': '考核管理',
    'url': '/pages/appraisal/manage'
  },
  {
    'icon': 'icon-shiyanshikaohe',
    'clientType': 'app',
    'name': '我的考核',
    'url': '/pages/appraisal/my/index'
  },
  {
    icon: 'icon-kaoheguanli',
    clientType: 'app',
    name: '信息核对',
    url: '/pages/worker/confirm'
  }
]