// 资产管理
export default {
  get: {
    funKey: 'get',
    name: '获取资产',
    apis: [
      { path: '/internalcontrol/assets', method: 'GET' }
    ]
  },
  del: {
    funKey: 'del',
    name: '删除资产',
    apis: [
      { path: '/internalcontrol/assets/{id}', method: 'DELETE' }
    ]
  },
  update: {
    funKey: 'update',
    name: '修改资产',
    apis: [
      { path: '/internalcontrol/assets/{id}', method: 'PUT' },
      { path: '/ucenter/user', method: 'GET' },
      { path: '/ucenter/org/all', method: 'GET' },
      { path: '/internalcontrol/goods', method: 'GET' }
    ]
  },
  add: {
    funKey: 'add',
    name: '新增资产',
    apis: [
      { path: '/internalcontrol/assets', method: 'POST' },
      { path: '/ucenter/user', method: 'GET' },
      { path: '/ucenter/org/all', method: 'GET' },
      { path: '/internalcontrol/goods', method: 'GET' }
    ]
  },
  scanRecord: {
    funKey: 'scanRecord',
    name: '盘点入库',
    apis: [
      { path: '/internalcontrol/scan_record', method: 'POST' }
    ]
  },
}