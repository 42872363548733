import {
  worker,
  activity,
  askLeave,
  base,
  appraisal,
  doc,
  issues,
  count,
  resume,
  report,
  analysis,
  evaluation,
  article,
  performance
} from './children'

export default [
  {
    path: 'person',
    name: 'person.index',
    component: () => import('../views/person/index')
  },
  {
    path: 'worker',
    name: 'worker',
    component: () => import('../views/worker/index'),
    children: worker
  },
  {
    path: 'activity',
    name: 'activity',
    component: () => import('../views/activity'),
    children: activity
  },
  {
    path: 'article',
    name: 'article',
    component: () => import('../views/article'),
    children: article
  },
  {
    path: 'ask_leave',
    name: 'ask_leave',
    component: () => import('../views/askleave'),
    children: askLeave
  },
  {
    path: 'base',
    name: 'base',
    component: () => import('../views/base'),
    children: base
  },
  {
    path: 'appraisal',
    name: 'appraisal',
    component: () => import('../views/appraisal'),
    children: appraisal
  },
  {
    path: 'performance',
    name: 'performance',
    component: () => import('../views/performance'),
    children: performance
  },
  {
    path: 'doc',
    name: 'doc',
    component: () => import('../views/doc/index'),
    children: doc
  },
  {
    path: 'issues',
    name: 'issues',
    component: () => import('../views/issues/index'),
    children: issues
  },
  {
    path: 'count',
    name: 'count',
    component: () => import('../views/count/index'),
    children: count
  },
  {
    path: 'resume',
    name: 'resume',
    component: () => import('../views/resume/router'),
    children: resume
  },
  {
    path: 'report',
    name: 'report',
    component: () => import('../views/report/router'),
    children: report
  },
  {
    path: 'analysis',
    name: 'analysis',
    component: () => import('../views/analysis/router'),
    children: analysis
  },
  {
    path: 'evaluation',
    name: 'evaluation',
    component: () => import('../views/evaluation/router'),
    children: evaluation
  },
  {
    path: 'salary/cop',
    name: 'salary.cop',
    component: () => import('../views/salary/cop/router'),
    children: [
      {
        path: 'worker',
        name: 'salary.cop.worker',
        component: () => import('../views/salary/cop/worker')
      },
      {
        path: 'official',
        name: 'salary.cop.official',
        component: () => import('../views/salary/cop/table'),
        props: { type: 1 }
      },
      {
        path: 'auxiliary',
        name: 'salary.cop.auxiliary',
        component: () => import('../views/salary/cop/table'),
        props: { type: 2 }
      },
      {
        path: 'soldier',
        name: 'salary.cop.soldier',
        component: () => import('../views/salary/cop/table'),
        props: { type: 3 }
      },
      {
        path: 'my',
        name: 'salary.cop.my',
        component: () => import('../views/salary/cop/my')
      },
      {
        path: 'config',
        name: 'salary.cop.config',
        component: () => import('../views/salary/cop/config')
      }
    ]
  }
]
