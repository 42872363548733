/* eslint-disable */
function numTo (el, {value}) {
  el.dataset.timer && clearTimeout(el.dataset.timer)
  const precision = el.dataset.precision || 2
  const index = ~~el.dataset.index
  const step = Math.max((value - parseFloat(el.innerText.replace(/[^\d\.]/g, '') || 0)) / 30, 1);
  value = value === undefined ? 0 : Number(value)
  if (process.env.NODE_ENV === 'development') {
    el.innerText = toThousands(value.toFixed(precision));
  } else {
    el.dataset.timer = setTimeout(() => {
      (function inc () {
        const current = parseFloat(el.innerText.replace(/[^\d\.]/g, '') || 0);
        // const current = parseFloat(el.innerText || 0);
        if (current < value) {
          el.innerText = ((current + step).toFixed(precision));
          requestAnimationFrame(inc);
        } else {
          el.innerText = toThousands(value.toFixed(precision));
        }
      })();
    }, ([1, 3, 6, 9, 2, 5, 8, 1, 4][index] * 100) + 0)
  }
}

function toThousands (str) {
  let [num, precision] = String(str).split('.')
    num = (num || 0).toString()
    let result = ''
    while (num.length > 3) {
        result = ',' + num.slice(-3) + result
        num = num.slice(0, num.length - 3)
    }
    if (num) { result = num + result }
    return result + (!precision ? '' : '.' + precision)
}

export default {
    inserted: numTo,
    update: numTo
}
