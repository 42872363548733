import {
  getFUn
} from '../auth_lib'

export default {
  ...getFUn('train'),
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/basis/train',
      method: 'POST'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/basis/train/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/train/{id}',
      method: 'DELETE'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '材料'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取材料'
  },
  updateDataFile: {
    funKey: 'updateDataFile',
    name: '上传材料',
    apis: [{
      path: '/basis/train/{id}',
      method: 'PUT'
    }]
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载材料'
  },
  delDataFile: {
    funKey: 'delDataFile',
    name: '删除材料',
    apis: [{
      path: '/basis/train/{id}',
      method: 'PUT'
    }]
  }
}