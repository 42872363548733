<template>
  <div class="fm-pagination">
    <span class="total-num">共{{total}}条</span>
    <a href="javascript:;" class="page-item page-item-w" @click="choosePage(1)">《 首页</a>
    <a href="javascript:;" class="page-item" @click="choosePage(page)" :class="{'page-item-active': cPage === page}" v-for="page in showPageList" :key="page">{{page}}</a>
    <a href="javascript:;" class="page-item page-item-w" @click="choosePage(pageList[pageList.length - 1])">末页 》</a>
    <div class="jump-page">
      <span>跳至</span>
      <input type="number" v-model="inputPage" @blur="inputPageOver" @keyup.enter="inputPageOver">
      <span>页</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmPagination',
  props: {
    maxShowPageSize: {
      type: Number,
      default: 6
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 1
    },
    currentPage: {
      type: Number,
      default: 6
    }
  },
  data () {
    return {
      cPage: 0,
      inputPage: null
    }
  },
  created() {
    this.cPage = this.currentPage
  },
  watch: {
    currentPage (value) {
      this.cPage = value
    }
  },
  computed: {
    showPageList: {
      get () {
        let startIndex = (this.cPage - 1) - Math.ceil(this.maxShowPageSize / 2)
        startIndex = startIndex < 0 ? 0 : startIndex
        let endIndex = this.maxShowPageSize + startIndex
        endIndex = endIndex > this.pageList.length ? this.pageList.length : endIndex
        if ((endIndex - startIndex) < this.maxShowPageSize) {
          startIndex = endIndex - this.maxShowPageSize
          startIndex = startIndex < 0 ? 0 : startIndex
        }
        return this.pageList.slice(startIndex, endIndex)
      }
    },
    pageList: {
      get () {
        let data = []
        let pageLength = Math.ceil(this.total / this.pageSize)
        while (pageLength > 0) {
          data = [pageLength].concat(data)
          pageLength -= 1
        }
        return data
      }
    }
  },
  methods: {
    inputPageOver () {
      this.inputPage = Number(this.inputPage)
      if (this.pageList.includes(this.inputPage)) {
        this.choosePage(this.inputPage)
      } else {
        this.inputPage = null
      }
    },
    choosePage (page) {
      if (this.cPage !== page) {
        this.cPage = page
        this.$emit('currentPageChange', this.cPage)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './styles/values.less';
.total-num {
  margin-right: 0.6rem;
}
.page-item {
  border: @size-border solid @color-border;
  border-radius: @size-border-radius;
  height: 2rem;
  width: 2rem;
  margin: 0 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-item-w {
  width: 4.5rem;
  margin: 0 0.4rem;
}
.page-item-active {
  background: #dcdddd;
}
.fm-pagination {
  font-size: @size-font-mini;
  justify-content: center;
  display: flex;
  align-items: center;
  color: @color-content-text;
  a {
    color: @color-content-text;
    font-weight: 400;
  }
}
.jump-page {
  margin-left: 1rem;
  input {
    margin: 0 0.6rem;
    height: 2rem;
    width: 2rem;
    border: @size-border solid @color-border;
    border-radius: @size-border-radius;
    padding: 0 0.3rem;
    color: @color-content-text;
  }
}
</style>
