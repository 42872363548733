import assetsManage from './assetsManage'
import assetsCheck from './assetsCheck'
import assetsIn from './assetsIn'
import assetsOut from './assetsOut'
import assetsRepair from './assetsRepair'
import assetsRepairApply from './assetsRepairApply'
import assetsRepairApplyRecord from './assetsRepairApplyRecord'
import assetsScrapApply from './assetsScrapApply'
import assetsScrapApplyRecord from './assetsScrapApplyRecord'

import assetsType from './assetsType'
import goods from './goods'
import budgetManage from './budget_manage'
import budget from './budget'
import purchase from './purchase'
import purchaseContract from './purchaseContract'
import purchaseContractEndWarning from './purchaseContractEndWarning'
import purchaseContractWarrantyWarning from './purchaseContractWarrantyWarning'
import purchaseImplement from './purchaseImplement'
import home from './home'
import sysConfig from './sys_config'

import purchaseBatch from './purchaseBatch'
import purchaseBatchMy from './purchaseBatchMy'
import purchaseDetail from './purchase_detail'
import purchaseHome from './purchase_home'
import purchaseInfo from './purchase_info'

import purchaseMethodHome from './purchase_method_home'
import purchaseMethodInfo from './purchase_method_info'
import purchaseMethodDetail from './purchase_method_detail'
import purchaseMethodBatchManage from './purchaseMethodBatchManage'
import purchaseMethodBatch from './purchaseMethodBatch'

import bill from './bill'
import accept from './accept'
import acceptMy from './acceptMy'

import pdaAssets from './pda/assets'
import pdaInventory from './pda/inventory'
import pdaInvoice from './pda/invoice'
import pdaPandian from './pda/pandian'
import pdaRepair from './pda/repair'

import appBudget from './app/budget'
import appPurchase from './app/purchase'
import appPurchaseWay from './app/purchaseWay'
import budgetDetail from './budget_detail'
import budgetHome from './budget_home'
import budgetInfo from './budget_info'

export default [{
  routerNames: ['home'],
  funs: home
},
{
  routerNames: ['accept.manage'],
  funs: accept
},
{
  routerNames: ['accept.my'],
  funs: acceptMy
},
{
  routerNames: ['purchase_method.home'],
  funs: purchaseMethodHome
},
{
  routerNames: ['purchase_method.batch_detail'],
  funs: purchaseMethodInfo
},
{
  routerNames: ['purchase_method.detail_list'],
  funs: purchaseMethodDetail
},
{
  routerNames: ['purchase_method.index'],
  funs: purchaseMethodBatchManage
},
{
  routerNames: ['purchase_method.handle'],
  funs: purchaseMethodBatch
},
{
  routerNames: ['purchase.index'],
  funs: purchaseBatch
},
{
  routerNames: ['purchase.handle'],
  funs: purchaseBatchMy
},
{
  routerNames: ['purchase.home'],
  funs: purchaseHome
},
{
  routerNames: ['purchase.batch_detail'],
  funs: purchaseInfo
},
{
  routerNames: ['purchase.detail_list'],
  funs: purchaseDetail
},
{
  routerNames: ['budget.manage'],
  funs: budgetManage
},
{
  routerNames: ['budget.detail_list'],
  funs: budgetDetail
},
{
  routerNames: ['budget.home'],
  funs: budgetHome
},
{
  routerNames: ['budget.batch_detail'],
  funs: budgetInfo
},
{
  routerNames: ['budget.handle'],
  funs: budget
},
{
  routerNames: ['purchase.contract'],
  funs: purchaseContract
},
{
  routerNames: ['purchase.contract_end_warning'],
  funs: purchaseContractEndWarning
},
{
  routerNames: ['purchase.contract_warranty_warning'],
  funs: purchaseContractWarrantyWarning
},
{
  routerNames: ['purchase.implement'],
  funs: purchaseImplement
},
{
  routerNames: ['budget.purchase'],
  funs: purchase
},
{
  routerNames: ['assets.manage', 'assets.manage_gy', 'assets.manage_dw', '/pages/assets/index'],
  funs: assetsManage
},
{
  routerNames: ['assets.check', '/pages/pandian/index'],
  funs: assetsCheck
},
{
  routerNames: ['assets.in', '/pages/inventory/index'],
  funs: assetsIn
},
{
  routerNames: ['assets.out'],
  funs: assetsOut
},
{
  routerNames: ['repair.manage', 'assets.repair', '/pages/repair/index', '/pages/repair/manage'],
  funs: assetsRepair
},
{
  routerNames: ['repair.apply', '/pages/repair/apply', '/pages/repair/apply/index'],
  funs: assetsRepairApply
},
{
  routerNames: ['repair.apply_record', '/pages/repair/apply/record'],
  funs: assetsRepairApplyRecord
},
{
  routerNames: ['scrap.apply', '/pages/scrap/index'],
  funs: assetsScrapApply
},
{
  routerNames: ['scrap.record', '/pages/scrap/record'],
  funs: assetsScrapApplyRecord
},
{
  routerNames: ['base.assets_type'],
  funs: assetsType
},
{
  routerNames: ['budget.budget'],
  funs: budget
},
{
  routerNames: ['base.goods'],
  funs: goods
},
{
  routerNames: ['base.bill'],
  funs: bill
},
{
  routerNames: ['base.status'],
  funs: {}
},
{
  routerNames: ['base.bill_check_times'],
  funs: {}
},
{
  routerNames: ['base.config'],
  funs: sysConfig
}, {
  routerNames: ['/pages/assets/index'],
  funs: pdaAssets
}, {
  routerNames: ['/pages/pandian/index'],
  funs: pdaPandian
}, {
  routerNames: ['/pages/inventory/index'],
  funs: pdaInventory
}, {
  routerNames: ['/pages/repair/index'],
  funs: pdaRepair
}, {
  routerNames: ['/pages/invoice/index'],
  funs: pdaInvoice
}, {
  routerNames: ['/pages/budget/index?type=handler', '/pages/budget/index?type=manage'],
  funs: appBudget
}, {
  routerNames: ['/pages/purchase/index?type=handler', '/pages/purchase/index?type=manage'],
  funs: appPurchase
}, {
  routerNames: ['/pages/purchase/way/index?type=handler', '/pages/purchase/way/index?type=manage'],
  funs: appPurchaseWay
}]
