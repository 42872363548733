<style scoped></style>

<template>
  <div>
    <h2>标签(fm-tag)</h2>
    <div>
      <fm-tag>标签</fm-tag>
    </div>
    <h2>尺寸(<fm-tag>size</fm-tag>)</h2>
    <div>
      <fm-tag size="norm">norm</fm-tag>
      <fm-tag size="large">large</fm-tag>
      <fm-tag size="small">small</fm-tag>
      <fm-tag size="mini">mini</fm-tag>
    </div>
    <h2>可关闭(<fm-tag>closable</fm-tag>)</h2>
    <div>
      <fm-tag closable @close="show = false" v-if="show">关闭</fm-tag>
    </div>
    <h2>动态添加和删除</h2>
    <div>
      <fm-input-new placeholder="输入名称回车确认" v-model="val" @enten="onAdd"></fm-input-new>
      <fm-tag v-for="(tag, i) in list" :key="tag + i" closable @close="list.splice(i, 1)">{{tag}}</fm-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmTagDemo',
  data () {
    return {
      show: true,
      val: null,
      list: []
    }
  },
  methods: {
    onAdd () {
      this.list.push(this.val)
      this.val = null
    }
  }
}
</script>
