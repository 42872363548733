export default {
  base: {
    funKey: 'base',
    name: '基础功能',
    apis: [{
      path: '/basis/count_group',
      method: 'GET'
    },
    {
      path: '/basis/count_worker',
      method: 'GET'
    }]
  }
}
