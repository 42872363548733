export default {
  base: {
    funKey: 'base',
    name: '基础',
    apis: [{
      path: '/basis/worker/batch',
      method: 'POST'
    },
    {
      path: '/ucenter/user_org_role',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all_tree',
      method: 'GET'
    },
    {
      path: '/ucenter/user_org_role',
      method: 'POST'
    },
    {
      path: '/ucenter/user_org_role/{id}',
      method: 'DELETE'
    }]
  }
}