export default [
  {
    path: 'screen',
    name: 'screen',
    component: () => import('../views/appraisal/screen')
  },
  {
    path: 'wap',
    name: 'wap',
    component: () => import('../views/wap/index'),
    children: [
      {
        path: 'menu',
        name: 'wap.menu',
        component: () => import('../views/wap/menu'),
      },
      {
        path: 'salary',
        name: 'wap.salary',
        component: () => import('../views/wap/salary'),
      },
      {
        path: 'salary_tongcheng',
        name: 'wap.salary_tongcheng',
        component: () => import('../views/wap/salaryTongcheng'),
      },
      {
        path: 'checking',
        name: 'wap.checking',
        component: () => import('../views/appraisal/checking'),
      },
      {
        path: 'my',
        name: 'wap.my',
        component: () => import('../views/appraisal/my'),
      },
      {
        path: 'question',
        name: 'wap.question',
        component: () => import('../views/appraisal/question'),
      },
      {
        path: 'vote',
        name: 'wap.vote',
        component: () => import('../views/appraisal/vote'),
      },
      {
        path: 'all_info',
        name: 'wap.all_info',
        component: () => import('../views/wap/allInfo'),
      },
      {
        path: 'worker_work',
        name: 'wap.worker_work',
        component: () => import('../views/wap/worker/workerDataWork'),
      },
      {
        path: 'worker_base',
        name: 'wap.worker_base',
        component: () => import('../views/wap/worker/workerDataBase'),
      },
      {
        path: 'edu',
        name: 'wap.edu',
        component: () => import('../views/wap/worker/eduData'),
      },
      {
        path: 'his_work',
        name: 'wap.his_work',
        component: () => import('../views/wap/worker/hisWorkData'),
      },
      {
        path: 'job_title',
        name: 'wap.job_title',
        component: () => import('../views/wap/worker/jobTitleData'),
      },
      {
        path: 'rew',
        name: 'wap.rew',
        component: () => import('../views/wap/worker/rew'),
      },
      {
        path: 'train',
        name: 'wap.train',
        component: () => import('../views/wap/worker/train'),
      },
      {
        path: 'certificate',
        name: 'wap.certificate',
        component: () => import('../views/wap/worker/certificateData'),
      },
      {
        path: 'certificate_doctor_pra',
        name: 'wap.certificate_doctor_pra',
        component: () => import('../views/wap/worker/certificateDataDoctorPra'),
      },
      {
        path: 'certificate_doctor_qua',
        name: 'wap.certificate_doctor_qua',
        component: () => import('../views/wap/worker/certificateDataDoctorQua'),
      },
      {
        path: 'certificate_pro_hire',
        name: 'wap.certificate_pro_hire',
        component: () => import('../views/wap/worker/certificateDataProHire'),
      },
      {
        path: 'certificate_other',
        name: 'wap.certificate_other',
        component: () => import('../views/wap/worker/certificateDataOther'),
      },
      {
        path: 'idcard',
        name: 'wap.idcard',
        component: () => import('../views/wap/worker/idCard'),
      },
      {
        path: 'form_page',
        name: 'wap.form_page',
        component: () => import('../views/wap/worker/formPage'),
      },
      {
        path: 'doc/handle',
        name: 'wap.doc.handle',
        component: () => import('../views/wap/doc/handle')
      },
      {
        path: 'doc/detail',
        name: 'wap.doc.detail',
        component: () => import('../views/wap/doc/detail')
      },
      {
        path: 'doc/step',
        name: 'wap.doc.step',
        component: () => import('../views/wap/doc/step')
      }
    ]
  }
]
