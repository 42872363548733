export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/worker/certificate_level',
      method: 'GET'
    }]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '查看本机构下的',
    apis: [{
      path: '/basis/worker/certificate_level/my_org',
      method: 'GET'
    }]
  },
  getMyOrgContainChild: {
    funKey: 'getMyOrgContainChild',
    name: '查看本机构及其子机构下的',
    apis: [{
      path: '/basis/worker/certificate_level/my_org_contain_child',
      method: 'GET'
    }]
  }
}