export default {
  bsae: {
    funKey: 'base',
    name: '基础',
    apis: [{
      path: '/basis/worker/all',
      method: 'GET'
    },
    {
      path: '/ucenter/sys_config',
      method: 'POST'
    },
    {
      path: '/basis/official_document/detail',
      method: 'GET'
    },
    {
      path: '/basis/official_document_handle/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/official_document',
      method: 'POST'
    },
    {
      path: '/basis/official_document_send',
      method: 'GET'
    },
    {
      path: '/basis/official_document_send/handle',
      method: 'GET'
    },
    {
      path: '/basis/official_document_send/by_auth',
      method: 'GET'
    },
    {
      path: '/basis/official_document_send/handle/my_org',
      method: 'GET'
    }]
  },
  get: {
    funKey: 'get',
    name: '查看全部',
    apis: []
  },
  getMy: {
    funKey: 'getMy',
    name: '查看我的',
    apis: []
  }
}