export default {
  routerNames: ['/pages/wages/my'],
  funs: {
    get: {
      funKey: 'get',
      name: '基础功能',
      apis: [
        { path: '/performance/wage_month/worker_config/{id}', method: 'GET' },
        { path: '/performance/wage_month_worker/my', method: 'GET' }
      ]
    }
  }
}