export default {
  routerNames: ['wages.worker.index'],
  funs: {
    add: {
      funKey: 'add',
      name: '新增',
      apis: [
        { method: 'POST', path: '/performance/wage_worker' },
      ]
    },
    get: {
      funKey: 'get',
      name: '查看',
      apis: [
        { method: 'GET', path: '/performance/wage_worker' }
      ]
    },
    del: {
      funKey: 'del',
      name: '删除',
      apis: [
        { method: 'DELETE', path: '/performance/wage_worker/{id}' }
      ]
    },
    edit: {
      funKey: 'edit',
      name: '编辑',
      apis: [
        { method: 'PUT', path: '/performance/wage_worker/{id}' }
      ]
    },
  }
}