<template>
  <div>
    <h2>表单对话框(fm-form-dialog)</h2>
    <div class="flex">
      <fm-btn @click="formDialog = true">打开对话框</fm-btn>
      <fm-form-dialog
        form-title="表单对话框"
        :open-dialog.sync="formDialog"
        :form-parms="formParms"
        :onChange="onChange"
        @handleClose="formDialog = false">
      </fm-form-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "FmFormDialogDemo",
  components: {},
  data() {
    return {
      formDialog: false,
     formParms: [
          {
            type: 'input',
            label: '必填',
            placeholder: '必填',
            key: 'input1',
            check: {
              required: true
            }
          },
          {
            type: 'input',
            label: '可空',
            placeholder: '',
            key: 'input2'
          },
          {
            type: 'input',
            label: '正则',
            placeholder: '自定义正则验证',
            key: 'input3',
            check: {
              rules: [
                {type: 'regular', regular: /^1/, message: '必须数字1开头'}
              ]
            }
          },
          {
            type: 'input',
            label: '邮箱',
            key: 'input4',
            check: {
              required: true,
              rules: [
                {
                  type: 'email'
                }
              ]
            }
          },
          {
            type: 'select',
            label: '选择框',
            key: 'select',
            selectDatas: [
              {label: '无', key: ''},
              {label: '数据1', key: '数据1'},
              {label: '数据2', key: '数据2'},
              {label: '数据3', key: '数据3'},
              {label: '数据4', key: '数据4'},
              {label: '数据5', key: '数据5'},
              {label: '数据6', key: '数据6'},
              {label: '数据7', key: '数据7'},
              {label: '数据8', key: '数据8'}
            ],
            check: {
              required: true
            }
          },
          {
            type: 'input',
            label: '非必须',
            placeholder: '不为空时才验证',
            key: 'input5',
            check: {
              rules: [
                {
                  type: 'date'
                }
              ]
            }
          },
          {
            type: 'multipleChoose',
            label: '多选框',
            key: 'multipleChoose',
            selectDatas: [
              {label: '数据1', key: '数据1'},
              {label: '数据2', key: '数据2'},
              {label: '数据3', key: '数据3'},
              {label: '数据4', key: '数据4'},
              {label: '数据5', key: '数据5'},
              {label: '数据6', key: '数据6'},
              {label: '数据7', key: '数据7'},
              {label: '数据8', key: '数据8'}
            ],
            check: {
              required: true
            }
          },
          {
            type: 'datePicker',
            label: '日期',
            key: 'datePicker',
            check: {
            }
          },
          {
            type: 'datetimePicker',
            label: '日期时间',
            key: 'datetimePicker',
            check: {
            }
          }
        ] 
    }
  },
  props: {},
  computed: {},
  methods: {
    onChange (key, value) {
      // console.log(key)
      if (key === 'input') {
        // 返回的新对象将会覆盖到formData上
        return {
          input2: value + ' - ' + new Date().getSeconds()
        }
      }
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
};
</script>

<style lang="less" scoped></style>