<style scoped>
  .fm-breadcrumb {
    display: flex;
  }
</style>

<template>
  <div class="fm-breadcrumb">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FmBreadcrumb',
  props: {
    separator: {
      type: String,
      default: '/'
    }
  },
  provide () {
    return {
      separator: this.separator
    }
  }
}
</script>
