<template>
  <div>
    <table>
      <tr>
        <td>名称</td>
        <td>示例</td>
        <td>粗细</td>
        <td>颜色</td>
        <td>字号</td>
      </tr>
      <tr>
        <td>主标题</td>
        <td><h1>主标题</h1></td>
        <td>加粗</td>
        <td>#17233d @color-title-text</td>
        <td>18px @size-font-large</td>
      </tr>
      <tr>
        <td>次标题</td>
        <td><h2>次标题</h2></td>
        <td>加粗</td>
        <td>#17233d @color-title-text</td>
        <td>14px @size-font-norm</td>
      </tr>
      <tr>
        <td>小标题</td>
        <td><h3>小标题</h3></td>
        <td>加粗</td>
        <td>#17233d @color-title-text</td>
        <td>12px @size-font-small</td>
      </tr>
      <tr>
        <td>正文</td>
        <td class="basics-content-text">正文</td>
        <td>默认</td>
        <td>#515a6e @color-content-text</td>
        <td>14px @size-font-norm</td>
      </tr>
      <tr>
        <td>辅助文字</td>
        <td class="basics-sub-text">辅助文字</td>
        <td>默认</td>
        <td>#808695 @color-sub-text</td>
        <td>14px @size-font-norm</td>
      </tr>
      <tr>
        <td>失效文字</td>
        <td class="basics-disabled-text">失效文字</td>
        <td>默认</td>
        <td>#c5c8ce @color-disabled-text</td>
        <td>14px @size-font-norm</td>
      </tr>
      <tr>
        <td>链接文字</td>
        <td class="basics-link-text">链接文字</td>
        <td>默认</td>
        <td>#3399ff @color-link-text</td>
        <td>14px @size-font-norm</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "FontDemo",
  components: {},
  data() {
    return {}
  },
  props: {},
  computed: {},
  methods: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  @import '../components/styles/values.less';
  td {
    padding: 0 10px;
  }
</style>