<style lang="less">
  .fm-poper-default-enter-active, .fm-poper-default-leave-active {
    transition: all .5s;
    opacity: 1;
    transform: scale(1);
  }
  .fm-poper-default-enter, .fm-poper-default-leave-to {
    opacity: 0;
    transform: scale(0.7);
  }
  .fm-poper-noscale-enter-active, .fm-poper-noscale-leave-active {
    transition: all .5s;
    opacity: 1;
  }
  .fm-poper-noscale-enter, .fm-poper-noscale-leave-to {
    opacity: 0;
  }
  .fm-poper {
    position: fixed;
    z-index: 1;
  }
  .fm-poper-init {
    transition: all .5s;
    opacity: 0;
  }
</style>

<template>
  <transition :name="'fm-poper-' + transition" mode="in-out">
    <div class="fm-poper" v-show="show" :style="poperStyle">
      <div :class="{'fm-poper-init': init === false}">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FmPoper',
  data () {
    return {
      show: this.value,
      init: false,
      top: -1,
      left: -1
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    outclose: { type: Boolean, default: true },
    value: { type: Boolean, default: false },
    position: {
      type: String, default: null, validator: function(pst) {
        return ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end'].includes(pst)
      }
    },
    margin: { type: Number, default: 10 },
    inset: { type: Boolean, default: false },
    transition: { type: String, default: 'default' }
  },
  watch: {
    value () {
      if (this.show !== this.value) {
        this.show = this.value
      }
    },
    show (v) {
      if (v) {
        this.$nextTick(this.onShow)
        document.addEventListener('mousewheel', this.onShow)
        if (this.outclose) {
          document.body.addEventListener('mouseup', this.onMouseUp)
        }
      } else {
        this.init = false
        document.removeEventListener('mousewheel', this.onShow)
        document.body.removeEventListener('mouseup', this.onMouseUp)
      }
    }
  },
  computed: {
    poperStyle () {
      return {
        top: this.top < 0 ? 'unset' : (this.top + 'px'),
        left: this.left < 0 ? 'unset' : (this.left + 'px')
      }
    }
  },
  methods: {
    onMouseUp (e) {
      if (e.target !== this.$el && (e.target.contains(this.$el) || !e.path.includes(this.$el))) {
        this.$emit('input', false)
        this.show = false
      }
    },
    autoPst () {
      const {top, bottom, left, right} = this.$el.parentElement.getBoundingClientRect()

      if (top < 0 || bottom > window.innerHeight || left < 0 || right > window.innerWidth) {
        return
      }

      const width = this.$el.offsetWidth
      const height = this.$el.offsetHeight

      let wLeft = left - width / 2
      let wRight = left + width
      let wTop = bottom + this.margin
      let wBottom = wTop + height

      if (wRight < window.innerWidth && wLeft > 0) { // 正常
        this.left = wLeft
      } else if (wRight > window.innerWidth && wLeft > 0) { // 右侧超出
        this.left = right - width + (window.innerWidth - right)
      } else if (wLeft < 0 && wRight < window.innerWidth) { // 左侧超出
        this.left = left
      } else { // 两侧超出
        this.left = window.innerWidth / 2 - width / 2
      }

      if (wTop > 0 && wBottom < window.innerHeight) { // 正常
        this.top = wTop
      } else if (wBottom > window.innerHeight && top - height - this.margin > 0) { // 底部超出
        this.top = top - height - this.margin
      } else { // 顶部超出
        this.top = window.innerHeight / 2 - height / 2
      }

      this.init = true
    },
    fixedPst () {
      const { top, bottom, left, right, width, height } = this.$el.parentElement.getBoundingClientRect()
      const offsetWidth = this.$el.offsetWidth
      const offsetHeight = this.$el.offsetHeight

      const [type, pst] = this.position.split('-')

      if (['top', 'bottom'].includes(type)) {
        if (pst === 'start') {
          this.left = left
        } else if (pst === 'end') {
          this.left = right - offsetWidth
        } else {
          this.left = left + (width > offsetWidth ? (width / 2 - offsetWidth / 2) : -(offsetWidth / 2 - width / 2))
        }
        this.top = type === 'top' ? (top - this.margin - offsetHeight) : (bottom + this.margin)
      } else {
        if (pst === 'start') {
          this.top = top
        } else if (pst === 'end') {
          this.top = bottom - offsetHeight
        } else {
          this.top = top + (height > offsetHeight ? (height / 2 - offsetHeight / 2) : -(offsetHeight / 2 - height / 2))
        }
        this.left = type === 'left' ? (left - this.margin - offsetWidth) : (right + this.margin)
      }

      this.init = true
    },
    onShow () {
      if (this.position) {
        this.fixedPst()
      } else {
        this.autoPst()
      }
    }
  },
  destroyed () {
    document.body.removeEventListener('mouseup', this.onMouseUp)
  }
}
</script>
