/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Link: https://www.slippersclown.com
 * Date: Fri Dec 06 2019
 */
export const iconTypes = {
  'info': 'fmico-info',
  'success': 'fmico-success',
  'warning': 'fmico-warning',
  'error': 'fmico-error'
}

export function findChildrenComponents (context, componentName) {
  return context.$children.reduce((components, child) => {
    if (child.$options.name === componentName) components.push(child)
    const foundChilds = findChildrenComponents(child, componentName)
    return [...components, ...foundChilds]
  }, [])
}

export function findParentComponent (context, componentName) {
  let parent = context.$parent
  let name = parent.$options.name
  while (parent && (!name || componentName === name)) {
    parent = parent.$parent
    if (parent) name = parent.$options.name
  }
  return parent.$options.name === componentName ? parent : null
}