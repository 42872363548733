export default {
  data () {
    return {
      borderLeft: false,
      dragField: null,
      dragFieldHisWidth: 0,
      dragFieldIng: false,
      dragStartX: 0,
      dragEndX: 0,
      minDradWidth: 0,
      dragWirtualLineStyle: {
        left: '0px'
      },
      minFieldWidth: 30
    }
  },
  methods: {
    handleTitleMouseMove (event) {
      // 鼠标在title上移动时触发事件
      // 改变鼠标样式 设置是否在可拖动范围内
      if (this.dragFieldIng) {
        // 若已按下鼠标并在拖动 则什么都不做
        this.setDragLinePosition(event)
        return
      }
      let target = event.target
      if (!target.classList.contains('fm-table-cell-drag')) {
        target = target.parentElement
      }
      if (!target.classList.contains('fm-table-cell-drag')) {
        target = target.parentElement
      }
      if (!target.classList.contains('fm-table-cell-drag')) {
        target = target.parentElement
      }
      let rect = target.getBoundingClientRect()
      if (target.classList.contains('fm-table-cell-drag') && rect.right - event.pageX < 10) {
        this.borderLeft = true
      } else {
        this.borderLeft = false
      }
      this.modifyMouseStyle()
    },
    handleTitleMouseDown (event, field, fieldWidth) {
      // 鼠标在title上按下去的事件
      // 若在拖动范围内则设置全局鼠标移动事件及鼠标放下事件
      if (this.borderLeft) {
        this.setDragLinePosition(event)
        this.dragField = field
        this.dragFieldHisWidth = fieldWidth
        this.dragFieldIng = true
        this.dragStartX = event.clientX
        // 需要定义mouthup事件
        window.document.addEventListener('mouseup', this.handleDragMouseUp)
        window.document.addEventListener('mousemove', this.handleDragMouseMove)
        this.minDradWidth = this.minFieldWidth - this.dragFieldHisWidth
      }
    },
    handleTitleMouseOut () {
      // 鼠标移出本title
      // 若不处于点击状态
      if (!this.dragFieldIng) {
        this.initDradWidthMixin()
      } else {
        this.borderLeft = false
      }
      this.modifyMouseStyle()
    },
    handleDragMouseMove (event) {
      // 全局拖动鼠标移动事件
      this.setDragLinePosition(event)
    },
    handleDragMouseUp (event) {
      // 全局拖动鼠标放下事件
      // 初始化数据
      this.dragEndX = event.clientX
      let changeWidth = (this.dragEndX - this.dragStartX) > this.minDradWidth ? (this.dragEndX - this.dragStartX) : this.minDradWidth
      this.dragUpdateColConfig(this.dragField, changeWidth)
      this.initDradWidthMixin()
      this.modifyMouseStyle()
      // 清空全局事件
      window.document.removeEventListener('mouseup', this.handleDragMouseUp)
      window.document.removeEventListener('mousemove', this.handleDragMouseMove)
    },
    initDradWidthMixin () {
      this.dragField = null
      this.borderLeft = false
      this.dragFieldIng = false
      this.dragStartX = 0
      this.dragEndX = 0
      this.dragWirtualLineStyle = {
        left: '0px'
      }
    },
    modifyMouseStyle () {
      let bodyStyle = document.body.style
      if (this.dragFieldIng || this.borderLeft) {
        bodyStyle.cursor = 'col-resize'
      } else {
        bodyStyle.cursor = ''
      }
    },
    setDragLinePosition (event) {
      let diff = (event.clientX - this.dragStartX) > this.minDradWidth ? (event.clientX - this.$el.getBoundingClientRect().x) : (this.dragStartX - this.$el.getBoundingClientRect().x + this.minDradWidth)
      this.dragWirtualLineStyle = {
        left: diff + 'px'
      }
    }
  }
}