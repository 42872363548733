<style scoped></style>

<template>
  <div>
    <h2>面包屑(<fm-tag>fm-breadcrumb</fm-tag>)</h2>
    <fm-breadcrumb>
      <fm-breadcrumb-item>item</fm-breadcrumb-item>
      <fm-breadcrumb-item>item</fm-breadcrumb-item>
      <fm-breadcrumb-item>item</fm-breadcrumb-item>
    </fm-breadcrumb>
    <h2>连接跳转(<fm-tag>to</fm-tag><fm-tag>target</fm-tag>)</h2>
    <fm-breadcrumb>
      <fm-breadcrumb-item to="//baidu.com" target="_blank">item</fm-breadcrumb-item>
      <fm-breadcrumb-item :to="{path: 'test'}">item</fm-breadcrumb-item>
      <fm-breadcrumb-item>item</fm-breadcrumb-item>
    </fm-breadcrumb>
    <h2>图标</h2>
    <fm-breadcrumb>
      <fm-breadcrumb-item to="//baidu.com" target="_blank"><i class="fmico fmico-add"></i>item</fm-breadcrumb-item>
      <fm-breadcrumb-item :to="{path: 'test'}"><i class="fmico fmico-dingdan"></i>item</fm-breadcrumb-item>
      <fm-breadcrumb-item><i class="fmico fmico-eye"></i>item</fm-breadcrumb-item>
    </fm-breadcrumb>
    <h2>自定义分隔(<fm-tag>separator="|"</fm-tag>)</h2>
    <fm-breadcrumb separator="|">
      <fm-breadcrumb-item to="//baidu.com" target="_blank">item</fm-breadcrumb-item>
      <fm-breadcrumb-item :to="{path: 'test'}">item</fm-breadcrumb-item>
      <fm-breadcrumb-item>item</fm-breadcrumb-item>
    </fm-breadcrumb>
    <h2>html分隔(<fm-tag>separator="&lt;b style='color: red;'&gt;|&lt;/b&gt;"</fm-tag>)</h2>
    <fm-breadcrumb separator="<b style='color: red;'>|</b>">
      <fm-breadcrumb-item to="//baidu.com" target="_blank">item</fm-breadcrumb-item>
      <fm-breadcrumb-item :to="{path: 'test'}">item</fm-breadcrumb-item>
      <fm-breadcrumb-item>item</fm-breadcrumb-item>
    </fm-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'FmBreadcrumbDemo'
}
</script>
