<style lang="less">
  @import url('../styles/values.less');
  .fm-table-sort {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
    span {
      height: 0;
      cursor: pointer;
      transition: all .3s;
      width: 0;
      border: 5px solid transparent;
      display: block;
      margin: 1px;
      &:hover, &.fm-table-sort-active {
        &:first-of-type {
        border-bottom-color: @color-primary;
        }
        &:last-of-type {
          border-top-color: @color-primary;
        }
      }
      &:first-of-type {
        border-bottom-color: @color-border;
      }
      &:last-of-type {
        border-top-color: @color-border;
      }
    }
  }
</style>

<template>
  <div class="fm-table-sort">
    <span :class="{'fm-table-sort-active': sortType === 'asc'}" @click="switchSortType('asc')"></span>
    <span :class="{'fm-table-sort-active': sortType === 'desc'}" @click="switchSortType('desc')"></span>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sortType: null
    }
  },
  inject: ['table'],
  props: {
    column: {
      type: Object, default () {
        return {}
      }
    }
  },
  computed: {
    unionSort () {
      return this.table.unionSort
    }
  },
  methods: {
    switchSortType (type) {
      if (this.sortType === type) {
        this.sortType = undefined
      } else {
        this.sortType = type
      }
      this.table.$emit('column-sort-set', { type: this.sortType, dataType: this.column.config.dataType, method: this.column.config.sortMethod, field: this.column.config.field })
    },
    columnSortSet ({field}) {
      if (this.unionSort === false && field !== this.column.config.field) {
        this.sortType = undefined
      }
    },
    updateSortConfig (config) {
      let fields = config.map(v => v.field)
      if (!fields.includes(this.column.config.field)) {
        this.sortType = undefined
      }
    }
  },
  mounted () {
    this.table.$on('column-sort-set', this.columnSortSet) 
    this.table.$on('update-sort-config', this.updateSortConfig) 
  }
}
</script>
