// 出入库管理
export default {
  get: {
    funKey: 'get',
    name: '获取',
    apis: [
      { path: '/internalcontrol/assets_in', method: 'GET' }
    ]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [
      { path: '/internalcontrol/assets_in/{id}', method: 'DELETE' }
    ]
  },
  update: {
    funKey: 'update',
    name: '修改',
    apis: [
      { path: '/internalcontrol/assets_in/{id}', method: 'PUT' },
      { path: '/internalcontrol/assets', method: 'GET' }
    ]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [
      { path: '/internalcontrol/assets_in', method: 'POST' },
      { path: '/internalcontrol/assets', method: 'GET' }
    ]
  }
}