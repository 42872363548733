export default {
  name: '货物处置',
  icon: 'icon-shuju',
  clientType: 'web',
  router: {
    name: 'handle',
    path: 'handle',
    component: () => import('../../../views/handle/router.vue'),
    redirect: { name: 'scm.handle.index' }
  },
  children: [
    {
      name: '管理',
      icon: 'icon-shuju',
      clientType: 'web',
      router: {
        name: 'handle.index',
        path: 'handle',
        component: () => import('../../../views/handle/index.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_handle', method: 'GET' },
            { path: '/scm/goods_handle/detail', method: 'GET' }
          ]
        },
        manage: {
          name: '维护',
          funKey: 'manage',
          apis: [
            // 处置管理
            { path: '/scm/goods_handle', method: 'GET' },
            { path: '/scm/goods_handle', method: 'POST' },
            { path: '/scm/goods_handle/{id}', method: 'PUT' },
            { path: '/scm/goods_handle/exe_status/{id}', method: 'PUT' },
            { path: '/scm/goods_handle/{id}', method: 'DELETE' },
            { path: '/scm/goods', method: 'GET' },
            // 处置明细
            { path: '/scm/goods_stock', method: 'GET' },
            { path: '/scm/goods_handle/detail', method: 'GET' },
            { path: '/scm/goods_handle/detail', method: 'POST' },
            { path: '/scm/goods_handle/detail/{id}', method: 'PUT' },
            { path: '/scm/goods_handle/detail/{id}', method: 'DELETE' }
          ]
        }
      }
    },
    {
      name: '处置执行',
      icon: 'icon-shuju',
      clientType: 'web',
      router: {
        name: 'handle.exec',
        path: 'exec',
        component: () => import('../../../views/handle/exec.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_handle', method: 'GET' },
            { path: '/scm/goods_handle/detail', method: 'GET' },
            { path: '/scm/storehouse', method: 'GET' },
          ]
        },
        manage: {
          name: '执行',
          funKey: 'manage',
          apis: [
            { path: '/scm/goods_handle', method: 'GET' },
            { path: '/scm/goods_handle/detail', method: 'GET' },
            { path: '/scm/goods_handle/detail/handle/{id}', method: 'POST' },
            { path: '/scm/goods_handle/detail/handle/{id}', method: 'DELETE' }
          ]
        }
      }
    }
  ]
}