<template>
  <div class="fm-option" :class="optionCls" @click.stop="select" v-show="show">
    <!-- 当没有插槽 -->
    <template v-if="!$slots.default">{{label || value}}</template>
    <slot v-else></slot>
    <i class="fmico fmico-gou" v-if="isSelected && FmSelect.isMultiple"></i>
  </div>
</template>

<script>
export default {
  name: 'FmOption',
  data() {
    return {
      isSelected: false
    }
  },
  props: {
    value: {
      default: null,
      required: true
    },
    label: {
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  inject: ['FmSelect'],
  computed: {
    show () {
      if (this.FmSelect.isFilterable && this.FmSelect.filterableValue !== '') {
        return this.FmSelect.filterableCandidate.has(this.label)
      } else {
        return true
      }
    },
    optionCls () {
      return {
        'fm-option-disabled': this.isDisabled,
        'fm-option-selected': this.isSelected
      }
    },
    isDisabled () {
      return this.disabled === undefined || this.disabled === true
    }
  },
  methods: {
    getData () {
      return {
        label: this.label || this.value,
        value: this.value
      }
    },
    select () {
      if (![undefined, true].includes(this.disabled)) {
        this.FmSelect.addSelectedValue(this.value)
      }
    }
  },
  watch: {},
  beforeCreate() {},
  created() {
    this.FmSelect.$on('change', (v) => {
      const value = this.FmSelect.withLabel ? v.value : v
      this.isSelected = this.FmSelect.isMultiple ? value.includes(this.value) : this.value === value
    })
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
@import "./styles/values.less";
.fm-option {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all .2s;
  padding: 0 16px;
  font-weight: normal;
  &.fm-option-disabled {
    cursor: not-allowed;
  }
}

.fm-select.fm-select-multiple {
  .fmico-gou {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }
}

// 颜色设置
.fm-select.fm-select-primary {
  .fm-option {
    color: #515a6e;
    &:hover {
      background-color: #f3f3f3;
    }
    &:hover, &.fm-option-selected {
      color: @color-primary;
    }
    &.fm-option-disabled {
      color: #c5c8ce;
    }
  }
}

// 尺寸设置
// .fm-select.fm-select-norm {
.fm-select {
  .fm-option {
    line-height: @size-height-norm;
    padding: 0 8px;
  }
}
</style>