<template>
  <div class="fm-tag" :class="tagCls">
    <slot></slot>
    <i class="fmico fmico-cha" v-if="isClosable" @click.stop="$emit('close')"></i>
  </div>
</template>

<script>
export default {
  name: 'FmTag',
  data() {
    return {}
  },
  props: {
    size: {
      type: String,
      default: 'norm',
      validator: function(size) {
        return ['norm', 'large', 'small', 'mini'].includes(size)
      }
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isClosable () {
      return this.closable === undefined || this.closable === true
    },
    tagCls () {
      return {
        'fm-tag-closable': this.isClosable,
        [`fm-tag-${this.size}`]: true
      }
    }
  },
  methods: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
  @import "./styles/values.less";
  .fm-tag {
    border: 1px solid #e8eaec;
    border-radius: 3px;
    background: #f7f7f7;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    margin: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & ~ & {
      margin-left: 4px;
    }
    &.fm-tag-large {
      height: @size-height-large;
      &, .fmico-cha {
        font-size: @size-font-large;
      }
    }
    &.fm-tag-norm {
      height: @size-height-norm;
      &, .fmico-cha {
        font-size: @size-font-norm;
      }
    }
    &.fm-tag-small {
      height: @size-height-small;
      &, .fmico-cha {
        font-size: @size-font-small;
      }
    }
    &.fm-tag-mini {
      height: @size-height-mini;
      &, .fmico-cha {
        font-size: @size-font-mini;
      }
    }
  }
  .fm-tag.fm-tag-closable {
    .fmico-cha {
      margin-left: 10px;
      transform: scale(.8);
      transition: all .2s;
      cursor: pointer;
      opacity: .3;
    }
    &:hover {
      .fmico-cha {
        opacity: 1;
        color: rgba(255, 0, 0, .5);
        transform: scale(1);
      }
    }
  }
</style>