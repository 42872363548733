<style lang="less">
  .fm-table-simple-filter-cell {
    display: flex;
    .fm-input-new {
      border-color: #D9D9D9;
      background: #F3F3F3;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.65);
      & input::placeholder {
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .fm-table-filter-options {
      width: 100%;
      max-width: 100%;
      .fm-select {
        width: 100%;
        .fm-select-box {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .fm-select-placeholder {
          color: rgba(0, 0, 0, 0.65);
        }
        input::placeholder {
          color: rgba(0, 0, 0, 0.65) !important;
        }
      }
    }
    .fm-table-simple-filter-date {
      .fm-table-filter-section-gap {
        color: #000;
        display: inline-flex;
        align-items: center;
      }
      background: #F3F3F3;
      .fm-date-picker {
        &:first-of-type {
          .fmico-rili {
            display: none;
          }
        }
        .fm-date-picker-input {
          border-color: transparent;
        }
        &, input {
          background: #F3F3F3;
        }
        &, .fmico {
          color: rgba(0, 0, 0, 0.65);
        }
        & input::placeholder {
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
  .fm-table-simple-filter-number, .fm-table-simple-filter-date {
    display: flex;
    flex: 1;
  }
</style>

<template>
  <div class="fm-table-simple-filter-cell">
    <div class="fm-table-simple-filter-number" v-if="column.config.dataType === Number && column.config.filterRange">
      <fm-input-new :readonly="unusable" clearable @clear="apply" size="small" @keyup.native="delayApply" v-model="start" style="width: 0; flex: 1;"></fm-input-new>
      <span class="fm-table-filter-section-gap">-</span>
      <fm-input-new :readonly="unusable" clearable @clear="apply" size="small" @keyup.native="delayApply" v-model="end" style="width: 0; flex: 1;"></fm-input-new>
    </div>
    <div class="fm-table-simple-filter-date" v-else-if="column.config.dataType === Date && column.config.filterRange">
      <fm-date-picker placeholder="开始时间" :readonly="unusable" clearable @clear="apply" size="small" @change="delayApply" v-model="start" style="width: 0; flex: 1;"></fm-date-picker>
      <span class="fm-table-filter-section-gap">-</span>
      <fm-date-picker placeholder="结束时间" :readonly="unusable" clearable @clear="apply" size="small" @change="delayApply" v-model="end" style="width: 0; flex: 1;"></fm-date-picker>
    </div>
    <div class="fm-table-filter-options" v-else-if="column.config.filters && column.config.filters.length">
      <fm-select filterable style="flex: 1;" :readonly="unusable" clearable size="small" v-model="option" @change="apply">
        <fm-option v-for="(option, key) in column.config.filters" :key="key" :label="option.label" :value="option.value"></fm-option>
      </fm-select>
    </div>
    <fm-input-new v-else-if="column.search !== false" :readonly="unusable" clearable @clear="apply" style="width: 0;flex: 1" v-model="text" @keyup.native="delayApply" ref="input" size="small" placeholder="筛选内容"></fm-input-new>
  </div>
</template>

<script>
let timer = null
export default {
  data () {
    return {
      option: null,
      text: null,
      start: null,
      end: null
    }
  },
  inject: ['table'],
  props: {
    column: {
      type: Object, default () {
        return {}
      }
    },
    groupIndex: {
      type: Number, default: -1
    },
    columnIndex: {
      type: Number, default: -1
    }
  },
  computed: {
    unusable () {
      return Boolean(this.column.field)
    }
  },
  methods: {
    delayApply () {
      clearTimeout(timer)
      timer = setTimeout(this.apply, 300)
    },
    apply () {
      if (this.column.config.filterRange && [Number, Date].includes(this.column.config.dataType)) {
        let cover = this.column.config.dataType === Number ? (v) => {
          return (v !== '' && v !== null) ? Number(v) : NaN
        } : (v) => {
          return (v !== '' && v !== null) ? new Date(v) : null
        }
        let range = [cover(this.start), cover(this.end)]
        cover = null

        if ((isNaN(range[0]) === true && isNaN(range[1]) === true) || (range[0] === null && range[1] === null)) {
          range = []
        } else {
          range = [range]
        }

        this.table.$emit('column-filter-set', {field: this.column.config.field, tags: [], options: [], sections: range})
      } else if (this.column.config.filters && this.column.config.filters.length) {
        this.table.$emit('column-filter-set', {field: this.column.config.field, tags: [], options: this.option? [this.option] : [], sections: []})
      } else {
        this.table.$emit('column-filter-set', {field: this.column.config.field, tags: this.text ? [this.text] : [], options: [], sections: []})
      }
    },
    updateFilterConfig (config) {
      if (!Object.keys(config).includes(this.column.config.field)) {
        this.option = null
        this.text = null
        this.start = null
        this.end = null
      }
    }
  },
  mounted () {
    this.table.$on('update-filter-config', this.updateFilterConfig)
  },
  destroyed () {
    this.table.$off('update-filter-config', this.updateFilterConfig)
  }
}
</script>
