export default {
  base: {
    funKey: 'base',
    name: '基础',
    apis: [{
      path: '/ucenter/user',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/ucenter/sys_config',
      method: 'GET'
    },
    {
      path: '/ucenter/sys_config',
      method: 'POST'
    }]
  }
}