<template>
  <div class="fm-time-picker">
    <div class="fm-time-picker-unit-list">
      <div class="fm-time-picker-unit">时</div>
      <div class="fm-time-picker-unit">分</div>
      <div class="fm-time-picker-unit">秒</div>
    </div>
    <div class="fm-time-picker-times">
      <div class="fm-time-picker-hour">
        <div>
          <div class="fm-time-picker-item" @click="setTime('hours', i, $event.target)" :class="{
            'fm-time-picker-selected': current && current.getHours() === i
          }" v-for="(l, i) in 24" :key="i">{{ i < 10 ? ('0' + i) : i}}</div>
        </div>
      </div>
      <div class="fm-time-picker-minute">
        <div>
          <div class="fm-time-picker-item" @click="setTime('minutes', i, $event.target)" :class="{
            'fm-time-picker-selected': current && current.getMinutes() === i
          }"  v-for="(l, i) in 60" :key="i">{{ i < 10 ? ('0' + i) : i}}</div>
        </div>
      </div>
      <div class="fm-time-picker-second">
        <div>
          <div class="fm-time-picker-item" @click="setTime('seconds', i, $event.target)" :class="{
            'fm-time-picker-selected': current && current.getSeconds() === i
          }"  v-for="(l, i) in 60" :key="i">{{ i < 10 ? ('0' + i) : i}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XDate } from './lib'
export default {
  name: '',
  components: {},
  data() {
    return {}
  },
  inject: ['picker'],
  props: {
    current: {
      type: XDate
    },
    flag: {
      type: String,
      default: 'startTime'
    }
  },
  computed: {},
  methods: {
    setTime (unit, value, target) {
      target.offsetParent.scrollTo(0, target.offsetTop)
      let date = this.current ? this.current.copy() : new XDate()
      date['set' + unit.charAt(0).toUpperCase() + unit.substr(1)](value)
      this.picker.choose({
        date: date,
        flag: this.flag
      })
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  @import '../styles/datapicker.less';
  @time-size-height: 40px;
  .fm-time-picker {
    background-color: #FFF;
    text-align: center;
    flex: 1;
  }
  .fm-time-picker-unit-list, .fm-time-picker-times {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    color: @color-text;
    .fm-time-picker-unit {
      border-bottom: 1px solid #EEE;
      width: 33.33%;
      line-height: @time-size-height;
      color: @color-assist-1;
      border-right: 1px solid transparent;
      &:last-child {
        border-right-color: transparent;
      }
    }
  }
  .fm-time-picker-times {
    .fm-time-picker-hour, .fm-time-picker-minute, .fm-time-picker-second {
      width: 33.33%;
      max-height: 200px;
      overflow: auto;
      border-right: 1px solid #EEE;
      position: relative;
      &:last-child {
        border-right-color: transparent;
      }
      &>div {
        padding-bottom: (200px / @time-size-height - 1) * @time-size-height;
      }
    }
    .fm-time-picker-item {
      cursor: pointer;
      transition: all .2s;
      line-height: @time-size-height;
      &.fm-time-picker-selected {
        color: @color-active;
        background-color: @color-candidate;
      }
      &:hover {
        background-color: @color-candidate;
      }
    }
  }
</style>