let dcopy = require('deep-copy')

export default {
  // 当前时间加减几天days, hours
  computeDay: function ({format = 'number', days = 0, hours = 0, date} = {format: 'number', days: 0}) {
    if (typeof date === 'string') {
      date = date.length === 10 ? (date + 'T00:00') : date
      if (date.slice(10, 11) === ' ') {
        date = date.slice(0, 10) + 'T' + date.slice(11)
      }
    }
    let nowDate = new Date(dcopy(date)) || new Date()
    if (days && days !== 0) {
      nowDate.setDate(nowDate.getDate() + days)
    }
    if (hours && hours !== 0) {
      nowDate = new Date(nowDate.getTime() + hours * 60 * 60 * 1000)
    }
    return this.formatDate({
      date: nowDate.getTime(),
      format: format
    })
  },
  formatDate: function ({date = null, format = 'number'} = {date: null, format: 'number'}) {
    if (!date) {
      return null
    }
    if (typeof date === 'string') {
      date = date.replace('T', ' ')
      date = date.replace(/-/g, '/')
    }
    date = date ? new Date(date) : new Date()
    let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : String(0) + (date.getMonth() + 1)
    let day = date.getDate() > 9 ? date.getDate() : String(0) + date.getDate()
    let hour = date.getHours() > 9 ? date.getHours() : String(0) + date.getHours()
    let minute = date.getMinutes() > 9 ? date.getMinutes() : String(0) + date.getMinutes()
    let year = date.getFullYear()
    let seconds = date.getSeconds() > 9 ? date.getSeconds() : String(0) + date.getSeconds()
    if (format === 'yy-mm-dd') {
      return year + '-' + month + '-' + day
    } else if (format === 'yy/mm/dd') {
      return year + '/' + month + '/' + day
    } else if (format === 'yy年mm月dd日') {
      return year + '年' + month + '月' + day + '日'
    } else if (format === 'm-d hh:mm') {
      return (date.getMonth() + 1) + '-' + date.getDate() + ' ' + hour + ':' + minute
    } else if (format === 'm-d') {
      return (date.getMonth() + 1) + '-' + date.getDate()
    } else if (format === 'yy.mm.dd') {
      return year + '.' + month + '.' + day
    } else if (format === 'yy-mm-dd hh:mm') {
      return year + '-' + month + '-' + day + ' ' + hour + ':' + minute
    } else if (format === 'yy-mm-dd hh:mm:ss') {
      return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + seconds
    } else if (format === 'hh:mm') {
      return hour + ':' + minute
    } else if (format === 'stamps') {
      return date.getTime()
    } else if (format === 'mm-dd hh:mm:ss') {
      return month + '-' + day + ' ' + hour + ':' + minute + ':' + seconds
    } else {
      return new Date(date.getTime())
    }
  },
  getMonthDays: function (year, month) {
    if (!year || !month) {
      return 0
    }
    year = Number(year)
    month = Number(month)
    let date1 = new Date(year + '/' + month + '/01 00:00:00')
    let date2Year = month === 12 ? year + 1 : year
    let date2Month = month === 12 ? 1 : month + 1
    let date2 = new Date(date2Year + '/' + date2Month + '/01 00:00:00')
    return (date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24
  },
  checkSameDay: function (date1, date2) {
    date2 = date2 || new Date()
    return this.formatDate({date: date1, format: 'yy-mm-dd'}) === this.formatDate({date: date2, format: 'yy-mm-dd'})
  },
  minusDate: function (d1 = new Date(), d2 = new Date()) {
    return d1.getTime() - d2.getTime()
  },
  intervalDays: function (dateStr) {
    let date = new Date(dateStr).getDate()
    let date2 = new Date().getDate()
    return date - date2
  }
}