<template>
  <div class="fm-rate" @mouseleave="hoverIndex = -1">
    <div class="fm-rate-star" v-for="i in count" :key="i" :class="{
      'fm-rate-in': half === false && currentIndex >= i - 1,
      'fm-rate-full': half !== false && currentIndex >= (i - 1) * 2 + 1,
      'fm-rate-single': half !== false && currentIndex >= (i - 1) * 2
    }">
      <template v-if="char === undefined">
        <div class="fm-rate-half" v-if="half">
          <i @click="dataIndex = hoverIndex" @mouseenter="hoverIndex = (i - 1) * 2" class="fmico fmico-star fm-rate-icon"></i>
          <i @click="dataIndex = hoverIndex" @mouseenter="hoverIndex = (i - 1) * 2 + 1" class="fmico fmico-star fm-rate-icon"></i>
        </div>
        <i v-else @click="dataIndex = hoverIndex" @mouseenter="hoverIndex = (i - 1) * (half !== false ? 2 : 1)" class="fmico fmico-star fm-rate-icon"></i>
      </template>
      <template v-else>
        <div class="fm-rate-half" v-if="half">
          <div @click="dataIndex = hoverIndex" @mouseenter="hoverIndex = (i - 1) * 2" class="fm-rate-char"><span>{{char}}</span></div>
          <div @click="dataIndex = hoverIndex" @mouseenter="hoverIndex = (i - 1) * 2 + 1" class="fm-rate-char"><span>{{char}}</span></div>
        </div>
        <div v-else @click="dataIndex = hoverIndex" @mouseenter="hoverIndex = (i - 1) * (half !== false ? 2 : 1)" class="fm-rate-char"><span>{{char}}</span></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmRate',
  props: {
    count: {
      type: Number,
      default: 5
    },
    half: {
      type: Boolean,
      default: false
    },
    char: {
      type: String,
      default: undefined
    },
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    currentIndex () {
      return this.hoverIndex > -1 ? this.hoverIndex : this.dataIndex
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      hoverIndex: -1,
      dataIndex: !this.value ? -1 : (this.half ? (this.value * 2 - 1) : this.value - 1)
    }
  },
  watch: {
    dataIndex () {
      this.$emit('input', this.dataIndex < 0 ? null : (this.half ? ((this.dataIndex + 1) / 2) : this.dataIndex + 1))
    }
  }
}
</script>

<style lang="less">
  @import url('./styles/values.less');
  .fm-rate {
    display: flex;
    align-items: center;
  }
  .fm-rate-star {
    position: relative;
  }
  .fm-rate-icon, .fm-rate-char {
    color: @color-border;
    cursor: pointer;
    font-size: @size-height-mini;
    line-height: 1;
    transition: color .3s;
  }
  .fm-rate-half {
    width: @size-height-mini;
    height: @size-height-mini;
    display: flex;
    i, div {
      overflow: hidden;
      width: 50%;
      display: block;
      position: relative;
      &::before, span {
        position: absolute;
        top: 0;
      }
      // &:first-child {
        // &::before, span {}
      // }
      &:last-child {
        &::before, span {
          transform: translateX(-50%);
        }
      }
    }
  }
  .fm-rate-in {
    .fm-rate-icon, .fm-rate-char {
      color: #ec9738;
    }
  }
  .fm-rate-single {
    .fm-rate-half {
      .fm-rate-icon, .fm-rate-char {
        &:first-child {
          color: #ec9738;
        }
      }
    }
  }
  .fm-rate-full {
    .fm-rate-half {
      .fm-rate-icon, .fm-rate-char {
        &:last-child {
          color: #ec9738;
        }
      }
    }
  }
</style>