import XLSX from 'xlsx'

export function getEmptyMonthItem (month) {
  const spl = month.split('-')
  return {
    id: null,
    status: 'empty',
    unitValueTotal: null,
    workerTotal: null,
    avgPfm: null,
    month: month,
    web: {
      year: Number(spl[0]),
      month: Number(spl[1])
    }
  }
}

export const detailMenus = [
  {
    label: '考核单元配置', icon: 'icon-xiangmu', key: 'unit',
    status: ['plan'], roles: ['manage', 'editor'], mode: 'manage',
    url: 'performance.calculate.details.unit.config',
    level: 0, routerOnly: false
  },
  {
    label: '人员配置', icon: 'icon-zongrenshu', key: 'worker',
    status: ['plan'], roles: ['manage', 'editor'], mode: 'manage',
    url: 'performance.calculate.details.worker.config',
    level: 0, routerOnly: false
  },
  {
    label: '开展项目', icon: 'icon-shuju1', key: 'project',
    status: ['plan'], roles: ['manage', 'editor'], mode: 'manage',
    url: 'performance.calculate.details.project.config',
    level: 0, routerOnly: false
  },
  {
    label: '办公用品耗材', icon: 'icon-bangongyongpinguanli', key: 'consume',
    status: ['plan'], roles: ['manage', 'editor'], mode: 'manage',
    url: 'performance.calculate.details.consume.config',
    level: 0, routerOnly: false
  },
  {
    label: '住院明细', icon: 'icon-a-lujing80', key: 'hospitalization',
    status: ['plan'], roles: ['manage', 'editor'], mode: 'manage',
    url: 'performance.calculate.details.hospitalization.config',
    level: 0, routerOnly: false
  },
  // 源数据
  {
    label: '考核单元', icon: 'icon-xiangmu', key: 'unit',
    status: ['end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.unit.show',
    level: 0, routerOnly: false
  },
  {
    label: '参与人员', icon: 'icon-zongrenshu', key: 'worker',
    status: ['end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.worker.show',
    level: 0, routerOnly: false
  },
  {
    label: '开展项目', icon: 'icon-shuju1', key: 'project',
    status: ['end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.project.show',
    level: 0, routerOnly: false
  },
  {
    label: '办公用品耗材', icon: 'icon-bangongyongpinguanli', key: 'consume',
    status: ['end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.consume.show',
    level: 0, routerOnly: false
  },
  {
    label: '住院明细', icon: 'icon-a-lujing80', key: 'hospitalization',
    status: ['end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.hospitalization.show',
    level: 0, routerOnly: false
  },
  // 源数据
  {
    label: '重点项目绩效', icon: 'icon-liujisuan', key: 'small',
    status: ['compute', 'end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.small',
    level: 0, routerOnly: false
  },
  {
    label: '普通工作量绩效', icon: 'icon-qitazhichu', key: 'big',
    status: ['compute', 'end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.big',
    level: 0, routerOnly: false
  },
  {
    label: '平均奖', icon: 'icon-pingjunbupingheng', key: 'avgValue',
    status: ['compute', 'end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.worker.avgValue',
    level: 0, routerOnly: false
  },
  {
    label: '普通工作量绩效分配', icon: 'icon-repeatInfinite2', key: 'unit',
    status: ['divide'], roles: ['operation'], mode: 'operation',
    url: 'performance.calculate.details.unit.divide',
    level: 0, routerOnly: false
  },
  {
    label: '普通工作量绩效分配', icon: 'icon-repeatInfinite2', key: 'divide',
    status: ['divide'], roles: ['operation'], mode: 'operation',
    url: 'performance.calculate.details.worker.divide',
    level: 1, routerOnly: true
  },
  {
    label: '普通工作量绩效分配状态', icon: 'icon-shiyanshikaohe', key: 'status',
    status: ['divide'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.status',
    level: 0, routerOnly: false
  },
  {
    label: '普通工作量绩效分配明细', icon: 'icon-yuangongfenhong', key: 'unitValue',
    status: ['end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    url: 'performance.calculate.details.worker.unitValue',
    level: 1, routerOnly: true
  },
  {
    label: '统计', icon: 'icon-tongjichaifenjieguo', key: 'statistics',
    status: ['compute', 'end'], roles: ['manage', 'operation', 'view'], mode: 'view',
    level: 0, routerOnly: false
  }
]

export function xlsxDataMapping (file, keyMap) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = ({ target }) => {
      const workbook = XLSX.read(target.result, {
        type: 'binary',
        cellDates: true
      })
      const json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
        blankrows: true, raw: false, defval: null
      })
      if (!json.length) {
        return reject(new Error('数据为空'))
      }
      for (let i = 0; i < keyMap.length; i++) {
        if (keyMap[i].mapping === false) {
          continue;
        }
        if (json[0][keyMap[i].title] === undefined) {
          return reject(new Error('缺少' + keyMap[i].title + '字段'))
        }
      }
      resolve(json.map(row => {
        const obj = {}
        keyMap.forEach(item => {
          if (item.mapping !== false) {
            if (row[item.title]) {
              obj[item.key] = item.convert ? item.convert(row[item.title], obj) : row[item.title]
            } else {
              obj[item.key] = item.default || null
            }
          } else {
            obj[item.key] = item.default || null
          }
        })
        return obj
      }))
    }
    reader.readAsBinaryString(file)
  })
}