export const refQuery = Object.freeze({
  get: (key) => `/basis/data/ref/query/${key}`
})

export const data = Object.freeze({
  get: (name) => `/basis/data/${name}`,
  add: (name) => `/basis/data/${name}`,
  update: (name, id) => `/basis/data/${name}/${id}`,
  del: (name, id) => `/basis/data/${name}/${id}`,
})

export const common = Object.freeze({
  get: (name) => `/basis/${name}`,
  add: (name) => `/basis/${name}`,
  update: (name, id) => `/basis/${name}/${id}`,
  del: (name, id) => `/basis/${name}/${id}`,
})

export const file = Object.freeze({
  mkdir: '/fms/file/mkdir',
  upload: '/fms/upload',
  download: '/fms/download',
  del: '/fms/file'
})

export const operationLog = Object.freeze({
  get: '/basis/operation_log'
})

export const dataAuditLog = Object.freeze({
  base: '/basis/data/audit/log'
})

export const workOperation = Object.freeze({
  log: '/basis/operation/log'
})

export const agreement = Object.freeze({
  warning: '/basis/agreement/warning'
})

export const hisOrder = Object.freeze({
  get: '/basis/hisOrder'
})

export const org = Object.freeze({
  getTree: '/basis/org/tree',
  getAllTree: '/basis/org/all_tree',
  getAll: '/basis/org/all'
})

export const position = Object.freeze({
  getBaseCount: '/basis/position/base_count'
})

export const salary = Object.freeze({
  calculate: '/basis/salary/calculate',
  get_worker_salary: '/basis/data/ref/query/get_worker_salary',
  volume: '/basis/salary/worker/month/volume',
  init: '/basis/salary/init/org',
  initWorker: '/basis/salary/init/worker',
  updateAvg: '/basis/salary/add/avg',
  addOrg: '/basis/salary/add/org',
  addWorker: '/basis/salary/add/worker',
  count: 'basis/salary/count'
})

export const dataSyncLog = Object.freeze({
  getLastLog: '/basis/data_sync_log/lastLog',
  syncData: '/basis/data_sync_log/sync'
})

export const jobTitle = Object.freeze({
  getQueue: '/basis/job_title/promotion/queue',
  getQueueWarning: '/basis/job_title/promotion/warning'
})

export const worker = Object.freeze({
  getDetail: '/basis/worker/detail',
})

export const dataerImport = Object.freeze({
  url: '/basis/import'
})

export const wage = Object.freeze({
  clear: '/basis/wage/clear'
})
