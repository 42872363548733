export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [
      { method: 'GET', path: '/internalcontrol/budget/detail/info' },
      { method: 'GET', path: '/internalcontrol/budget/year/info' },
      { method: 'GET', path: '/internalcontrol/status_manage/config' },
    ]
  },
  getByAuth: {
    funKey: 'getByAuth',
    name: '查看权限内的',
    apis: [
      { method: 'GET', path: '/internalcontrol/budget/detail/info/by_auth' },
      { method: 'GET', path: '/internalcontrol/budget/year/info/my' },
      { method: 'GET', path: '/internalcontrol/status_manage/config' }
    ]
  }
}