import router from '@/router'

const state = {
  jumpPageParm: {},
  currentMenus: [],
  currentMenu: null,
  activeMenuCode: null,
  topMenus: [],
  openLeftMenuCode: null
}

const getNoMenuPageParentRouter = (routerData) => {
  if (routerData.name === 'internalcontrol.budget.details') {
    return ['internalcontrol.budget.manage', 'internalcontrol.budget.home', 'internalcontrol.budget.handle']
  }
  if (routerData.name === 'internalcontrol.purchase.details') {
    return ['internalcontrol.purchase.manage', 'internalcontrol.purchase.home', 'internalcontrol.purchase.handle']
  }
  if (routerData.name === 'internalcontrol.purchase.details') {
    return ['internalcontrol.purchase_method.manage', 'internalcontrol.purchase_method.home', 'internalcontrol.purchase_method.handle']
  }
  if (routerData.name === 'hrms.count.count') {
    return ['hrms.count.home']
  }
  return []
}

const findMenu = (menus, name) => {
  let find = false
  let i = 0
  while (i < menus.length) {
    let v = menus[i]
    if (v.url === name) {
      find = true
      break
    }
    find = findMenu(v.children, name)
    if (find) {
      find = true
      break
    }
    i += 1
  }
  return find
}

const findMenuData = (menus, name) => {
  let find = null
  let i = 0
  while (i < menus.length) {
    let v = menus[i]
    if (v.url === name) {
      find = v
      break
    }
    find = findMenuData(v.children, name)
    if (find) {
      break
    }
    i += 1
  }
  return find
}

const authTest = (menus, routerData) => {
  let pass = findMenu(menus, routerData.name)
  if (!pass) {
    let routerList = getNoMenuPageParentRouter(routerData).map(v => {
      return {
        name: v
      }
    })
    let i = 0
    while (!pass && i < routerList.length) {
      pass = findMenu(menus, routerList[i].name)
      i += 1
    }
  }
  return pass
}

const getFirstMenu = (menus) => {
  let i = 0
  let findMenu = null
  while (findMenu == null && i < menus.length) {
    if (menus[i].url) {
      findMenu = menus[i]
    } else if (menus[i].children) {
      findMenu = getFirstMenu(menus[i].children)
    }
    i ++
  }
  return findMenu
}

const getters = {
  jumpPageParm: state => state.jumpPageParm,
  currentMenus: state => state.currentMenus,
  currentMenu: state => state.currentMenu,
  activeMenuCode: state => state.activeMenuCode,
  topMenus: state => state.topMenus,
  openLeftMenuCode: state => state.openLeftMenuCode,
  userFirstPage: (state) => {
    let goMenu = getFirstMenu(state.currentMenus)
    return {name: goMenu && goMenu.url ? goMenu.url : 'null_menu'}
  }
}

const actions = {
  setOpenLeftMenuCode ({ commit }, data) {
    commit('setOpenLeftMenuCode', data)
  },
  setJumpPageParm ({ commit }, data) {
    commit('setJumpPageParm', data)
  },
  setCurrentMenus ({ commit }, menus) {
    let menuMap = (pmenu, menu) => {
      let keys = ['clientType', 'icon', 'id', 'name', 'pid', 'sort', 'sys', 'type', 'url']
      let data = {}
      keys.forEach(key => data[key] = menu[key])
      data.pathCode = pmenu ? pmenu.pathCode + '_' + (menu.id + '#') : ('0#_' + String(menu.id) + '#')
      data.children = menu.children ? menu.children.map(v => menuMap(data, v)) : []
      data.open = false
      return data
    }
    commit('setCurrentMenus', menus.map(v => menuMap(null, v)))
  },
  setCurrentMenu ({ commit, getters }, {menu, goChild, noJump}) {
    // if (getters.activeMenuCode && getters.activeMenuCode.startsWith(menu.pathCode)) {
    //   return
    // }
    let jumpRouter = null
    if (menu.url) {
      jumpRouter = menu
    } else if (goChild && menu.type === 'menu') {
      jumpRouter = getFirstMenu(menu.children)
    }
    menu = jumpRouter || menu
    commit('setActiveMenuCode', menu.pathCode)
    commit('setCurrentMenu', menu)
    if (menu.pathCode.split('_'.length > 2)) {
      let letMenu = getters.currentMenus.find(v => menu.pathCode.startsWith(v.pathCode))
      if (letMenu) {
        letMenu = letMenu.children.find(v => menu.pathCode.startsWith(v.pathCode))
        if (letMenu) {
          commit('setOpenLeftMenuCode', letMenu.pathCode)
        }
      }
    }
    if (jumpRouter && jumpRouter.pathCode.split('_').length === 5) {
      let m = {children: getters.currentMenus}
      let i = 0
      while (m && i < 3) {
        m = m && m.children ? m.children.find(v => getters.activeMenuCode.startsWith(v.pathCode)) : null
        i += 1
      }
      commit('setTopMenus', m ? m.children : [])
    } else if (jumpRouter) {
      commit('setTopMenus', [])
    }

    if (!noJump && jumpRouter) {
      router.push({name: jumpRouter.url})
    }
  },
  setActiveMenuCode ({ commit }, code) {
    commit('setActiveMenuCode', code)
  },
  routerUpdate ({ getters, dispatch }, data) {
    if (getters.currentMenu && data.name === getters.currentMenu.url) {
      return
    }
    let menu = findMenuData(getters.currentMenus, data.name)
    if (!menu) {
      let pMenus = getNoMenuPageParentRouter(data).map(v => {
        return {
          name: v
        }
      })
      let i = 0
      while (!menu && i < pMenus.length) {
        menu = findMenuData(getters.currentMenus, pMenus[i].name)
        i += 1
      }
    }
    if (menu) {
      dispatch('setCurrentMenu', {
        menu, noJump: true
      })
    }
  },
  goFirstPage ({ getters }) {
    console.log(getters.jumpPageParm)
    if (getters.jumpPageParm.value || getters.jumpPageParm.path || getters.jumpPageParm.name) {
      if (getters.jumpPageParm.type === 'href') {
        window.location.href = getters.jumpPageParm.value
      } else if (getters.jumpPageParm.name && authTest(getters.currentMenus, getters.jumpPageParm)) {
        // 零时处理， 需要优化
        router.push(getters.jumpPageParm)
      } else if (getters.currentUserId && ['install.app'].includes(getters.jumpPageParm.name)) {
        // 已登录就可以访问的页面
        router.push(getters.jumpPageParm)
      } else {
        router.push(getters.userFirstPage)
      }
    } else {
      router.push(getters.userFirstPage)
    }
  }
}

const mutations = {
  setOpenLeftMenuCode (state, data) {
    state.openLeftMenuCode = data
  },
  setTopMenus (state, data) {
    state.topMenus = data
  },
  setCurrentMenus (state, data) {
    state.currentMenus = data
  },
  setCurrentMenu (state, data) {
    state.currentMenu = data
  },
  setActiveMenuCode (state, data) {
    state.activeMenuCode = data
  },
  setJumpPageParm (state, data) {
    state.jumpPageParm = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
