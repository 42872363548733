export const sortTypes = ['text', 'number']

export const clSortDatas = function ({datas, sortType, funSort, type, key}) {
  if (datas.length < 2) {
    return datas
  }
  let useFun = null
  if (funSort) {
    useFun = funSort
  } else if (sortType === 'text') {
    useFun = sortText
  } else {
    useFun = sortNumber
  }
  for (let indexA in datas) {
    for (let indexB in datas) {
      let nIndexB = Number(indexB)
      if (nIndexB < (datas.length - 1 - indexA) && useFun(datas[nIndexB][key], datas[nIndexB + 1][key], type)) {
        [datas[nIndexB], datas[nIndexB + 1]] = [datas[nIndexB + 1], datas[nIndexB]]
      }
    }
  }
  return datas
}

const sortText = function (a, b, type) {
  a = a || ''
  b = b || ''
  return type === 'desc' ? (a > b) : (a < b)
}

const sortNumber = function (a, b, type) {
  a = Number(a) || 0
  b = Number(b) || 0
  return type === 'desc' ? (a > b) : (a < b)
}

export const tableSortDatas = function (dataList, relationSort, sortMap, sortConfig) {
  if (!sortMap) {
    return dataList
  }
  // 关联排序
  let i = relationSort.length - 1
  while (i > -1) {
    let field = relationSort[i]
    let type = sortMap[field]
    if (type) {
      dataList = clSortDatas({datas: dataList, sortType: sortConfig[field], type, key: field})
    }
    i = i - 1
  }
  for (let field in sortMap) {
    let type = sortMap[field]
    if (!relationSort.includes(field) && type) {
      dataList = clSortDatas({datas: dataList, sortType: sortConfig[field], type, key: field})
    }
  }
  return dataList
}