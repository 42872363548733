export default {
  handler: {
    funKey: 'handler',
    name: '处理',
    apis: [
      { method: 'GET', path: '/internalcontrol/purchase_batch/detail/by_auth' },
      { method: 'GET', path: '/internalcontrol/purchase_batch' },
      { method: 'GET', path: '/internalcontrol/purchase_batch/my' },
      { method: 'POST', path: '/internalcontrol/purchase_batch/switch/{id}' },

      { method: 'GET', path: '/internalcontrol/purchase_batch/detail' },
      { method: 'POST', path: '/internalcontrol/purchase_batch/detail' },
      { method: 'PUT', path: '/internalcontrol/purchase_batch/detail/{id}' },
      { method: 'DELETE', path: '/internalcontrol/purchase_batch/detail/{id}' },
      { method: 'POST', path: '/internalcontrol/purchase_batch/detail/switch/{id}' },

      { method: 'GET', path: '/internalcontrol/budget/detail' },
      { method: 'GET', path: '/internalcontrol/status_manage/switch_config' },
      { method: 'GET', path: '/internalcontrol/status_manage/config' }
    ]
  },
  manage: {
    funKey: 'manage',
    name: '管理',
    apis: [
      { method: 'GET', path: '/internalcontrol/purchase_batch/detail' },
      { method: 'GET', path: '/internalcontrol/purchase_batch' },
      { method: 'POST', path: '/internalcontrol/purchase_batch' },
      { method: 'PUT', path: '/internalcontrol/purchase_batch/{id}' },
      { method: 'DELETE', path: '/internalcontrol/purchase_batch/{id}' },
      { method: 'POST', path: '/internalcontrol/purchase_batch/switch/{id}' },

      { method: 'GET', path: '/internalcontrol/purchase_batch/detail' },
      { method: 'POST', path: '/internalcontrol/purchase_batch/detail' },
      { method: 'PUT', path: '/internalcontrol/purchase_batch/detail/{id}' },
      { method: 'DELETE', path: '/internalcontrol/purchase_batch/detail/{id}' },
      { method: 'POST', path: '/internalcontrol/purchase_batch/detail/switch/{id}' },
      { method: 'GET', path: '/internalcontrol/purchase_batch/detail/by_auth' },

      { method: 'GET', path: '/internalcontrol/budget/detail' },
      { method: 'GET', path: '/internalcontrol/status_manage/switch_config' },
      { method: 'GET', path: '/internalcontrol/status_manage/config' }
    ]
  },
  viewFile: {
    funKey: 'viewFile',
    name: '文件查看',
    apis: [
      { method: 'GET', path: '/fms/file' },
      { method: 'GET', path: '/fms/download' }
    ]
  },
  delFile: {
    funKey: 'delFile',
    name: '文件删除',
    apis: [
      { method: 'DELETE', path: '/fms/file' },
      { method: 'PUT', path: '/internalcontrol/purchase_batch/file_ids/{id}' }
    ]
  },
  uploadFile: {
    funKey: 'uploadFile',
    name: '文件上传',
    apis: [
      { method: 'DELETE', path: '/fms/file' },
      { method: 'PUT', path: '/internalcontrol/purchase_batch/file_ids/{id}' }
    ]
  }
}