export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/job_title/promotion',
      method: 'GET'
    }]
  },
  queue: {
    funKey: 'queue',
    name: '明细'
  }
}