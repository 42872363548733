export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/goods',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/internalcontrol/goods',
      method: 'POST'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/internalcontrol/goods/{id}',
      method: 'PUT'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/internalcontrol/goods/{id}',
      method: 'DELETE'
    }]
  }
}