<style scoped></style>

<template>
  <div style="padding-bottom: 200px;">
    <h2>自定义插槽<fm-btn @click="add">添加</fm-btn></h2>
    <div>
      <fm-table-new highlight-row :columns="columns" :data-list="data">
        <div slot-scope="{ row, column, index }" slot="test">
          <div :data-row="row" :data-column="column" :data-index="index">自定义插槽</div>
        </div>
      </fm-table-new>
    </div>

    <div style="display: flex;margin-top: 20px;">
      <div>
          <h2>固定宽度</h2>
          <div style="width: 500px;">
            <fm-table-new highlight-row :columns="columns" :data-list="data">
              <div slot-scope="{ row, column, index }" slot="test">
                <div :data-row="row" :data-column="column" :data-index="index">自定义插槽</div>
              </div>
            </fm-table-new>
          </div>
      </div>
      <div style="flex: 1; margin-left: 20px;">
        <h2>列宽拖拽调整</h2>
        <div style="display: flex;">
          <fm-table-new style="flex: 1;" :columns="[
            {field: 'v1', resizable: true, title: 'v1'},
            {field: 'v2', resizable: true, title: 'v2'}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1; margin-left: 20px;">
        <h2>自动填充满父级大小</h2>
        <div style="display: flex;height: 150px;padding: 0;">
          <fm-table-new auto-height style="flex: 1;" :columns="[
            {field: 'v1', resizable: true, title: 'v1'},
            {field: 'v2', resizable: true, title: 'v2'}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
    </div>

    <h2>固定列</h2>
    <div>
      <fm-table-new highlight-row :columns="columns2" :data-list="data2">
        <div slot-scope="{ row, column, index }" slot="test">
          <div :data-row="row" :data-column="column" :data-index="index">自定义插槽</div>
        </div>
      </fm-table-new>
    </div>

    <h2>固定表头+展开+大小调整</h2>
    <div>
      <fm-table-new sizer :height="200" :columns="columns" :data-list="data">
        <div slot-scope="{ row, column, index }" slot="test">
          <div :data-row="row" :data-column="column" :data-index="index">自定义插槽</div>
        </div>
      </fm-table-new>
    </div>

    <h2>固定列+固定表头</h2>
    <div>
      <fm-table-new :height="200" :columns="columns2" :data-list="data2">
        <div slot-scope="{ row, column, index }" slot="test">
          <div :data-row="row" :data-column="column" :data-index="index">自定义插槽</div>
        </div>
      </fm-table-new>
    </div>

    <div style="display: flex;margin-top: 20px;">
      <div style="flex: 1">
        <h2>自定义显示字段</h2>
        <div>
          <fm-table-new highlight-row :columns="columns3" :data-list="data" custom-field>
            <div slot-scope="{ row, column, index }" slot="test">
              <div :data-row="row" :data-column="column" :data-index="index">自定义插槽</div>
            </div>
          </fm-table-new>
        </div>
      </div>
      <div style="flex: 1;margin-left: 20px;">
        <h2>可选择</h2>
        <div>
          <fm-table-new sizer highlight-row selection :columns="[
            {title: 'A', slot: 'A'},
            {title: 'B', slot: 'B'}
          ]" :data-list="[...new Array(3)].map((v, i) => {
            return {v: i}
          })" custom-field>
            <div slot-scope="{ index }" slot="A">A{{index}}</div>
            <div slot-scope="{ index }" slot="B">A{{index}}</div>
          </fm-table-new>
        </div>
      </div>
    </div>

    <h2>对齐设置</h2>
    <div style="display: flex;">
      <fm-table-new border style="flex: 1;" :columns="[
        {align: 'left', field: 'v1', title: '左对齐'},
        {align: 'center', field: 'v2', title: '居中'}
      ]" :data-list="tmpData"></fm-table-new>
      <fm-table-new border style="flex: 1;" :columns="[
        {halign: 'right', field: 'v1', title: '标题右对齐'},
        {valign: 'right', field: 'v2', title: '数据右对齐'}
      ]" :data-list="tmpData"></fm-table-new>
      <fm-table-new border style="flex: 1;" :columns="[
        {halign: 'center', valign: 'right', field: 'v1', title: '标题和数据自定义对齐'},
        {align: 'center', field: 'v2', title: 'B'}
      ]" :data-list="tmpData"></fm-table-new>
    </div>

    <div style="display: flex;margin-top: 20px;">
      <div style="flex: 1">
        <h2>排序</h2>
        <div style="display: flex;">
          <fm-table-new style="flex: 1;" :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1;margin-left: 20px;">
        <h2>自定义排序方法</h2>
        <div style="display: flex;">
          <fm-table-new style="flex: 1;" :columns="[
            {field: 'v1', title: 'v1', sort: true, sortMethod: (row1, row2, type) => type === 'desc' ? row1.v1 < row2.v1 : row1.v1 > row2.v1},
            {field: 'v2', title: 'v2', sort: true, sortMethod: (row1, row2, type) => type === 'asc' ? row1.v2 > row2.v2 : row1.v2 < row2.v2}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1;margin-left: 20px;">
        <h2>组合排序<fm-tag>union-sort</fm-tag></h2>
        <div style="display: flex;">
          <fm-table-new style="flex: 1;" union-sort :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number},
            {field: 'v3', title: 'v3', sort: true, dataType: Number},
            {field: 'v4', title: 'v4', sort: true, dataType: Number}
          ]" :data-list="randUnionSortData"></fm-table-new>
        </div>
      </div>
    </div>

    <h2>筛选</h2>
    <div>
      <fm-table-new style="flex: 1;" :columns="[
        {field: 'v1', title: 'v1', filter: true, sort: true},
        {field: 'v2', title: 'v2', filter: true, filterMultiple: false},
        {
          field: 'v3', title: 'v3', filter: true, filters: [
            {label: '包含1', value: '1'},
            {label: '包含2', value: '2'},
            {label: '包含3', value: '3'}
          ], filterMultiple: false, filterMethod: ({row, column, value, type}) => value.indexOf(String(row.v3)) > -1
        },
        {
          field: 'v4', title: 'v4', filter: true, filters: [
            {label: '包含1', value: '1'},
            {label: '包含2', value: '2'},
            {label: '包含3', value: '3'}
          ], filterMethod: ({row, column, value, type}) => value.indexOf(String(row.v4)) > -1
        },
        {field: 'v5', title: 'v5', filter: true, dataType: Number},
        {field: 'v6', title: 'v6', filter: true, dataType: Number, filterMultiple: false}
      ]" :data-list="tmpData"></fm-table-new>
    </div>

    <h2>简易筛选</h2>
    <div>
      <fm-table-new simple-filter style="flex: 1;" :columns="[
        {field: 'v1', title: 'v1', filter: true, sort: true},
        {field: 'v2', title: 'v2', filter: true, filterMultiple: false},
        {
          field: 'v3', title: 'v3', filter: true, filters: [
            {label: '包含1', value: '1'},
            {label: '包含2', value: '2'},
            {label: '包含3', value: '3'}
          ], filterMultiple: false, filterMethod: ({row, column, value, type}) => value.indexOf(String(row.v3)) > -1
        },
        {
          field: 'v4', title: 'v4', filter: true, filters: [
            {label: '包含1', value: '1'},
            {label: '包含2', value: '2'},
            {label: '包含3', value: '3'}
          ], filterMethod: ({row, column, value, type}) => value.indexOf(String(row.v4)) > -1
        },
        {field: 'v5', title: 'v5', filter: true, dataType: Number},
        {field: 'v6', title: 'v6', filter: true, dataType: Number, filterMultiple: false}
      ]" :data-list="tmpData"></fm-table-new>
    </div>

    <div style="display: flex;margin-top: 20px;">
      <div style="flex: 1">
        <h2>斑马纹</h2>
        <div style="display: flex;">
          <fm-table-new style="flex: 1;" stripe :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1; margin-left: 10px;">
        <h2>选择行高亮</h2>
        <div style="display: flex;">
          <fm-table-new style="flex: 1;" highlight-row @highlight-row-change="highlight-rowChange" :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
    </div>

    <div style="display: flex;margin-top: 20px;">
      <div style="flex: 1">
        <h2>普通边框</h2>
        <div style="display: flex;">
          <fm-table-new style="flex: 1;" border :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1">
        <h2>行边框</h2>
        <div style="display: flex;margin-left: 10px;">
          <fm-table-new style="flex: 1;" border="row" :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1">
        <h2>列边框</h2>
        <div style="display: flex;margin-left: 10px;">
          <fm-table-new style="flex: 1;" border="col" :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number},
            {field: 'v3', title: 'v3', sort: true, dataType: Number},
            {field: 'v4', title: 'v4', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
    </div>

    <div style="display: flex;margin-top: 20px;">
      <div style="flex: 1">
        <h2>large</h2>
        <div style="display: flex;">
          <fm-table-new size="large" style="flex: 1;" border :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1; margin-left: 10px;">
        <h2>norm</h2>
        <div style="display: flex;">
          <fm-table-new size="norm" style="flex: 1;" border :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1; margin-left: 10px;">
        <h2>small</h2>
        <div style="display: flex;">
          <fm-table-new size="small" style="flex: 1;" border :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1; margin-left: 10px;">
        <h2>mini</h2>
        <div style="display: flex;">
          <fm-table-new size="mini" style="flex: 1;" border :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
    </div>

    <div style="display: flex;margin-top: 20px;">
      <div style="flex: 1">
        <h2>合计</h2>
        <div style="display: flex;margin-left: 10px;">
          <fm-table-new summary style="flex: 1;" border="col" :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: String},
            {field: 'v2', title: 'v2', sort: true, dataType: String},
            {field: 'v3', title: 'v3', sort: true, dataType: Number},
            {field: 'v4', title: 'v4', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1">
        <h2>不参与合计</h2>
        <div style="display: flex;margin-left: 10px;">
          <fm-table-new summary :summary-field="['v2', 'v3']" style="flex: 1;" border="col" :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number},
            {field: 'v3', title: 'v3', sort: true, dataType: Number},
            {field: 'v4', title: 'v4', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1">
        <h2>自定义合计</h2>
        <div style="display: flex;margin-left: 10px;">
          <fm-table-new summary :summary-method="summaryMethod" style="flex: 1;" border="col" :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number},
            {field: 'v3', title: 'v3', sort: true, dataType: Number},
            {field: 'v4', title: 'v4', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1">
        <h2>指定小数位</h2>
        <div style="display: flex;margin-left: 10px;">
          <fm-table-new summary :summary-decimal="2" style="flex: 1;" border="col" :columns="[
            {field: 'v1', title: 'v1', sort: true, dataType: Number},
            {field: 'v2', title: 'v2', sort: true, dataType: Number},
            {field: 'v3', title: 'v3', sort: true, dataType: Number},
            {field: 'v4', title: 'v4', sort: true, dataType: Number}
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
    </div>

    <div style="display: flex;margin-top: 20px;">
      <div style="flex: 1">
        <h2>render自定义渲染</h2>
        <div style="display: flex;margin-left: 10px;">
          <fm-table-new style="flex: 1;" border :columns="[
            {
              title: 'v1', render: (h, row, column, index) => {
                return h('fm-btn', {
                  props: {type: 'primary'}
                }, index)
              }
            },
            {
              title: 'v2', render: (h, row, column, index) => {
                return h('fm-tag', {}, index)
              }
            }
          ]" :data-list="tmpData"></fm-table-new>
        </div>
      </div>
    </div>

    <div style="display: flex;margin-top: 20px;">
      <div style="flex: 1; margin-left: 20px;">
        <h2>固定高度</h2>
        <div style="display: flex;height: 150px;padding: 0;">
          <fm-table-new :border="false" auto-height full style="flex: 1;" :columns="[
            {field: 'v1', title: 'v1'},
            {field: 'v2', title: 'v2'}
          ]" :data-list="[]"></fm-table-new>
        </div>
      </div>
      <div style="flex: 1; margin-left: 20px;">
        <h2>空白提示</h2>
        <div style="display: flex;height: 150px;padding: 0;">
          <fm-table-new auto-height style="flex: 1;" :columns="[
            {field: 'v1', title: 'v1'},
            {field: 'v2', title: 'v2'}
          ]" :data-list="[]">
          </fm-table-new>
        </div>
      </div>
      <div style="flex: 1; margin-left: 20px;">
        <h2>自定义空白提示</h2>
        <div style="display: flex;height: 150px;padding: 0;">
          <fm-table-new auto-height style="flex: 1;" :columns="[
            {field: 'v1', title: 'v1'},
            {field: 'v2', title: 'v2'}
          ]" :data-list="[]">
            <div slot="empty">这里啥东西都没有</div>
          </fm-table-new>
        </div>
      </div>
      <div style="flex: 1; margin-left: 20px;">
        <h2>无高度空白提示</h2>
        <div style="display: flex;padding: 0;">
          <fm-table-new style="flex: 1;" :columns="[
            {field: 'v1', title: 'v1'},
            {field: 'v2', title: 'v2'}
          ]" :data-list="[]">
            <div slot="empty">这里啥东西都没有</div>
          </fm-table-new>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var repeat = 3
var rand = [...new Array(repeat)].map(() => {
  return (Math.random() * 100).toFixed(Math.random() * 10)
})

var randUnionSortData = [...new Array(10)].map(() => {
  return {
    v1: rand[parseInt(Math.random() * 10 % repeat)],
    v2: rand[parseInt(Math.random() * 10 % repeat)],
    v3: rand[parseInt(Math.random() * 10 % repeat)],
    v4: rand[parseInt(Math.random() * 10 % repeat)]
  }
})

export default {
  name: 'FmTableNewDemo',
  data () {
    return {
      randUnionSortData: randUnionSortData,
      tmpData: [...new Array(3)].map((v, i) => {
        return {v1: i + 1, v2: i + 1, v3: i + 1, v4: i + 1, v5: i + 1, v6: i + 1}
      }),
      columns: [...[...new Array(10)].map((v, i) => {
        return {field: 'key' + i, title: '字段' + i}
      }), {
        slot: 'test',
        title: 'test插槽'
      }, {
        title: '展开',
        expand (h) {
          return h('div', {}, 'hello')
        }
      }],
      data: [...new Array(1)].map((n, m) => {
        let rows = {}
        ;[...new Array(10)].forEach((v, i) => {
          rows[`key${i}`] = m + '-' + i
        })
        return rows
      }),
      columns1: [...[...new Array(10)].map((v, i) => {
        return i % 3 === 0 ? {field: 'key' + i, title: '字段' + i, fixed: true, width: 100} : {field: 'key' + i, title: '字段' + i}
      })],
      data1: [...new Array(10)].map((n, m) => {
        let rows = {}
        ;[...new Array(10)].forEach((v, i) => {
          rows[`key${i}`] = m + '-' + i
        })
        return rows
      }),
      columns2: [...[...new Array(10)].map((v, i) => {
        if (i % 2 === 0) {
          return {field: 'key' + i, title: '字段' + i}
        } else if (i % 3 === 0) {
          return {fixed: 'left', field: 'key' + i, title: '字段' + i, width: 100}
        } else {
          return {fixed: 'right', field: 'key' + i, title: '字段' + i}
        }
      })],
      data2: [...new Array(10)].map((n, m) => {
        let rows = {}
        ;[...new Array(10)].forEach((v, i) => {
          rows[`key${i}`] = m + '-' + i
        })
        return rows
      }),
      columns3: [...[...new Array(10)].map((v, i) => {
        return {field: 'key' + i, title: '字段' + i, hidden: i % 2 === 0}
      })],
    }
  },
  methods: {
    add () {
      let rows = {}
      ;[...new Array(10)].forEach((v, i) => {
        rows[`key${i}`] = 'n' + '-' + i
      })
      this.data = [...this.data, rows]
    },
    highlightRowChange (newData, oldData) {
      console.log(newData, oldData)
    },
    summaryMethod ({data, column}) {
      if (column.field === 'v1') {
        return '合计'
      } else if (column.field === 'v2') {
        return data.length + '项'
      } else if (column.field === 'v3') {
        return data.reduce((a, b) => isNaN(b[column.field]) ? a : (a + b[column.field]), 0) + '元'
      } else {
        return ''
      }
    }
  }
}
</script>
