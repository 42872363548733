<template>
  <div class="fm-form-content" :class="{
    'fm-form-inline': inline === undefined || inline === true || typeof inline === 'number',
    'fm-form-inline-2': typeof inline === 'number' && inline === 2,
    'fm-form-inline-3': typeof inline === 'number' && inline === 3,
    'fm-form-inline-4': typeof inline === 'number' && inline === 4
  }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FmForm',
  components: {},
  data() {
    return {
      formRegisterVerifier: []
    }
  },
  provide () {
    return {
      form: this,
      labelWidth: this.labelWidth,
      labelAlign: this.labelAlign,
      labelAlone: this.labelAlone === undefined || this.labelAlone === true
    }
  },
  props: {
    inline: {
      type: [Boolean, Number],
      default: false,
      validator: function (value) {
        return !(typeof value === 'number' && value < 1 && value > 4)
      }
    },
    labelWidth: {
      type: String,
      default: null
    },
    labelAlone: {
      type: Boolean,
      default: false
    },
    labelAlign: {
      type: String,
      default: 'right',
      validator: function (value) {
        return ['left', 'center', 'right'].includes(value)
      }
    }
  },
  computed: {},
  methods: {
    verifier () {
      try {
        this.formRegisterVerifier.forEach(component => {
          const msg = component.verifier()
          if (msg !== '') {
            throw new Error(msg)
          }
        })
        return true
      } catch {
        return false
      }
    }
  },
  watch: {},
  beforeCreate() {},
  created () {
    this.$on('form-register-verifier', (component) => {
      this.formRegisterVerifier.push(component)
    })
  },
  beforeMount() {},
  mounted () {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
@import './styles/form.less';
</style>