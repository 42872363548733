<style lang="less"></style>

<template>
  <div>
    <fm-badge>aaa</fm-badge>
  </div>
</template>

<script>
export default {
  name: 'FmBadgeDemo'
}
</script>
