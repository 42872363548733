export default {
  get: {
    name: '获取',
    funKey: 'get',
    apis: [
      { path: '/scm/goods', method: 'GET' }
    ]
  },
  add: {
    name: '新增',
    funKey: 'add',
    apis: [
      { path: '/scm/goods', method: 'POST' }
    ]
  },
  update: {
    name: '修改',
    funKey: 'update',
    apis: [
      { path: '/scm/goods/{id}', method: 'PUT' }
    ]
  },
  fileData: {
    name: '附件',
    funKey: 'fileData',
    apis: [
      { path: '/scm/goods/{id}', method: 'PUT' }
    ]
  },
  del: {
    name: '删除',
    funKey: 'del',
    apis: [
      { path: '/scm/goods/{id}', method: 'DELETE' }
    ]
  }
}