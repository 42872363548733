<template>
  <div class="fm-table-thead-search">
    <template v-if="searchType === 'text'">
      <div class="thead-search-one">
        <input class="thead-search-one" type="text" :value="searchValue" placeholder="" @input="valueChange($event.target.value)" @focus="focusInput">
      </div>
    </template>
    <template v-if="searchType === 'select'">
      <fm-select text block :value="searchValue" @input="valueChange" clearable placeholder="" size="mini" filterable>
        <fm-option
          v-for="item in selectDatas"
          :key="item.key"
          :label="item.label"
          :value="item.key">
        </fm-option>
      </fm-select>
    </template>
    <template v-if="searchType === 'mu_select'">
      <fm-select text block :value="searchValue" @input="valueChange" multiple placeholder="" size="mini" collapse-tags>
        <fm-option
          v-for="item in selectDatas"
          :key="item.key"
          :label="item.label"
          :value="item.key">
        </fm-option>
      </fm-select>
    </template>
    <template v-if="searchType === 'interval_number'">
      <div class="thead-search-one db-input">
        <input class="" type="number" :value="searchValue[0]" @focus="focusInput" placeholder="" @input="valueChange($event.target.value, 'min')"><span style="color: #dcdcdd;">—</span>
        <input type="number" :value="searchValue[1]" placeholder="" @focus="focusInput" @input="valueChange($event.target.value, 'max')">
      </div>
    </template>
  </div>
</template>

<script>
let dcopy = require('deep-copy')

export default {
  model: {
    prop: 'searchValue',
    event: 'change'
  },
  props: {
    searchType: {
      // text 模糊搜索
      // number 区间值
      // select 下拉选择
      type: String,
      default: 'text',
      validator: (data) => {
        return ['text', 'number', 'select', 'interval_number', 'mu_select'].includes(data)
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchValue: [String, Array, Number],
    selectDatas: {
      type: Array,
      default: () => {
        return []
      },
      validator: (data) => {
        let pass = true
        if (pass) {
          data.forEach((item) => {
            pass = pass && (typeof item.key === 'string' || typeof item.key === 'number') && (typeof item.label === 'string' || typeof item.label === 'number')
          })
        }
        return pass
      }
    }
  },
  methods: {
    focusInput (event) {
      event.currentTarget.select()
    },
    valueChange (text, type) {
      if (!type) {
        this.$emit('change', text)
      } else {
        let newValue = dcopy(this.searchValue) || []
        if (type === 'min') {
          newValue[0] = text
        } else if (type === 'max') {
          newValue[1] = text
        }
        this.$emit('change', newValue)
      }
    },
    clickTitleMenu (key) {
      this.$emit('clickTitleMenu', key)
    }
  }
}
</script>

<style lang="less">
@fm-table-search-height: 100%;
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
.fm-table-thead-search {
  width: 100%;
  font-size: 0.7rem;
  .thead-search-one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .el-select__tags {
      span {
        display: flex;
      }
    }
  }
  .db-input {
    display: flex;
    input {
      flex: 1;
      height: @fm-table-search-height;
    }
  }
  input {
    padding: 0;
    margin: 0;
    height: @fm-table-search-height;
    line-height: @fm-table-search-height;
    width: 100%;
    border-width: 0;
  }
  .el-input--mini .el-input__inner {
    height: @fm-table-search-height;
    line-height: @fm-table-search-height;
  }
  .el-select .el-input .el-select__caret {
    font-size: 0.88rem;
  }
  .el-input__icon {
    width: @fm-table-search-height;
  }
  .el-input--mini .el-input__icon {
    line-height: @fm-table-search-height;
  }
  .el-input--suffix .el-input__inner {
    padding-right: 1.5rem;
  }
  .el-input__inner {
    border-width: 0;
    color: #888;
    padding: 0 0.2rem;
  }
}
.fm-table-thead-search .fm-select .fm-select-list .fm-option {
  text-align: left;
}
</style>