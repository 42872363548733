/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Fri Dec 06 2019
 */
import Vue from 'vue'
import FmNotice from '../components/FmNotice.vue'

const listCls = 'fm-notice-box'
let listBox = null

function initBoxList () {
  if (!listBox) {
    listBox = new Vue({
      render(h) {
        return h('div', {
          attrs: {
            class: listCls
          }
        })
      }
    }).$mount()
    document.body.appendChild(listBox.$el)
  }
}

function getInstance(params, desc, duration, type) {
  initBoxList()


  if (typeof params === 'object') {
    params = Object.assign({}, defaultOptions, params)
  } else if (typeof params === 'string') {
    params = Object.assign({}, defaultOptions, {
      title: params
    })
    if (typeof desc === 'number') {
      params.duration = desc
    } else if (typeof desc === 'string') {
      params.desc = desc
      params.duration = duration
    }
  } else {
    params = Object.assign({}, defaultOptions)
  }

  const instance = new Vue({
    render(h) {
      return h(FmNotice, {
        props: {
          title: params.title,
          desc: params.desc,
          type: type,
          duration: params.duration
        },
        on: {
          close () {
            if (typeof params.onClose === 'function') {
              params.onClose()
            }
          }
        }
      })
    }
  }).$mount()

  listBox.$el.appendChild(instance.$el)
  const notice = instance.$children[0]
  return notice
}

const defaultOptions = {
  title: undefined,
  desc: undefined,
  type: 'norm',
  duration: 4500,
  onClose: undefined
}

export default {
  name: '$notice',
  methods: {
    info(title = undefined, desc = undefined, duration = 4500) {
      getInstance(title, desc, duration, 'info').show = true
    },
    success(title = undefined, desc = undefined, duration = 4500) {
      getInstance(title, desc, duration, 'success').show = true
    },
    warning(title = undefined, desc = undefined, duration = 4500) {
      getInstance(title, desc, duration, 'warning').show = true
    },
    error(title = undefined, desc = undefined, duration = 4500) {
      getInstance(title, desc, duration, 'error').show = true
    },
    open(title = undefined, desc = undefined, duration = 4500) {
      getInstance(title, desc, duration, 'norm').show = true
    }
  }
}