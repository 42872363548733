import roles from '../roles'
const allRoles = roles.map(v => v.code)

const tmpBudget = ['self', 'nk', 'assets', 'lc', 'bill', 'contract', 'other' ,'yz', 'fyz', 'sj', 'fsj', 'kz', 'fkz', 'zr', 'fzr', 'hsz', 'fhsz', 'ys', 'hs'].map(v => {
  let d = {}
  d[v] = ['get', 'viewFile', 'detial']
  return d
})

const tmpPurchase = ['self', 'nk', 'assets', 'lc', 'bill', 'contract', 'other', 'yz', 'fyz', 'sj', 'fsj', 'kz', 'fkz', 'zr', 'fzr', 'hsz', 'fhsz', 'ys', 'hs'].map(v => {
  let d = {}
  d[v] = ['get', 'viewFile']
  return d
})

const tmpPurchaseMethod = ['self', 'nk', 'assets', 'lc', 'bill', 'contract', 'other', 'yz', 'fyz', 'sj', 'fsj', 'kz', 'fkz', 'zr', 'fzr', 'hsz', 'fhsz', 'ys', 'hs'].map(v => {
  let d = {}
  d[v] = ['get', 'viewFile', 'viewDetailFile', 'uploadDetail', 'delDetailFile']
  return d
})

const tmp1 = ['yz', 'fyz', 'sj', 'fsj'].map(v => {
  let d = {}
  d[v] = ['get', 'data_file', 'getDataFile', 'downDataFile']
  return d
}).concat('nk').concat({
  kz: ['getMyOrg', 'add', 'edit', 'del', 'data_file', 'updateDataFile', 'delDataFile' ,'getDataFile', 'downDataFile']
})

const tmpAssets = ['yz', 'fyz', 'sj', 'fsj'].map(v => {
  let d = {}
  d[v] = ['get', 'label', 'ledger', 'detail']
  return d
}).concat(['nk', 'assets'])

const tmpAssetsIn = ['yz', 'fyz', 'sj', 'fsj'].map(v => {
  let d = {}
  d[v] = ['get']
  return d
}).concat(['nk', 'assets'])

export const tmpAssetsRepair = ['yz', 'fyz', 'sj', 'fsj'].map(v => {
  let d = {}
  d[v] = ['get', 'apply']
  return d
}).concat('nk')

let qb = ['yz', 'fyz', 'sj', 'fsj', 'nk', 'assets', 'lc', 'bill', 'contract']
let gr = ['self', 'kz', 'fkz', 'zr', 'fzr', 'hsz', 'fhsz', 'ys', 'hs', 'other']

export const applyRecordTmp = gr.map(v => {
  let data = {}
  data[v] = ['getMyOrg', 'back', 'detail', 'print']
  return data
}).concat(qb)

export default [{
  'icon': 'icon-a-zu1444',
  'name': '首页',
  'roles': allRoles,
  'url': 'home',
  'clientType': 'web'
},
{
  'code': 'budget',
  'icon': 'icon-yusuan',
  'clientType': 'web',
  'roles': allRoles,
  'name': '预算',
  'children': [{
    'clientType': 'web',
    'name': '预算管理',
    'roles': ['nk', 'lc', 'yz', 'sj'],
    'url': 'budget.manage'
  },
  {
    'clientType': 'web',
    'name': '预算',
    'roles': tmpBudget,
    'url': 'budget.handle'
  }]
},
{
  'code': 'purchase',
  'icon': 'icon-caigou',
  'roles': allRoles,
  'clientType': 'web',
  'name': '采购申报',
  'children': [{
    'clientType': 'web',
    'roles': ['nk', 'lc', 'yz', 'sj'],
    'name': '采购申报管理',
    'url': 'purchase.index'
  },
  {
    'clientType': 'web',
    'name': '采购申报',
    'roles': tmpPurchase,
    'url': 'purchase.handle'
  }]
},
{
  'code': 'purchase_method',
  'icon': 'icon-caigoufangshi',
  'clientType': 'web',
  'roles': allRoles,
  'name': '采购过程',
  'children': [{
    'clientType': 'web',
    'roles': ['nk', 'lc', 'yz', 'sj'],
    'name': '采购过程管理',
    'url': 'purchase_method.index'
  },
  {
    'clientType': 'web',
    'name': '采购过程',
    'roles': tmpPurchaseMethod,
    'url': 'purchase_method.handle'
  }]
},
{
  'icon': 'icon-caigouhetong',
  'name': '采购合同',
  'roles': tmp1.concat('contract'),
  'url': 'purchase.contract',
  'clientType': 'web'
},
{
  'code': 'accept',
  'icon': 'icon-cheliangleixing',
  'clientType': 'web',
  'roles': allRoles,
  'name': '采购验收',
  'children': [{
    'clientType': 'web',
    'roles': ['nk', 'yz', 'sj'].concat({
      kz: ['getMyOrg', 'new', 'editData', 'start', 'end', 'reStart', 'del', 'detail', 'data_file', 'getDataFile', 'updateDataFile', 'downDataFile', 'delDataFile']
    }),
    'name': '采购验收',
    'url': 'accept.manage'
  },
  {
    'clientType': 'web',
    'roles': allRoles,
    'name': '我的验收单',
    'url': 'accept.my'
  }]
},
{
  'icon': 'icon-caigouhetong',
  'name': '采购执行',
  'roles': tmp1,
  'url': 'purchase.implement',
  'clientType': 'web'
},
{
  'code': 'assets',
  'icon': 'icon-zichanxinxiguanli',
  'roles': ['yz', 'fyz', 'sj', 'fsj', 'nk', 'assets'],
  'clientType': 'web',
  'name': '资产信息管理',
  'children': [{
    'clientType': 'web',
    'roles': tmpAssets,
    'name': '全部资产',
    'url': 'assets.manage'
  },
  {
    'clientType': 'web',
    'roles': tmpAssets,
    'name': '单位资产',
    'url': 'assets.manage_dw'
  },
  {
    'clientType': 'web',
    'roles': tmpAssets,
    'name': '国有资产',
    'url': 'assets.manage_gy'
  },
  {
    'clientType': 'web',
    'name': '出入库管理',
    'roles': tmpAssetsIn,
    'url': 'assets.in'
  }]
},
{
  'icon': 'icon-zichanpandian',
  'name': '资产盘点',
  'roles': ['yz', 'fyz', 'sj', 'fsj', 'nk', 'assets'],
  'url': 'assets.check',
  'clientType': 'web'
},
{
  'code': 'repair',
  'icon': 'icon-install',
  'clientType': 'web',
  'roles': allRoles.concat('assets'),
  'name': '维修',
  'children': [{
    'clientType': 'web',
    'name': '维修申请',
    'roles': allRoles.concat('assets'),
    'url': 'repair.apply'
  },
  {
    'clientType': 'web',
    'roles': applyRecordTmp,
    'name': '维修申请记录',
    'url': 'repair.apply_record'
  },
  {
    'clientType': 'web',
    'roles': tmpAssetsRepair.concat('assets'),
    'name': '维修管理',
    'url': 'repair.manage'
  }]
},
{
  'code': 'scrap',
  'icon': 'icon-right-rotate',
  'roles': allRoles.concat('assets'),
  'clientType': 'web',
  'name': '报废',
  'children': [{
    'clientType': 'web',
    'name': '报废申请',
    'roles': allRoles.concat('assets'),
    'url': 'scrap.apply'
  },
  {
    'clientType': 'web',
    'roles': applyRecordTmp,
    'name': '申请记录',
    'url': 'scrap.record'
  }]
},
{
  'icon': 'icon-zhijieshouyao11',
  'name': '发票管理',
  'roles': ['bill', 'nk'],
  'url': 'base.bill',
  'clientType': 'web'
},
{
  'code': 'base',
  'icon': 'icon-shujuguanli',
  'clientType': 'web',
  'roles': ['nk'],
  'name': '数据管理',
  'children': [{
    'clientType': 'web',
    'name': '固定资产分类',
    'roles': ['nk'],
    'url': 'base.assets_type'
  },
  {
    'clientType': 'web',
    'name': '采购物品',
    'roles': ['nk'],
    'url': 'base.goods'
  },
  {
    'clientType': 'web',
    'name': '状态管理',
    'url': 'base.status'
  }]
}]