export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/official_document_send',
      method: 'GET'
    },
    {
      path: '/basis/status_manage/config',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/ucenter/user',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '明细'
  },
  editSendCode: {
    funKey: 'editSendCode',
    name: '文号编辑',
    apis: [{
      path: '/basis/official_document_send/send_code/{id}',
      method: 'PUT'
    }]
  },
  out_put: {
    funKey: 'out_put',
    name: '导出'
  },
  back: {
    funKey: 'back',
    name: '状态切换',
    apis: [{
      path: '/basis/official_document_send/switch/{id}',
      method: 'POST'
    }]
  },
  signin: {
    funKey: 'signin',
    name: '下发',
    apis: [{
      path: '/basis/official_document_send/assign/{id}',
      method: 'PUT'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '下发文件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取下发文件'
  },
  updateDataFile: {
    funKey: 'updateDataFile',
    name: '上传下发文件',
    apis: [{
      path: '/basis/official_document_send/over_file_ids/{id}',
      method: 'PUT'
    }]
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载下发文件'
  },
  delDataFile: {
    funKey: 'delDataFile',
    name: '删除下发文件',
    apis: [{
      path: '/basis/official_document_send/over_file_ids/{id}',
      method: 'PUT'
    }]
  }
}