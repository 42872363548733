<style scoped lang="less">
@import '../components/styles/values.less';
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * + & > * {
    margin-left: 10px;
  }
  .color {
    & > div:first-child {
      width: 180px;
      padding: 10px 20px;
      color: #FFF;
      font-weight: 800;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
.flex + .flex {
  margin-top: 10px;
}
.color-primary {
  background-color: @color-primary;
}
.color-primary-weight {
  background-color: @color-primary-weight;
}
.color-info {
  background-color: @color-info;
}
.color-info-weight {
  background-color: @color-info-weight;
}
.color-success {
  background-color: @color-success;
}
.color-success-weight {
  background-color: @color-success-weight;
}
.color-warning {
  background-color: @color-warning;
}
.color-warning-weight {
  background-color: @color-warning-weight;
}
.color-fail {
  background-color: @color-fail;
}
.color-fail-weight {
  background-color: @color-fail-weight;
}
.color-danger {
  background-color: @color-danger;
}
.color-danger-weight {
  background-color: @color-danger-weight;
}
h2 {
  margin: 40px 0 20px 0;
}
.color-border {
  background-color: @color-border;
}
.color-divider {
  background-color: @color-divider;
}
.color-component-text {
  background-color: @color-component-text;
}
.color-input-placeholder {
  background-color: @color-placeholder-font;
}
.color-disabled-text {
  background-color: @color-disabled-text;
}
.color-disabled-background {
  background-color: @color-disabled-background;
}
.color-title-text {background-color: @color-title-text;}
.color-content-text {background-color: @color-content-text;}
.color-sub-text {background-color: @color-sub-text;}
</style>

<template>
  <div>
    <div class="flex">
      <div class="color">
        <div class="color-primary">
          <div>主色调</div>
          <div>@color-primary</div>
          <div>#1cb5e0</div>
        </div>
      </div>
    </div>

    <h2>状态类颜色</h2>
    <div class="flex">
      <div class="color">
        <div class="color-primary">
          <div>主色</div>
          <div>@color-primary</div>
          <div>#1cb5e0</div>
        </div>
      </div>
      <div class="color">
        <div class="color-primary-weight">
          <div>着重主色</div>
          <div>@color-primary-weight</div>
          <div>#1e9de1</div>
        </div>
      </div>
      <div class="color">
        <div class="color-info">
          <div>info</div>
          <div>@color-info</div>
          <div>#65b9f0</div>
        </div>
      </div>
      <div class="color">
        <div class="color-info-weight">
          <div>着重info</div>
          <div>@color-info-weight</div>
          <div>#3fabf4</div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="color">
        <div class="color-success">
          <div>success</div>
          <div>@color-success</div>
          <div>#65bd72</div>
        </div>
      </div>
      <div class="color">
        <div class="color-success-weight">
          <div>着重success</div>
          <div>@color-success-weight</div>
          <div>#3cbf50</div>
        </div>
      </div>
      <div class="color">
        <div class="color-warning">
          <div>warning</div>
          <div>@color-warning</div>
          <div>#ec9738</div>
        </div>
      </div>
      <div class="color">
        <div class="color-warning-weight">
          <div>着重warning</div>
          <div>@color-warning-weight</div>
          <div>#ec8b1c</div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="color">
        <div class="color-fail">
          <div>fail</div>
          <div>@color-fail</div>
          <div>#d43f2c</div>
        </div>
      </div>
      <div class="color">
        <div class="color-fail-weight">
          <div>着重fail</div>
          <div>@color-fail-weight</div>
          <div>#d1230c</div>
        </div>
      </div>
      <div class="color">
        <div class="color-danger">
          <div>danger</div>
          <div>@color-danger</div>
          <div>#ed4014</div>
        </div>
      </div>
      <div class="color">
        <div class="color-danger-weight">
          <div>着重danger</div>
          <div>@color-danger-weight</div>
          <div>#d3330b</div>
        </div>
      </div>
    </div>

    <h2>其他颜色</h2>
    <div class="flex">
      <div class="color">
        <div class="color-border">
          <div>边框</div>
          <div>@color-border</div>
          <div>#dcdee2</div>
        </div>
      </div>
      <div class="color">
        <div class="color-divider">
          <div>分割线</div>
          <div>@color-divider</div>
          <div>#e8eaec</div>
        </div>
      </div>
      <div class="color">
        <div class="color-component-text">
          <div>组件字体</div>
          <div>@color-component-text</div>
          <div>#515a6e</div>
        </div>
      </div>
      <div class="color">
        <div class="color-input-placeholder">
          <div>输入框占位符</div>
          <div>@color-placeholder-font</div>
          <div>#dcdee2</div>
        </div>
      </div>
    </div>

    <h2>文字颜色</h2>
    <div class="flex">
      <div class="color">
        <div class="color-title-text">
          <div>标题</div>
          <div>@color-title-text</div>
          <div>#0e1524</div>
        </div>
      </div>
      <div class="color">
        <div class="color-content-text">
          <div>正文</div>
          <div>@color-content-text</div>
          <div>#17233d</div>
        </div>
      </div>
      <div class="color">
        <div class="color-sub-text">
          <div>辅助文字</div>
          <div>@color-sub-text</div>
          <div>#515a6e</div>
        </div>
      </div>
    </div>

    <h2>失效颜色</h2>
    <div class="flex">
      <div class="color">
        <div class="color-disabled-background" style="color: #657180;">
          <div>背景</div>
          <div>@color-disabled-background</div>
          <div>#f8f8f9</div>
        </div>
      </div>
      <div class="color">
        <div class="color-disabled-text">
          <div>文字</div>
          <div>@color-disabled-text</div>
          <div>#c5c8ce</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorDemo'
}
</script>
