export default {
  get: {
    funKey: 'get',
    name: '管理',
    apis: [
      {
        path: '/basis/worker/confirm/batch_wait',
        method: 'POST'
      },
      {
        path: '/basis/worker/confirm',
        method: 'GET'
      }
    ],
  }
}