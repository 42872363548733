<style scoped lang="less">
  .demo-wrap {
    width: 100px;height: 100px;background-color: #999;display: flex;
    align-items: center;
    justify-content: center;
  }
  .poper-demo-2 {
    width: 400px;
    height: 400px;
    background-color: #EEE;
    margin: 200px;
  }
  .poper-demo-3 {
    width: 400px;
    background-color: #EEE;
    margin: 30px;
  } 
</style>

<template>
  <div>
    <div class="poper-demo-1">
      <fm-btn @click="demo1 = !demo1">打开</fm-btn>
      <fm-poper v-model="demo1">
        <div class="demo-wrap">
          <fm-btn @click="demo1 = false">关闭</fm-btn>
        </div>
      </fm-poper>
    </div>
    <div class="poper-demo-2">
      <fm-btn @click="demo2 = !demo2" block>打开</fm-btn>
      <fm-poper v-model="demo2" position="right-end">
        <div class="demo-wrap">
          <fm-btn @click="demo2 = false">关闭</fm-btn>
        </div>
      </fm-poper>
    </div>
    <div class="poper-demo-3">
      <fm-btn @click="demo3 = !demo3" block>打开</fm-btn>
      <fm-poper v-model="demo3" position="right-end">
        <div class="demo-wrap">
          <fm-btn @click="demo3 = false">关闭</fm-btn>
        </div>
      </fm-poper>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmPoperDemo',
  data () {
    return {
      demo1: false,
      demo2: false,
      demo3: false
    }
  }
}
</script>
