export default {
  routerNames: ['calculate.index'],
  funs: {
    manage: {
      funKey: 'manage',
      name: '管理员',
      apis: [
        { method: 'POST', path: '/performance/pfm_month' },
        { method: 'GET', path: '/performance/pfm_month' },
        { method: 'DELETE', path: '/performance/pfm_month/{id}' },
        { method: 'POST', path: '/performance/pfm_month/status/{id}' },
        { method: 'POST', path: '/performance/pfm_worker_month/zb_batch' },

        { method: 'POST', path: '/performance/pfm_consume/init_month' },
        { method: 'DELETE', path: '/performance/pfm_consume/{id}' },
        { method: 'POST', path: '/performance/pfm_consume/to_month' },
        { method: 'POST', path: '/performance/pfm_consume/remove_month/{id}' },
        { method: 'POST', path: '/performance/pfm_consume/batch' },
        { method: 'POST', path: '/performance/pfm_consume/sync' },
        { method: 'POST', path: '/performance/pfm_consume/clear' },
        { method: 'PUT', path: '/performance/pfm_consume/{id}' },
        { method: 'POST', path: '/performance/pfm_consume' },
        { method: 'GET', path: '/performance/pfm_consume' },
        { method: 'GET', path: '/performance/pfm_consume/by_auth' },

        { method: 'DELETE', path: '/performance/pfm_project_bill/detail/{id}' },
        { method: 'PUT', path: '/performance/pfm_project_bill/detail/{id}' },
        { method: 'POST', path: '/performance/pfm_project_bill/detail' },
        { method: 'GET', path: '/performance/pfm_project_bill/detail' },

        { method: 'POST', path: '/performance/pfm_project_bill/init_month' },
        { method: 'POST', path: '/performance/pfm_project_bill/sync' },
        { method: 'POST', path: '/performance/pfm_project_bill/batch' },
        { method: 'POST', path: '/performance/pfm_project_bill/clear' },
        { method: 'POST', path: '/performance/pfm_project_bill/to_month' },
        { method: 'POST', path: '/performance/pfm_project_bill/remove_month/{id}' },
        { method: 'GET', path: '/performance/pfm_project/single' },
        { method: 'PUT', path: '/performance/pfm_project_bill/{id}' },
        { method: 'POST', path: '/performance/pfm_project_bill' },
        { method: 'DELETE', path: '/performance/pfm_project_bill/{id}' },
        { method: 'GET', path: '/performance/pfm_project_bill' },
        { method: 'GET', path: '/performance/pfm_project_bill/by_auth' },

        { method: 'POST', path: '/performance/pfm_unit_month/init' },
        { method: 'POST', path: '/performance/pfm_unit_month/sync' },
        { method: 'PUT', path: '/performance/pfm_unit_month/{id}' },
        { method: 'POST', path: '/performance/pfm_unit_month' },
        { method: 'DELETE', path: '/performance/pfm_unit_month/{id}' },
        { method: 'GET', path: '/performance/pfm_unit_month' },
        { method: 'GET', path: '/performance/pfm_unit_month/by_auth' },


        { method: 'POST', path: '/performance/pfm_worker_month/init' },
        { method: 'POST', path: '/performance/pfm_worker_month/sync' },
        { method: 'POST', path: '/performance/pfm_worker_month' },
        { method: 'PUT', path: '/performance/pfm_worker_month/{id}' },
        { method: 'POST', path: '/performance/pfm_worker_month/batch' },
        { method: 'DELETE', path: '/performance/pfm_worker_month/{id}' },
        { method: 'GET', path: '/performance/pfm_worker_month' },
        { method: 'GET', path: '/performance/pfm_worker_month/by_auth' },
        { method: 'GET', path: '/performance/pfm_worker_month/result' },

        { method: 'GET', path: '/performance/pfm_worker' },
        { method: 'GET', path: '/performance/pfm_worker/by_auth' },

        { method: 'GET', path: '/performance/pfm_unit/by_auth' },
        { method: 'GET', path: '/performance/pfm_unit' },

        { method: 'GET', path: '/performance/pfm_org' },

        { method: 'GET', path: '/performance/pfm_position' },

        { method: 'GET', path: '/performance/pfm_project' },
        { method: 'GET', path: '/performance/pfm_project_type' },

        { method: 'GET', path: '/performance/pfm_hospitalization' },
        { method: 'POST', path: '/performance/pfm_hospitalization' },
        { method: 'PUT', path: '/performance/pfm_hospitalization/{id}' },
        { method: 'DELETE', path: '/performance/pfm_hospitalization/{id}' },
        { method: 'POST', path: '/performance/pfm_hospitalization/init_month' },
        { method: 'POST', path: '/performance/pfm_hospitalization/to_month' },
        { method: 'POST', path: '/performance/pfm_hospitalization/remove_month/{id}' },
        { method: 'POST', path: '/performance/pfm_hospitalization/sync' },
        { method: 'POST', path: '/performance/pfm_hospitalization/batch' },
        { method: 'POST', path: '/performance/pfm_hospitalization/clear' },

        { method: 'GET', path: '/performance/pfm_month/unit_config/{id}' },
        { method: 'GET', path: '/performance/pfm_month/worker_config/{id}' },
      ]
    },
    operation: {
      funKey: 'operation',
      name: '操作员',
      apis: [
        { method: 'GET', path: '/performance/pfm_month' },

        { method: 'POST', path: '/performance/pfm_worker_month/unit_value/{id}' },
        { method: 'POST', path: '/performance/pfm_unit_month/push/{id}' },
        { method: 'POST', path: '/performance/pfm_unit_month/push_back/{id}' },
        { method: 'POST', path: '/performance/pfm_unit_month/file_ids/{id}' },
        { method: 'POST', path: '/performance/pfm_unit_month/file_ids/{id}' },
        { method: 'POST', path: '/performance/pfm_worker_month/divide' },
        { method: 'DELETE', path: '/performance/pfm_worker_month/divide/{id}' },

        { method: 'GET', path: '/performance/pfm_consume/by_auth' },
        { method: 'GET', path: '/performance/pfm_unit/by_auth' },
        { method: 'GET', path: '/performance/pfm_worker/by_auth' },
        { method: 'GET', path: '/performance/pfm_worker' },
        { method: 'GET', path: '/performance/pfm_project_bill/detail' },
        { method: 'GET', path: '/performance/pfm_project_bill/by_auth' },
        { method: 'GET', path: '/performance/pfm_org' },
        { method: 'GET', path: '/performance/pfm_project' },
        { method: 'GET', path: '/performance/pfm_project_type' },
        { method: 'GET', path: '/performance/pfm_unit_month/by_auth' },
        { method: 'GET', path: '/performance/pfm_worker_month/by_auth' },
        { method: 'GET', path: '/performance/pfm_position' },
        { method: 'GET', path: '/performance/pfm_hospitalization' },

        { method: 'GET', path: '/performance/pfm_month/unit_config/{id}' },
        { method: 'GET', path: '/performance/pfm_month/worker_config/{id}' }
      ]
    },
    view: {
      funKey: 'view',
      name: '查看人员',
      apis: [
        { method: 'GET', path: '/performance/pfm_month' },
        { method: 'GET', path: '/performance/pfm_consume/by_auth' },
        { method: 'GET', path: '/performance/pfm_unit/by_auth' },
        { method: 'GET', path: '/performance/pfm_worker/by_auth' },
        { method: 'GET', path: '/performance/pfm_project_bill/detail' },
        { method: 'GET', path: '/performance/pfm_project_bill/by_auth' },
        { method: 'GET', path: '/performance/pfm_org' },
        { method: 'GET', path: '/performance/pfm_worker' },
        { method: 'GET', path: '/performance/pfm_project' },
        { method: 'GET', path: '/performance/pfm_project_type' },
        { method: 'GET', path: '/performance/pfm_unit_month/by_auth' },
        { method: 'GET', path: '/performance/pfm_worker_month/by_auth' },
        { method: 'GET', path: '/performance/pfm_position' },
        { method: 'GET', path: '/performance/pfm_hospitalization' },

        { method: 'GET', path: '/performance/pfm_month/unit_config/{id}' },
        { method: 'GET', path: '/performance/pfm_month/worker_config/{id}' },
      ]
    },
    editor: {
      funKey: 'editor',
      name: '编辑人员',
      apis: [
        { method: 'GET', path: '/performance/pfm_month' },

        { method: 'POST', path: '/performance/pfm_consume/init_month' },
        { method: 'DELETE', path: '/performance/pfm_consume/{id}' },
        { method: 'POST', path: '/performance/pfm_consume/to_month' },
        { method: 'POST', path: '/performance/pfm_consume/remove_month/{id}' },
        { method: 'POST', path: '/performance/pfm_consume/batch' },
        { method: 'POST', path: '/performance/pfm_consume/sync' },
        { method: 'POST', path: '/performance/pfm_consume/clear' },
        { method: 'PUT', path: '/performance/pfm_consume/{id}' },
        { method: 'POST', path: '/performance/pfm_consume' },
        { method: 'GET', path: '/performance/pfm_consume' },

        { method: 'DELETE', path: '/performance/pfm_project_bill/detail/{id}' },
        { method: 'PUT', path: '/performance/pfm_project_bill/detail/{id}' },
        { method: 'POST', path: '/performance/pfm_project_bill/detail' },
        { method: 'GET', path: '/performance/pfm_project_bill/detail' },

        { method: 'POST', path: '/performance/pfm_project_bill/init_month' },
        { method: 'POST', path: '/performance/pfm_project_bill/sync' },
        { method: 'POST', path: '/performance/pfm_project_bill/batch' },
        { method: 'POST', path: '/performance/pfm_project_bill/clear' },
        { method: 'POST', path: '/performance/pfm_project_bill/to_month' },
        { method: 'POST', path: '/performance/pfm_project_bill/remove_month/{id}' },
        { method: 'GET', path: '/performance/pfm_project/single' },
        { method: 'PUT', path: '/performance/pfm_project_bill/{id}' },
        { method: 'POST', path: '/performance/pfm_project_bill' },
        { method: 'DELETE', path: '/performance/pfm_project_bill/{id}' },
        { method: 'GET', path: '/performance/pfm_project_bill' },
        { method: 'GET', path: '/performance/pfm_project_bill/by_auth' },

        { method: 'POST', path: '/performance/pfm_unit_month/init' },
        { method: 'POST', path: '/performance/pfm_unit_month/sync' },
        { method: 'PUT', path: '/performance/pfm_unit_month/{id}' },
        { method: 'POST', path: '/performance/pfm_unit_month' },
        { method: 'DELETE', path: '/performance/pfm_unit_month/{id}' },
        { method: 'GET', path: '/performance/pfm_unit_month' },

        { method: 'POST', path: '/performance/pfm_worker_month/init' },
        { method: 'POST', path: '/performance/pfm_worker_month/sync' },
        { method: 'POST', path: '/performance/pfm_worker_month' },
        { method: 'PUT', path: '/performance/pfm_worker_month/{id}' },
        { method: 'POST', path: '/performance/pfm_worker_month/batch' },
        { method: 'DELETE', path: '/performance/pfm_worker_month/{id}' },
        { method: 'GET', path: '/performance/pfm_worker_month' },

        { method: 'GET', path: '/performance/pfm_worker' },

        { method: 'GET', path: '/performance/pfm_unit' },

        { method: 'GET', path: '/performance/pfm_org' },

        { method: 'GET', path: '/performance/pfm_position' },

        { method: 'GET', path: '/performance/pfm_project' },
        { method: 'GET', path: '/performance/pfm_project_type' },

        { method: 'GET', path: '/performance/pfm_hospitalization' },
        { method: 'POST', path: '/performance/pfm_hospitalization' },
        { method: 'PUT', path: '/performance/pfm_hospitalization/{id}' },
        { method: 'DELETE', path: '/performance/pfm_hospitalization/{id}' },
        { method: 'POST', path: '/performance/pfm_hospitalization/init_month' },
        { method: 'POST', path: '/performance/pfm_hospitalization/to_month' },
        { method: 'POST', path: '/performance/pfm_hospitalization/remove_month/{id}' },
        { method: 'POST', path: '/performance/pfm_hospitalization/sync' },
        { method: 'POST', path: '/performance/pfm_hospitalization/batch' },
        { method: 'POST', path: '/performance/pfm_hospitalization/clear' },

        { method: 'GET', path: '/performance/pfm_month/unit_config/{id}' },
        { method: 'GET', path: '/performance/pfm_month/worker_config/{id}' },
      ]
    }
  }
}