<template>
  <div>
    <h2>高度尺寸(Height)</h2>
    <div class="size-list">
      <div class="large">大号 42px @size-height-large</div>
      <div class="norm">默认 30px @size-height-norm</div>
      <div class="small">中号 25px @size-height-small</div>
      <div class="mini">小号 20px @size-height-mini</div>
    </div>
    <h2>字体尺寸(Font)</h2>
    <div class="size-list font">
      <div class="large">大号 18px @size-font-large</div>
      <div class="norm">默认 14px @size-font-norm</div>
      <div class="small">中号 12px @size-font-small</div>
      <div class="mini">小号 10px @size-font-mini</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SizeDemo",
  components: {},
  data() {
    return {}
  },
  props: {},
  computed: {},
  methods: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  @import '../components/styles/values.less';
  .size-list {
    display: flex;
    align-items: center;
    div {
      flex: 1;
      margin: 10px;
      background-color: #808695;
      color: #FFF;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
    .large { height: @size-height-large; }
    .norm { height: @size-height-norm; }
    .small { height: @size-height-small; }
    .mini { height: @size-height-mini; }
  }
  .size-list.font {
    div {
      background-color: #515a6e;
    }
    .large { font-size: @size-font-large; }
    .norm { font-size: @size-font-norm; }
    .small { font-size: @size-font-small; }
    .mini { font-size: @size-font-mini; }
  }
</style>