import {
  getFUn
} from '../auth_lib'

export default {
  ...getFUn('rew_pun'),
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/basis/rew_pun',
      method: 'POST'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/basis/rew_pun/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/rew_pun/{id}',
      method: 'DELETE'
    }]
  }
}