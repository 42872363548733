export default {
  base: {
    funKey: 'base',
    name: '基础功能',
    apis: [{
      path: '/basis/worker_job_title/current',
      method: 'GET'
    },
    {
      path: '/basis/worker/certificate_level',
      method: 'GET'
    }]
  },
  get: {
    funKey: 'get',
    name: '全部员工',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    }]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '本机构下的员工',
    apis: [{
      path: '/basis/worker/my_org',
      method: 'GET'
    }]
  },
  getMyOrgContainChild: {
    funKey: 'getMyOrgContainChild',
    name: '本机构及其子机构下的员工',
    apis: [{
      path: '/basis/worker/my_org_contain_child',
      method: 'GET'
    }]
  }
}
