<template>
  <div class="fm-table" :style="tableAllStyle">
    <div class="fm-table-thead" :style="tableTheadStyle">
      <div class="fm-table-thead-item" :class="getClass(index, 'fm-table-thead-item')" @scroll="tableScroll($event, 'thead', index)" :style="tableTheadItemStyle[index]" :key="index" v-for="(itemColConfig, index) in colConfigList">
        <table border="0" cellspacing="0" cellpadding="0" class="fm-table-thead-table" :class="{'fm-table-thead-table-th-noSearch': !showSearch}">
          <colgroup>
            <col
              :key="colItem.key"
              v-for="colItem in itemColConfig"
              :width="colItem.width + 'px'"/>
          </colgroup>
          <thead class="fm-table-cell-thead-tr">
            <tr v-if="titleRows.length > 0 && titleRows[index].length > 0">
              <template
                v-for="(titleItem, titleItemIndex) in titleRows[index]">
                <th
                  :style="titleItem.cellStyle ? titleItem.cellStyle() : null"
                  :key="titleItemIndex"
                  :colspan="titleItem.colspan || 1"
                  class="th-title">
                  <div class="fm-table-thead-cell fm-table-cell-th fm-table-cell-drag">
                    <div class="cell-text-div cell-sort-div">
                      <div class="cell-text">
                        {{titleItem.title}}
                      </div>
                    </div>
                  </div>
                </th>
              </template>
            </tr>
            <tr>
              <template
                v-for="(colItem, colItemIndex) in itemColConfig">
                <th :key="colItem.key" v-if="colItem.key === 'fm-table-checkbox'">
                  <div class="fm-table-thead-cell fm-table-cell-th fm-table-cell-checkbox">
                    <fm-checkbox v-model="allChecked" @change="allCheckedChange"></fm-checkbox>
                  </div>
                </th>
                <th class="thead-th-last" :key="colItem.key" v-else-if="colItem.key === 'fm-table-actions'">
                  <div class="fm-table-thead-cell fm-table-cell-th">
                    <div class="cell-text-div" style="display: flex;justify-content: center;">
                      <span class="cell-text">
                        操作
                      </span>
                    </div>
                  </div>
                </th>
                <th
                  v-else
                  :style="colItem.column.cellStyle ? colItem.column.cellStyle() : null"
                  :key="colItem.key"
                  @mousemove.stop="handleTitleMouseMove($event)"
                  @mousedown.stop="handleTitleMouseDown($event, colItem.column.field, colItem.width)"
                  @mouseout.stop="handleTitleMouseOut($event)"
                  :class="{'thead-th-last': index === colConfigList.length - 1 && colItemIndex === itemColConfig.length - 1}">
                  <div class="fm-table-thead-cell fm-table-cell-th fm-table-cell-drag">
                    <div class="cell-text-div cell-sort-div">
                      <div class="cell-sort-div-c" v-if="!colItem.column.noSort" @click="changeTableSort(colItem.column.field)">
                          <i class="fmico fmico-paixu-shengxu" :class="{'cell-text-icon-active': sortMap[colItem.column.field] === 'desc'}"></i>
                          <i class="fmico fmico-paixu-jiangxu" :class="{'cell-text-icon-active': sortMap[colItem.column.field] === 'asc'}"></i>
                      </div>
                      <div class="cell-text" @click="clickTitle(colItem.column.field)" :class="{'fm-table-cell-th-content-right': colItem.column.textAlign === 'right', 'fm-table-cell-th-content-center': colItem.column.textAlign === 'center'}">
                        <span>
                          {{colItem.column.title}}
                        </span>
                      </div>
                      <div class="dreg-area"></div>
                    </div>
                    <div class="cell-search" v-if="showSearch">
                      <fm-table-thead-search v-model="searchMap[colItem.column.field]" :search-type="searchConfig[colItem.column.field].searchType" :select-datas="searchConfig[colItem.column.field].searchSelectDatas" v-if="!colItem.column.noSearch"></fm-table-thead-search>
                    </div>
                  </div>
                </th>
              </template>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="fm-table-tbody" :style="tableBodyStyle" @scroll="scrollBody">
      <div class="fm-table-tbody-item" :class="getClass(index, 'fm-table-tbody-item')" @scroll="tableScroll($event, 'tbody', index)" :style="tableBodyItemStyle[index]" :key="index" v-for="(itemColConfig, index) in colConfigList">
        <table border="0" cellspacing="0" cellpadding="0" class="fm-table-tbody-table" :class="{'fm-table-tbody-table-merge': mergeColumn.length > 0}">
          <colgroup>
            <!-- <col :key="colItem.key" v-for="(colItem, indexCol) in itemColConfig" :width="(colItem.width - ((index === colConfigList.length -1 && indexCol === itemColConfig.length - 1) ? 10 : 0)) + 'px'"/> -->
            <col :key="colItem.key" v-for="colItem in itemColConfig" :width="colItem.width + 'px'"/>
          </colgroup>
          <tbody>
            <tr
              :key="index"
              v-for="(data, index) in viewTableData"
              @mouseover="hoverDataTr(data)"
              @mouseout="hoverDataTr(data, 'out')"
              @click="clickRow($event, data, index, null)"
              :style="getRowStyle ? getRowStyle(data) : null"
              :class="{'locking-row': lockingRowId === data.__fmIndex && mergeColumn.length === 0, 'hover-row': hoverRowId === data.__fmIndex && mergeColumn.length === 0}">
              <template v-for="(colItem, colItemIndex) in itemColConfig">
                <td :key="index + colItem.key" class="fm-table-cell-td" @click.stop="" v-if="colItem.key === 'fm-table-checkbox'" :class="{'last-td': colItemIndex === itemColConfig.length - 1}">
                  <div class="fm-table-cell-td-content fm-table-cell-td-content-checkbox">
                    <fm-checkbox @change="checkedDataChange" :disabled="checkedData[data.__fmIndex].__checkeDisabled" v-model="checkedData[data.__fmIndex].__checked" v-if="checkedData[data.__fmIndex]"></fm-checkbox>
                  </div>
                </td>
                <td :key="index + colItem.key" class="fm-table-cell-td" v-else-if="colItem.key === 'fm-table-actions'" :class="{'last-td': colItemIndex === itemColConfig.length - 1}">
                  <div class="fm-table-cell-td-content">
                    <fm-table-menu :table-actions="tableActions" :rowData="data" @click_table_action="clickTableAction"></fm-table-menu>
                  </div>
                </td>
                <td
                  v-else
                  class="fm-table-cell-td"
                  :class="{'last-td': colItemIndex === itemColConfig.length - 1}"
                  :style="colItem.column.cellStyle ? colItem.column.cellStyle(data, clickData, index) : null"
                  :key="index + colItem.key"
                  @click.stop="clickRow($event, data, index, colItem.column)">
                  <div class="fm-table-cell-td-content" :style="colItem.column.cellTextStyle" :class="{'can-edit-cell': colItem.column.canEdit && !clickEdit, 'fm-table-cell-td-content-right': colItem.column.textAlign === 'right', 'fm-table-cell-td-content-center': colItem.column.textAlign === 'center'}">
                    <span v-html="getCellData(data, colItem)"></span>
                  </div>
                </td>
              </template>
            </tr>
            <tr
              v-if="countData.__count_ok"
              :key="countData.__fmIndex"
              @mouseover="hoverDataTr(countData)"
              @mouseout="hoverDataTr(countData, 'out')"
              :class="{'locking-row': lockingRowId === countData.__fmIndex && mergeColumn.length === 0, 'hover-row': hoverRowId === countData.__fmIndex && mergeColumn.length === 0}">
              <template v-for="(colItem, colItemIndex) in itemColConfig">
                <td :key="countData.__fmIndex + colItem.key" class="fm-table-cell-td" @click.stop="" v-if="colItem.key === 'fm-table-checkbox'" :class="{'last-td': colItemIndex === itemColConfig.length - 1}">
                  <div class="fm-table-cell-td-content fm-table-cell-td-content-checkbox">
                  </div>
                </td>
                <td :key="countData.__fmIndex + colItem.key" class="fm-table-cell-td" v-else-if="colItem.key === 'fm-table-actions'" :class="{'last-td': colItemIndex === itemColConfig.length - 1}">
                  <div class="fm-table-cell-td-content">
                  </div>
                </td>
                <td
                  v-else
                  class="fm-table-cell-td"
                  :class="{'last-td': colItemIndex === itemColConfig.length - 1}"
                  :key="countData.__fmIndex + colItem.key"
                  @click.stop="clickRow($event, countData, countData.__fmIndex, colItem.column)">
                  <div class="fm-table-cell-td-content asd" :style="colItem.column.cellTextStyle" :class="{'fm-table-cell-td-content-right': colItem.column.textAlign === 'right', 'fm-table-cell-td-content-center': colItem.column.textAlign === 'center'}">
                    <span>{{countData[colItem.column.field]}}</span>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <div class="fm-table-form" :class="{'fm-edit-tr': !clickEdit}" :id="tableId + editDomIdBase" :style="editRowStyle" v-if="editData.__fmIndex" @blur="editRowBlur()" tabindex="-1">
          <div
            :style="getEditRowItemWidth(colItem)" :key="colIndex" v-for="(colItem, colIndex) in itemColConfig" class="fm-edit-cell" >
            <div v-if="['fm-table-checkbox', 'fm-table-actions'].includes(colItem.key)"></div>
            <span v-html="colItem.column.formatter ? colItem.column.formatter(editData.hisData, colItem.key) : editData.hisData[colItem.column.field]" v-else-if="!colItem.column.canEdit"></span>
            <template v-else>
              <el-date-picker
                v-if="colItem.column.editType === 'datePicker'"
                v-model="editValueMap[colItem.column.field]"
                :placeholder="colItem.column.placeholder"
                size="mini"
                @blur="editRowBlur()"
                :ref="tableId + editDomIdBase + colItem.column.field + 'ref'">
              </el-date-picker>
              <el-date-picker
                v-else-if="colItem.column.editType === 'dateTimePicker'"
                v-model="editValueMap[colItem.column.field]"
                type="datetime"
                :placeholder="colItem.column.placeholder"
                size="mini"
                @blur="editRowBlur()"
                :ref="tableId + editDomIdBase + colItem.column.field + 'ref'"
                :default-time="colItem.column.defaultTime || null">
              </el-date-picker>
              <fm-select
                v-else-if="colItem.column.editType === 'select'"
                v-model="editValueMap[colItem.column.field]"
                @change="setEditRowFocus"
                @visible-change="setEditRowFocus2"
                filterable
                block
                text
                :placeholder="colItem.column.placeholder || ''"
                :ref="tableId + editDomIdBase + colItem.column.field + 'ref'"
                size="mini">
                <fm-option v-for="itemO in (colItem.column.selectDatas || colItem.column.getSelectDatas(editValueMap, editData.hisData))"
                  :key="itemO.key"
                  :label="itemO.label"
                  :value="itemO.key">
                </fm-option>
              </fm-select>
              <input
                v-model="editValueMap[colItem.column.field]"
                v-else
                :disabled="colItem.column.editDisabled"
                :id="tableId + editDomIdBase + colItem.column.field"
                type="text"
                class="fm-table-cell-td-input"
                :placeholder="colItem.column.placeholder"
                @click.stop=""
                @blur="editRowBlur()"
                @keyup.enter="commitEdit">
            </template>
          </div>
        </div>
      </div>
      <div class="no-date-text" v-if="viewTableData.length === 0"></div>
    </div>
    <div class="fm-table-scroll" :style="tableScrollStyle">
      <div class="fm-table-scroll-item" :class="getClass(index, 'fm-table-scroll-item')" @scroll="tableScroll($event, 'scroll', index)" :style="tableTheadItemStyle[index]" :key="index" v-for="(itemColConfig, index) in colConfigList">
        <div class="scroll-item" :style="{width: itemColConfig.reduce((a, b) => { return a + b.width}, 0) + 'px'}"></div>
      </div>
    </div>
    <div class="fm-table-drag-line" :style="dragWirtualLineStyle" v-if="dragFieldIng"></div>
  </div>
</template>

<script>
import Vue from 'vue'

import dragWidthMixin from './FmTable/drag_width_mixin'

import FmTableTheadSearch from './FmTable/FmTableTheadSearch'
import FmTableMenu from './FmTable/FmTableMenu'

import {
  tableSortDatas
} from './FmTable/sort_list'

import {
  getStrShowLength,
  getDomFatherLevel,
  dataListFilter,
  fmMergeColumn
} from './FmTable/tools'

import dcopy from 'deep-copy'

const setSearchData = (parm) => {
  let data = {}
  parm.forEach((item) => {
    if (!item.noSearch) {
      data[item.field] = item.defaultSearchData || (item.searchType === 'interval_number' ? [null, null] : null)
      data[item.field] = item.searchType === 'mu_select' ? [] : data[item.field]
    }
  })
  return data
}

const setSearchConfig = (parm) => {
  let data = {}
  parm.forEach((item) => {
    if (!item.noSearch) {
      data[item.field] = {
        searchType: item.searchType || 'text',
        defaultSearchData: item.defaultSearchData || [],
        searchSelectDatas: item.searchSelectDatas || [],
        searchFun: item.searchFun || null
      }
    }
  })
  return data
}

export default {
  name: 'FmTable',
  mixins: [dragWidthMixin],
  components: {
    FmTableTheadSearch,
    FmTableMenu
  },
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    titleRows: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 表格内部排序
    insideSort: {
      type: Boolean,
      default: true
    },
    clickEdit: {
      type: Boolean,
      default: false
    },
    countParm: {
      type: Object,
      default: () => {
        return {
          type: null,
          fields: [],
          label: {
            field: '',
            title: ''
          }
        }
      }
    },
    // 表格内部过滤
    insideFilter: {
      type: Boolean,
      default: true
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    formWatch: {
      type: Function
    },
    getRowStyle: {
      type: Function
    },
    countFun: {
      type: Function
    },
    searchDataHis: {
      type: Object,
      default: () => {
        return {}
      }
    },
    keepLockingKey: {
      type: String,
      default: ''
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    upDownSwitchTag: {
      type: String,
      default: ''
    },
    maxFieldLength: {
      type: Number,
      default: 12
    },
    relationSort: {
      type: Array,
      default: () => {
        return []
      }
    },
    columnList: {
      type: Array,
      default: () => {
        return []
      },
      validator: (data) => {
        let pass = true
        if (pass) {
          data.forEach((item) => {
            pass = pass && typeof item.field === 'string' && typeof item.title === 'string'
          })
        }
        return pass
      }
    },
    tableActions: {
      type: Array,
      default: () => {
        return []
      },
      validator: (data) => {
        let pass = true
        if (pass) {
          data.forEach((item) => {
            pass = pass && typeof item.key === 'string'
          })
        }
        return pass
      }
    },
    tableId: {
      type: String,
      default: () => {
        window.FmTableId = window.FmTableId ? window.FmTableId + 1 : 1
        return 'fm-table-' + window.FmTableId
      }
    }
  },
  watch: {
    upDownSwitchTag (value) {
      this.upDownSwitch(value)
    },
    showTableData (value) {
      if (this.showCheckbox) {
        this.updateCheckedData(value)
      }
      let data = value ? value.length : 0
      if (this.countFun) {
        data = this.countFun(value)
      }
      this.$emit('countDataChange', data)
    },
    dataList (value) {
      if (value.length > 0 && !this.getColumnLengthByData) {
        this.getColumnLengthByData = true
        this.getColumnLength()
      }
    },
    columnList (value) {
      this.searchConfig = setSearchConfig(this.columnList)
      this.initCanEditFields(value)
    },
    tableActions () {
      this.getColumnLength()
    },
    htmlFontSize () {
      this.getColumnLength()
    },
    tableData (value) {
      if (this.lockingRowValue) {
        let find = false
        value.forEach((item) => {
          if (item[this.keepLockingKey] === this.lockingRowValue) {
            this.lockingRowId = item.__fmIndex
            find = true
          }
        })
        if (!find) {
          this.lockingRowId = null
          this.lockingRowValue = null
        }
      } else {
        this.lockingRowId = null
      }
      if (this.showCheckbox) {
        this.allChecked = false
        this.checkedData = {}
        value.forEach((item) => {
          Vue.set(this.checkedData, item.__fmIndex, {
            data: item,
            __checked: item.__checked && true,
            __checkeDisabled: item.__checkeDisabled && true
          })
        })
        this.updateCheckedData(this.showTableData)
      }
    }
  },
  data () {
    return {
      getColumnLengthByData: false,
      editDomIdBase: 'fm-table-edit-',
      editRowStyle: {
        top: 0
      },
      mergeColumn: [],
      lockingRowId: null,
      lockingRowValue: null,
      hoverRowId: null,
      sortMap: {},
      tableWidth: 0,
      tableHeight: 0,
      tableParentHeight: 0,
      colConfigList: [],
      colConfigListWidth: [],
      allChecked: false,
      dragColumnEd: false,
      checkedData: {},
      searchMap: setSearchData(this.columnList),
      clickTimes: 0,
      clickData: {},
      searchConfig: setSearchConfig(this.columnList),
      editData: {
        __fmIndex: null,
        hisData: null
      },
      viewNum: 60,
      viewIndex: 1,
      canEditFields: [],
      editValueMap: {},
      editIng: false,
      scrollHeight: null,
      tableScrollConfig: {
        type: null,
        bIndex: null,
        sIndex: 0
      },
      clickTitleField: null
    }
  },
  created () {
    if (this.autoHeight) {
      this.tableParentHeight = 34 + 33 * this.dataList.length
    }
  },
  computed: {
    countData: {
      get () {
        let data = {
          __count_ok: false,
          __fmIndex: '__count'
        }
        if (this.countParm.type === 'fields') {
          this.countParm.fields.forEach(v => {
            data[v.field] = 0
          })
          this.showTableData.forEach((item) => {
            data.__count_ok = true
            this.countParm.fields.forEach((fieldItem) => {
              if (!fieldItem.getData) {
                data[fieldItem.field] += isNaN(item[fieldItem.field]) ? 0 : Number(item[fieldItem.field])
              } else {
                data[fieldItem.field] = fieldItem.getData(data)
              }
            })
          })
        }
        for (let key in data) {
          if (!isNaN(data[key])) {
            data[key] = Number(Number(data[key]).toFixed(2))
          }
        }
        return data
      }
    },
    tableData: {
      get () {
        let data = []
        let allData = this.dataList
        allData.forEach((item, index) => {
          let newItem = dcopy(item)
          newItem.__fmIndex = index + 1
          data.push(newItem)
        })
        return data
      }
    },
    showTableData: {
      get () {
        let data = []
        if (this.insideFilter) {
          data = dataListFilter(this.tableData, this.searchMap, this.searchConfig)
        } else {
          this.tableData.forEach((item) => {
            data.push(item)
          })
        }
        return data
      }
    },
    showSortTableData: {
      get () {
        let allData = []
        this.showTableData.forEach((item) => {
          allData.push(item)
        })
        let data = this.insideFilter ? tableSortDatas(allData, this.relationSort, this.sortMap, this.sortConfig) : allData
        data = this.mergeColumn.length > 0 ? fmMergeColumn(data, this.mergeColumn) : data
        return data
      }
    },
    viewTableData: {
      get () {
        return this.showSortTableData.slice(0, this.viewIndex * this.viewNum)
      }
    },
    sortConfig: {
      get () {
        let data = {}
        this.columnList.forEach((item) => {
          if (!item.noSort) {
            data[item.field] = item.sortBy || 'text'
          }
        })
        return data
      }
    },
    tdthFontSize: {
      get () {
        return this.htmlFontSize
      }
    },
    htmlFontSize: {
      get () {
        return 13
      }
    },
    tableTheadStyle: {
      get () {
        return {
          width: this.tableWidth + 'px'
        }
      }
    },
    tableAllStyle: {
      get () {
        return {
          height: this.tableParentHeight + 'px'
        }
      }
    },
    tableBodyStyle: {
      get () {
        return {
          height: this.tableHeight + 'px',
          width: this.tableWidth + 'px'
        }
      }
    },
    tableScrollStyle: {
      get () {
        return {
          height: '10px',
          width: this.tableWidth + 'px'
        }
      }
    },
    tableBodyItemStyle: {
      get () {
        let data = []
        let num = this.viewTableData.length + (this.countData.__count_ok ? 1 : 0)
        this.colConfigListWidth.forEach((item) => {
          data.push({
            width: item.width + 'px',
            'max-width': item.width + 'px',
            height: (num * 32 + 2) + 'px',
            'max-height': (num * 32 + 2) + 'px'
          })
        })
        return data
      },
    },
    tableTheadItemStyle: {
      get () {
        let data = []
        this.colConfigListWidth.forEach((item) => {
          data.push({
            width: item.width + 'px',
            'max-width': item.width + 'px',
          })
        })
        return data
      }
    }
  },
  mounted () {
    if (this.countFun) {
      this.$emit('countDataChange', this.countFun(this.showTableData))
    }
    this.tableWidth = this.$el.clientWidth
    if (!this.autoHeight) {
      this.tableParentHeight = this.$el.parentElement.clientHeight
      this.tableHeight = this.tableParentHeight - this.$el.querySelector('.fm-table-thead').clientHeight - this.$el.querySelector('.fm-table-scroll').clientHeight
      // this.$el.querySelector('.fm-table-tbody').addEventListener('scroll', this.testS)
    }
    this.$emit('searchMapChange', {searchMap: dcopy(this.searchMap), searchConfig: this.searchConfig})
    this.getColumnLength()
    this.initCanEditFields()
    this.$watch('searchMap', () => {
      this.$emit('searchMapChange', {searchMap: dcopy(this.searchMap), searchConfig: this.searchConfig})
    }, {
      deep: true
    })
    this.$watch('searchDataHis', () => {
      this.updateSearchMapByHis()
    }, {
      deep: true
    })
    if (this.formWatch) {
      this.$watch('editValueMap', () => {
        this.formWatch(this.editValueMap, this.editData.hisData)
      }, {
        deep: true
      })
    }
    if (this.tableData.length > 0) {
      if (this.showCheckbox) {
        this.allChecked = false
        this.checkedData = {}
        this.tableData.forEach((item) => {
          Vue.set(this.checkedData, item.__fmIndex, {
            data: item,
            __checked: item.__checked && true,
            __checkeDisabled: item.__checkeDisabled && true
          })
        })
        this.updateCheckedData(this.showTableData)
      }
    }
  },
  methods: {
    getCellData (data, colItem) {
      let text = ''
      if (colItem.column.formatter) {
        text = colItem.column.formatter(data, colItem.column.field)
      } else {
        text = data[colItem.column.field]
      }
      return text
    },
    hoverDataTr (data, isOut) {
      this.hoverRowId = isOut ? null : data.__fmIndex
    },
    getClass (index, str) {
      let data = {
        'dont-show-s': index !== this.colConfigList.length - 1,
        'no-right-border': index === this.colConfigList.length - 1
        // 'no-btm-scrollbar': this.tableWidthEnough
      }
      let key = str + '-' + index
      data[key] = true
      return data
    },
    updateSearchMapByHis () {
      for (let key in this.searchMap) {
        let value = null
        if (typeof this.searchDataHis[key] === 'undefined') {
          value = this.searchMap[key]
          value = (value && typeof value === 'object') ? [null, null] : null
        } else {
          value = this.searchDataHis[key]
          value = (value && typeof value === 'object') ? dcopy(value) : value
        }
        Vue.set(this.searchMap, key, value)
      }
    },
    initCanEditFields (data) {
      data = data || this.columnList
      this.mergeColumn = []
      this.canEditFields = []
      data = data || []
      data.forEach((item) => {
        if (item.canEdit) {
          this.canEditFields.push({
            field: item['field'],
            editType: item['editType']
          })
        }
        if (item.merge) {
          this.mergeColumn.push(item['field'])
        }
      })
    },
    getEditRowItemWidth (colData) {
      let width = colData.width
      return {
        width: width + 'px',
        'min-width': width + 'px'
      }
    },
    editRowBlur () {
      // 编辑列失去焦点
      // 若获得焦点的是其子元素 则不做处理 如不是 则提交修改
      let editRowDom = window.document.getElementById(this.tableId + this.editDomIdBase)
      Vue.nextTick(() => {
        // 不是该元素或其子元素的焦点，啥都不做
        if (getDomFatherLevel(document.activeElement, editRowDom, 6) === -1) {
          this.commitEdit()
        }
      })
    },
    commitEdit () {
      if (!this.editData.__fmIndex) {
        return
      }
      let changeEd = false
      this.canEditFields.forEach((item) => {
        if (this.editData.hisData[item.field] !== this.editValueMap[item.field]) {
          changeEd = true
        }
      })
      if (changeEd) {
        this.$emit('editRowData', {editData: {hisData: dcopy(this.editData.hisData)}, valueMap: dcopy(this.editValueMap)})
      }
      this.editData = {
        __fmIndex: null,
        hisData: null
      }
      this.editValueMap = {}
      this.editIng = false
    },
    upDownSwitch (type) {
      let lockingData = null
      let lockingIndex = null
      let newLockingIndex = null
      this.showSortTableData.forEach((item, index) => {
        if (item.__fmIndex === this.lockingRowId) {
          lockingIndex = index
        }
      })
      if (lockingIndex === null) {
        newLockingIndex = this.showSortTableData.length > 0 ? 0 : null
      } else if (type.includes('up') && lockingIndex > 0) {
        newLockingIndex = lockingIndex - 1
      } else if (type.includes('down') && lockingIndex < (this.showSortTableData.length - 1)) {
        newLockingIndex = lockingIndex + 1
      }
      lockingData = newLockingIndex !== null ? this.showSortTableData[newLockingIndex] : {}
      if (lockingData.__fmIndex) {
        this.lockingRowId = lockingData.__fmIndex || null
        if (this.keepLockingKey) {
          this.lockingRowValue = lockingData[this.keepLockingKey]
        }
        this.clickData = {rowIndex: newLockingIndex, rowData: lockingData, column: null}
        this.$emit('rowClick', this.clickData)
      }
    },
    getEditRowStyle (event) {
      event.path.forEach((item) => {
        if (item.tagName === 'TR') {
          this.editRowStyle.top = (item.getBoundingClientRect().top - item.parentElement.getBoundingClientRect().top) + 'px'
        }
      })
    },
    clickTitle (field) {
      this.clickTitleField = field
      this.clickTimes += 1
      if (this.clickTimes === 1) {
        setTimeout(this.clickTitleDeal, 200)
      } else {
        this.setColumnFull(field)
        this.clickTimes = 0
      }
    },
    setColumnFull (field) {
      let width = 0
      let minFieldWidth = 4 * this.tdthFontSize
      let paddingLength = 1 * this.htmlFontSize
      this.columnList.forEach((item) => {
        if (item.field === field) {
          width = getStrShowLength(item.title)
          this.dataList.forEach((dataItem) => {
            let dataLength = getStrShowLength(dataItem[item.field])
            if (dataLength > width) {
              width = dataLength
            }
          })
          width = width * this.tdthFontSize + paddingLength
          if (width < minFieldWidth) {
            width = minFieldWidth
          }
        }
      })
      this.dragUpdateColConfig(field, width + 10, true)
    },
    clickTitleDeal () {
      if (this.clickTimes === 1) {
        this.changeTableSort(this.clickTitleField)
        this.clickTimes = 0
      }
    },
    clickRow (event, data, index, column) {
      this.clickData = {rowIndex: index, rowData: data, column}
      if (this.clickEdit) {
        if (this.editIng) {
          return
        }
        this.editIng = true
        let vm = this
        Vue.nextTick(() => {
          setTimeout(function () {
            vm.getEditRowStyle(event)
            vm.initEdit(event, vm.clickData.column)
          }, 200)
        })
        return
      }
      this.clickTimes += 1
      this.lockingRowId = data.__fmIndex || null
      if (this.keepLockingKey) {
        this.lockingRowValue = data[this.keepLockingKey]
      }
      if (this.clickTimes === 1) {
        setTimeout(this.touchClickEvent, 200)
      } else {
        if (this.canEditFields.length > 0) {
          this.getEditRowStyle(event)
          this.initEdit(event, this.clickData.column)
        } else {
          this.$emit('rowDbClick', this.clickData)
        }
        this.clickTimes = 0
      }
    },
    setEditRowFocus2 (type) {
      if (type) {
        return
      }
      let vm = this
      Vue.nextTick(() => {
        let editRowDom = window.document.getElementById(vm.tableId + vm.editDomIdBase)
        editRowDom.focus()
      })
    },
    setEditRowFocus () {
      let vm = this
      Vue.nextTick(() => {
        let editRowDom = window.document.getElementById(vm.tableId + vm.editDomIdBase)
        editRowDom.focus()
      })
    },
    initEdit (event, column) {
      // 双击列后进行编辑
      // 若双击的是可编辑行 则该单元获得焦点 否者该列获得焦点
      // 可编辑单元或列失去焦点后，nextTick判断获取焦点的元素，是不是该列元素或其子元素 不是则进行保存
      // 锁定修改数据
      this.editData = {
        __fmIndex: this.clickData.rowData.__fmIndex,
        hisData: this.clickData.rowData
      }
      this.canEditFields.forEach((item) => {
        let itemField = item.field
        if (['select', 'input'].includes(item.editType) || !item.editType) {
          Vue.set(this.editValueMap, itemField, this.editData.hisData[itemField])
        } else {
          Vue.set(this.editValueMap, itemField, null)
        }
      })
      if (column && column.canEdit && !column.editDisabled) {
        if (column.editType === 'input' || !column.editType) {
          let vm = this
          Vue.nextTick(() => {
            let inputDom = window.document.getElementById(vm.tableId + vm.editDomIdBase + column.field)
            inputDom.focus()
            inputDom.select()
          })
        } else if (['dateTimePicker', 'select', 'datePicker'].includes(column.editType)) {
          Vue.nextTick(() => {
            let dom = this.$refs[this.tableId + this.editDomIdBase + column.field + 'ref'][0]
            dom.focus()
          })
        }
      } else {
        this.setEditRowFocus()
      }
    },
    touchClickEvent () {
      if (this.clickTimes === 1) {
        this.$emit('rowClick', this.clickData)
        this.clickTimes = 0
      }
    },
    changeTableSort (field) {
      // 分关联排序
      let hisSortType = this.sortMap[field]
      let newSortMap = {}
      let isRelationSort = null
      if (this.relationSort.includes(field)) {
        isRelationSort = true
      } else {
        isRelationSort = false
      }
      if (!isRelationSort) {
        for (let key in this.sortMap) {
          newSortMap[key] = null
        }
      } else {
        for (let key in this.sortMap) {
          if (this.relationSort.includes(key)) {
            newSortMap[key] = this.sortMap[key]
          } else {
            newSortMap[key] = null
          }
        }
      }
      let newSortType = null
      if (!hisSortType) {
        newSortType = 'asc'
      } else if (hisSortType === 'asc') {
        newSortType = 'desc'
      } else if (hisSortType === 'desc') {
        newSortType = null
      }
      newSortMap[field] = newSortType
      for (let key in newSortMap) {
        Vue.set(this.sortMap, key, newSortMap[key])
      }
      this.$emit('tableSortChange', {relationSort: this.relationSort, sortMap: dcopy(this.sortMap), sortConfig: this.sortConfig})
    },
    clickTableAction (parm) {
      this.lockingRowId = parm.data.__fmIndex || null
      if (this.keepLockingKey) {
        this.lockingRowValue = parm.data[this.keepLockingKey]
      }
      this.$emit('tableAction', parm)
    },
    allCheckedChange () {
      for (let key in this.checkedData) {
        if (!this.checkedData[key].__checkeDisabled) {
          this.checkedData[key].__checked = this.allChecked
        }
      }
      this.updateCheckedData(this.showTableData)
    },
    checkedDataChange (value) {
      let checkedData = []
      for (let key in this.checkedData) {
        if (this.checkedData[key].__checked) {
          checkedData.push(this.checkedData[key].data)
        }
      }
      this.$emit('checkDataChange', checkedData)
      if (!value) {
        this.allChecked = false
      }
    },
    updateCheckedData (showTableData) {
      let __showFmIndexs = []
      showTableData.forEach((item) => {
        __showFmIndexs.push(item.__fmIndex)
      })
      for (let key in this.checkedData) {
        if (!__showFmIndexs.includes(Number(key))) {
          this.checkedData[key].__checked = false
        }
      }
      let checkedData = []
      for (let key in this.checkedData) {
        if (this.checkedData[key].__checked) {
          checkedData.push(this.checkedData[key].data)
        }
      }
      this.$emit('checkDataChange', checkedData)
    },
    scrollBody (event) {
      if (this.scrollHeight !== event.target.scrollHeight && event.target.scrollTop > (event.target.scrollHeight - event.target.clientHeight - 10)) {
        this.scrollHeight = event.target.scrollHeight
        if (this.viewNum * this.viewIndex < this.showSortTableData.length) {
          this.viewIndex += 1
        }
      }
    },
    tableScroll (event, type, index) {
      if (this.tableScrollConfig.type === null) {
        this.tableScrollConfig.type = type
      }
      if (this.tableScrollConfig.type !== type) {
        return
      }
      if (this.tableScrollConfig.bIndex === null) {
        this.tableScrollConfig.bIndex = index
      }
      if (this.tableScrollConfig.bIndex !== index) {
        return
      }
      this.tableScrollConfig.sIndex += 1
      let sIndex = this.tableScrollConfig.sIndex
      let vm = this
      setTimeout(function () {
        if (vm.tableScrollConfig.sIndex === sIndex) {
          vm.tableScrollConfig = {
            type: null,
            bIndex: null,
            sIndex: 0
          }
        }
      }, 400)
      let datas = ['thead', 'tbody', 'scroll']
      datas.forEach((item) => {
        if (item !== type) {
          this.$el.querySelector('.fm-table-' + item + '-item-' + index).scrollLeft = event.target.scrollLeft
        }
      })
    },
    dragUpdateColConfig (field, width, noAdd) {
      this.colConfigList.forEach((itemColConfig, index) => {
        let pWidth = this.colConfigListWidth[index].width
        let useWidth = 0
        let isThisField = false
        itemColConfig.forEach((item) => {
          if (item.key === field) {
            item.width = noAdd ? width : (item.width + width)
            isThisField = true
          }
          useWidth += item.width
        })
        if (isThisField && useWidth < pWidth) {
          itemColConfig[itemColConfig.length - 1].width = itemColConfig[itemColConfig.length - 1].width + pWidth - useWidth
        }
      })
      this.dragColumnEd = true
    },
    getColumnLength () {
      if (this.columnList.length === 0 || this.dragColumnEd) {
        return
      }
      // 所需表长
      let needWidth = 0
      // padding-length
      let paddingLength = 2 * this.htmlFontSize
      // 最小表宽度 能显示 99999
      let minFieldWidth = 4 * this.tdthFontSize
      // 最大表宽度
      let maxFieldWidth = this.maxFieldLength * this.tdthFontSize
      // 表格块宽度
      let colConfigListWidth = []
      // 多表格宽度列宽度
      let colConfigList = []
      let nextItem = null
      // 固定列
      let fixedColConfigWidth = 0
      let dealFun = (dataItem) => {
        dataItem.width = parseInt(dataItem.width)
        if (nextItem && nextItem.fixed === dataItem.fixed) {
          colConfigList[colConfigList.length - 1].push(dataItem)
          colConfigListWidth[colConfigList.length - 1].width += dataItem.width
        } else {
          colConfigList.push([dataItem])
          colConfigListWidth.push({
            fixed: dataItem.fixed,
            width: dataItem.width
          })
        }
        if (dataItem.fixed) {
          fixedColConfigWidth += dataItem.width
        }
        needWidth += dataItem.width
        nextItem = dataItem
      }
      // checkbox宽度单独处理
      if (this.showCheckbox) {
        let checkboxCellWidth = 30
        dealFun({
          key: 'fm-table-checkbox',
          width: checkboxCellWidth,
          fixed: true,
          column: null
        })
      }
      this.columnList.forEach((item) => {
        let width = 0
        if (item.width) {
          width = item.width
        } else {
          // 表头宽度
          let maxLength = getStrShowLength(item.title)
          this.dataList.forEach((dataItem) => {
            // 数据宽度
            let dataLength = getStrShowLength(dataItem[item.field])
            maxLength = dataLength > maxLength ? dataLength : maxLength
          })
          width = maxLength * this.tdthFontSize + paddingLength
          // 限制宽度再最大最小区间内
          width = width > minFieldWidth ? (width > maxFieldWidth ? maxFieldWidth : width) : minFieldWidth
        }
        dealFun({
          key: item.field,
          width: width,
          fixed: item.fixed || false,
          column: item
        })
      })
      if (this.tableActions.length > 0) {
        let actionWidth = this.htmlFontSize
        this.tableActions.forEach((item) => {
          // 文字长度加间距
          actionWidth += this.tdthFontSize * (getStrShowLength(item.label) || 2) + this.htmlFontSize + 3
        })
        dealFun({
          key: 'fm-table-actions',
          width: actionWidth,
          fixed: true,
          column: null
        })
      }
      // 除fixed剩余多少
      let surplus = this.tableWidth - fixedColConfigWidth
      // 缩放比例
      let assignRate = (needWidth - fixedColConfigWidth) / surplus
      // 全部实际用的长度
      let allWidth = 0
      // 表格所需长度小于等于父亲组件宽度
      if (needWidth > this.tableWidth) {
        colConfigListWidth.forEach((item) => {
          if (!item.fixed) {
            item.width = parseInt(item.width / assignRate)
          }
          allWidth += item.width
        })
      } else {
        colConfigListWidth.forEach((item, index) => {
          if (!item.fixed) {
            item.width = 0
            colConfigList[index].forEach((itemCol) => {
              itemCol.width = parseInt(itemCol.width / assignRate)
              item.width += itemCol.width
            })
            allWidth += item.width
          } else {
            allWidth += item.width
          }
        })
      }
      if (colConfigList.length > 0 && colConfigList[0].length > 0) {
        colConfigList[0][0].width += (this.tableWidth - allWidth)
        colConfigListWidth[0].width += (this.tableWidth - allWidth)
      }
      this.colConfigListWidth = colConfigListWidth
      this.colConfigList = colConfigList
    }
  }
}
</script>

<style scoped lang="less">
@import './styles/values.less';
@fm-table-base-size: 16px;
@fm-table-th-font-size: 14px;
@fm-table-font-size: 12px;
@fm-table-thead-height: @fm-table-base-size * 4;
@fm-table-thead-height-no-search: @fm-table-base-size * 2;
@fm-table-tbody-tr-height: @fm-table-base-size * 2;
@cell-paddng: @fm-table-base-size * 0.5;
@color-border: #dcdee2;
@color-th-font: @color-title-text;
@color-td-font: @color-title-text;
@color-active-bak: #dff0f8;
@color-hover-bak: #dff0f8;
@color-shadow: #666;

.fm-table-scroll {
  display: flex;
}
.scroll-item {
  height: 8px;
}

.fm-table-scroll-item::-webkit-scrollbar {
  background: rgba(0,0,0,0);
  height: @scrollbar-size;
  width: @scrollbar-size;
}
.fm-table-scroll-item::-webkit-scrollbar-thumb{
  background-color: #d5d5d5;
  border-radius: 4px;
}
.fm-table-scroll-item::-webkit-scrollbar-track{
  background-color: rgba(0, 0, 0, 0);
}
.dont-show-s::-webkit-scrollbar {
  width: 0px;
  height: @scrollbar-size;
  background: rgba(0,0,0,0);
}
.fm-table-scroll-item {
  overflow-x: auto;
}

.fm-table-cell-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cell-sort-div {
  position: relative;
}
.cell-sort-div-c {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background: #000;
  display: flex;
  .fmico {
    margin: 0 auto;
    width: @fm-table-base-size;
    line-height: 0.3;
    font-size: @fm-table-base-size;
  }
  .fmico-paixu-shengxu {
    margin-top: @fm-table-base-size * 0.35;
    display: none;
  }
  .fmico-paixu-jiangxu {
    display: none;
  }
  .cell-text-icon-active {
    color: #666;
    display: block;
  }
}
.fm-edit-cell {
    line-height: @fm-table-tbody-tr-height;
    width: 100%;
    height: 100%;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    span {
      padding: 0 @fm-table-base-size * 0.35;
    }
    input:disabled {
      color: #828c95;
      border-width: 1px;
      border-color: @color-border;
    }
  }
.fm-table-cell-td {
  .fm-table-cell-td-content {
    height: @fm-table-tbody-tr-height;
    display: flex;
    align-items: center;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    color: @color-td-font;
  }
  .fm-table-cell-td-content-right {
    justify-content: flex-end;
  }
  .fm-table-cell-td-content-center {
    justify-content: center;
  }
  .fm-table-cell-td-content-checkbox {
    justify-content: center;
  }
  span {
    padding: 0 @fm-table-base-size * 0.35;
  }
}
.thead-th-last {
  border-right: 0px solid #000 !important;
}
.fm-table-cell-td-input {
  color: #828c95;
  width: 100%;
  height: 100%;
  background: #fff;
}
.fm-table-thead-cell {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  .cell-text-div {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .cell-text {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    display: flex;
    font-size: @fm-table-th-font-size;
    padding: 0 @fm-table-base-size * 0.35;
    font-weight: 400;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    .icon-paixu {
      font-size: @fm-table-th-font-size;
      color: rgba(0,0,0,0);
    }
    .icon-paixu-desc {
      color: #999;
      transform: rotate(180deg);
    }
    .icon-paixu-asc {
      color: #999;
    }
  }
  .cell-search {
    border-top: 1px solid @color-border;
    padding-left: 0.3rem;
    height: 31px;
    display: flex;
    align-items: center;
  }
}
.fm-table-cell-th {
  .cell-text {
    color: @color-th-font;
  }
  white-space:nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}
.fm-table {
  width: 100%;
  height: 100%;
  font-size: @fm-table-font-size;
  display: flex;
  flex-direction: column;
  position: relative;
}
.fm-table-drag-line {
  position: absolute;
  border-left: 2px dashed @color-border;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 0;
}
@scrollbar-size: 8px;
.fm-table-thead {
  min-height: @fm-table-tbody-tr-height;
  font-size: @fm-table-th-font-size;
  display: flex;
  .fm-table-cell-th-content-center {
    justify-content: center;
  }
  .fm-table-cell-th-content-right {
    justify-content: flex-end;
  }
}
.fm-table-thead-item {
  overflow-x: auto;
}
.fm-table-thead-item::-webkit-scrollbar {
  display:none;
}
.fm-table-tbody {
  flex: 1;
  display: flex;
  position: relative;
  overflow-y: auto;
}
.fm-table-tbody-item {
  position: relative;
  overflow-x: auto;
}
.fm-table-tbody-item::-webkit-scrollbar {
  display:none;
}
.no-btm-scrollbar {
  overflow-x: hidden;
}
.fm-table-form {
  position: absolute;
  display: flex;
  outline: none;
  align-items: center;
  height: @fm-table-tbody-tr-height;
  z-index: 2000;
  left: 0;
  background-color: @color-active-bak;
}
.fm-table-tbody-table {
  width: 1px;
  table-layout: fixed;
  border-bottom: 0.5px solid @color-border;
  tr:nth-of-type(even) {
    background-color: #F8F8F8;
    &:hover {
      background: @color-active-bak;
    }
  }
  .locking-row {
    background-color: @color-active-bak !important;
  }
  tr {
    height: @fm-table-tbody-tr-height;
    transition: 0.1s;
    outline: none;
    border-bottom: 1px solid @color-border;
  }
  .hover-row {
    background: @color-active-bak !important;
  }
  td {
    border-right: 1px solid @color-border;
  }
}

.no-right-border {
  .last-td {
    border-right: 1px solid rgba(0,0,0,0);
  }
}

.fm-table-tbody-table-merge {
  .locking-row {
    background-color: @color-active-bak;
  }
  td {
    border-bottom: 1px solid @color-border !important;
    border-right: 1px solid @color-border !important;
  }
}
.fm-table-thead-table {
  width: 1px;
  user-select: none;
  table-layout: fixed;
  th {
    height: @fm-table-thead-height;
    border-bottom: 1px solid @color-border;
    border-top: 1px solid @color-border;
    border-right: 1px solid @color-border;
  }
  .th-title {
    border-bottom: 0px solid @color-border;
  }
}
.fm-table-thead-table-th-noSearch {
  th {
    height: @fm-table-thead-height-no-search;
  }
}
.fm-edit-tr {
  box-shadow: 2px 3px 5px @color-shadow !important;
}
.no-date-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 2rem;
  letter-spacing: 1rem;
  color: #bbb;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  background-size: 80px 80px;
  background-repeat: no-repeat;
  background-image: url(/static/images/no-data.png);
}
.fm-table-cell-drag {
  .cell-text-div {
    display: flex;
    justify-content: space-between;
  }
  .dreg-area {
    height: 100%;
    width: 10px;
    min-width: 10px;
  }
  .dreg-area:hover {
    background-color: rgba(200, 200, 200, 0.9);
  }
}
</style>

<style lang="less">
@fm-table-tbody-tr-height: 32px;
.fm-edit-cell {
  .el-select {
    width: 100%;
    input {
      height: @fm-table-tbody-tr-height;
      border-radius: 0;
      padding-left: 0.1rem;
    }
  }
}
.fm-table {
  .fm-table-cell-td-content-checkbox {
    .fm-checkbox {
      justify-content: center;
    }
  }
}
</style>
