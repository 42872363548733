import axios from 'axios'

const httpRequestMethods = Object.freeze({
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put',
  PATCH: 'patch'
})

class APIError extends Error {
  constructor (type, httpCode, message) {
    super(message)
    this.name = this.constructor.name
    this.httpCode = httpCode
    this.type = type
    this.unprocessed = true
  }
}

const ErrorType = Object.freeze({
  // 请求未发出就抛出异常 网络错误
  'NETWORK': 'NETWORK',
  // 400 客户端错误 地址不存在
  'CLINENT': 'CLINENT',
  // token失效
  'UNAUTHORIZED': 'UNAUTHORIZED',
  // 没有权限
  'NOPERMISSION': 'NOPERMISSION',
  // 自定义错误，后台捕获的错误
  'CUSTOMIZE': 'CUSTOMIZE',
  // 服务器异常错误
  'SERVER': 'SERVER',
  // 未知错误
  'UNKNOWN': 'UNKNOWN'
})

const dealError = function (error, showMessageFun) {
  if (!error.unprocessed) {
    return Promise.reject(error)
  }
  let showMessage = null
  if (['CLINENT', 'UNKNOWN', 'NETWORK'].includes(error.type)) {
    // 发请求失败，请求结果出异常且为空，未知错误 处理
    showMessage = '请求失败，请检查网络！'
  } else if (['UNAUTHORIZED'].includes(error.type)) {
    // 未认证，即token无效，没有登录 跳转登录页面
    showMessage = '无权限，请确定是否已登录，若已登录请确定是否有权限。'
  } else if (['NOPERMISSION'].includes(error.type)) {
    // 没有权限
    showMessage = '您没有该操作权限，请联系管理员赋权！'
  } else if (['CUSTOMIZE'].includes(error.type)) {
    // 自定义错误，后台捕获的错误
    showMessage = '请求失败:' + error.message
  } else if (['SERVER'].includes(error.type)) {
    // 异常错误
    showMessage = '服务器错误，请联系管理员! 错误信息:' + error.message
  } else {
    showMessage = '未知错误，请联系管理员! 错误信息:' + error.message
  }
  if (showMessage) {
    showMessageFun(showMessage)
  }
  error.unprocessed = false
  return Promise.reject(error)
}

class axiosInit {
  constructor ({baseUrl, showMessageFun, timeout, getHeaderParm}) {
    this.httpRequestMethods = httpRequestMethods
    this.axiosCommonConfig = {
      timeout,
      baseURL: baseUrl,
      headers: {
        'content-type': 'application/json'
      }
    }
    this.getHeaderParm = getHeaderParm
    this.showMessageFun = showMessageFun
    this.axiosInstance = null
    this.request = null
    this.init()
  }
  init () {
    this.axiosInstance = axios.create(this.axiosCommonConfig)
    // http request 拦截器
    this.axiosInstance.interceptors.request.use(function (config) {
      return config
    }, function (error) {
      return Promise.reject(new APIError(ErrorType.NETWORK, null, error.message))
    })
    // http response 拦截器
    this.axiosInstance.interceptors.response.use(function (response) {
      return response.data || response
    },
    function (error) {
      if (!error.response) {
        return Promise.reject(new APIError(ErrorType.CLINENT, null, error.message))
      }
      let errorMessage = error.response && error.response.data ? error.response.data.message : error.message
      let responseStatus = error.response.status
      if (responseStatus === 400) {
        return Promise.reject(new APIError(ErrorType.CLINENT, responseStatus, errorMessage))
      } else if (responseStatus === 401) {
        return Promise.reject(new APIError(ErrorType.UNAUTHORIZED, responseStatus, errorMessage))
      } else if (responseStatus === 403) {
        return Promise.reject(new APIError(ErrorType.NOPERMISSION, responseStatus, errorMessage))
      } else if (responseStatus === 409) {
        return Promise.reject(new APIError(ErrorType.CUSTOMIZE, responseStatus, errorMessage))
      } else if (responseStatus === 500) {
        return Promise.reject(new APIError(ErrorType.SERVER, responseStatus, errorMessage))
      } else {
        return Promise.reject(new APIError(ErrorType.UNKNOWN, responseStatus, errorMessage))
      }
    })
    this.request = (endPoint, method, params, c) => {
      c = c || {}
      let contentType = c.contentType || null
      let responseType = c.responseType || null
      let isPathParm = c.isPathParm || false
      let config = {
        method: method,
        url: endPoint,
        headers: {
          // 'multipart/form-data' 'application/x-www-form-urlencoded; charset=UTF-8'
          'Content-Type': contentType || 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }
      let headerParm = this.getHeaderParm ? this.getHeaderParm() : null
      if (headerParm) {
        Object.keys(headerParm).forEach((key) => {
          config.headers[key] = headerParm[key]
        })
      }
      if (responseType) {
        // 'blob'
        config.responseType = responseType
      }
      if (isPathParm || method === 'get') {
        config.params = params || ''
      } else {
        config.data = contentType ? params : JSON.stringify(params)
      }
      return this.axiosInstance.request(config).then(function (data) {
        return Promise.resolve(data)
      }, (error) => {
        return dealError(error, this.showMessageFun)
      }).catch((error) => {
        return dealError(error, this.showMessageFun)
      })
    }
  }
}

export default {
  axiosInit,
  httpRequestMethods
}
