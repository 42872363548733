export const sortTypes = ['text', 'number']

export const clSortDatas = function ({datas, sortType, funSort, type, key}) {
  if (datas.length < 2) {
    return datas
  }
  let useFun = null
  if (funSort) {
    useFun = funSort
  } else if (sortType === 'text') {
    useFun = sortText
  } else {
    useFun = sortNumber
  }
  for (let indexA in datas) {
    for (let indexB in datas) {
      let nIndexB = Number(indexB)
      if (nIndexB < (datas.length - 1 - indexA) && useFun(datas[nIndexB][key], datas[nIndexB + 1][key], type)) {
        [datas[nIndexB], datas[nIndexB + 1]] = [datas[nIndexB + 1], datas[nIndexB]]
      }
    }
  }
  return datas
}

const sortText = function (a, b, type) {
  a = a || ''
  b = b || ''
  return type === 'desc' ? (a > b) : (a < b)
}

const sortNumber = function (a, b, type) {
  a = Number(a) || 0
  b = Number(b) || 0
  return type === 'desc' ? (a > b) : (a < b)
}

export const tableSortDatas = function (dataList, relationSort, sortMap, sortConfig) {
  if (!sortMap) {
    return dataList
  }
  // 关联排序
  let i = relationSort.length - 1
  while (i > -1) {
    let field = relationSort[i]
    let type = sortMap[field]
    if (type) {
      dataList = clSortDatas({datas: dataList, sortType: sortConfig[field], type, key: field})
    }
    i = i - 1
  }
  for (let field in sortMap) {
    let type = sortMap[field]
    if (!relationSort.includes(field) && type) {
      dataList = clSortDatas({datas: dataList, sortType: sortConfig[field], type, key: field})
    }
  }
  return dataList
}

export const dataListFilter = function (dataList, searchMap, searchConfig) {
  let showData = []
  searchMap = searchMap || {}
  searchConfig = searchConfig || {}
  dataList.forEach((item) => {
    let show = true
    for (let key in searchMap) {
      if (searchMap[key] === undefined || searchMap[key] === null) {
        continue
      }
      let cellData = String(item[key])
      if (searchConfig[key].searchFun) {
        if (!searchConfig[key].searchFun(item, searchMap[key])) {
          show = false
        }
      } else if (searchConfig[key].searchType === 'text' && !(cellData.toUpperCase()).includes(searchMap[key].toUpperCase())) {
        show = false
      } else if (searchConfig[key].searchType === 'select') {
        if (['fmNull', 'fmNotNull'].includes(searchMap[key])) {
          if (searchMap[key] === 'fmNull' && cellData !== 'null') {
            show = false
          } else if (searchMap[key] === 'fmNotNull' && cellData === 'null') {
            show = false
          }
        } else if (cellData !== String(searchMap[key])) {
          show = false
        }
      } else if (searchConfig[key].searchType === 'mu_select') {
        if (searchMap[key] && searchMap[key].length > 0 && !searchMap[key].includes(cellData)) {
          show = false
        }
      } else if (searchConfig[key].searchType === 'interval_number') {
        let cellDataNumber = Number(cellData)
        if (![null, ''].includes(searchMap[key][0]) && (cellDataNumber < searchMap[key][0] || isNaN(cellDataNumber))) {
          show = false
        }
        if (![null, ''].includes(searchMap[key][1]) && (cellDataNumber > searchMap[key][1] || isNaN(cellDataNumber))) {
          show = false
        }
      }
    }
    if (show) {
      showData.push(item)
    }
  })
  return showData
}

export default {
  dataListFilter,
  tableSortDatas
}