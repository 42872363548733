<style lang="less">
  .fm-down-select-item {
    .fm-checkbox, .fm-down-select-option {
      padding: 5px 10px;
      flex: 1;
    }
    .fm-checkbox {
      .fm-down-select-option {
        padding: unset;
        flex: unset;
      }
    }
  }
</style>

<template>
  <div class="fm-down-select-option">
    <div v-if="typeof option.render === 'function'" v-html="option.render(option)"></div>
    <div v-else>{{option.label}}</div>
  </div>
</template>

<script>
export default {
  name: 'FmDownSelectOption',
  props: {
    option: { type: Object, default: null }
  }
}
</script>
