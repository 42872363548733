export default {
  routerNames: ['project'],
  funs: {
    get: {
      funKey: 'get',
      name: '项目查看',
      apis: [
        { method: 'GET', path: '/performance/pfm_project' },
        { method: 'GET', path: '/performance/pfm_project_type' },
        { method: 'GET', path: '/performance/pfm_project/single' }
      ]
    },
    manage: {
      funKey: 'manage',
      name: '项目管理',
      apis: [
        { method: 'PUT', path: '/performance/pfm_project/{id}' },
        { method: 'POST', path: '/performance/pfm_project' },
        { method: 'DELETE', path: '/performance/pfm_project/{id}' }
      ]
    },
    typeGet: {
      funKey: 'typeGet',
      name: '项目类型查看',
      apis: [
        { method: 'GET', path: '/performance/pfm_project_type' }
      ]
    },
    typeManage: {
      funKey: 'typeManage',
      name: '项目类型管理',
      apis: [
        { method: 'PUT', path: '/performance/pfm_project_type/{id}' },
        { method: 'POST', path: '/performance/pfm_project_type' },
        { method: 'DELETE', path: '/performance/pfm_project_type/{id}' }
      ]
    },
    singleGet: {
      funKey: 'singleGet',
      name: '单项奖励查看',
      apis: [
        { method: 'GET', path: '/performance/pfm_project/single' },
        { method: 'GET', path: '/performance/pfm_project' }
      ]
    },
    singleManage: {
      funKey: 'singleManage',
      name: '单项奖励管理',
      apis: [
        { method: 'PUT', path: '/performance/pfm_project/single/{id}' },
        { method: 'POST', path: '/performance/pfm_project/single' },
        { method: 'DELETE', path: '/performance/pfm_project/single/{id}' }
      ]
    },
  }
}