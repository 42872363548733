<template>
  <base-sign-page
    :title-text="titleText"
    :note-text="noteText"
    :column-list="columnList"
    :data-list="dataList"
    :search-parms="searchParms"
    :form-parms="formParms"
    :his-data="chooseData"
    :openDialog="openDialog"
    :fileDataIds="fileDataIds"
    @formHandleClose="openDialog = false"
    :data-file-title="dataFileTitle"
    :toolbox="showToolBox"
    :export-file-name="exportFileName"
    :open-dialog-data-file="openDialogDataFile"
    @dataFileHandleClose="openDialogDataFile = false"
    @formSubmit="formSubmit"
    :dataFilefuns="dataFilefuns"
    @clickSearch="loadData"
    @countDataChange="countDataChange"
    :count-fun="countFun"
    :show-search="true"
    :loading="loading"
    :dataFileUploadCheck="dataFileUploadCheck"
    @tableAction="tableAction"
    :on-form-change="onFormChange"
    :table-actions="tableActionsAuth"
    :table-actions-width="tableActionsWidth"
    :table-actions-fixed="tableActionsFixed"
    :title-menus="titleMenusAuth"
    @clickTitleMenu="clickTitleMenu"
    :simple-filter="simpleFilter"
    :table-size="tableSize"
    @dataFileUpdate="dataFileUpdate"
    @checkDataChange="checkDataChange"
    ref="page"
    :table-border="tableBorder"
    :need-pagination="true">
    <template v-slot:title>
      <slot name="title">
      </slot>
      <slot>
      </slot>
    </template>
  </base-sign-page>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'

import {
  dateOperating
} from '@/fmlib'

export default {
  name: 'SignPage',
  components: {
    BaseSignPage
  },
  props: {
    tableBorder: { type: [String, Boolean], default: 'row' },
    initAddFormData: {
      type: Function
    },
    onFormChange: {
      type: Function
    },
    allAuthFuns: {
      type: Boolean
    },
    titleText: {
      type: String
    },
    dataFileUploadCheck: {
      type: Function
    },
    initSearchData: {
      type: Object
    },
    dataFileParm: {
      type: Object
    },
    dealSearchParm: {
      type: Function
    },
    request: {
      type: Object
    },
    formParmsAdd: {
      type: Array
    },
    formParmsUpdate: {
      type: Array
    },
    titleMenus: {
      type: Array,
      default: () => {
        return [
          { key: 'add', label: '新增' }
        ]
      }
    },
    tableActions: {
      type: Array,
      default: () => {
        return [
          { key: 'edit', label: '修改' },
          { key: 'del', label: '删除' }
        ]
      }
    },
    searchParms: {
      type: Array
    },
    dealData: {
      type: Function
    },
    getFileDataIds: {
      type: Function
    },
    dealFormData: {
      type: Function
    },
    columnList: {
      type: Array
    },
    tableActionsWidth: { type: Number, default: null },
    tableActionsFixed: { type: Boolean, default: false },
    simpleFilter: { type: [Boolean, String], default: 'head' },
    tableSize: { type: String, default: 'norm' },
    exportFileName: { default: '导出数据' }
  },
  created () {
    this.loadData(this.initSearchData)
  },
  methods: {
    dataFileUpdate (parm) {
      this.fileDataIds = parm
      this.$emit('dataFileUpdate', parm, this)
    },
    getTableData () {
      return this.$refs.page.getTableData()
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    tableAction (data) {
      this.chooseData = data.data
      this.sourceData = data.action.indexOf('apply') > -1 ? JSON.parse(JSON.stringify(data.data)) : null
      this.tableActionKey = data.action
      this.clickTitleMenuKey = null
      let action = this.tableActions.find(v => v.key === data.action)
      if (action && action.dealFun) {
        action.dealFun(this, this.chooseData)
      } else if (data.action === 'edit') {
        this.formParms = this.formParmsUpdate
        this.openDialog = true
      } else if (data.action === 'data_file') {
        this.fileDataIds = this.getFileDataIds ? this.getFileDataIds(this.chooseData) : (this.chooseData.fileIds ? this.chooseData.fileIds.split(',') : [])
        this.openDialogDataFile = true
      } else if (data.action === 'del') {
        this.delData()
      } else {
        this.$emit('tableAction', data, this)
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除' + this.titleText.replace('管理', '') + '吗?'})
      if (result) {
        this.request.del(this.chooseData.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText.replace('管理', '') + '已删除',
            type: 'info'
          })
          this.$emit('dataUpdate', this.chooseData)
          this.loadData()
        })
      }
    },
    formSubmit (data, resolve) {
      if (this.dealFormData) {
        try {
          data = this.dealFormData(data, this)
        } catch (e) {
          resolve()
          return
        }
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
        if (data[key] instanceof Array) {
          data[key] = data[key].join(',')
        }
      }

      if (this.chooseData && this.chooseData.id) {
        this.request.update(this.chooseData.id, data).then(() => {
          this.openDialog = false
          this.$notify({
            title: '系统提示',
            message: this.titleText.replace('管理', '') + '修改完成',
            type: 'success'
          })
          resolve()
          this.loadData()
          this.$emit('dataUpdate', data)
        }).catch((e) => {
          console.log(e)
          resolve()
        })
      } else {
        this.request.add(data).then(() => {
          this.openDialog = false
          this.$notify({
            title: '系统提示',
            message: this.titleText.replace('管理', '') + '新增完成',
            type: 'success'
          })
          resolve()
          this.loadData()
          this.$emit('dataUpdate', data)
        }).catch((e) => {
          console.log(e)
          resolve()
        })
      }
    },
    clickTitleMenu (key, parm) {
      this.chooseData = null
      this.sourceData = null
      this.clickTitleMenuKey = key
      this.tableActionKey = null
      if (parm.dealFun) {
        parm.dealFun(this)
      } else if (key === 'add') {
        if (this.initAddFormData) {
          this.chooseData = this.initAddFormData()
        }
        this.formParms = this.formParmsAdd
        this.openDialog = true
      } else {
        this.$emit('clickTitleMenu', key, this)
      }
    },
    async loadData (searchParm) {
      if (!(this.$authFunsProxy.get || this.$authFunsProxy.getMy || this.$authFunsProxy.getMyOrg || this.$authFunsProxy.getMyOrgContainChild)) {
        this.loading = false
        return
      }
      try {
        let searchParmData = this.dealSearchParm ? this.dealSearchParm(searchParm) : searchParm
        this.searchData = searchParmData || this.searchData
        this.loading = true
        let data = await this.request.get(this.searchData)
        if (this.dealData) {
          this.dataList = await this.dealData(data)
        } else {
          this.dataList = data
        }
        this.countDataChange(this.dataList.length)
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    checkDataChange (parm) {
      this.$emit('checkDataChange', parm)
    }
  },
  computed: {
    showToolBox () {
      return ['export'].concat(['table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', '')))
    },
    tableActionsAuth () {
      return this.tableActions.filter(v => this.$authFunsProxy[v.key] || this.allAuthFuns)
    },
    titleMenusAuth () {
      return this.titleMenus.filter(v => this.$authFunsProxy[v.key] || this.allAuthFuns)
    },
    dataFilefuns () {
      return {
        get: this.$authFunsProxy.getDataFile,
        upload: this.$authFunsProxy.updateDataFile,
        down: this.$authFunsProxy.downDataFile,
        del: this.$authFunsProxy.delDataFile,
        mkdir: this.$authFunsProxy.mkdirDataFile
      }
    },
    dataFileTitle () {
      return (this.tableActions.find(v => v.key === 'data_file') || {label: ''}).label
    }
  },
  data () {
    return {
      formParms: [],
      chooseList: [],
      searchData: {},
      dataList: [],
      noteText: '',
      fileDataIds: [],
      loading: true,
      chooseData: null,
      sourceData: null,
      openDialog: false,
      openDialogDataFile: false,
      tableActionKey: null,
      clickTitleMenuKey: null
    }
  }
}
</script>
