export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/activity',
      method: 'GET'
    },
    {
      path: '/basis/activity_type',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    },
    {
      path: '/basis/activity/edit/{id}',
      method: 'PUT'
    }]
  },
  getMy: {
    funKey: 'getMy',
    name: '查看我的',
    apis: [{
      path: '/basis/activity/by_auth',
      method: 'GET'
    },
    {
      path: '/basis/activity_type',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    },
    {
      path: '/basis/activity/edit/{id}',
      method: 'PUT'
    }]
  }
}