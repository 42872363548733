export default {
  routerNames: ['wages.manage.index'],
  funs: {
    getMonth: {
      funKey: 'getMonth',
      name: '查看月份管理',
      apis: [
        { method: 'GET', path: '/performance/wage_month' }
      ]
    },
    manageMonth: {
      funKey: 'manageMonth',
      name: '月份工资管理',
      apis: [
        { method: 'GET', path: '/performance/wage_month' },
        // { method: 'PUT', path: '/performance/wage_month/{id}' },
        { method: 'POST', path: '/performance/wage_month' },
        { method: 'DELETE', path: '/performance/wage_month/{id}' }
      ]
    },
    switchStatus: {
      funKey: 'switchStatus',
      name: '状态切换',
      apis: [
        { method: 'POST', path: '/performance/wage_month/status/{id}' }
      ]
    },
    getDetail: {
      funKey: 'getDetail',
      name: '查看明细',
      apis: [
        { method: 'GET', path: '/performance/wage_month' },
        { method: 'GET', path: '/performance/wage_month_worker' },
        { method: 'GET', path: '/performance/wage_month/worker_config/{id}' }
      ]
    },
    editDetail: {
      funKey: 'editDetail',
      name: '修改明细',
      apis: [
        { method: 'POST', path: '/performance/wage_month_worker' }
      ]
    },
    delDetail: {
      funKey: 'delDetail',
      name: '删除明细',
      apis: [
        { method: 'DELETE', path: '/performance/wage_month_worker/{id}' }
      ]
    },
    exportDetail: {
      funKey: 'exportDetail',
      name: '导出明细',
      apis: [
        { method: 'GET', path: '/performance/wage_month' },
        { method: 'GET', path: '/performance/wage_month/worker_config/{id}' }
      ]
    },
    uploadDetail: {
      funKey: 'uploadDetail',
      name: '上传明细',
      apis: [
        { method: 'POST', path: '/performance/wage_worker' },
        { method: 'GET', path: '/performance/wage_month/worker_config/{id}' },
        { method: 'POST', path: '/performance/wage_month/worker_config/init/{id}' },
        { method: 'POST', path: '/performance/wage_month_worker/batch' }
      ]
    }
  }
}