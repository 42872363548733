import {
  getFUn
} from '../auth_lib'

export default {
  ...getFUn('worker_job_title'),
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/basis/worker_job_title',
      method: 'POST'
    },
    {
      path: '/basis/job_title',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/basis/worker_job_title/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/job_title',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/worker_job_title/{id}',
      method: 'DELETE'
    }]
  // },
  // applyEdit: {
	// 	funKey: 'applyEdit',
	// 	name: '申请修改',
	// 	apis: [
	// 		{ path: '/basis/modify_apply', method: 'POST' },
  //     {
  //       path: '/basis/worker',
  //       method: 'GET'
  //     },
  //     {
  //       path: '/basis/job_title',
  //       method: 'GET'
  //     }
	// 	]
	// },
	// applyDel: {
	// 	funKey: 'applyDel',
	// 	name: '申请删除',
	// 	apis: [
	// 		{ path: '/basis/modify_apply', method: 'POST' }
	// 	]
	// },
	// applyAdd: {
	// 	funKey: 'applyAdd',
	// 	name: '申请新增',
	// 	apis: [
	// 		{ path: '/basis/modify_apply', method: 'POST' },
  //     {
  //       path: '/basis/worker',
  //       method: 'GET'
  //     },
  //     {
  //       path: '/basis/job_title',
  //       method: 'GET'
  //     }
	// 	]
	// },
	// applyLog: {
	// 	funKey: 'applyLog',
	// 	name: '申请记录',
	// 	apis: [
	// 		{path: '/basis/modify_apply/my', method: 'GET'}
	// 	]
	}
}