export const updateOption = ({xData, lineList, btmValue, topValue, barData, barData2, maxValue, maxValue2, maxValue3, simple, markData}) => {
  let option = initOption({xData, btmValue, topValue, maxValue, maxValue2, maxValue3, simple})
  option.series = makeLineSeries(lineList, barData, barData2)
  initMarkSeries(option.series[0], markData)
  return option
}

const initMarkSeries = (markSeries, markData) => {
  if (markData.lineList) {
    markSeries.markLine = {
      lineStyle: {
        normal: {
          color: '#828c95',
          type: 'solid'
        },
        emphasis: {
          color: '#828c95',
          type: 'solid'
        }
      },
      symbol: 'none',
      data: markData.lineList
    }
  }
  if (markData.areaList) {
    markSeries.markArea = {
      silent: true,
      itemStyle: {
        normal: {
          borderWidth: 2,
          borderType: 'dashed'
        },
        emphasis: {
          borderWidth: 2,
          borderType: 'dashed'
        }
      },
      data: markData.areaList
    }
  }
}

const initOption = ({xData, btmValue, topValue, maxValue, maxValue2, maxValue3, simple}) => {
  let option = {
    animation: false,
    grid: simple ? [{
      top: '10px',
      left: '30px',
      bottom: '20px',
      right: '35px'
    }] : [{
      top: '30px',
      left: '60px',
      bottom: '20px',
      right: '60px'
    }],
    color: ['#fe6800', '#0075c7'],
    tooltip: makeTooltip(),
    toolbox: simple ? {} : makeToolbox(),
    xAxis: makeXAxis(xData, simple),
    yAxis: makeYAxis(maxValue, maxValue2, maxValue3, simple)
    // visualMap: {
    //   show: false,
    //   pieces: [{
    //     gt: btmValue,
    //     lte: topValue,
    //     color: '#67C23A'
    //   }],
    //   outOfRange: {
    //     color: '#F56C6C'
    //   }
    // }
  }
  return option
}

const makeTooltip = () => {
  let formatterFun = (parm) => {
    let str = ''
    if (parm && parm.length > 0) {
      str += parm[0].data.name.slice(5, 16)
      let realValue = null
      let preValue = null
      let outValue = 0
      let inValue = 0
      let inOrderStr = ''
      parm.forEach((item) => {
        if (item.data.data) {
          if (item.data.data.type === 'stock') {
            realValue = item.data.data.inventory !== null ? item.data.data.inventory : realValue
          } else {
            preValue = item.data.data.inventory !== null ? item.data.data.inventory : preValue
          }
          outValue += item.data.data.outVolume || 0
          inValue += item.data.data.inVolume || 0
          let inOrder = item.data.data.inOrder || []
          inOrder.forEach((orderItem) => {
            inOrderStr += '<br/>' + orderItem.carNo + ': ' + orderItem.volume + 'L '
          })
        }
      })
      if (realValue !== null) {
        str += '<br/> 实际库存量: ' + realValue
      }
      if (preValue !== null) {
        str += '<br/> 预测库存量: ' + preValue
      }
      if (outValue !== null) {
        str += '<br/> 出库量: ' + outValue.toFixed(0)
      }
      if (inValue) {
        str += '<br/> 入库量: ' + inValue.toFixed(0)
      }
      str += inOrderStr
    }
    return str
  }
  return {
    formatter: formatterFun,
    trigger: 'axis'
  }
}

const makeToolbox = () => {
  return {
    show: true,
    feature: {
      magicType: {type: ['line', 'bar']},
      saveAsImage: {}
    }
  }
}

const makeXAxis = (xData, simple) => {
  let data = [{
    type: 'time',
    splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(99,162,255,0.1)'],
          width: 2,
          type: 'dotted'
        }
    },
    minInterval: 86400000,
    maxInterval: 86400000
  }]
  if (xData && xData.length > 0) {
    data.push({
      type: 'category',
      boundaryGap: false,
      splitLine: {
        show: false
      },
      data: xData || [],
      axisTick: {
        show: !simple
      },
      axisLabel: {
        // rotate: 45,
        formatter: function (value) {
          return value.includes('00:00') ? value.split(' ')[0] : (Number(value.split(' ')[1].split(':')[0]))
        },
        fontSize: 10,
        color: '#828c95',
        interval: simple ? 'auto' : function (index, value) {
          if (!value) {
            return false
          }
          let data = value.includes('00:00') ? value.split(' ')[0] : (Number(value.split(' ')[1].split(':')[0]))
          data = String(data)
          return data.includes('-') || Number(data) % 4 === 0 || Number(data) === 7
        },
        textStyle: {
          color: function (value) {
            if (!value) {
              return null
            }
            let data = value.includes('00:00') ? value.split(' ')[0] : (Number(value.split(' ')[1].split(':')[0]))
            data = String(data)
            return Number(data) === 7 ? 'red' : '#828c95'
          }
        }
      },
      axisLine: {
        lineStyle: {
          color: '#828c95'
        }
      }
    })
  }
  return data
}

const makeYAxis = (maxValue, maxValue2, maxValue3, simple) => {
  let YAxis = {
    type: 'value',
    name: '库存',
    minInterval: 10000,
    splitArea: {
      show: true,
      areaStyle: {
        color: ['rgba(99,162,255,0.1)', 'rgba(0,0,0,0.0)']
      }
    },
    axisTick: {
      show: !simple
    },
    splitLine: {
      show: false
    }
  }
  if (simple) {
    YAxis.axisLabel = {
      formatter: function (value) {
        return (value / 10000).toFixed(0) + '万'
      }
    }
  }
  if (maxValue) {
    YAxis.max = (Number((maxValue / 1000).toFixed(0)) + 2) * 1000
  }
  YAxis.axisLine = {
    lineStyle: {
      color: '#828c95'
    }
  }
  let YAxis2 = {
    type: 'value',
    name: '销量',
    min: 0,
    max: (maxValue2 || 0) * 4,
    position: 'right',
    axisLine: {
      lineStyle: {
        color: '#828c95'
      }
    },
    show: false
  }
  let YAxis3 = {
    type: 'value',
    name: '入库统计',
    min: 0,
    max: (maxValue3 || 0) * 4,
    position: 'right',
    axisLine: {
      lineStyle: {
        color: '#828c95'
      }
    },
    show: false
  }
  return [YAxis, YAxis2, YAxis3]
}

const makeLineSeries = (lineList, barData, barData2) => {
  let series = []
  lineList.forEach((item) => {
    let seriesItem = {
      type: 'line',
      symbolSize: 0,
      data: item.data,
      name: item.name,
      xAxisIndex: 0
    }
    if (item.lineStyle) {
      seriesItem.lineStyle = item.lineStyle
    }
    // seriesItem.markPoint = {
    //   data: [{
    //     type: 'max',
    //     name: '最大值'
    //   },
    //   {
    //     type: 'min',
    //     name: '最小值',
    //     itemStyle: {
    //       normal: {
    //         color: '#67C23A'
    //       }
    //     }
    //   }]
    // }
    series.push(seriesItem)
  })
  if (barData) {
    series.push(barData)
  }
  if (barData2) {
    series.push(barData2)
  }
  return series
}
