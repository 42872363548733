<template>
  <div class="gas-day-sale" v-loading="loading">
    <div class="title-month-label">
      <span> <i class="icon-rili iconfont"></i> {{monthDatas[monthDataIndex] || ''}}</span>
      <div class="change-month-btn">
        <a href="javascript:;" key="top" class="gd-action-item" @click="changIndex('shang')">
          上
        </a>
        <a href="javascript:;" key="bottom" class="gd-action-item" @click="changIndex('xia')">
          下
        </a>
      </div>
    </div>
    <div class="gas-day-sale-div" style="border-bottom: 2px solid #f8f8f8;">
      <div class="day-sale-item week-item" :key="index + 'week'" v-for="(item, index) in weekDay">
        <div class="day-sale-item-content">
          <div class="item-date">{{item}}</div>
        </div>
      </div>
    </div>
    <div class="gas-day-sale-div gas-day-sale-div-data" :ref="'data-sale-list'" @scroll="viewScroll">
      <div class="day-sale-item"
        :class="{'active-month': (item.date.slice(0, 4) + '年' + Number(item.date.slice(5, 7)) + '月') === monthDatas[monthDataIndex], 'day-sale-real': !item.isNull, 'day-sale-null': item.isNull, 'day-sale-month-label': item.isLabel}"
        :key="index"
        :ref="item.date.includes('月') ? 'data-sale-item-' + item.date : null"
        v-for="(item, index) in dataList">
        <a href="javascript:;" class="day-sale-item-content" v-if="!item.isLabel" @click="clickChooseData(item)">
          <span class="item-date">{{Number(item.date.slice(8, 10))}}</span>
          <div class="item-sale">
            <strong class="sale-volume" v-if="item.outVolume !== null">{{item.outVolume}}
            </strong>
            <strong class="sale-volume sale-volume-d" v-else-if="item.preOutVolume !== null">{{item.preOutVolume}}预
            </strong>
          </div>
        </a>
        <div class="month-label" :class="{'hide-month-label': item.date !== monthDatas[monthDataIndex]}" v-else :style="item.style">
          <span>{{item.date === monthDatas[monthDataIndex] ? item.date : null}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// 需要标记 当日销量、预测销量、是否促销、高低销预警
// import {
//   dateOperating
// } from 'fmlib'

import {XDate} from './FmDatePicker/lib'

export default {
  name: 'FmInventoryCalendar',
  props: {
    inventoryCellId: {
      type: String
    },
    startDate: {
      type: String,
      default () {
        return new XDate().compute('Y', -1).format('Y-M-D')
        // return dateOperating.computeDay({days: -365, date: new Date(), format: 'yy-mm-dd'})
      }
    },
    dataRequest: {
      type: Function
    },
    endDate: {
      type: String,
      default () {
        let date = new XDate().compute('D', 3)
        return date.compute('D', new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - date.getDate() + 1).format('Y-M-D')
        // let date = new Date()
        // date = dateOperating.computeDay({days: 3, date: date})
        // return dateOperating.computeDay({days: dateOperating.getMonthDays(date.getFullYear(), date.getMonth() + 1) - date.getDate() + 1, date: date, format: 'yy-mm-dd'})
      }
    },
    refreshTag: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      monthDatas: [],
      monthDataIndex: -1,
      weekDay: ['一', '二', '三', '四', '五', '六', '日'],
      dataList: [],
      dateRange: [],
      loading: false,
      showPDayList: [],
      chooseData: null
    }
  },
  watch: {
    refreshTag () {
      this.loadData()
    },
    inventoryCellId () {
      this.loadData()
    },
    monthDataIndex () {
      Vue.nextTick(() => {
        this.goBottom()
      })
    }
  },
  created () {
    // this.showPDayList = [dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd'}), dateOperating.computeDay({days: 1, date: new Date(), format: 'yy-mm-dd'}), dateOperating.computeDay({days: 2, date: new Date(), format: 'yy-mm-dd'})]
    this.showPDayList = [new XDate().format('Y-M-D'), new XDate().compute('D', 1).format('Y-M-D'), new XDate().compute('D', 2).format('Y-M-D')]
    this.dateRange = this.getDateRange([this.startDate, this.endDate])
    this.loadData()
  },
  methods: {
    clickChooseData (data) {
      this.chooseData = data
      this.$emit('chooseDayData', data)
    },
    viewScroll (event) {
      let cTop = event.target.scrollTop
      let find = false
      let chartDom = this.$refs
      for (let key in chartDom) {
        let chartDomItem = chartDom[key]
        chartDomItem = chartDomItem && chartDomItem.length > 0 ? chartDomItem[0] : null
        if (chartDomItem) {
          if (!find && chartDomItem.offsetTop - cTop > 0) {
            this.monthDatas.forEach((item, index) => {
              if (key.includes(item)) {
                this.monthDataIndex = index
              }
            })
            find = true
          }
        }
      }
    },
    changIndex (type) {
      if (type === 'xia') {
        if (this.monthDataIndex < this.monthDatas.length - 1) {
          this.monthDataIndex = this.monthDataIndex + 1
        }
      } else {
        if (this.monthDataIndex > 0) {
          this.monthDataIndex = this.monthDataIndex - 1
        }
      }
    },
    goBottom () {
      let chartDom = this.$refs['data-sale-item-' + this.monthDatas[this.monthDataIndex]]
      chartDom = chartDom && chartDom.length > 0 ? chartDom[0] : null
      if (chartDom) {
        chartDom.scrollIntoView()
      }
    },
    getDateRange (dateRange) {
      let newDateRange = [null, dateRange[1]]
      let cDate = dateRange[0]
      while (cDate !== dateRange[1] && !newDateRange[0]) {
        if (new Date(cDate).getDay() === 1) {
          newDateRange[0] = cDate
        }
        // cDate = dateOperating.computeDay({days: 1, date: cDate, format: 'yy-mm-dd'})
        cDate = new XDate(cDate).compute('D', 1).format('Y-M-D')
      }
      return newDateRange
    },
    loadData () {
      this.monthDataIndex = -1
      this.loading = true
      this.dataList = []
      let parm = {
        startTime: this.dateRange[0],
        endTime: this.dateRange[1],
        id: this.inventoryCellId
      }
      this.dataRequest(parm).then((data) => {
        let chooseData = null
        // let needDate = this.chooseData && this.chooseData.dayDate ? this.chooseData.dayDate : dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd'})
        let needDate = this.chooseData && this.chooseData.dayDate ? this.chooseData.dayDate : new XDate().format('Y-M-D')
        let monthDatas = []
        let newData = []
        let cDate = this.dateRange[0]
        while (cDate !== this.dateRange[1]) {
          let cDateTime = new Date(cDate)
          let findItem = null
          data.forEach((item) => {
            if (item.date.includes(cDate)) {
              findItem = item
            }
            if (item.dayDate === needDate) {
              chooseData = item
            }
          })
          if (!findItem) {
            findItem = {
              date: cDate,
              isNull: true,
              realOutVolume: null
            }
          }
          newData.push(findItem)
          if (cDateTime.getDay() === 0) {
            // let dDate = dateOperating.computeDay({days: 1, date: cDate, format: 'yy-mm-dd'})
            let dDate = new XDate(cDate).compute('D', 1).format('Y-M-D')
            let yMonth = Number(cDate.slice(5, 7))
            let i = 0
            while (dDate !== this.dateRange[1] && i < 7) {
              if (Number(dDate.slice(5, 7)) !== yMonth) {
                monthDatas.push(dDate.slice(0, 4) + '年' + Number(dDate.slice(5, 7)) + '月')
                newData.push({
                  date: dDate.slice(0, 4) + '年' + Number(dDate.slice(5, 7)) + '月',
                  isLabel: true,
                  style: {
                    'width': 14.28 * (7 - i) + '%',
                    'margin-left': 14.28 * i + '%'
                  }
                })
                i = 7
              } else {
                i = i + 1
              }
              // dDate = dateOperating.computeDay({days: 1, date: dDate, format: 'yy-mm-dd'})
              dDate = new XDate(dDate).compute('D', 1).format('Y-M-D')
            }
          }
          // cDate = dateOperating.computeDay({days: 1, date: cDate, format: 'yy-mm-dd'})
          cDate = new XDate(cDate).compute('D', 1).format('Y-M-D')
        }
        this.monthDatas = monthDatas
        this.dataList = newData
        this.monthDataIndex = this.monthDatas.length - 1
        this.loading = false
        this.clickChooseData(chooseData)
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './styles/values.less';
.change-month-btn-top {
  font-size: 2rem;
  color: @color-main;
  transform: rotateZ(90deg);
}
.gd-action-item {
  display: flex;
  justify-content: center;
  align-content: center;
  color: @color-main;
  i {
    font-size: 2rem;
    line-height: 1;
  }
}
.change-month-btn {
  z-index: 10;
  display: flex;
}
.cx-bj {
  width: 16px;
  background: @color-main;
  border-bottom-left-radius: 6px;
  font-size: 12px;
  color: #fff;
  position: absolute;
  text-align: center;
  line-height: 16px;
  top: 0;
  right: 0;
}
.cx-yc {
  padding: 0 2px;
  background: rgba(33, 137, 171, 1);
  border-bottom-right-radius: 6px;
  font-size: 12px;
  color: #fff;
  position: absolute;
  text-align: center;
  line-height: 16px;
  top: 0;
  left: 0;
  i {
    font-size: 10px;
    color: #fff;
  }
}
.sale-volume {
  position: relative;
  line-height: 1;
}
.title-month-label {
  display: flex;
  background: #f8f8f8;
  align-items: center;
  height: 2.5rem;
  span {
    margin-left: 0.5rem;
    width: 10rem;
    i {
      margin-right: 0.5rem;
    }
  }
}
.active-month {
  .day-sale-item-content {
    box-shadow: 2px 2px 4px rgba(33, 137, 171, 1);
  }
  .item-date {
    color: #aaa;
    font-weight: 400;
  }
}
.sale-volume-d {
  color: #218bab !important;
  .sale-note {
    color: #218bab !important;
  }
}

.sale-volume-g {
  color: #b73035 !important;
  .sale-note {
    color: #b73035 !important;
    transform: rotateZ(180deg);
  }
}

.sale-note {
  line-height: 1em;
  position: absolute;
  left: 100%;
  top: 0;
  font-size: 12px;
}
.gas-day-sale {
  width: 100%;
  box-shadow: @size-shadow #999;
  position: relative;
}
.gas-day-sale-div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.gas-day-sale-div-data {
  height: 30rem;
  min-height: 30rem;
  max-height: 30rem;
  overflow-y: auto;
}
.pi-day-edit {
  flex: 1;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    margin-bottom: 1rem;
  }
}
.day-sale-real {
  .day-sale-item-content {
    background: #eff6ff;
  }
}
.day-sale-null {
  .day-sale-item-content {
    background: #fff;
  }
}
.item-date {
  padding-top: 1rem;
  color: #bbb;
  font-size: 1.2rem;
  line-height: 1;
}
.item-sale {
  padding-bottom: 0.3rem;
  letter-spacing: 1px;
  flex: 1;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-sale-item-content {
  // border-bottom: 2px solid #fff;
  // border-right: 2px solid #fff;
  box-shadow: 0px 0px 0px rgba(33, 137, 171, 1);
  margin: 3px;
  height: 5rem;
  max-height: 5rem;
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.day-sale-item {
  position: relative;
  width: 14.28%;
  max-width: 14.28%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-sale-month-label {
  width: 100%;
  min-width: 100%;
  // background: rgba(254, 104, 0, 0.1);
  background: #fff;
  color: #999;
}
.month-label {
  // background: rgba(254, 104, 0, 0.2);
  height: 2rem;
  box-shadow: 2px 2px 4px rgba(33, 137, 171, 0.5);
  display: flex;
  align-items: flex-end;
  line-height: 1.4;
  span {
    padding-left: 0.5rem;
  }
}
.hide-month-label {
  height: 0;
  box-shadow: 0px 0px 0px rgba(33, 137, 171, 1);
}
.week-item {
  .day-sale-item-content {
    height: 2.5rem;
    border-bottom-width: 0;
    border-right-width: 0;
    .item-date {
      padding-top: 0;
      color: #999;
    }
  }
  background: #fff;
}
</style>
