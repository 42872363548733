export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/job_title/promotion/warning',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '明细'
  }
}