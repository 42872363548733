<template>
  <div class="fm-table-new" :class="tableCls" :style="tableStyle">
    <template v-if="width">
      <table-head ref="head">
        <template slot="head-tools-bar">
          <custom-sizer v-if="sizer !== false"></custom-sizer>
        </template>
      </table-head>
      <table-body ref="body">
        <slot></slot>
      </table-body>
      <div class="fm-table-empty" v-if="!tableData.length">
        <slot name="empty">暂无数据</slot>
      </div>
      <table-summary v-if="summary && tableData.length > 0" ref="summary"></table-summary>
      <cell-width-resize ref="cell-width-resize"></cell-width-resize>
    </template>
    <scrollbar v-if="columnConfig.isFixed"></scrollbar>
    <contextmenu ref="contextmenu" />
  </div>
</template>

<script>
import TableHead from './FmTableNew/head'
import TableBody from './FmTableNew/body'
import TableSummary from './FmTableNew/summary'
import CellWidthResize from './FmTableNew/cell-width-resize'
import CustomSizer from './FmTableNew/custom-sizer'
import { FmTableColumns, unionSort, bubbleSort } from './FmTableNew/lib'
import Scrollbar from './FmTableNew/scrollbar'
import Contextmenu from './FmTableNew/contextmenu/index'

function arraySum (a, b) {return a + b}

export default {
  name: 'FmTableNew',
  components: {
    TableHead, TableBody, TableSummary, CellWidthResize, CustomSizer, Scrollbar, Contextmenu
  },
  data () {
    return {
      columnConfig: [],
      filterConfig: {},
      sortConfig: [],
      columnWidth: [],
      selectionList: {},
      width: 0,
      allWidth: 0,
      currentExpand: null,
      activeRowIndex: -1,
      currentRowIndex: -1,
      viewPage: 1,
      sizeSet: this.size,
      summaryHeight: 0,
      contextMenuData: { row: null, column: null, area: null }
    }
  },
  props: {
    defaultOptionsFilterMethod: {
      type: Function, default: function (filterValue, columnValue) {
        // return typeof columnValue.indexOf === 'function' ? columnValue.indexOf(filterValue) > -1 : filterValue == columnValue
        return filterValue == columnValue
      }
    },
    emptySearch: { type: [ String, Boolean ], default: false },
    autoHeight: { type: Boolean, default: false },
    handlerCounter: { type: Function, default: null },
    selection: { type: [Boolean, String], default: false },
    summaryMethod: {
      type: Function, default: function ({data, column, summaryField, summaryDecimal}) {
        if (column.field && (!summaryField || !summaryField.length || summaryField.includes(column.field))) {
          if (column.dataType === Number) {
            return data.reduce((a, b) => isNaN(b[column.field]) ? a : (a + b[column.field]), 0).toFixed(summaryDecimal)
          } else {
            return data.length + '项'
          }
        } else {
          return '<div style="flex: 1;text-align: center;">-</div>'
        }
      }
    },
    summaryField: { type: Array, default: () => [] },
    summary: { type: Boolean, default: false },
    summaryExclude: { type: Boolean, default: true },
    summaryDecimal: { type: Number, default: 0 },
    stripe: { type: Boolean, default: true },
    highlightRow: { type: Boolean, default: false },
    border: {
      type: [Boolean, String], default: false, validator: function(border) {
        return ['row', 'col', undefined, true, false].includes(border)
      }
    },
    unionSort: { type: Boolean, default: false },
    full: { type: Boolean, default: true },
    size: {
      type: String, default: 'norm', validator: function(size) {
        return ['norm', 'large', 'small', 'mini'].includes(size)
      }
    },
    sizer: { type: Boolean, default: false },
    columns: {
      type: Array, default () {
        return []
      }
    },
    dataList: {
      type: Array, default () {
        return []
      }
    },
    height: {
      type: [Number, String],
      default: null
    },
    viewCount: { type: Number, default: 60 },
    emptyPlaceholder: {
      type: [Function, Boolean, String], default: null
    },
    simpleFilter: { type: [Boolean, String], default: false },
    filterMode: { type: String, default: 'AND' },
    contextMenus: {
      type: Array,
      default: () => []
    },
    toolbox: {
      type: Array, default: () => [], validator: function (val) {
        let vals = ['export', 'config']
        return Array.isArray(val) && val.find(v => !vals.includes(v)) === undefined
      }
    },
    exportFileName: {
      type: [Function, String],
      default () {
        return new Date().getTime() + '.xlsx'
      }
    }
  },
  computed: {
    filtratorConfig () {
      return Object.keys(this.filterConfig).filter(field => this.filterConfig[field].tags.length || this.filterConfig[field].options.length || this.filterConfig[field].sections.length).map(field => {
        const {tags, sections, options} = this.filterConfig[field]
        const column = this.columnConfig.columns.find(column => column.config.field == field)
        const filterMethod = typeof column.config.filterMethod === 'function' ? column.config.filterMethod : null

        let isInvalid = null

        if (column.config.dataType === Number) {
          isInvalid = (section) => section === '' || section === null || isNaN(section)
        } else if (column.config.dataType === Date) {
          isInvalid = (section) => section === '' || section === null || isNaN(section.getTime())
        } else {
          isInvalid = (section) => section === '' || section === null
        }

        return {
          column: column, tags, options, filterMethod, sections: sections.map(range => {
            if (isInvalid(range[0]) && isInvalid(range[1])) {
              return () => true
            } else if (isInvalid(range[0])) {
              return (v) => v <= range[1]
            } else if (isInvalid(range[1])) {
              return (v) => v >= range[0]
            } else {
              return (v) => v >= range[0] && v <= range[1]
            }
          })
        }
      })
    },
    filtrator () {
      return (row) => {
        const filterMode = this.filterMode.toUpperCase()
        for (const {column, tags, options, sections, filterMethod} of this.filtratorConfig) {
          let value = column.config.field ? row[column.config.field] : undefined
          let flag = false
          if (filterMethod !== null) {
            if (tags.length && tags.findIndex(tag => filterMethod({row, column: column.config, value: tag, type: 'tags'})) > -1) {
              flag = true
            }
            if (options.length && options.findIndex(option => filterMethod({row, column: column.config, value: option, type: 'options'})) > -1) {
              flag = true
            }
            if (sections.length && sections.findIndex(section => filterMethod({row, column: column.config, value: section, type: 'sections'})) > -1) {
              flag = true
            } 
          } else if (![undefined, null, ''].includes(value)) {
            let dateMatch = column.config.dataType === Date && (value instanceof Date || column.config.filterRange)
            if (dateMatch) {
              value = new Date(value)
            }

            if (tags.length && (dateMatch ? tags.map(v => new Date(v)) : tags).findIndex(tag => {
              return dateMatch ? (tag.getTime() === value.getTime()) : (String(value).indexOf(tag) !== -1)
            }) !== -1) {
              flag = true
            }
            if (options.length && options.findIndex(option => this.defaultOptionsFilterMethod(option, value)) !== -1) {
              flag = true
            }
            if (([Number, Date].includes(column.config.dataType)) && sections.length && sections.findIndex(v => v(value)) !== -1) {
              flag = true
            }
          } else if (this.emptySearch !== false) {
            if (tags.length && tags.findIndex(tag => this.emptySearch === tag) > -1) {
              flag = true
            }
            if (options.length && options.findIndex(option => this.emptySearch === option) > -1) {
              flag = true
            }
          }

          if ((flag && filterMode === 'OR') || (!flag && filterMode === 'AND')) {
            return flag
          }
        }
        return filterMode === 'AND' ? true : false
      }
    },
    filterData () {
      return this.filtratorConfig.length > 0 ? this.dataList.filter(this.filtrator) : this.dataList
    },
    sortData () {
      if (this.sortConfig.length < 1) {
        return this.filterData
      } else if (this.unionSort === false || this.sortConfig.length === 1) {
        return bubbleSort(this.filterData, this.sortConfig[0])
      } else {
        return unionSort(this.filterData, this.sortConfig)
      }
    },
    tableData () {
      return this.sortData.slice(0, this.viewPage * this.viewCount)
    },
    tableStyle () {
      return {
        height: isNaN(this.height) ? this.height : (this.height + 'px')
      }
    },
    tableCls () {
      return {
        'fm-table-stripe': this.stripe !== false,
        'fm-table-border': this.border === undefined || this.border === true,
        [`fm-table-border-${this.border}`]: this.border !== undefined && this.border !== true && this.border !== false,
        [`fm-table-${this.sizeSet}`]: true,
        'fm-table-auto-height': this.autoHeight !== false,
        'fm-table-fixed-height': this.height !== null,
        'fm-table-full': this.autoHeight !== false && this.full === true,
        'fm-table-column-fixed': this.isFixed
      }
    },
    isExpand () {
      return this.columnConfig.isExpand
    },
    isFixed () {
      return this.columnConfig.isFixed
    }
  },
  provide () {
    return {
      table: this
    }
  },
  methods: {
    openExport () {
      this.$refs.contextmenu.openExport()
    },
    activeContextmenu (e, {row, column, area} = {}) {
      if (this.toolbox.length > 0 || this.contextMenus.length > 0) {
        this.$refs.contextmenu.active(e)
        this.contextMenuData.row = row
        this.contextMenuData.column = column
        this.contextMenuData.area = area
      }
    },
    updateDataList () {
      this.selectionList = {}
      // 重新加载不关闭展开框
      // this.currentExpand = null
      this.activeRowIndex = -1
      this.currentRowIndex = -1
      this.columnConfig = new FmTableColumns(this.columns, {selection: this.selection})
      this.$emit('counterChange', typeof this.handlerCounter === 'function' ? this.handlerCounter(this.dataList) : this.dataList.length)
    },
    updateColumnConfig (newConfig) {
      let columnConfig = null
      if (newConfig) {
        columnConfig = new FmTableColumns(this.columnConfig.columns.map(v => {
          const config = v.config.field ? newConfig.find(n => n.field === v.config.field) : null
          return config ? Object.assign({}, v.config, config) : v.config
        }), {selection: this.selection})
      } else {
        columnConfig = new FmTableColumns(this.columns, {selection: this.selection})
      }

      this.selectionList = {}
      this.columnWidth = []
      this.currentExpand = null
      this.activeRowIndex = -1
      this.currentRowIndex = -1

      this.sortConfig = this.sortConfig.filter(item => {
        let column = columnConfig.columns.find(column => column.config.field == item.field)
        return column && !column.hidden
      })

      let filterConfig = {}
      Object.keys(this.filterConfig).forEach(field => {
        let column = columnConfig.columns.find(column => column.config.field == field)
        if (column && !column.hidden) {
          filterConfig[field] = this.filterConfig[field]
        }
      })
      this.filterConfig = filterConfig

      this.columnConfig = columnConfig

      this.$emit('update-filter-config', filterConfig)
      this.$emit('update-sort-config', this.sortConfig)
    },
    updateColumnSettings (configs) {
      this.updateColumnConfig(configs)
      this.$emit('on-column-config-update', this.columnConfig.columns.map(v => v.config))
    },
    setColumnSettings (configs) {
      this.updateColumnConfig(configs)
    },
    expandRow (data, index, reader) {
      if (!this.currentExpand) {
        this.currentExpand = {}
      }

      const config = { reader, index, row: data }
      if (this.currentExpand[index]) {
        this.$set(this.currentExpand, index, null)
        this.$emit('on-column-packup', config)
      } else {
        this.$set(this.currentExpand, index, config)
        this.$emit('on-column-expand', config)
      }
    },
    onColumnExpand ({column, index, row}) {
      if (!this.currentExpand) {
        this.currentExpand = {}
      }
      if (this.currentExpand[index]) {
        this.$set(this.currentExpand, index, null)
        this.$emit('on-column-packup', {column, row, index})
      } else {
        this.$set(this.currentExpand, index, {
          reader: column.config.expand,
          index, row
        })
        this.$emit('on-column-expand', {column, row, index})
      }
    },
    onRowClick (index) {
      if (this.highlightRow !== false) {
        this.$emit('highlight-row-change', this.tableData[index], this.tableData[this.activeRowIndex])
        this.activeRowIndex = index
      }
      this.$emit('row-click', {index: index, row: this.tableData[index]})
    },
    onCellClick (parm) {
      this.$emit('cell-click', parm)
    },
    onRowDbClick (index) {
      if (this.highlightRow !== false) {
        this.$emit('highlight-row-change', this.tableData[index], this.tableData[this.activeRowIndex])
        this.activeRowIndex = index
      }
      this.$emit('row-dbclick', {index: index, row: this.tableData[index]})
    },
    onRowHover (index) {
      this.currentRowIndex = index
    },
    handleResize (width) {
      const max = !this.columnWidth.length ? width : this.columnWidth.map((columns, columnIndex) => {
        return columns.map((current, currentIndex) => {
          return Math.max(width[columnIndex][currentIndex], current, this.columnConfig[columnIndex][currentIndex].width || 0)
        })
      })

      if (max[0].length) {
        max[0] = max[0].map((current, index) => this.columnConfig[0][index].width ? this.columnConfig[0][index].width : current)
      }

      if (max[2].length) {
        max[2] = max[2].map((current, index) => this.columnConfig[2][index].width ? this.columnConfig[2][index].width : current)
      }
      this.width = this.allWidth - (max[0].reduce(arraySum, 0) + max[2].reduce(arraySum, 0))

      // FIXME 最大列-最小列的列宽自动分配 #ID1001198
      // const usedWidth = max[1].map((current, index) => this.columnConfig[1][index].width ? this.columnConfig[1][index].width : 0).reduce(arraySum, 0)
      // const residueWidth = max[1].map((current, index) => this.columnConfig[1][index].width ? 0 : current).reduce(arraySum, 0)
      // let usableWidth = this.width - usedWidth
      // if (usableWidth > 0) {
      //   max[1] = max[1].map((current, index) => {
      //     if (this.columnConfig[1][index].width) {
      //       return this.columnConfig[1][index].width
      //     } else {
      //       return current / residueWidth * usableWidth
      //     }
      //   })
      // }

      this.columnWidth = max
      this.$emit('set-cell-width', this.columnWidth)
    },
    cellDragEnd ({x, groupIndex, columnIndex}) {
      this.columnWidth[groupIndex][columnIndex] = this.columnWidth[groupIndex][columnIndex] + x
      this.$emit('set-cell-width', this.columnWidth)
    },
    cellDataWrap ({groupIndex, columnIndex}) {
      const column = this.columnConfig[groupIndex][columnIndex]
      const max = Math.max(...this.dataList.map(v => {
        return String(v[column.config.field]).length
      }))
      this.columnWidth[groupIndex][columnIndex] = max * 12
      this.$emit('set-cell-width', this.columnWidth)
    },
    cellSelect ({status, index, row}) {
      this.$set(this.selectionList, index, status)
      this.$emit('on-select', {status, index, row})
      this.cellSelectChange()
    },
    cellAllSelect (status) {
      if (status) {
        let selectionList = {}
        ;[...new Array(this.filterData.length)].map((v, i) => {
          selectionList[i] = true
        })
        this.selectionList = selectionList
      } else {
        Object.keys(this.selectionList).forEach(index => {
          this.$set(this.selectionList, index, false)
        })
      }
      this.$emit('on-select-all', status)
      this.cellSelectChange()
    },
    cellSelectChange () {
      this.$emit('on-select-change', Object.keys(this.selectionList).filter(index => this.selectionList[index]).map(index => this.filterData[index]))
    },
    columnFilterSet ({field, tags, options, sections}) {
      this.$set(this.filterConfig, field, {tags, options, sections})
    },
    columnSortSet ({type, dataType, method, field}) {
      if (this.unionSort === false) {
        this.sortConfig = [{type, dataType, method, field}]
      } else {
        const index = this.sortConfig.findIndex(v => v.field === field)
        if (type === undefined) {
          if (index !== -1) {
            this.sortConfig.splice(index, 1)
          }
        } else {
          if (index !== -1) {
            this.sortConfig.splice(index, 1, {type, dataType, method, field})
          } else {
            this.sortConfig.push({type, dataType, method, field})
          }
        }
      }
    },
    sizeChange (size) {
      this.sizeSet = size
    },
    onUpdateSummaryHeight (height) {
      this.summaryHeight = height
    },
    onScrollY (scrollTop, scrollHeight, height) {
      if (scrollTop >= scrollHeight - height - 40 && this.viewCount * this.viewPage < this.sortData.length) {
        this.viewPage += 1
      }
    }
  },
  watch: {
    size: {
      handler (size) {
        this.sizeSet = size
      }
    },
    filterData: {
      handler () {
        this.selectionList = {}
        this.$emit('counterChange', typeof this.handlerCounter === 'function' ? this.handlerCounter(this.filterData) : this.filterData.length)
      }
    },
    columns: {
      handler () {
        this.updateColumnConfig()
      }
    },
    dataList: {
      handler () {
        this.updateDataList()
      },
      immediate: true,
      deep: true
    },
    tableData () {
      this.activeRowIndex = -1
      this.currentRowIndex = -1
    }
  },
  mounted () {
    this.$on('column-expand', this.onColumnExpand)
    this.$on('on-cell-click', this.onCellClick)
    this.$on('on-row-click', this.onRowClick)
    this.$on('on-row-dbclick', this.onRowDbClick)
    this.$on('on-row-hover', this.onRowHover)
    this.$on('update-head-cell-width', this.handleResize)
    this.$on('update-body-cell-width', this.handleResize)
    this.$on('cell-drag-end', this.cellDragEnd)
    this.$on('cell-data-wrap', this.cellDataWrap)
    this.$on('column-settings', this.updateColumnSettings)
    this.$on('cell-select', this.cellSelect)
    this.$on('cell-all-select', this.cellAllSelect)
    this.$on('column-filter-set', this.columnFilterSet)
    this.$on('column-sort-set', this.columnSortSet)
    this.$on('size-change', this.sizeChange)
    this.$on('scroll-y', this.onScrollY)
    this.$on('update-summary-height', this.onUpdateSummaryHeight)
    // FIXME 列宽自动计算会依赖与该宽度值，该值传递至内层有延迟，会导致列宽按照内容来设定，在内容无法填充满表格时会出问题
    // 但通过width有效后再进行渲染即可解决问题
    this.allWidth = this.$el.clientWidth
    this.width = this.allWidth
  }
}
</script>

<style lang="less">
@import './styles/values.less';
.fm-table-new {
  position: relative;
  box-sizing: border-box;
  &.fm-table-large {
    tr {height: 64px;}
    th, td {
      font-size: @size-font-large;
      padding: 0 16px;
    }
  }
  &.fm-table-norm {
    tr {height: 54px;}
    th, td {
      font-size: @size-font-norm;
      padding: 0 8px;
    }
  }
  &.fm-table-small {
    tr {height: 35px;}
    th, td {
      font-size: @size-font-small;
      padding: 0 4px;
    }
  }
  &.fm-table-mini {
    tr {height: 25px;}
    th, td {
      font-size: @size-font-mini;
      padding: 0 2px;
    }
  }
}
.fm-table-layout-fixed {
  table-layout: fixed;
}
.fm-table-layout-ready {
  visibility: hidden;
}
.fm-table-cell-align-left {
  text-align: left;
  justify-content: flex-start;
}
.fm-table-cell-align-center {
  text-align: center;
  justify-content: center;
}
.fm-table-cell-align-right {
  text-align: right;
  justify-content: flex-end;
}
.fm-table-border {
  border-left: 1px solid @color-border;
  border-bottom: 1px solid @color-border;
  box-sizing: border-box;
  .fm-table-head-cells {
    border-top: 1px solid @color-border;
  }
  .fm-table-head, .fm-table-body {
    th, td {
      border-bottom: 1px solid @color-border;
      border-right: 1px solid @color-border;
    }
  }
  .fm-table-body {
    .fm-table-row {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}
.fm-table-border-row {
  border-bottom: 1px solid @color-border;
  .fm-table-head-cells {
    border-top: 1px solid @color-border;
  }
  box-sizing: border-box;
  .fm-table-head, .fm-table-body {
    th, td {
      border-bottom: 1px solid @color-border;
    }
  }
  .fm-table-body {
    .fm-table-row {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}
.fm-table-border-col {
  border-left: 1px solid @color-border;
  box-sizing: border-box;
  .fm-table-head, .fm-table-body {
    th, td {
      border-right: 1px solid @color-border;
    }
  }
}
.fm-checkbox.fm-table-section > i:first-child {
  margin-right: 0;
}
.fm-table-simple-filter {
  background-color: #FFF;
}
</style>
