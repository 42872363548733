import {
  assetsTypeRequest,
  assetsRequest,
  goodsRequest
} from '../../api'

import {
  tools
} from '@/fmlib'

const dealData = (data, key, label) => {
  let newData = []
  data.forEach((item) => {
    let itemLabel = null

    if (Array.isArray(label)) {
      itemLabel = label.map(v => item[v]).join(' ')
    } else {
      itemLabel = item[label]
    }

    newData.push({
      key: item[key],
      label: itemLabel,
      data: item
    })
  })
  return newData
}

const state = {
  // 正在加载的数据
  icBaseDataLoading: {},
  // 已加载数据
  icBaseDataLoaded: {},
  assetsTypeList: [],
  assetsList: [],
  goodsList: [],
  budgetYearTypeList: [{key: 'normal', label: '年初预算'}, {key: 'append', label: '年终追加'}]
}

const loadData = async function ({commit, getters, reLoad, dataKey, request, mutation, dealSourceData, parm}) {
  if ((reLoad || !getters.icBaseDataLoaded[dataKey]) && !getters.icBaseDataLoading[dataKey]) {
    commit('updateIcBaseDataLoading', {key: dataKey, value: true})
    let dataList = await request(parm)
    commit(mutation, dealSourceData(dataList))
    commit('updateIcBaseDataLoaded', dataKey)
    commit('updateIcBaseDataLoading', {key: dataKey, value: false})
    return getters[dataKey]
  } else if (getters.icBaseDataLoading[dataKey]) {
    while (getters.icBaseDataLoading[dataKey]) {
      await tools.sleep(200)
    }
    return getters[dataKey]
  } else {
    return Promise.resolve(getters[dataKey])
  }
}

const getters = {
  icBaseDataLoading: state => state.icBaseDataLoading,
  icBaseDataLoaded: state => state.icBaseDataLoaded,
  assetsTypeList: state => state.assetsTypeList,
  assetsList: state => state.assetsList,
  goodsList: state => state.goodsList,
  budgetYearTypeList: state => state.budgetYearTypeList
}

const actions = {
  async loadAssetsTypeList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dataList
    }
    return await loadData({commit, getters, reLoad, dataKey: 'assetsTypeList', request: assetsTypeRequest.getTree, mutation: 'setAssetsTypeList', dealSourceData})
  },
  setAssetsTypeList ({ commit }, data) {
    commit('setAssetsTypeList', data)
    commit('updateIcBaseDataLoaded', 'assetsTypeList')
  },
  async loadAssetsList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['code', 'name', 'ggxh'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'assetsList', request: assetsRequest.get, mutation: 'setAssetsList', dealSourceData})
  },
  setAssetsList ({ commit }, data) {
    commit('setAssetsList', data)
    commit('updateIcBaseDataLoaded', 'assetsList')
  },
  async loadGoodsList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['id', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'goodsList', request: goodsRequest.get, mutation: 'setGoodsList', dealSourceData})
  },
  setGoodsList ({ commit }, data) {
    commit('setGoodsList', dealData(data, 'id', ['id', 'name']))
    commit('updateIcBaseDataLoaded', 'goodsList')
  }
}

const mutations = {
  updateIcBaseDataLoading (state, {key, value}) {
    state.icBaseDataLoading[key] = value
  },
  updateIcBaseDataLoaded (state, key) {
    state.icBaseDataLoaded[key] = true
  },
  setAssetsTypeList (state, data) {
    state.assetsTypeList = data
  },
  setAssetsList (state, data) {
    state.assetsList = data
  },
  setGoodsList (state, data) {
    state.goodsList = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
