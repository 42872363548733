<template>
  <div class="fm-date-picker-container" :picker-type="type">
    <div v-if="(timePicker && isTime) || type === 'time'" class="fm-date-picker-time">
      <TimePicker :current="startTime"></TimePicker>
      <TimePicker v-if="isRange" :current="endTime" flag="endTime"></TimePicker>
    </div>
    <template v-else>
      <div class="fm-date-picker-header" v-if="!isRange">
        <div class="fm-date-picker-pre-year" @click="seek('year', -1)"><i class="fmico fmico-left-db-arrow"></i></div>
        <div class="fm-date-picker-pre-month" v-if="showMonthSeek" @click="seek('month', -1)"><i class="fmico fmico-left-arrow"></i></div>
        <div class="fm-date-picker-year" @click="type === 'month' ? switchPickerType('year') : () => false" v-if="type === 'year' || type === 'month'">
          <span class="fm-date-picker-year">{{year}}年</span>
        </div>
        <div class="fm-date-picker-year-month" v-else>
          <span class="fm-date-picker-year" @click="switchPickerType('year')">{{year}}年</span>
          <span class="fm-date-picker-month" @click="switchPickerType('month')">{{month}}月</span>
        </div>
        <div class="fm-date-picker-next-month" v-if="showMonthSeek" @click="seek('month', 1)"><i class="fmico fmico-left-arrow"></i></div>
        <div class="fm-date-picker-next-year" @click="seek('year', 1)"><i class="fmico fmico-left-db-arrow"></i></div>
      </div>
      <div class="fm-date-picker-header fm-date-picker-header-range" v-else>
        <div class="fm-date-picker-pre-year" @click="seek('year', -1)"><i class="fmico fmico-left-db-arrow"></i></div>
        <div class="fm-date-picker-pre-month" @click="seek('month', -1)"><i class="fmico fmico-left-arrow"></i></div>
        <div class="fm-date-picker-year-month">
          <div>
            <span class="fm-date-picker-year" @click="switchPickerType('year')">{{year}}年</span>
            <span class="fm-date-picker-month" @click="switchPickerType('month')">{{month}}月</span>
          </div>
          至
          <div>
            <span class="fm-date-picker-year" @click="switchPickerType('year')">{{endYearYear}}年</span>
            <span class="fm-date-picker-month" @click="switchPickerType('month')">{{endMonth}}月</span>
          </div>
        </div>
        <div class="fm-date-picker-next-month" @click="seek('month', 1)"><i class="fmico fmico-left-arrow"></i></div>
        <div class="fm-date-picker-next-year" @click="seek('year', 1)"><i class="fmico fmico-left-db-arrow"></i></div>
      </div>
      <div class="fm-date-picker-content" @mouseleave="$refs.follow.me()">
          <CellFollow ref="follow"></CellFollow>
          <DateCells :disabledDate="disabledDate" :range="range" :cells="cells" :type="pickerType"></DateCells>
          <DateCells :disabledDate="disabledDate" :range="range" v-if="isRange" :cells="cells2" :type="pickerType"></DateCells>
      </div>
    </template>
    <div class="fm-date-picker-footer" v-if="isTime || isConfirm">
      <span v-if="isTime && type !== 'time'" :class="{
        'fm-date-picker-disabled': !showTimeChoose
      }" @click="chooseTime">{{timePicker ? '选择日期' : '选择时间'}}</span>
      <div>
        <span class="fm-data-picker-btn" @click="clear">清空</span>
        <span class="fm-data-picker-btn primary" @click="onConfirm">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getCellsDates, XDate } from './lib'
import DateCells from './DateCells.vue'
import CellFollow from './CellFollow.vue'
import TimePicker from './TimePicker.vue'

export default {
  name: '',
  components: {
    DateCells, CellFollow, TimePicker
  },
  data() {
    return {
      value: new Map(),
      date: null,
      date1: null,
      candidate: null,
      range: null,
      startTime: null,
      endTime: null,
      pickerType: 'date',
      timePicker: false
    }
  },
  provide () {
    return {
      picker: this
    }
  },
  props: {
    type: {
      type: String,
      default: 'date'
    },
    startDate: {
      type: [XDate, Date],
      default: () => new XDate()
    },
    data: {
      type: [XDate, Date, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Boolean,
      default: false
    },
    disabledDate: {
      type: Function,
      default: undefined
    },
    template: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    endYearYear () {
      if (!this.isRange) {
        return null
      } else {
        return this.date1 ? this.date1.getFullYear() : null
      }
    },
    endMonth () {
      if (!this.isRange) {
        return null
      } else {
        return this.date1 ? this.date1.getMonth() + 1 : null
      }
    },
    isConfirm () {
      return this.isTime || this.confirm === undefined || this.confirm === true
    },
    isTime () {
      return this.type.indexOf('time') > -1
    },
    isRange () {
      return ['daterange', 'datetimerange'].includes(this.type)
    },
    cells () {
      return getCellsDates(this.date, this.pickerType)
    },
    cells2 () {
      return this.isRange ? getCellsDates(this.date1, this.pickerType) : []
    },
    year () {
      return this.date.getFullYear()
    },
    month () {
      return this.date.getMonth() + 1
    },
    showMonthSeek () {
      return !['year', 'month'].includes(this.type)
    },
    showTimeChoose () {
      return (this.isRange && this.pickerType !== 'time') ? (this.range && this.range.start && this.range.end) : true
    }
  },
  methods: {
    getValueByIndex (index) {
      if (this.value.size < index + 1) {
        return null
      }
      return Array.from(this.value.values())[index]
    },
    switchPickerType (type) {
      if (type === this.pickerType) {
        this.pickerType = 'date'
      } else {
        this.pickerType = type
      }
    },
    onConfirm () {
      if (this.isTime && this.timePicker) {
        this.timePicker = false
        this.initPickerType()
      }
      this.onChange()
    },
    clear () {
      this.startTime = null
      this.endTime = null
      this.value.clear()
      this.range = null
      this.candidate = null
      this.$emit('update', this.value)
      this.onChange()
    },
    chooseTime () {
      if (this.showTimeChoose) {
        this.timePicker = !this.timePicker
        if (this.timePicker) {
          this.pickerType = 'time'
        } else {
          this.initPickerType()
        }
      }
    },
    seek (type, step) {
      if (type === 'year') {
        this.date = new XDate(this.date.getFullYear() + step * (this.pickerType === 'year' ? 9 : 1), this.date.getMonth(), 1)
      } else if (type === 'month') {
        this.date = new XDate(this.date.getFullYear(), this.date.getMonth() + step, 1)
      }
    },
    choose (cell) {
      const key = cell.date.format()

      const next = (this.type === 'date' && ['year', 'month'].includes(this.pickerType)) || (this.type === 'month' && this.pickerType === 'year') || (this.isRange && this.pickerType === 'month')

      if (this.multiple && ['year', 'month', 'date', 'datetime'].includes(this.type)) {
        if (this.type === 'date' && this.pickerType === 'year') {
          this.date = new XDate(cell.date.getFullYear(), this.date.getMonth(), 1)
          this.pickerType = 'month'
        } else if (this.type === 'date' && this.pickerType === 'month') {
          this.date = new XDate(cell.date.getFullYear(), cell.date.getMonth(), 1)
          this.pickerType = 'date'
        } else {
          const has = this.checkSelected(cell.date)
          if (has) {
            this.value.delete(key)
          } else {
            this.value.set(key, cell)
          }
        }
      } else if (this.isRange) {
        if (this.pickerType === 'year') {
          this.date = new XDate(cell.date.getFullYear(), this.date.getMonth(), 1)
          this.pickerType = 'month'
        } else if (this.pickerType === 'month') {
          this.date = new XDate(cell.date.getFullYear(), cell.date.getMonth(), 1)
          this.pickerType = 'date'
        } else {
          let start = this.getValueByIndex(0)
          let end = this.getValueByIndex(1)
  
          if (this.pickerType === 'time' && start && end) {
            if (cell.flag === 'startTime') {
              let sKey = start.date.format()
              start.date = new XDate(start.date.format('Y/M/D') + ' ' + cell.date.format('H:I:S'))
              this.value.set(sKey, start)
            } else {
              let eKey = end.date.format()
              end.date = new XDate(end.date.format('Y/M/D') + ' ' + cell.date.format('H:I:S'))
              this.value.set(eKey, end) 
            }
          } else if (cell.flag === 'candidate') {
            this.candidate = cell.date
          } else if (!start || cell.date < start.date) {
            this.value.clear()
            this.value.set(key, cell)
          } else if (start.date.format('ymd') === cell.date.format('ymd')) {
            this.value.clear()
          } else if (!end) {
            this.value.set(key, cell)
          } else if (end.date.format('ymd') === cell.date.format('ymd')) {
            this.value.delete(key)
          } else {
            this.value.clear()
          }
        }
      } else {
        this.value.clear()
        this.value.set(key, cell)

        if (this.type === 'date' || this.type === 'datetime') {
          if (this.pickerType === 'year') {
            this.date = new XDate(cell.date.getFullYear(), this.date.getMonth(), 1)
            this.pickerType = 'month'
          } else if (this.pickerType === 'month') {
            this.date = new XDate(cell.date.getFullYear(), cell.date.getMonth(), 1)
            this.pickerType = 'date'
          }
        } else if (this.type === 'month') {
          if (this.pickerType === 'year') {
            this.date = new XDate(cell.date.getFullYear(), this.date.getMonth(), 1)
            this.pickerType = 'month'
          }
        }
      }

      if (!['candidate'].includes(cell.flag)) {
        if (cell.pre) {
          this.seek('month', -1)
        } else if (cell.next) {
          this.seek('month', 1)
        }
      }

      this.updateSubCondition()

      // let keys = []
      // for (let key of this.value.keys()) {
      //   keys.push(key.substr(0, 8))
      // }

      this.onValueUpdate()
      !this.isConfirm && !next && this.onChange()
    },
    updateSubCondition () {
      if (this.isRange || this.isTime) {
        let values = Array.from(this.value.values())
        this.startTime = this.value.size > 0 ? values[0].date : new XDate()
        this.endTime = this.value.size > 1 ? values[values.length - 1].date : this.startTime
      }

      if (this.isRange) {
        let start = this.getValueByIndex(0)
        let end = this.getValueByIndex(1)
        this.range = {
          start: start ? start.date : null,
          end: end ? end.date : null,
          candidate: this.candidate
        }
      }
    },
    checkSelected (date) {
      const key = date.format()
      const vKeys = Array.from(this.value.keys())
      if (this.type === 'year') {
        return vKeys.map(v => v.substr(0, 4)).includes(key.substr(0, 4))
      } else if (this.type === 'month') {
        return vKeys.map(v => v.substr(0, 6)).includes(key.substr(0, 6))
      } else {
        return vKeys.map(v => v.substr(0, 8)).includes(key.substr(0, 8))
      }
    },
    onValueUpdate () {
      this.$emit('update', this.value)
    },
    onChange () {
      this.$emit('change', this.value)
    },
    initPickerType (type) {
      if (type === 'year') {
        this.pickerType = 'year'
      } else if (type === 'month') {
        this.pickerType = 'month'
      } else {
        this.pickerType = 'date'
      }
    }
  },
  watch: {
    startDate: {
      immediate: true,
      deep: true,
      handler (date) {
        this.date = new XDate(date.getTime()) || new XDate()
      }
    },
    type: {
      immediate: true,
      deep: true,
      handler (type) {
        this.initPickerType(type)
      }
    },
    data: {
      immediate: true,
      deep: true,
      handler (newData, oldData) {
        let isDifferent = !oldData || !newData
        let newIsArray = Array.isArray(newData)
        let oldIsArray = Array.isArray(oldData)

        if (newIsArray) {
          if (oldIsArray) {
            isDifferent = !newData.find(v => oldData.find(l => v && l && v.getTime() === l.getTime()))
          } else {
            isDifferent = true
          }
        } else {
          if (oldIsArray || !oldData) {
            isDifferent = true
          } else {
            isDifferent = !newData || newData.getTime() === oldData.getTime()
          }
        }

        if (isDifferent) {
          this.value.clear()
          if (newIsArray) {
            newData.forEach(v => {
              if (v) {
                v = v instanceof XDate ? v : new XDate(v.getTime())
                this.value.set(v.format(), {date: v})
              }
            })
          } else if (newData) {
            let v = v instanceof XDate ? newData : new XDate(newData.getTime())
            this.value.set(v.format(), {date: v})
          }

          const start = this.getValueByIndex(0)
          this.date = start ? start.date : this.date

          this.updateSubCondition()
          this.onValueUpdate()
        }
      }
    },
    date: {
      immediate: true,
      deep: true,
      handler (date) {
        if (date) {
          let d = new XDate(date.getTime())
          d.setMonth(d.getMonth() + 1)
          this.date1 = d
        }
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
@import '../styles/datapicker.less';
.fm-date-picker-content {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-width: 200px;
}
.fm-date-picker-time {
  display: flex;
  flex-wrap: wrap;
  min-width: 200px;
}
.fm-date-picker-container {
  width: 300px;
  border-radius: 5px;
  &[picker-type="year"], &[picker-type="month"], &[picker-type="time"] {
    width: 200px;
  }
}
.fm-date-picker-range {
  .fm-date-picker-container {
    width: 600px;
  }
}
</style>