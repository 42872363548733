/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Mon Aug 31 2020
 */
export function textWidth (text, fontSize, fontFamily = 'inherit') {
  let span = document.querySelector('#text-width-fixed')
  if (!span) {
    span = document.createElement('span')
    span.id = 'text-width-fixed'
    document.body.appendChild(span)
  }
  let result = { width: span.offsetWidth, height: span.offsetHeight }
  span.style.visibility = 'hidden'
  span.style.fontSize = fontSize
  span.style.fontFamily = fontFamily
  span.style.display = 'inline-block'
  span.style.maxWidth = '1000px'
  span.innerText = text
  result.width = parseFloat(window.getComputedStyle(span).width) - result.width
  result.height = parseFloat(window.getComputedStyle(span).height) - result.height
  return result
}

export class FmTableColumn {
  constructor (column) {
    !column.field && console.warn('表格列未配置field', column)
    this.fixed = ['left', 'right'].includes(column.fixed) ? column.fixed : false
    this.config = column
    this.needSelection = column.needSelection || null
    this.order = column.order || 0
    this.hidden = column.hidden || false
    this.search = typeof column.search === 'boolean' ? column.search : true

    this.minWidth = column.minWidth || undefined
    this.maxWidth = column.maxWidth || undefined
    this.width = undefined

    if (column.slot) {
      this.type = 'slot'
    } else if (column.render && !column.expand) {
      this.type = 'render'
    } else if (column.expand) {
      this.type = 'expand'
    } else if (column.type === 'selection') {
      this.type = column.type
      this.width = 30
    } else {
      this.type = 'cell'
    }

    if (column.width) {
      this.width = column.width
    } else if (this.minWidth) {
      this.width = this.minWidth
    }
  }
}

export class FmTableColumns extends Array {
  constructor (columnList, {selection}) {
    super()

    if (selection !== false) {
      if (selection === true) {
        columnList = [{type: 'selection', field: '_selection', search: false}, ...columnList]
      } else if (selection === 'left') {
        columnList = [{type: 'selection', field: '_selection', search: false, fixed: 'left'}, ...columnList]
      } else if (selection === 'right') {
        columnList = [...columnList, {type: 'selection', field: '_selection', search: false, fixed: 'right'}]
      }
    }

    const columns = columnList.map((columnItem) => {
      return new FmTableColumn(columnItem)
    })

    const showColumns = columns.filter(v => v.hidden === false).sort((a, b) => a.order - b.order)

    this[0] = showColumns.filter(v => v.fixed === 'left')
    this[1] = showColumns.filter(v => !v.fixed)
    this[2] = showColumns.filter(v => v.fixed === 'right')

    // let leftWidth = 0
    // let rightWidth = 0

    // showColumns.forEach(col => {
    //   if (col.width && col.fixed === 'left') {
    //     leftWidth += col.width
    //   }
    //   if (col.width && col.fixed === 'right') {
    //     rightWidth += col.width
    //   }
    // })

    this.expandList = this[1].filter(v => v.type === 'expand')
    this.isExpand = this.expandList.length > 0
    this.isFixed = (showColumns.findIndex(v => v.fixed !== false) > -1)
    this.columns = columns
    this.showColumns = showColumns

    this.getByField = function (field) {
      return this.columns.find(v => v.field === field)
    }
  }
}

export function sortBlock (array, field) {
  let groups = {}
  array.forEach(v => {
    if (!groups[v[field]]) {
      groups[v[field]] = []
    }
    groups[v[field]].push(v)
  })
  return Object.keys(groups).map(v => groups[v])
}

export function unionSort (dataList, configs, step = 0) {
  if (dataList.length <= 1) {
    return dataList
  }
  let config = configs[step]
  if (step < configs.length - 1) {
    return [...sortBlock(bubbleSort(dataList, config), config.field).map(group => {
      return [...unionSort(group, configs, step + 1)]
    })].flat()
  } else {
    return bubbleSort(dataList, config)
  }
}

export function bubbleSort (baseData, {type, dataType, method, field}) {
  if (field === undefined || !baseData.length) {
    return baseData
  } else {
    const dataList = [...baseData]
    let format = (value) => value === null ? '' : value

    if (dataType === String) {
      format = (value) => String(value).length
    } else if (dataType === Number) {
      format = (value) => Number(value)
    } else if (dataType === Date) {
      format = (value) => new Date(value).getTime()
    }

    let sort = type === 'desc' ? (a, b) => a < b : (a, b) => a > b
    let fn = typeof method === 'function' ? (a, b, type) => method(a, b, type) : (a, b) => sort(format(a[field]), format(b[field]))
    for (let i = 0, len = dataList.length; i < len; i++) {
      for (let l = 0, end = len - i - 1; l < end; l++) {
        if (fn(dataList[l], dataList[l + 1], type)) {
          [dataList[l], dataList[l + 1]] = [dataList[l + 1], dataList[l]]
        }
      }
    }
    format = sort = fn = null
    return dataList
  }
}