export const loadData = async (request, vm, parm) => {
  let datas = []
  if (vm.$authFunsProxy.get) {
    datas = await request.get(parm)
  } else if (vm.$authFunsProxy.getMyOrgContainChild) {
    datas = await request.getMyOrgContainChild(parm)
  } else if (vm.$authFunsProxy.getMyOrg) {
    datas = await request.getMyOrg(parm)
  } else if (vm.$authFunsProxy.getMy) {
    datas = await request.getMy(parm)
  }
  return datas
}

export const getSelectWorkerList = (vm) => {
  let datas = []
  if (vm.$authFunsProxy.get) {
    datas = vm.$store.getters.workerList
  } else if (vm.$authFunsProxy.getMyOrgContainChild) {
    datas = vm.$store.getters.workerListMyOrgContainChild
  } else if (vm.$authFunsProxy.getMyOrg) {
    datas = vm.$store.getters.workerListMyOrg
  } else if (vm.$authFunsProxy.getMy) {
    datas = vm.$store.getters.workerListMy
  }
  return datas
}

export const loadSelectWorkerList = async (vm) => {
  if (vm.$authFunsProxy.get) {
    vm.$store.dispatch('loadWorkerList')
  } else if (vm.$authFunsProxy.getMyOrgContainChild) {
    vm.$store.dispatch('loadWorkerListMyOrgContainChild')
  } else if (vm.$authFunsProxy.getMyOrg) {
    vm.$store.dispatch('loadWorkerListMyOrg')
  } else if (vm.$authFunsProxy.getMy) {
    vm.$store.dispatch('loadWorkerListMy')
  }
}

export const getFUn = (url) => {
  return {
    get: {
      funKey: 'get',
      name: '查看',
      apis: [{
        path: `/basis/${url}`,
        method: 'GET'
      },
      {
        path: '/basis/worker',
        method: 'GET'
      }]
    },
    getMy: {
      funKey: 'getMy',
      name: '查看我的',
      apis: [{
        path: `/basis/${url}/my`,
        method: 'GET'
      },
      {
        path: '/basis/worker/my',
        method: 'GET'
      }]
    },
    getMyOrg: {
      funKey: 'getMyOrg',
      name: '查看本机构下的',
      apis: [{
        path: `/basis/${url}/my_org`,
        method: 'GET'
      },
      {
        path: '/basis/worker/my_org',
        method: 'GET'
      }]
    },
    getMyOrgContainChild: {
      funKey: 'getMyOrgContainChild',
      name: '查看本机构及其子机构的',
      apis: [{
        path: `/basis/${url}/my_org_contain_child`,
        method: 'GET'
      },
      {
        path: '/basis/worker/my_org_contain_child',
        method: 'GET'
      }]
    }
  }
}