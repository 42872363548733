import {
  localstorageHelper
} from '@/fmlib'

const state = {
  userInfo: {},
  userSignFileId: null,
  orgRoles: [],
  currentOrgRole: null
}

const getters = {
  userInfo: state => state.userInfo,
  userSignFileId: state => state.userSignFileId,
  orgRoles: state => state.orgRoles,
  currentOrgRole: state => state.currentOrgRole,
  currentUserId: state => {
    return state.userInfo ? state.userInfo.id : null
  },
  currentOrgId: state => {
    return state.currentOrgRole ? state.currentOrgRole.orgId : []
  },
  currentRoleId: state => {
    return state.currentOrgRole ? state.currentOrgRole.roleId : []
  },
  currentUserIsAdmin: state => {
    return state.currentOrgRole && state.currentOrgRole.roleId === 1
  },
  currentFuns: state => {
    return state.currentOrgRole ? state.currentOrgRole.funs : []
  },
  currentFunKeys: state => {
    return (state.currentOrgRole ? state.currentOrgRole.funs : []).map(v => v.funKey)
  },
  currentDataAuthority: state => {
    return state.currentOrgRole ? state.currentOrgRole.authorities : []
  }
}

const actions = {
  setUserData ({ dispatch }, data) {
    dispatch('setUserInfo', {
      nickname: data.nickname,
      name: data.name,
      id: data.id
    })
    dispatch('setUserSignFileId', data.signFileId || null)
    dispatch('setOrgRoles', data.orgRoles || [])
  },
  setUserSignFileId ({ commit }, data) {
    commit('setUserSignFileId', data)
  },
  setUserInfo ({ commit }, data) {
    commit('setUserInfo', data)
  },
  setOrgRoles ({ commit, dispatch }, data) {
    data.forEach(v => {
      v.label = (v.orgName ? v.orgName + ' ' : '') + (v.roleName ? v.roleName : '')
    })
    commit('setOrgRoles', data)
    if (data.length > 0) {
      let orgRoleId = localstorageHelper.getData('orgRoleId')
      dispatch('setCurrentOrgRole', (data.find(v => v.id === orgRoleId) || data[0]).id)
    } else {
      dispatch('setCurrentOrgRole', null)
    }
  },
  setCurrentOrgRole ({ commit, getters, dispatch }, orgRoleId) {
    let orgRole = getters.orgRoles.find(v => v.id === orgRoleId)
    if (orgRole && orgRole.id) {
      localstorageHelper.updateData('roleId', orgRoleId)
    }
    commit('setCurrentOrgRole', orgRole)
    let currentMenus = getters.currentOrgRole && getters.currentOrgRole.menus ? getters.currentOrgRole.menus.filter(v => v.clientType === getters.clientType) : []
    dispatch('setCurrentMenus', currentMenus)
  }
}

const mutations = {
  setUserInfo (state, data) {
    state.userInfo = data
  },
  setUserSignFileId (state, data) {
    state.userSignFileId = data
  },
  setOrgRoles (state, data) {
    state.orgRoles = data
  },
  setCurrentOrgRole (state, data) {
    state.currentOrgRole = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
