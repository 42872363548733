export default {
  base: {
    funKey: 'base',
    name: '基础',
    apis: [{
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/ucenter/user',
      method: 'GET'
    },
    { method: 'GET', path: '/internalcontrol/budget/detail' },
    {
      path: '/internalcontrol/status_manage/config',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_repair_apply/by_auth',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets/my_org',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_repair_apply/{id}',
      method: 'DELETE'
    },
    {
      path: '/internalcontrol/assets_repair_apply/{id}',
      method: 'PUT'
    },
    {
      path: '/internalcontrol/assets_repair_apply/switch/{id}',
      method: 'POST'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增申请',
    apis: [{
      path: '/internalcontrol/assets_repair_apply',
      method: 'POST'
    }]
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取扫描件'
  },
  updateDataFile: {
    funKey: 'updateDataFile',
    name: '上传扫描件',
    apis: [{
      path: '/internalcontrol/assets_repair_apply/file_ids/{id}',
      method: 'PUT'
    }]
  },
  delDataFile: {
    funKey: 'delDataFile',
    name: '删除扫描件',
    apis: [{
      path: '/internalcontrol/assets_repair_apply/file_ids/{id}',
      method: 'PUT'
    }]
  }
}