<template>
  <div class="fm-tree-node" @contextmenu.stop.prevent="onContextmenu" v-show="isShow" :class="{
      'fm-tree-node-open': isOpen,
      'fm-tree-node-checked': checked,
      'fm-tree-node-selected': selected,
      'fm-tree-node-disable': node.disabled
    }">
    <div class="fm-tree-node-title">
      <div class="fm-tree-node-arrow" @click="openThis(true)" v-if="(node.children && node.children.length > 0) || showChildAdd">
        <i class="fmico fmico-top-arrow"></i>
      </div>
      <div class="fm-tree-content" @click="selectedThis()" v-if="nodeRender">
        <tree-node-render :render="nodeRender" :node="node" :data="node.data"></tree-node-render>
      </div>
      <div class="fm-tree-content fm-tree-content-loading" v-else-if="loading">
        <div class="fm-tree-label fm-tree-node-loading"><i class="fmico fmico-loading fm-load-loop"></i> {{loadType}}</div>
      </div>
      <div class="fm-tree-content fm-tree-content-input" v-else-if="node.title === null || needEdit">
        <input class="fm-tree-input" ref="editInput" placeholder="请输入名称" @focus="editClick" @keyup.enter="editEnter" @blur="editEnter"/>
        <i class="fmico fmico-edit" @click="editClick"></i>
      </div>
      <div class="fm-tree-content fm-tree-content-checkbox" v-else-if="isChecked === undefined || isChecked === true">
        <fm-checkbox @click.native="selectedThis(false)" :value="checked" :disabled="node.disabled"><div class="fm-tree-label">{{node.title}}</div></fm-checkbox>
      </div>
      <div class="fm-tree-content" v-else @click="selectedThis()" >
        <div class="fm-tree-label">{{node.title}}</div>
      </div>
    </div>
    <div class="fm-tree-children" v-show="isOpen">
      <template v-if="node.children">
        <fm-tree-node v-for="(n, i) in node.children" :key="$vnode.key + '-' + i"
        :node-render="nodeRender"
        :node="n"
        :isChecked="isChecked"
        :isSelected="isSelected"
        :edit="isEdit"
        :multiple="multiple"
        :index="i"
        :level="level + 1"
        :searchKey="searchKey"></fm-tree-node>
      </template>
      <div class="fm-tree-add" :class="{'fm-tree-node-loading': loading}" v-if="isEdit && showChildAdd">
        <input v-show="addInput" class="fm-tree-input" @keyup.enter="(e) => addNode({title: e.target.value})" ref="addInput" @blur="addInput = false, showChildAdd = false"/>
        <i class="fmico" :class="{'fmico-add': !loading, 'fmico-loading': loading, 'fm-load-loop': loading}"></i>
        <div v-show="!addInput" class="fm-tree-add-btn" @click="addClick">添加</div>
      </div>
    </div>
    <div class="fm-tree-node-menu-list" ref="menu-list" @mouseleave="showMenu = false" v-show="showMenu">
      <i class="fmico fmico-add" @click="showChildAdd = true, open = true"></i>
      <i class="fmico fmico-edit" @click="editClick(true)"></i>
      <i class="fmico fmico-del" @click="delClick"></i>
    </div>
  </div>
</template>

<script>

import TreeNodeRender from './FmTreeNode/render'

function getParents (treeNode) {
  if (treeNode.$parent.$options.name === 'FmTree') {
    return [treeNode]
  } else {
    return [...getParents(treeNode.$parent), treeNode]
  }
}

// function eachParents (treeNode, fn) {
// 	const going = fn(treeNode)

// 	if (treeNode.$parent.$options.name !== 'FmTree') {
// 		if (going === undefined || going === true) {
// 			eachParents(treeNode.$parent)
// 		}
// 	}
// }

function eachChildren (treeNode, fn) {
  if (treeNode.$options && treeNode.$options.name === 'FmTreeNode') {
    for (let i = 0; i < treeNode.$children.length; i++) {
      const going = fn(treeNode.$children[i])
      if (going === undefined || going === true) {
        eachChildren(treeNode.$children[i], fn)
      }
    }
  }
}

export default {
  components: {TreeNodeRender},
  name: 'FmTreeNode',
  data() {
    return {
      checked: false,
      open: false,
      selected: false,
      addInput: false,
      showMenu: false,
      showChildAdd: false,
      needEdit: false,
      loading: false,
      loadType: '加载中...'
    }
  },
  provide () {
    return {
      root: this.root
    }
  },
  inject: ['root'],
  props: {
    nodeRender: {
      type: Function
    },
    searchKey: {
      default: null
    },
    level: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    node: {
      type: Object,
      default () {
        return {}
      }
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isOpen () {
      return this.open || this.searchKey
    },
    isEdit () {
      return this.edit === undefined || this.edit === true
    },
    isShow () {
      if (this.searchKey) {
        let childrenShow = false
        const thisShow = this.node.title.indexOf(this.searchKey) > -1
        if (!thisShow) {
          eachChildren(this, (node) => {
            if (node.isShow) {
              childrenShow = true
              return false
            }
          })
        }
        return childrenShow || thisShow
      } else {
        return true
      }
    }
  },
  methods: {
    onContextmenu (e) {
      if (this.isEdit) {
        this.showMenu = !this.showMenu
      }
      this.report('contextmenu', e)
    },
    openThis (force = false) {
      if (force || !this.isSelected) {
        this.open = !this.open
      }
      this.showChildAdd = false
    },
    async delClick () {
      const result = await this.$dialog.confirm(`<div style="color: red;text-align: center;">确定要删除${this.node.title}及其子节点么?</div>`)
      if (result) {
        this.report('nodeRemove')
      }
    },
    editEnter (event) {
      if (event.type === 'keyup' && (event.key === 'enter' || event.code === 'enter' || event.keyCode === 13)) {
        let oldVal = this.$refs['editInput'].dataset.value
        let newVal = this.$refs['editInput'].value
        this.report('nodeTitleChange', {
          old: oldVal,
          new: newVal
        })
      }
      this.needEdit = false
    },
    editClick (write = false) {
      this.needEdit = true
      this.showMenu = false
      this.$nextTick(() => {
        if (write) {
          this.$refs['editInput'].dataset.value = this.node.title
        }
        this.$refs['editInput'].value = this.node.title
        setTimeout(() => {this.$refs['editInput'].focus()}, 200)
      })
    },
    addNode (node) {
      this.report('nodeAdd', node)
    },
    addClick () {
      this.addInput = true
      setTimeout(() => {this.$refs['addInput'].focus()}, 200)
    },
    selectedThis (open) {
      // console.log(this.isChecked, this.node.disabled)
      if (this.isSelected === undefined || this.isSelected === true && !this.node.disabled) {
        this.report('select')
      }
      if (this.isChecked === undefined || this.isChecked === true && !this.node.disabled) {
        this.report('check')
      }
      open !== false && this.openThis()
    },
    report (type, data = {}) {
      this.root.report(type, Object.assign({}, {
        eventData: data
      }, {
        data: this.node
      }, {
        index: this.index,
        level: this.level,
        checked: this.checked,
        selected: this.selected,
        open: this.open,
        paths: getParents(this),
        key: this.$vnode.key,
        parent: this.$parent,
        eventType: type,
        node: this
      }))
    },
    treeOnCheck (data) {
      if (this.multiple === true || this.multiple === undefined) {
        if (data.key === this.$vnode.key) {
          this.checked = !this.checked
        } else if (data.checked === false) {
          const keys = data.paths.map(v => v.$vnode.key)
          if (keys.includes(this.$vnode.key)) {
            this.checked = true
          }
        }
      } else if (!this.multiple) {
        this.checked = this.checked ? false : (data.key === this.$vnode.key)
      }
    },
    treeOnSelect (data) {
      // console.log({selected: this.selected, m: this.multiple, dkey: data.key, vkey: this.$vnode.key, el: this.$el, in: (this.multiple === true || this.multiple === undefined) && data.key === this.$vnode.key})
      if ((this.multiple === true || this.multiple === undefined) && data.key === this.$vnode.key) {
        this.selected = !this.selected
      } else if (!this.multiple) {
        this.selected = data.key === this.$vnode.key
      }
    }
  },
  watch: {
    node: {
      deep: true,
      immediate: true,
      handler () {
        this.$nextTick(() => {
          if (this.node.selected) {
            this.report('select')
          }
          if (this.node.checked) {
            this.report('check')
          }
          this.open = this.node.open !== undefined ? this.node.open : this.open
        })
      }
    },
    selected () {
      this.report('selected')
    },
    checked () {
      this.report('checked')
    },
    showMenu (val) {
      if (val) {
        const rect = this.$el.getBoundingClientRect()
        this.$refs['menu-list'].style.left = rect.left - 15 + rect.width + 'px'
        this.$refs['menu-list'].style.top = rect.top + 2 + 'px'
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.root.$on('check', this.treeOnCheck)
    this.root.$on('select', this.treeOnSelect)
    this.root.$on('matched', this.treeOnMatched)
    // this.isEdit && document.addEventListener('contextmenu', this.contextmenu)
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {
    // document.removeEventListener('contextmenu', this.contextmenu)
  },
  errorCaptured() {}
}
</script>

<style lang="less">
  @import './styles/values.less';
  @tree-node-color: #515a6e;
  @tree-node-sub-color: #808695;
  @size-node-height: 20px;
  .fm-tree-node {
    line-height: @size-node-height;
    position: relative;
    margin-top: 5px;
    // .fm-tree-node-menu {
    //   position: absolute;
    //   top: 2px;
    //   right: 0;
    //   display: none;
    //   .fmico-side-menu {
    //     color: @tree-node-sub-color;
    //     cursor: pointer;
    //   }
    // }
    .fm-tree-node-title {
      display: flex;
      align-items: center;
    }
    .fm-tree-node-menu-list {
      background-color: #FFF;
      position: fixed;
      .fmico {
        color: @tree-node-sub-color;
        cursor: pointer;
        &:hover {
          color: @tree-node-color;
        }
      }
    }
    &:hover {
      &>.fm-tree-node-menu {
        display: block;
      }
    }
    .fm-tree-node, .fm-tree-add {
      padding: 2px 18px 2px 18px;
    }
    .fm-tree-add {
      line-height: @size-node-height + 1px;
      padding: 0 0 0 18px;
      position: relative;
      &>* {
        margin-left: 23px;
      }
      .fmico-add, .fmico-loading {
        left: 22px;
        top: 1px;
        position: absolute;
        transition: all .2s;
        color: @tree-node-sub-color;
      }
      .fm-tree-add-btn {
        transition: all .2s;
        color: @tree-node-sub-color;
        padding-left: 25px;
        text-align: left;
        font-size: 0.8rem;
        cursor: pointer;
        &:hover {
          color: @color-info;
        }
      }
    }
    .fm-tree-input {
      line-height: @size-node-height;
      height: @size-node-height;
      position: relative;
      opacity: 0;
      background-color: rgba(87, 163, 243, 1);
      transition: all .2s;
      transform: translateX(20px);
      & + .fmico {
        transition: all .2s;
      }
    }
    .fm-tree-input:focus {
      opacity: 1;
      background-color: rgba(87, 163, 243, 0);
      transform: translateX(0);
      & + .fmico {
        transform: rotate(360deg);
        top: 1px;
        color: rgba(87, 163, 243, 1);
      }
    }
    .fm-tree-node-loading {
      display: flex;
      align-items: center;
      color: @tree-node-sub-color;
      &>.fmico-loading {
        margin-right: 5px;
        color:rgba(87, 163, 243, 1);
        display: inline-block;
      }
    }
  }
  .fm-tree-label {
    cursor: pointer;
    border-radius: 4px;
    padding: 0 5px;
    color: @tree-node-color;
  }
  .fm-tree-node-arrow, .fm-tree-content {
    display: inline-block;
  }
  .fm-tree-content {
    position: relative;
    color: @tree-node-color;
    &.fm-tree-content-checkbox {
      padding-left: 5px;
    }
    & .fmico-edit {
      position: absolute;
      left: 26px;
      top: 4px;
    }
    &>.fmico-edit {
      cursor: pointer;
    }
  }
  .fm-tree-node-arrow {
    z-index: 1;
    cursor: pointer;
    // position: absolute;
    .fmico-top-arrow {
      position: relative;
      top: 1px;
      font-size: 0.8rem;
      color: #6e727a;
      transition: all .2s;
      display: block;
      transform: rotate(90deg);
      transform-origin: center center;
      &:hover {
        color: #3a4458;
      }
    }
  }
  // 选中效果相关
  .fm-tree-node {
    &.fm-tree-node-open {
      &>.fm-tree-node-title>.fm-tree-node-arrow>.fmico-top-arrow {
        transform: rotate(180deg);
      }
    }
    &.fm-tree-node-disable {
      cursor: not-allowed;
      &>.fm-tree-node-title>.fm-tree-content>.fm-tree-label{
        color: #c3c9d5;
        &:hover {
          color: #cdd2dd;
        }
      }
    }
    &.fm-tree-node-selected {
      &>.fm-tree-node-title>.fm-tree-content>.fm-tree-label,
      &>.fm-tree-node-title>.fm-tree-content>.fm-checkbox>span>.fm-tree-label {
        background-color: #d5e8fc;
      }
    }
  }
</style>