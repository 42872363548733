<template>
  <div>
    <h2>弹出下拉选择(FmDownSelect)</h2>
    <div class="flex">
      <fm-down-select :options="options">
        <fm-btn>test</fm-btn>
      </fm-down-select>
      <fm-down-select :options="options" confirm>
        <fm-btn>单选+确认</fm-btn>
      </fm-down-select>
      <fm-down-select :options="options" multiple trigger="hover">
        <fm-btn>悬浮触发</fm-btn>
      </fm-down-select>
      <fm-down-select position="right-start" :options="options" multiple confirm>
        <fm-btn>多选+确认</fm-btn>
      </fm-down-select>
      <fm-down-select position="right" :options="options" v-model="value" multiple confirm>
        <fm-btn>双向绑定</fm-btn>
      </fm-down-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmDownSelectDemo',
  components: {},
  data() {
    return {
      value: ['1', '3']
    }
  },
  props: {},
  computed: {
    options () {
      return [
        { label: '选项A', value: '1' },
        { label: '选项B', value: '2' },
        {
          label: '选项C', value: '3', render ({label, value}) {
            return label + ' - ' + value
          }
        }
      ]
    }
  },
  methods: {
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped></style>