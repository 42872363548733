import request from '../../api'

const state = {
  storeHouseList: [],
  goodsProduceStandardList: []
}

const defaultPlanStatusList = [
  {
    label: '计划', value: 0, color: 'default', actions: [
      { label: '修改', type: 'btn', value: 'update' },
      { label: '删除', type: 'btn', value: 'del', confirm: true },
      { label: '作废', type: 'status', value: -1, confirm: true },
      { label: '执行', type: 'status', value: 1, confirm: true }
    ]
  },
  {
    label: '执行中', value: 1, color: 'blue', actions: [
      { label: '回退计划中', type: 'status', value: 0, confirm: true },
      { label: '完成计划', type: 'status', value: 2, confirm: true },
    ]
  },
  {
    label: '已完成', value: 2, color: 'geekblue', actions: [
      { label: '回退执行中', type: 'status', value: 1, confirm: true },
    ]
  },
  {
    label: '作废', value: -1, color: 'red', actions: [
      { label: '回退计划中', type: 'status', value: 0, confirm: true },
      { label: '修改', type: 'btn', value: 'update' },
      { label: '删除', type: 'btn', value: 'del', confirm: true },
    ]
  },
]

const getters = {
  scmGoodsUseTypeList: () => [
    { label: '原料', value: 1 },
    { label: '配件', value: 2 },
    { label: '设备', value: 3 },
    { label: '零件', value: 5 },
    { label: '标准件', value: 6 },
    { label: '电器件', value: 7 },
    { label: '外购件', value: 12 },
    { label: '工具', value: 8 },
    { label: '办公耗材', value: 9 },
    { label: '加工耗材', value: 10 },
    { label: '劳保用品', value: 11 },
    { label: '废品', value: 4 },
  ],
  scmGoodsUnitList: () => {
    return ['台', '件', '把', '套' ,'块', '条', '个', 'kg', 'g', 'm', 'cm', 'mm', 'L', '盒', '支', '瓶', '桶', '卷', '片', '张']
  },
  scmGoodsStatusList: () => {
    return [
      { label: '启用', value: 1, color: 'blue' },
      { label: '未启用', value: 2, color: 'default' },
      { label: '废弃', value: 3, color: 'red' },
    ]
  },
  scmHouseTypeList: () => {
    return [
      { label: '仓库', value: 'store', color: 'blue' },
      { label: '车间', value: 'workshop', color: 'geekblue' },
    ]
  },
  scmStoreHouseList: (state) => state.storeHouseList,
  goodsProduceStandardList: (state) => state.goodsProduceStandardList,
  scmStockDetailActionList: () => {
    return [
      { label: '采购', value: 'buy' },
      { label: '采购退货', value: 'buy_return' },
      { label: '移库', value: 'move' },
      { label: '盘点', value: 'check' },
      { label: '生产', value: 'produce' },
      { label: '零拆', value: 'open' },
      { label: '打包', value: 'bale' },
      { label: '拆卸', value: 'remove' },
      { label: '销售', value: 'sell' },
      { label: '售后', value: 'sellafter' },
      { label: '退货', value: 'sales_return' },
      { label: '回退', value: 'back' },
    ]
  },
  scmGoodsProduceStandardStatusList: () => {
    return [
      { label: '设计中', value: 0, color: 'default' },
      { label: '启用', value: 1, color: 'blue' },
      { label: '停用', value: 2, color: 'volcano' },
      { label: '废弃', value: -1, color: 'red' }
    ]
  },
  scmCheckPlanStatusList: () => {
    return defaultPlanStatusList
  },
  scmPlanStatusList: () => {
    return defaultPlanStatusList
  },
  scmGoodsPurchaseStatusList: () => {
    return defaultPlanStatusList
  },
  scmInorderGroupStatusList: () => {
    return defaultPlanStatusList
  },
  scmOutorderGroupStatusList: () => {
    return defaultPlanStatusList
  },
  scmGoodsHandleTypeList: () => {
    return [
      { label: '报废', value: 1, color: 'red' },
      { label: '报损', value: 2, color: 'blue' },
      { label: '废料处置', value: 3, color: 'geekblue' }
    ]
  }
}

const actions = {
  async loadStoreHouse ({ getters, commit }, refresh = false) {
    if (!getters.scmStoreHouseList.length || refresh) {
      const data = await request.get('/storehouse')
      data.forEach(item => {
        item.typeData = getters.scmHouseTypeList.find(v => v.value === item.type)
      })
      commit('setStoreHouse', data)
    }
    return getters.scmStoreHouseList
  },
  async loadGoodsProduceStandard ({ getters, commit }, refresh = false) {
    if (!getters.goodsProduceStandardList.length || refresh) {
      const data = await request.get('/goods_produce_standard')
      commit('setGoodsProduceStandard', data)
    }
    return getters.goodsProduceStandardList
  }
}

const mutations = {
  setStoreHouse (state, data) {
    state.storeHouseList = data
  },
  setGoodsProduceStandard (state, data) {
    state.goodsProduceStandardList = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
