<style lang="less">
@import './styles/values.less';
.fm-down-select-items {
  padding: 0;
}
.fm-down-select-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  word-break: keep-all;
  white-space: nowrap;
  transition: all .3s;
  color: @color-content-text;
  .fm-checkbox {
    flex: 1;
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.fm-poptip-down-select-confirm {
  .fm-down-select-item:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.fm-down-select-item-selected {
  background-color: #EEE;
}
.fm-down-select-multiple {
  .fm-down-select-item-selected {
    background-color: rgba(238, 255, 255, .5);
  }
}
.fm-down-select-confirm {
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  border-top: 1px solid #EEE;
  button {
    flex: 1;
    text-align: center;
    word-break: keep-all;
    white-space: nowrap;
    background: none;
    line-height: 30px;
    border: none;
    cursor: pointer;
    background-color: #FFF;
    &:active, &:focus {outline: none;}
  }
}
.fm-down-select-cancel-btn, .fm-down-select-confirm-btn {
  transition: all .3s;
}
.fm-down-select-cancel-btn {
  color: @color-disabled-text;
  &:hover {
    color: @color-fail;
  }
}
.fm-down-select-confirm-btn {
  color: @color-content-text;
  &:hover {
    color: @color-primary;
  }
}
</style>

<template>
  <fm-poptip ref="poptip" :class="{'fm-poptip-down-select-confirm': confirm}" :arrow="arrow" :display="targetDisplay" :position="position" :trigger="trigger">
    <slot></slot>
    <template slot="content">
      <div class="fm-down-select-items" :class="{'fm-down-select-multiple': multiple}">
        <div class="fm-down-select-item" v-for="(option, i) in options" :key="option.value + '' + i" :class="{
          'fm-down-select-item-selected': Boolean(selectedList[option.value])
        }">
          <fm-checkbox :value="Boolean(selectedList[option.value])" size="small" v-if="multiple" @change="(isChecked) => onChecked(isChecked, option)">
            <fm-down-select-option :option="option"></fm-down-select-option>
          </fm-checkbox>
          <fm-down-select-option v-else @click.native="selected = [option]" :option="option"></fm-down-select-option>
        </div>
      </div>
      <div class="fm-down-select-confirm" v-if="confirm">
        <button class="fm-down-select-cancel-btn" v-if="clearable && selected.length" @click="clear">清空</button>
        <button class="fm-down-select-cancel-btn" v-else @click="cancel">取消</button>
        <button class="fm-down-select-confirm-btn" @click="pitch">确认</button>
      </div>
    </template>
  </fm-poptip>
</template>

<script>
import FmCheckbox from './FmCheckbox.vue'
import FmDownSelectOption from './FmDownSelectOption'
import FmPoptip from './FmPoptip.vue'
export default {
  components: { FmCheckbox, FmDownSelectOption, FmPoptip },
  name: 'FmDownSelect',
  data () {
    let selected = []
    if (!this.value || !this.value.length)  {
      selected = []
    } else if (Array.isArray(this.value)) {
      selected = this.options.filter(o => this.value.includes(o.value))
    } else {
      selected = this.options.filter(o => this.value === o.value)
    }
    return {
      data: this.value,
      selected: selected,
      targetDisplay: 'none'
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: [Array, Object, String], default: null },
    trigger: { type: String, default: 'click' },
    display: { type: String, default: null },
    options: {
      type: Array, default: () => {
        return []
      }
    },
    multiple: { type: Boolean, default: false },
    confirm: { type: Boolean, default: false },
    arrow: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    widthLabel: { type: Boolean, default: false },
    position: {
      type: String, default: 'bottom', validator: function(pst) {
        return ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end'].includes(pst)
      }
    }
  },
  watch: {
    selected () {
      if (!this.confirm) {
        this.onChange()
        if (!this.multiple) {
          this.cancel()
        }
      }
    }
  },
  computed: {
    selectedList () {
      let list = {}
      this.selected.forEach((s, i) => {
        list[s.value] = true
      })
      return list
    }
  },
  methods: {
    onChecked (isChecked, option) {
      let index = this.selected.findIndex(v => v.value === option.value)
      if (isChecked && index === -1) {
        this.selected.push(option)
      } else if (index > -1) {
        this.selected.splice(index, 1)
      }
    },
    onChange () {
      let data = null
      let event = null
      if (this.multiple) {
        data = this.selected.map(v => v.value)
      } else {
        data = this.selected && this.selected.length ? this.selected[0].value : null
      }
      if (this.widthLabel) {
        event = this.multiple ? this.selected.map(v => {
          return {value: v.value, label: v.lavel}
        }) : {label: this.selected[0].label, value: this.selected[0].value}
      } else {
        event = data
      }
      this.$emit('change', event)
      this.$emit('input', data)
    },
    cancel () {
      this.$refs.poptip.show = false
    },
    clear () {
      this.selected = []
      this.pitch()
    },
    pitch () {
      this.onChange()
      this.cancel()
    }
  },
  mounted() {
    if (this.$slots.default && this.$slots.default[0].elm) {
      let styles = getComputedStyle(this.$slots.default[0].elm)
      this.targetDisplay = this.display ? this.display : styles.display
    }
  },
}
</script>
