export default {
  routerNames: ['calculate.result'],
  funs: {
    get: {
      funKey: 'get',
      name: '获取全部',
      apis: [
        { method: 'GET', path: '/performance/pfm_worker_month/result' },
        { method: 'GET', path: '/performance/pfm_unit' },
      ]
    },
    getByAuth: {
      funKey: 'getByAuth',
      name: '获取权限内的',
      apis: [
        { method: 'GET', path: '/performance/pfm_worker_month/result/by_auth' },
        { method: 'GET', path: '/performance/pfm_unit' },
      ]
    },
    output: {
      funKey: 'output',
      name: '导出'
    }
  }
}