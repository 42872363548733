<template>
  <div class="fm-modal" :class="{
    ['theme-' + (theme || '')]: theme ? true : false,
    'fm-modal-inner': inner,
    'fm-modal-open': value
  }">
    <transition name="fm-modal-mask-transition" mode="in-out">
      <div class="fm-modal-mask" :style="{ zIndex }" v-show="value && isMask"></div>
    </transition>
    <transition name="fm-modal-transition" mode="in-out">
      <div class="fm-modal-wrap" :style="{ zIndex }" v-show="value" @click.stop.self="() => { if (maskClosable === true || maskClosable === undefined) event('cancel')}">
        <div class="fm-modal-content" :style="{
          height: isNaN(height) ? height : (height + 'px'),
          width: isNaN(width) ? width : (width + 'px')
        }">
          <div class="fm-modal-close" @click.stop="event('cancel')" v-if="isClosable">
            <a class="fmico fmico-cha" v-if="!$slots.close"></a>
            <slot name="close"></slot>
          </div>
          <div class="fm-modal-header" v-if="$slots.header || title">
            <slot name="header" v-if="$slots.header"></slot>
            <template v-else>{{title}}</template>
          </div>
          <div class="fm-modal-body"><slot></slot></div>
          <div class="fm-modal-footer" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FmModal',
  data() {
    return {}
  },
  model: { prop: 'value', event: 'input' },
  props: {
    title: { type: String, default: null },
    closable: { type: Boolean, default: true },
    value: { type: Boolean, default: false },
    footer: { type: Boolean, default: false },
    mask: { type: Boolean, default: true },
    maskClosable: { type: Boolean, default: true },
    zIndex: { type: Number, default: 1000 },
    width: { type: [Number, String], default: 500 },
    height: { type: [Number, String], default: 'auto' },
    theme: { type: String, default: '' },
    inner: { type: Boolean, default: false }
  },
  computed: {
    isClosable () {
      return this.closable === undefined || this.closable === true
    },
    isMask () {
      return this.mask === undefined || this.mask === true
    },
    modalCls () {
      return {
        'fm-modal-nomask': !this.isMask
      }
    }
  },
  methods: {
    event (type) {
      this.$emit('input', false)
      this.$emit(type)
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
  .fm-modal {
    pointer-events: none;
    &.fm-modal-open {
      pointer-events: auto;
    }
  }
  .fm-modal-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(55,55,55,.6);
    height: 100%;
    z-index: 0;
  }
  .fm-modal-close {
    z-index: 1;
    position: absolute;
    right: 8px;
    top: 8px;
    overflow: hidden;
    cursor: pointer;
    .fmico-cha {
      font-size: 1rem;
      color: #999;
      transition: color .2s ease;
      &:hover {
        color: #444;
      }
    }
  }
  .fm-modal-wrap {
    position: fixed;
    z-index: 1;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    outline: none;
  }
  .fm-modal-content {
    z-index: 2;
    margin: 0 auto;
    position: relative;
    outline: 0;
    top: 100px;
    background-color: #FFF;
    border: 0;
    border-radius: 6px;
    background-clip: padding-box;
    box-shadow: 0 4px 12px rgba(0,0,0,.15);
  }
  .fm-modal-header, .fm-modal-dialog-header {
    border-bottom: 1px solid #e8eaec;
    padding: 14px 16px;
    line-height: 1;
    color: #17233d;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
  }
  .fm-modal-body, .fm-modal-dialog-body {
    padding: 16px;
    font-size: 14px;
    line-height: 1.5;
    color: #515a6e;
  }
  .fm-modal-footer, .fm-modal-dialog-footer {
    border-top: 1px solid #e8eaec;
    padding: 12px 18px;
    text-align: right;
  }
  .fm-modal-inner {
    .fm-modal-mask, .fm-modal-wrap {
      position: absolute;
    }
  }
  // 内容框动画
  .fm-modal-transition-enter-active, .fm-modal-transition-leave-active {
    transition: all .5s;
    opacity: 1;
    transform: scale(1);
  }
  .fm-modal-transition-enter, .fm-modal-transition-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
  // 遮罩层
  .fm-modal-mask-transition-enter-active, .fm-modal-mask-transition-leave-active {
    transition: all .5s;
    opacity: 1;
  }
  .fm-modal-mask-transition-enter, .fm-modal-mask-transition-leave-to {
    opacity: 0;
  }

  // dialog样式
  .fm-modal-dialog-header {
    border-bottom: none;
  }
  .fm-modal-dialog-footer {
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  // 主题方式
  .fm-modal.theme-mh-withe {
    .fm-modal-header {
      color: #1cb5e0;
      padding: 32px;
      border-bottom: none;
    }
    .fm-modal-dialog-header {
      color: #1cb5e0;
    }
    .fm-modal-footer {
      padding-bottom: 65px;
      padding-top: 32px;
      border-top: none;
    }
    .fm-modal-dialog-footer {
      justify-content: center;
      padding-bottom: 49px;
      padding-top: 16px;
    }
  }
  .fm-modal.theme-mh-blackt {
    .fm-modal-header {
      font-weight: 800;
      border-bottom: none;
    }
    .fm-modal-footer {
      padding-bottom: 35px;
      padding-top: 15px;
      border-top: none;
    }
    .fm-modal-dialog-footer {
      justify-content: center;
      padding-bottom: 25px;
      padding-top: 8px;
    }
  }
</style>