<template>
  <div class="fm-form-item" :class="{
    'fm-label-alone': labelAlone,
    'fm-form-item-required': this.isRequired !== false
  }">
    <div class="fm-form-item-label" :style="{
      width: labelWidth ? labelWidth : '',
      textAlign: labelAlign
    }">{{label}}<slot name="labelInfo"></slot></div>
    <div class="fm-form-item-content"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'FmFormItem',
  components: {},
  data() {
    return {
      componentRequired: false
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  provide () {
    return {form: this.form}
  },
  inject: ['labelWidth', 'labelAlign', 'labelAlone', 'form'],
  computed: {
    isRequired () {
      return this.required !== false || this.componentRequired !== false
    }
  },
  methods: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
@import './styles/values.less';
@import './styles/form.less';
</style>