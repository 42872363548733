const sortTypes = ['text', 'date', 'number']

const sortText = function (a, b, type) {
  return type === 'desc' ? (a > b) : (a < b)
}

const sortDate = function (a, b, type) {
  let newA = new Date(a).getTime()
  let newB = new Date(b).getTime()
  return type === 'desc' ? (newA > newB) : (newA < newB)
}

const sortNumber = function (a, b, type) {
  a = Number(a) || 0
  b = Number(b) || 0
  return type === 'desc' ? (a > b) : (a < b)
}

const sort = function ({datas, sortType, funSort, type, key}) {
  if (datas.length < 2) {
    return datas
  }
  let useFun = null
  if (funSort) {
    useFun = funSort
  } else if (sortType === 'text') {
    useFun = sortText
  } else if (sortType === 'date') {
    useFun = sortDate
  } else {
    useFun = sortNumber
  }
  for (let indexA in datas) {
    for (let indexB in datas) {
      let nIndexB = Number(indexB)
      if (nIndexB < (datas.length - 1 - indexA) && useFun(datas[nIndexB][key], datas[nIndexB + 1][key], type)) {
        [datas[nIndexB], datas[nIndexB + 1]] = [datas[nIndexB + 1], datas[nIndexB]]
      }
    }
  }
  return datas
}

export default {
  sortTypes,
  sort
}