<template>
  <div class="fm-date-picker-cells" :class="{
      'fm-date-picker-day': type === 'date',
      'fm-date-picker-year': type === 'year',
      'fm-date-picker-month': type === 'month',
    }">
    <div v-if="type === 'date'" class="fm-date-picker-cell" v-for="d in ['日', '一', '二', '三', '四', '五', '六']" :key="d">
      <div class="fm-date-picker-cell-text fm-date-picker-cell-header">{{d}}</div>
    </div>
    <DateCell :disabledDate="disabledDate" :range="range" v-for="(cell, i) in cells" :cell="cell" :type="type" :key="i"></DateCell>
  </div>
</template>

<script>
import DateCell from './DateCell.vue'
export default {
  name: '',
  components: {
    DateCell
  },
  data() {
    return {}
  },
  inject: ['picker'],
  provide () {
    return {
      picker: this.picker
    }
  },
  props: {
    cells: {
      type: Array
    },
    range: {
      type: Object
    },
    type: {
      type: String,
      default: 'date'
    },
    disabledDate: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    cellCls () {

    }
  },
  methods: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
@import '../styles/datapicker.less';
.fm-date-picker-cells {
  z-index: 1;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  &.fm-date-picker-day {
    .fm-date-picker-cell {
      width: 14.285714%
    }
  }
  &.fm-date-picker-year {
    .fm-date-picker-cell {
      width: 33.33%
    }
  }
  &.fm-date-picker-month {
    .fm-date-picker-cell {
      width: 25%;
    }
  }
  &:nth-of-type(3) {
    border-left: 1px solid @color-assist;
  }
}
</style>