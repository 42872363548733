/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Tue Jun 02 2020
 */
import echarts from 'echarts'
import 'echarts/extension/bmap/bmap'

export function loadScript(url) {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.onload = resolve
    script.src = url
    document.body.appendChild(script)
  })
}

export async function loadBMapScript(version, ak, time) {
  window.BMap_loadScriptTime = (new Date).getTime()
  await loadScript(`http://api.map.baidu.com/getscript?v=${version}&ak=${ak}&services=&t=${time}`)
  return Promise.resolve(window.BMap)
}

let BMap = null
export async function getEchartsMapInstance (el, {ak, version, time}) {
  version = version || '3.0'
  time = time || '20200520175020'

  if (!ak) {
    return Promise.reject(new Error('ak参数不能为空'))
  }

  if (!BMap) {
    BMap = await loadBMapScript(version, ak, time)
  }

  const echart = echarts.init(el)
  echart.setOption({
    bmap: {
      roam: true
    }
  })
  const bMap = echart.getModel().getComponent('bmap').getBMap()
  return Promise.resolve(new XMap(echart, bMap))
}

export const SecondaryTrigger = function (single, double, interval = 300) {
  let timer = null
  let time = new Date()
  return function (data) {
    if (Math.abs(new Date() - time) < 300 && double) {
      clearTimeout(timer)
      double && double.call(this, data)
    } else if (!double && single) {
      single.call(this, data)
    } else if (single) {
      timer = setTimeout(() => {
        single.call(this, data)
      }, interval)
    }
    time = new Date()
  }
}

export const seriesLineTheme = {
  default: {
    id: '',
    name: '',
    type: 'lines',
    polyline: true,
    large: true,
    coordinateSystem: 'bmap',
    data: [],
    label: {
      normal: {
        position: 'right',
        show: false
      }
    },
    lineStyle: {
      normal: {
        color: '#3f7ff4',
        width: 3,
        opacity: 1
      },
      emphasis: {
        color: '#3f7ff4',
        width: 3,
        opacity: 1
      }
    },
    z: 10
  }
}

export class EventEmitter extends Object {
  constructor () {
    super()
    let events = {}

    this.addEventListener = (name, fn) => {
      if (!events[name]) {
        events[name] = []
      }
      if (!events[name].includes(fn)) {
        events[name].push(fn)
      }
    }

    this.hasEventListener = (name, fn) => {
      return !events[name] || !events[name].includes(fn) ? false : true
    }

    this.dispatchEvent = (name, data) => {
      if (events[name]) {
        events[name].forEach(fn => {
          fn.call(this, data)
        })
      }
    }

    this.removeEventListener = (name, fn) => {
      if (fn && this.hasEventListener(name, fn)) {
        const index = events[name].findIndex(f => f === fn)
        if (index > -1) {
          events[name].splice(index, 1)
        }
      } else {
        events[name] = []
      }
    }

    this.getEventListOwner = () => {
      return events
    }
  }
}

export class SeriesData extends EventEmitter {
  _isSeriesData = true
  constructor () {
    super()
  }
  getViewport () {
    console.error('该类未实现该方法')
  }
}

export class SeriesLine extends SeriesData {
  constructor (options = {}, theme = 'default') {
    super()
    Object.assign(this, seriesLineTheme[theme], options)
  }

  getViewport () {
    return this.data.reduce((points, item) => {
      return [...points, ...item.coords.map(v => new BMap.Point(v[0], v[1]))]
    }, [])
  }
}

export class XMap extends EventEmitter {
  constructor (echart, bMap) {
    super()
    this.echart = echart
    this.bMap = bMap
  }

  get echartOption () {
    return this.echart.getOption()
  }

  set echartOption (option) {
    this.echart.setOption(option, {notMerge: true, lazyUpdate: false, silent: false})
    this.updateBMap()
    this.updateEventListener(option)
  }

  setEchartOption (option, notMerge = false, lazyUpdate = false, silent = false) {
    this.echart.setOption(option, {notMerge, lazyUpdate, silent})
    notMerge && this.updateBMap()
    this.updateEventListener(option)
  }
  
  /**
   * ***************** 注意 ***************
   * setOption时, 如果notMerge为true
   * 会导致之前的bMap对象失效 需要重新获取
   * 否则bMap将失去与地图的关联, 所有地图
   * 操作都将会失效
   * ***************** 注意 ***************
   */
  updateBMap () {
    this.bMap = this.echart.getModel().getComponent('bmap').getBMap()
  }

  updateEventListener (echartOption) {
    (echartOption || this.echartOption).series.filter(item => item instanceof SeriesData || item._isSeriesData).forEach(item => {
      const events = item.getEventListOwner()
      Object.keys(events).forEach(eventName => {
        this.echart.off(eventName)
        events[eventName].forEach(eventFn => {
          this.echart.on(eventName, eventFn.bind(this))
        })
      })
    })
  }

  setSeriesItem (seriesItem) {
    let newOption = this.echartOption
    const index = newOption.series.findIndex(series => series.id === seriesItem.id && series.name === seriesItem.name)
    if (index > -1) {
      newOption.series[index] = seriesItem
    } else {
      newOption.series.push(seriesItem)
    }
    this.echartOption = newOption
  }

  appendSeriesItem (itemIndex, data) {
    this.echart.appendData({ itemIndex, data })
  }

  seriesInViewPort(series, option = {}) {
    this.setViewport(series.getViewport(), option)
  }

  setViewport (points, options = {}) {
    this.bMap.setViewport(points, Object.assign({
      enableAnimation: true,
      margins: [20, 20, 20, 20],
      zoomFactor: 0,
      delay: 0
    }, options))
  }
}
