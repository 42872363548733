import {
  appViews
} from './views'

import {
  getOtherSysPages
} from '@/sys'

export default [
  {
    path: '/',
    name: 'app',
    component: () => import('@/views/app'),
    children: appViews
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/test'),
  },
  {
    path: '/test2',
    name: 'test2',
    component: () => import('@/views/test/test2'),
  },
  {
    path: '/install/app',
    name: 'install.app',
    component: () => import('@/views/app/install'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => /iphone|ios|android|ipad/i.test(navigator.userAgent) ? import('@/views/login/mobile') : import('@/views/login/index')
  },
  ...getOtherSysPages()
]
