/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Link: https://www.slippersclown.com
 * Date: Thu Dec 05 2019
 */
import Vue from 'vue'
import FmFormDialog from '../components/FmFormDialog'

function getInstance(options) {
  let resolve, promise = new Promise(r => {
    resolve = r
  })
  const instance = new Vue({
    data: Object.assign({
      openDialog: false,
      candidateValue: [],
      oldData: null,
      formWidth: '800px',
      formTitle: '',
      submitBtnLabel: undefined,
      submitBtnLabelFun: undefined,
      formParms: [],
      onChange: undefined,
      labelAlone: false,
      labelAlign: 'right',
      inline: false
    }, options),
    render: function (h) {
      return h(FmFormDialog, {
        props: {
          openDialog: this.openDialog,
          candidateValue: this.candidateValue,
          oldData: this.oldData,
          formWidth: this.formWidth,
          formTitle: this.formTitle,
          submitBtnLabel: this.submitBtnLabel,
          submitBtnLabelFun: this.submitBtnLabelFun,
          formParms: this.formParms,
          onChange: this.onChange,
          labelAlone: this.labelAlone,
          labelAlign: this.labelAlign,
          inline: this.inline
        },
        on: {
          handleClose: () => {
            this.openDialog = false
            resolve(false)
            setTimeout(() => {
              document.body.removeChild(this.$el)
              this.$destroy()
            }, 1000)
          },
          formSubmit: (data) => {
            this.openDialog = false
            resolve(data)
            setTimeout(() => {
              document.body.removeChild(this.$el)
              this.$destroy()
            }, 1000)
          }
        }
      })
    }
  }).$mount()

  document.body.appendChild(instance.$el)
  instance.openDialog = true
  return {
    open () {
      return promise
    }
  }
}

export default {
  name: '$form',
  methods: {
    open (config = {}) {
      const form = getInstance(config)
      return form.open()
    }
  }
}
