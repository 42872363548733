<template>
  <div>
    <h2>树形组件(fm-tree)</h2>
    <h2>Checkbox(单选和多选)</h2>
    <div style="display: flex;">
      <fm-tree :nodes="nodes" is-checked @checked="onTreeChecked"></fm-tree>
      <fm-tree :nodes="nodes" is-checked multiple @checked="onTreeChecked"></fm-tree>
      <fm-tree :nodes="[{title: 'item1'}, {title: 'item2'}, {title: 'item3'}, {title: 'item4'}]" search></fm-tree>
      <fm-tree :nodes="nodes1" search></fm-tree>
    </div>
    <div style="display: flex;">
      <fm-tree :nodes="nodes"></fm-tree>
      <fm-tree :nodes="nodes" is-selected></fm-tree>
      <fm-tree :nodes="nodes" search></fm-tree>
      <fm-tree :nodes="nodes" is-selected multiple @selected="onTreeSelected"></fm-tree>
      <fm-tree :nodes="nodes" is-checked is-selected multiple></fm-tree>
      <!-- 加上edit属性可以使得tree开启编辑功能 -->
      <!-- nodeAdd, nodeTitleChange, nodeRemove 这三个属性分别为对添加节点, 节点名字修改, 节点删除的拦截处理 -->
      <!-- FmTree可根据这三个属性所传入的Function的返回值进行相应的处理 -->
      <!-- 如果不需要对节点的增删改进行拦截处理, 只需要监听节点的变化则可以监听与属性同名的事件 -->
      <!-- nodeAdd, nodeTitleChange, nodeRemove -->
      <fm-tree :nodes="nodes" edit search placeholder="鼠标右键激活编辑菜单" is-selected multiple :nodeAdd="nodeAdd" :nodeTitleChange="nodeTitleChange" :nodeRemove="nodeRemove"></fm-tree>
    </div>
  </div>
</template>

<script>
export default {
  name: "FmTreeDemo",
  components: {},
  data() {
    return {
      nodes: [
        {
          title: 'parent 1',
          open: true,
          children: [
            {
              title: 'parent 1-1',
              open: false,
              children: [
                {
                  title: 'leaf 1-1-1'
                },
                {
                  title: 'leaf 1-1-2',
                  disabled: true
                }
              ]
            },
            {
              title: 'parent 1-2',
              open: true,
              children: [
                {
                  title: 'leaf 1-2-1'
                },
                {
                  title: 'leaf 1-2-2',
                  selected: true
                }
              ]
            }
          ]
        }
      ],
      nodes1: [
        {
          title: 'parent 1',
          open: true,
          children: [
            {
              title: 'parent 1-1',
              open: false,
              children: [
                {
                  title: 'leaf 1-1-1'
                },
                {
                  title: 'leaf 1-1-2',
                  disabled: true
                }
              ]
            },
            {
              title: 'parent 1-2',
              open: true
            }
          ]
        }
      ]
    };
  },
  props: {},
  computed: {},
  methods: {
    async nodeRemove ({data}) {
      return new Promise(resolve => {
        setTimeout(() => {
          this.$notice.success(`${data.title}删除成功`)
          resolve(true)
        }, 2000)
      })
    },
    async nodeAdd ({eventData}) {
      // 可以直接return要新增的节点数据
      // return {title: eventData.title}

      // 也支持返回promise
      return new Promise((resolve) => {
        // 演示代码
        if (eventData.title === '空') { // 返回null则不执行添加
          this.$notice.warning('FmTree', '不可新增空节点')
          resolve(null)
        } else if (eventData.title === '子') { // 自定义返回的节点
          this.$notice.success('FmTree', '新增节点' + eventData.title)
          resolve({
            title: eventData.title, selected: true, children: [
              {
                title: 'leaf 1-1-1'
              },
              {
                title: 'leaf 1-1-2',
                disabled: true
              }
            ]
          })
        } else { // 简单的添加
          this.$notice.info('FmTree', '新增节点' + eventData.title)
          setTimeout(() => {
            resolve(eventData)
          }, 1500)
        }
      })
    },
    async nodeTitleChange ({eventData, data}) {
      // title的修改也支持Promise
      this.$notice.info('Tree Title Change', `将${eventData.old}修改为${eventData.new}`, 2000)
      // 返回一个新的node数据将会覆盖久的node
      return Object.assign({}, data, {title: eventData.new})
    },
    onTreeChecked (item, data) {
      console.log(data)
    },
    onTreeSelected (item, data) {
      this.$notice.info('Tree Selected', `${item.data.title} - ${item.selected}`, 2000)
      console.log(data, item)
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
};
</script>

<style lang="less" scoped></style>