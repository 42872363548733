<template>
  <a :href="href" @click.stop="clickBtn" :class="btnClass" class="fm-btn">
    <div class="fm-btn-wrap"><slot></slot></div>
  </a>
</template>

<script>
export default {
  name: 'FmBtn',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'norm'
    },
    href: {
      type: String,
      default: 'javascript:;'
    },
    type: {
      type: String,
      default: 'norm',
      validator (type) {
        return ['norm', 'warning', 'success', 'danger', 'primary', 'info', 'dashed'].includes(type)
      }
    },
    tint: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    long: {
      type: [String, Boolean, Number],
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  },
  methods: {
    clickBtn () {
      if ([undefined, 'false'].includes(this.$el.dataset.loading) && !this.isDisabled) {
        this.$emit('click')
      }
    }
  },
  watch: {
    long: {
      immediate: true,
      handler (value) {
        if (value) {
          value = typeof value === 'number' ? `${value}px` : value
          this.$nextTick(() => {
            this.$el.style.paddingLeft = value
            this.$el.style.paddingRight = value
          })
        }
      }
    }
  },
  computed: {
    isDisabled () {
      return [undefined, true].includes(this.disabled)
    },
    isText () {
      return [undefined, true].includes(this.text)
    },
    isBlock () {
      return this.block === undefined || this.block === true
    },
    isLong () {
      return [undefined, '', true].includes(this.long)
    },
    btnClass: {
      get () {
        return {
          [`fm-btn-${this.size}`]: true,
          [`fm-btn-${this.type}`]: true,
          'fm-btn-block': this.isBlock,
          'fm-btn-long': this.isLong,
          'fmico': this.icon ? true: false,
          [`fmico-${this.icon}`]: this.icon ? true : false,
          'fm-btn-disabled': this.isDisabled,
          'fm-btn-text': this.isText,
          'fm-btn-tint': this.tint
        }
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang="less">
@import './styles/values.less';
.fm-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  position: relative;
  line-height: 1;
  border-radius: @size-border-radius;
  letter-spacing: @size-letter-spacing-m;
  transition: all .2s;

  padding: 0 10px;

  color: @color-content-text;
  border: 1px solid @color-btn-border-base;
  background-color: @color-btn-background-base;

  .fm-btn-wrap {
    transition: all .2s;
  }

  &:hover {
    border-color: @color-primary;
  }

  &:active {
    .fm-btn-wrap {
      transform: scale(0.95);
    }
    transition: all .1s;
    box-shadow: 0px -5px 5px 0px rgba(255, 255, 255, .1) inset,
                -5px 0px 5px 0px rgba(255, 255, 255, .1) inset,
                5px 0px 5px 0px rgba(0, 0, 0, .1) inset,
                0px 5px 5px 0px rgba(0, 0, 0, .1) inset;
  }

  & ~ & {
    margin-left: 10px;
    &.fm-btn-block {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .fmico:first-child {
    margin-right: 5px;
  }
  .fmico:last-child {
    margin-left: 5px;
  }

  // 长按钮
  &.fm-btn-long {
    padding: 0 54px;
  }

  // 按钮类型
  &.fm-btn-norm {
    height: @size-height-norm - 2px;
    line-height: @size-height-norm - 2px;
    font-size: @size-font-norm;
    &.fmico {
      width: @size-height-norm;
      height: @size-height-norm;
      border-radius: @size-height-norm / 2;
    }
  }
  &.fm-btn-large {
    height: @size-height-large - 2px;
    line-height: @size-height-large - 2px;
    font-size: @size-font-large;
    &.fmico {
      width: @size-height-large;
      height: @size-height-large;
      border-radius: @size-height-large / 2;
    }
  }
  &.fm-btn-small {
    height: @size-height-small - 2px;
    line-height: @size-height-small - 2px;
    font-size: @size-font-small;
    &.fmico {
      width: @size-height-small;
      height: @size-height-small;
      border-radius: @size-height-small / 2;
    }
  }
  &.fm-btn-mini {
    height: @size-height-mini - 2px;
    line-height: @size-height-mini - 2px;
    font-size: @size-font-mini;
    &.fmico {
      width: @size-height-mini;
      height: @size-height-mini;
      border-radius: @size-height-mini / 2;
    }
  }

  // 按钮状态
  &.fm-btn-text {
    background-color: transparent;
    border-color: transparent;
  }
  &.fm-btn-disabled {
    color: @color-disabled-text !important;
    background-color: @color-btn-background-disabled !important;
    border-color: #dcdee2 !important;
    pointer-events: none;
  }
  &.fm-btn-block {
    display: flex;
    flex: 1;
  }

  // 按钮样式
  &.fm-btn-primary {
    color: #FFF;
    background-color: @color-btn-background-primary;
    border-color: @color-btn-background-primary;
  }
  &.fm-btn-primary.fm-btn-tint {
    color: @color-btn-background-primary;
    background-color: @color-btn-background-primary-tint;
    &:hover {color: #FFF;}
  }
  &:hover.fm-btn-primary {
    background-color: @color-btn-background-primary-weight;
    border-color: @color-btn-background-primary-weight;
  }
  &.fm-btn-warning {
    color: #FFF;
    background-color: @color-btn-background-warning;
    border-color: @color-btn-background-warning;
  }
  &.fm-btn-warning.fm-btn-tint {
    color: @color-btn-background-warning;
    background-color: @color-btn-background-warning-tint;
    &:hover {color: #FFF;}
  }
  &:hover.fm-btn-warning {
    background-color: @color-btn-background-warning-weight;
    border-color: @color-btn-background-warning-weight;
  }
  &.fm-btn-info {
    color: #FFF;
    background-color: @color-btn-background-info;
    border-color: @color-btn-background-info;
  }
  &.fm-btn-info.fm-btn-tint {
    color: @color-btn-background-info;
    background-color: @color-btn-background-info-tint;
    &:hover {color: #FFF;}
  }
  &:hover.fm-btn-info {
    background-color: @color-btn-background-info-weight;
    border-color: @color-btn-background-info-weight;
  }
  &.fm-btn-success {
    color: #FFF;
    background-color: @color-btn-background-success;
    border-color: @color-btn-background-success;
  }
  &.fm-btn-success.fm-btn-tint {
    color: @color-btn-background-success;
    background-color: @color-btn-background-success-tint;
    &:hover {color: #FFF;}
  }
  &:hover.fm-btn-success {
    background-color: @color-btn-background-success-weight;
    border-color: @color-btn-background-success-weight;
  }
  &.fm-btn-danger {
    color: #FFF;
    background-color: @color-btn-background-danger;
    border-color: @color-btn-background-danger;
  }
  &.fm-btn-danger.fm-btn-tint {
    color: @color-btn-background-danger;
    background-color: @color-btn-background-danger-tint;
    &:hover {color: #FFF;}
  }
  &:hover.fm-btn-danger {
    background-color: @color-btn-background-danger-weight;
    border-color: @color-btn-background-danger-weight;
  }
  &.fm-btn-dashed {
    color: @color-component-text;
    background-color: #FFF;
    border-color: @color-border;
    border-style: dashed;
  }
  &:hover.fm-btn-dashed {
    color: @color-component-text;
    background-color: #FFF;
    border-color: @color-primary;
  }
}
</style>
