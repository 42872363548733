export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/article',
      method: 'GET'
    },
    {
      path: '/basis/article_type',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    }]
  },
  new: {
    funKey: 'new',
    name: '新文章',
    apis: [{
      path: '/basis/article',
      method: 'POST'
    }]
  },
  up: {
    funKey: 'up',
    name: '上架',
    apis: [{
      path: '/basis/article/{id}',
      method: 'PUT'
    }]
  },
  down: {
    funKey: 'down',
    name: '下架',
    apis: [{
      path: '/basis/article/{id}',
      method: 'PUT'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '详细',
    apis: [{
      path: '/basis/article',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'update',
    name: '修改',
    apis: [{
      path: '/basis/article/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/article/{id}',
      method: 'DELETE'
    }]
  }
}