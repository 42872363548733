<template>
  <div class="fm-form-input">
    <div class="label_text" v-if="labelText"><span>{{labelText}}</span></div>
    <input ref="input" @blur="verifier" :type="inputType" :value="value" :disabled="disabled" :placeholder="placeholder" @input="inputText($event.target.value)">
  </div>
</template>

<script>
export default {
  name: 'FmFormInput',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    labelText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // warningParm: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       pass: 0,
    //       error: ''
    //     }
    //   },
    //   validator: (data) => {
    //     return (!data) || [0, 1, -1].includes(data.pass)
    //   }
    // },
    inputType: {
      type: String,
      default: 'text'
    },
    value: [String, Number]
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  methods: {
    inputText (text) {
      this.$emit('change', text)
    }
  },
  mounted () {
    this.registerVerifier()
    // this.$nextTick(this.verifier)
  },
  watch: {
    value: {
      handler () {
        // this.$nextTick(this.verifier)
      },
      immediate: true
    }
  }
}
</script>

<style lang="less">
@import './styles/values.less';
.fm-form-input {
  height: @size-height-norm;
  display: flex;
  align-items: center;
  transition: @time-transition;
  font-size: @size-font-norm;
  position: relative;
  input {
    outline: none;
    flex: 1;
    background-color: @color-tm;
    line-height: @size-height-norm;
    font-size: @size-font-norm;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
    padding-left: 8px;
    color: @color-input-font;
    border: 1px solid @color-input-border;
    border-radius: @size-border-radius;
    box-shadow: 0 1px 6px transparent;
    transition: all .2s;
  }
  input:focus, input:hover {
    border-color: #57a3f3;
  }
  input:focus {
    box-shadow: 0 1px 6px rgb(87, 163, 243);
  }
  input::placeholder {
    color: @color-placeholder-font;
  }
  input:disabled {
    background-color: #eee;
    opacity: 1;
    color: @color-input-font;
    -webkit-text-fill-color: @color-input-font;
  }
  .label_text {
    width: 10rem;
    color: @color-input-label-font;
    padding: 0 0.7rem 0 0.7rem;
    font-size: @size-font-norm;
    text-align: right;
    span {
      margin-right: 0.5rem;
    }
  }
  &.verifier-error {
    input {
      border-color: red;
    }
    input:focus, input:hover {
      border-color: red;
    }
    input:focus {
      box-shadow: 0 1px 6px red;
    }
  }
}
</style>
