export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/article/by_auth',
      method: 'GET'
    },
    {
      path: '/basis/article_type',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    },
    {
      path: '/basis/article/edit/{id}',
      method: 'PUT'
    }]
  }
}