import roles from '../roles'
import { tmpAssetsRepair, applyRecordTmp } from './web'

// 所有职务、个人及内控角色有普通权限
// 仅内控和流程管理有管理权限

// 手机APP
export default [
  {
    'icon': 'icon-yusuanguanli',
    'clientType': 'app',
    'roles': roles.filter(v => v.roleType === 'position' || (v.roleType === 'role' && ['self', 'nk'].includes(v.code))).map(v => {
      return {
        [v.code]: ['get', 'viewFile', 'detial']
      }
    }),
    'name': '预算',
    'url': '/pages/budget/index?type=handler'
  },
  {
    'icon': 'icon-yusuanguanli1',
    'clientType': 'app',
    'roles': ['nk', 'lc', 'yz', 'sj'],
    'name': '预算管理',
    'url': '/pages/budget/index?type=manage'
  },
  {
    'icon': 'icon-caigou1',
    'clientType': 'app',
    'roles': roles.filter(v => v.roleType === 'position' || (v.roleType === 'role' && ['self', 'nk'].includes(v.code))).map(v => {
      return {
        [v.code]: ['handler', 'viewFile']
      }
    }),
    'name': '采购',
    'url': '/pages/purchase/index?type=handler'
  },
  {
    'icon': 'icon-caigou1',
    'clientType': 'app',
    'roles': ['nk', 'lc'].map(v => {
      return {
        [v]: ['manage', 'viewFile', 'delFile', 'uploadFile']
      }
    }),
    'name': '采购管理',
    'url': '/pages/purchase/index?type=manage'
  },
  {
    'icon': 'icon-caigoudan',
    'clientType': 'app',
    'roles': roles.filter(v => v.roleType === 'position' || (v.roleType === 'role' && ['self', 'nk'].includes(v.code))).map(v => {
      return {
        [v.code]: ['handler', 'viewFile', 'detailsFile']
      }
    }),
    'name': '采购方式',
    'url': '/pages/purchase/way/index?type=handler'
  },
  {
    'icon': 'icon-caigoudan',
    'clientType': 'app',
    'roles': ['nk', 'lc'].map(v => {
      return {
        [v]: ['manage', 'viewFile', 'delFile', 'uploadFile']
      }
    }),
    'name': '采购方式管理',
    'url': '/pages/purchase/way/index?type=manage'
  },
  {
    'icon': 'icon-weixiushenqingdan',
    'clientType': 'app',
    'roles': roles.map(v => v.code).concat('assets'),
    'name': '维修申请',
    'url': '/pages/repair/apply'
  },
  {
    'icon': 'icon-shebeiweixiushenqingliucheng',
    'clientType': 'app',
    'roles': applyRecordTmp,
    'name': '维修申请记录',
    'url': '/pages/repair/apply/record'
  },
  {
    'icon': 'icon-weixiuguanli',
    'clientType': 'app',
    'roles': tmpAssetsRepair.concat('assets'),
    'name': '维修管理',
    'url': '/pages/repair/manage'
  },
  {
    'icon': 'icon-RectangleCopy',
    'clientType': 'app',
    'roles': [...roles.map(v => v.code), 'assets'],
    'name': '报废申请',
    'url': '/pages/scrap/index'
  },
  {
    'icon': 'icon-RectangleCopy1',
    'clientType': 'app',
    'roles': applyRecordTmp,
    'name': '报废申请记录',
    'url': '/pages/scrap/record'
  }
]