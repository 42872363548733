<template>
  <div>
    <div class="flex">
      <div>
        <h2>多选框(fm-checkbox)</h2>
        <div class="flex">
          <fm-checkbox v-model="check">哈哈</fm-checkbox>
          <fm-checkbox v-model="nocheck" disabled>不可用</fm-checkbox>
        </div>
      </div>
      <div>
        <h2>显示图标</h2>
        <div class="flex">
          <fm-checkbox v-model="iconTrue"><i class="fmico fmico-success" style="margin-right: 10px;"></i>正确</fm-checkbox>
          <fm-checkbox v-model="iconFalse"><i class="fmico fmico-error" style="margin-right: 10px;"></i>错误</fm-checkbox>
        </div>
      </div>
      <div>
        <h2>边框(border)</h2>
        <div class="flex">
          <fm-checkbox border>选项1</fm-checkbox>
          <fm-checkbox border>选项2</fm-checkbox>
        </div>
      </div>
    </div>

    <h2>尺寸(size: <fm-tag>large</fm-tag><fm-tag>norm</fm-tag><fm-tag>small</fm-tag><fm-tag>mini</fm-tag>)</h2>
    <div class="flex">
      <fm-checkbox size="large">large</fm-checkbox>
      <fm-checkbox size="norm">norm</fm-checkbox>
      <fm-checkbox size="small">small</fm-checkbox>
      <fm-checkbox size="mini">mini</fm-checkbox>
    </div>

    <h2>事件(change)</h2>
    <div class="flex">
      <fm-checkbox @change="(v) => $notice.info('switch change', String(v))">small</fm-checkbox>
    </div>

    <h2>自定义选中值(<fm-tag>true-value</fm-tag><fm-tag>false-value</fm-tag>) - {{valueset}}</h2>
    <div class="flex">
      <fm-checkbox
        v-model="valueset"
        true-value="checked"
        false-value="no checked"
        @change="(v) => $notice.info('switch change', String(v))">
        自定义选中值
      </fm-checkbox>
      <fm-btn @click="valueset = 'checked'">checked</fm-btn>
      <fm-btn @click="valueset = 'no checked'">no checked</fm-btn>
    </div>

    <h2>组合使用 - {{groups1}}</h2>
    <div>
      <fm-checkbox-group v-model="groups1" @change="(v) => $notice.success('双向绑定的change', String(v))">
        <fm-checkbox label="选项1">选项1</fm-checkbox>
        <fm-checkbox label="选项2">选项2</fm-checkbox>
        <fm-checkbox label="选项3">选项3</fm-checkbox>
        <fm-checkbox label="选项4">选项4</fm-checkbox>
      </fm-checkbox-group>
      <fm-checkbox-group @change="(v) => $notice.success('普通的change', String(v))">
        <fm-checkbox label="1">1</fm-checkbox>
        <fm-checkbox label="2">2</fm-checkbox>
        <fm-checkbox label="3">3</fm-checkbox>
        <fm-checkbox label="4">4</fm-checkbox>
      </fm-checkbox-group>
    </div>

    <h2>垂直排列(vertical) - {{groups2}}</h2>
    <div class="flex">
      <fm-checkbox-group vertical v-model="groups2" @change="(v) => $notice.success('选中事件测试', String(v))">
        <fm-checkbox label="选项1">选项1</fm-checkbox>
        <fm-checkbox label="选项2">选项2</fm-checkbox>
        <fm-checkbox label="选项3">选项3</fm-checkbox>
        <fm-checkbox label="选项4">选项4</fm-checkbox>
      </fm-checkbox-group>
      <fm-btn @click="groups2 = ['选项3', '选项4']">设置选中</fm-btn>
      <fm-btn @click="groups2 = []">取消选中</fm-btn>
    </div>

    <h2>dom嵌套 - {{groups3}}</h2>
    <div class="flex">
      <fm-checkbox-group v-model="groups3" @change="(v) => $notice.success('选中事件测试', String(v))">
        <ul>
          <li><i class="fmico fmico-warning"></i><fm-checkbox label="选项1">选项1</fm-checkbox></li>
          <li><i class="fmico fmico-warning"></i><fm-checkbox label="选项2">选项2</fm-checkbox></li>
          <li><i class="fmico fmico-warning"></i><fm-checkbox label="选项3">选项3</fm-checkbox></li>
          <li><i class="fmico fmico-warning"></i><fm-checkbox label="选项4">选项4</fm-checkbox></li>
        </ul>
      </fm-checkbox-group>
      <fm-btn @click="groups3 = ['选项3', '选项4']">设置选中</fm-btn>
      <fm-btn @click="groups3 = []">取消选中</fm-btn>
    </div>

    <h2>值顺序保持(keep-order) - {{groups4}}</h2>
    <div class="flex">
      <fm-checkbox-group v-model="groups4" @change="(v) => $notice.success('选中事件测试', String(v))" keep-order>
        <fm-checkbox label="选项1">选项1</fm-checkbox>
        <fm-checkbox label="选项2">选项2</fm-checkbox>
        <fm-checkbox label="选项3">选项3</fm-checkbox>
        <fm-checkbox label="选项4">选项4</fm-checkbox>
      </fm-checkbox-group>
      <fm-btn @click="groups4 = ['选项3', '选项4']">设置选中</fm-btn>
      <fm-btn @click="groups4 = []">取消选中</fm-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmCheckBoxDemo',
  components: {},
  data() {
    return {
      check: true,
      nocheck: false,
      iconTrue: true,
      iconFalse: false,
      valueset: 'checked',
      groups1: ['选项1', '选项2'],
      groups2: [],
      groups3: [],
      groups4: []
    }
  },
  props: {},
  computed: {},
  methods: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>h2 {margin: 20px 0;}</style>