export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/purchase_contract/warranty_warning',
      method: 'GET'
    }]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '查看本机构创建的',
    apis: [{
      path: '/internalcontrol/purchase_contract/warranty_warning/my_org',
      method: 'GET'
    }]
  },
  deal: {
    funKey: 'deal',
    name: '处理',
    apis: [{
      path: '/internalcontrol/purchase_contract/{id}',
      method: 'PUT'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '扫描件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取扫描件'
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载扫描件'
  }
}