export default {
  get: {
    funKey: 'base',
    name: '基础功能',
    apis: [{
      path: '/basis/appraisal_group_detail/my',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/appraisal',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_group',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_group_detail/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/appraisal_group_detail/status/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/appraisal_result',
      method: 'POST'
    },
    {
      path: '/basis/appraisal_result/vote',
      method: 'POST'
    },
    {
      path: '/basis/appraisal_result/detail',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_result/calculate/{id}',
      method: 'POST'
    },
    {
      path: '/basis/appraisal_result',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_item',
      method: 'GET'
    }]
  }
}