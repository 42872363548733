import stockView from "./stock"
import purchaseView from "./purchase"
import saleView from "./sale"
import saleafterView from "./saleafter"
import handleView from "./handle"

function get (dir) {
  let files = []
  if (dir === 'base') {
    files = require.context('./base', true, /\.js$/)
  }
  const menus = []
  files.keys().map(key => {
    const m = files(key).default || {}
    if (m.clientType) {
      menus.push(m)
    }
  })
  return menus
}

export default [
  {
    icon: 'icon-shuju',
    name: '供应链',
    clientType: 'web',
    code: 'scm',
    children: [
      {
        icon: 'icon-shuju',
        name: '基础数据',
        code: 'scm.base',
        clientType: 'web',
        children: get('base')
      },
      stockView,
      {
        clientType: 'web',
        router: {
          name: 'produce',
          path: 'produce',
          component: () => import('../../views/produce/router.vue')
        },
        children: [
          {
            icon: 'icon-shuju',
            name: '生产标准',
            clientType: 'web',
            router: {
              name: 'produce.standard',
              path: 'standard',
              component: () => import('../../views/produce/standard/index.vue')
            },
            funs: {
              get: {
                name: '查看',
                funKey: 'get',
                apis: [
                  { path: '/scm/goods_produce_standard', method: 'GET' },
                  { path: '/scm/goods_produce_standard/detail', method: 'GET' },
                ]
              },
              manage: {
                name: '维护',
                funKey: 'manage',
                apis: [
                  { path: '/scm/goods_produce_standard/{id}', method: 'PUT' },
                  { path: '/scm/goods_produce_standard/{id}', method: 'DELETE' },
                  { path: '/scm/goods_produce_standard', method: 'POST' },
                  { path: '/scm/goods_produce_standard', method: 'GET' },
                  { path: '/scm/goods', method: 'GET' },

                  { path: '/scm/goods_produce_standard/detail/{id}', method: 'DELETE' },
                  { path: '/scm/goods_produce_standard/detail/{id}', method: 'PUT' },
                  { path: '/scm/goods_produce_standard/detail', method: 'POST' },
                  { path: '/scm/goods_produce_standard/detail', method: 'GET' },
                ]
              }
            }
          },
          {
            icon: 'icon-shuju',
            name: '出库单编辑器',
            clientType: 'web',
            router: {
              name: 'produce.out_order',
              path: 'out_order',
              component: () => import('../../views/produce/outorderGroup.vue')
            },
            funs: {
              get: {
                name: '获取',
                funKey: 'get',
                apis: [
                  { path: '/scm/goods_stock_out_order/group', method: 'GET' },
                  { path: '/scm/goods_stock_out_order', method: 'GET' },
                  { path: '/scm/goods_stock_out_order/count', method: 'GET' },
                ]
              },
              manage: {
                name: '管理',
                funKey: 'manage',
                apis: [
                  { path: '/scm/goods_stock_out_order/{id}', method: 'PUT' },
                  { path: '/scm/goods_stock_out_order/{id}', method: 'DELETE' },
                  { path: '/scm/goods_stock_out_order', method: 'POST' },
                  { path: '/scm/goods_stock_out_order/by_produce_standard', method: 'POST' },
                  { path: '/scm/goods_stock_out_order', method: 'GET' },
                  { path: '/scm/goods_stock_out_order/count', method: 'GET' },

                  { path: '/scm/goods_stock_out_order/group/{id}', method: 'DELETE' },
                  { path: '/scm/goods_stock_out_order/group/status/{id}', method: 'POST' },
                  { path: '/scm/goods_stock_out_order/group/{id}', method: 'PUT' },
                  { path: '/scm/goods_stock_out_order/group', method: 'POST' },
                  { path: '/scm/goods_stock_out_order', method: 'GET' },

                  { path: '/scm/storehouse', method: 'GET' },
                  { path: '/scm/goods', method: 'GET' },
                  { path: '/scm/goods_produce_standard', method: 'GET' },
                  { path: '/scm/goods_group', method: 'GET' },
                  { path: '/scm/goods_group/detail', method: 'GET' }
                ]
              }
            }
          },
          {
            icon: 'icon-shuju',
            name: '出库单查看',
            clientType: 'web',
            router: {
              name: 'produce.out_order_group_list',
              path: 'out_order_group_list',
              component: () => import('../../views/produce/outorderGroupList.vue')
            },
            funs: {
              get: {
                name: '获取',
                funKey: 'get',
                apis: [
                  { path: '/scm/goods_stock_out_order/group', method: 'GET' }
                ]
              }
            }
          },
          {
            icon: 'icon-shuju',
            name: '出库单明细查看',
            clientType: 'web',
            router: {
              name: 'produce.out_order_list',
              path: 'out_order_list',
              component: () => import('../../views/produce/outorderList.vue')
            },
            funs: {
              get: {
                name: '获取',
                funKey: 'get',
                apis: [
                  { path: '/scm/goods_stock_out_order', method: 'GET' }
                ]
              }
            }
          },
          {
            icon: 'icon-shuju',
            name: '入库单编辑器',
            clientType: 'web',
            router: {
              name: 'produce.in_order',
              path: 'in_order',
              component: () => import('../../views/produce/inorderGroup.vue')
            },
            funs: {
              get: {
                name: '获取',
                funKey: 'get',
                apis: [
                  { path: '/scm/goods_stock_in_order/group', method: 'GET' },
                  { path: '/scm/goods_stock_in_order', method: 'GET' },
                  { path: '/scm/goods_stock_in_order/count', method: 'GET' },
                ]
              },
              manage: {
                name: '管理',
                funKey: 'manage',
                apis: [
                  { path: '/scm/goods_stock_in_order/{id}', method: 'PUT' },
                  { path: '/scm/goods_stock_in_order/{id}', method: 'DELETE' },
                  { path: '/scm/goods_stock_in_order', method: 'POST' },
                  { path: '/scm/goods_stock_in_order', method: 'GET' },
                  { path: '/scm/goods_stock_in_order/count', method: 'GET' },
                  { path: '/scm/storehouse', method: 'GET' },

                  { path: '/scm/goods_stock_in_order/group/{id}', method: 'DELETE' },
                  { path: '/scm/goods_stock_in_order/group/status/{id}', method: 'POST' },
                  { path: '/scm/goods_stock_in_order/group/{id}', method: 'PUT' },
                  { path: '/scm/goods_stock_in_order/group', method: 'POST' },
                  { path: '/scm/goods_stock_in_order', method: 'GET' },

                  { path: '/scm/goods', method: 'GET' },
                  { path: '/scm/goods_group', method: 'GET' },
                  { path: '/scm/goods_group/detail', method: 'GET' },
                ]
              }
            }
          },
          {
            icon: 'icon-shuju',
            name: '入库单查看',
            clientType: 'web',
            router: {
              name: 'produce.in_order_group_list',
              path: 'in_order_group_list',
              component: () => import('../../views/produce/inorderGroupList.vue')
            },
            funs: {
              get: {
                name: '获取',
                funKey: 'get',
                apis: [
                  { path: '/scm/goods_stock_in_order/group', method: 'GET' }
                ]
              }
            }
          },
          {
            icon: 'icon-shuju',
            name: '入库单明细查看',
            clientType: 'web',
            router: {
              name: 'produce.in_order_list',
              path: 'in_order_list',
              component: () => import('../../views/produce/inorderList.vue')
            },
            funs: {
              get: {
                name: '获取',
                funKey: 'get',
                apis: [
                  { path: '/scm/goods_stock_in_order', method: 'GET' }
                ]
              }
            }
          }
        ]
      },
      purchaseView,
      saleView,
      handleView,
      saleafterView
    ]
  }
]