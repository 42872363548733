import { request } from '@/api'

import md5 from 'js-md5'

/**
 * 分片上传的选项
 * @typedef {Object} SliceUploadOpt
 * @property {File} file - 要上传的文件
 * @property {number} size - 各个分片的大小
 */

/**
 * 将文件分片上传到服务器
 * @function sliceUpload
 * @param {SliceUploadOpt} param - 包含 file, size
 * @returns {Promise<any>} 上传操作完成后的结果
 */
export async function sliceUpload ({ file, size }) {
  const num = Math.ceil(file.size / size)
  const hash = md5(file.size + file.name)
  const cacheKey = 'slice-upload-' + hash

  let uploadedIndex = sessionStorage.getItem(cacheKey)
  if (uploadedIndex !== null && uploadedIndex !== undefined && !isNaN(uploadedIndex)) {
    uploadedIndex = Number(uploadedIndex)
  } else {
    uploadedIndex = -1
  }

  for (let index = uploadedIndex + 1; index < num; index++) {
    const start = index * size
    const end = Math.min(start + size, file.size)
    const chunk = file.slice(start, end, file.type)
    const data = new FormData()
    data.append('hash', hash)
    data.append('chunk', chunk)
    data.append('size', size)
    data.append('index', index)
    data.append('num', num)
    data.append('total', file.size)
    data.append('type', file.type)
    await request('/fms/upload/part', 'post', data, {
      contentType: 'multipart/form-data'
    })
    sessionStorage.setItem(cacheKey, index)
  }

  sessionStorage.removeItem(cacheKey)

  return request('/fms/upload/part/complete', 'post', {
    hash,
    name: file.name,
    total: file.size,
    type: file.type,
    size, num
  })
}
