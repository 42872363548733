<style lang="less">
@import url('../drag/row.less');
.fm-table-export-plane {
  display: flex;
  height: 60vh;
  position: relative;
}
.fm-table-export-config {
  flex: 1;
  height: 0;
}
.fm-table-export-config .fm-table-export-column {
  height: 100% !important;
  max-height: 100%;
}
.fm-table-export-picked {
  width: 25vw;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #EEE;
}
.fm-table-export-picker {
  flex: 1;
  width: 0;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
}
.fm-table-export-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  height: 0;
  overflow: auto;
  align-content: flex-start;
  .field-item {
    width: 25%;
  }
}
.fm-table-export-action-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EEE;
  padding: 0 5px 5px 5px;
  margin-bottom: 5px;
}

.fm-table-export-column .column-item {
  display: flex;
  padding-left: 40px;
  justify-content: space-between;
  align-items: center;
}
.fm-table-export-config .fm-table-export-column .column-item .fmico-row-drage {
  left: 5px !important;
}
</style>

<template>
  <fm-modal width="80vw" v-model="show" @cancel="$emit('cancel')" :mask-closable="false" title="表格导出选项配置">
    <div class="fm-table-export-plane">
      <div class="fm-table-export-picker">
        <div class="fm-table-export-action-title">
          <fm-input-new v-model="search" placeholder="请输入要搜索的字段名称">
            <span slot="prepend">可选字段</span>
          </fm-input-new>
          <div>
            <fm-btn @click="pickAll">全选</fm-btn>
            <fm-btn @click="reverseAll">反选</fm-btn>
          </div>
        </div>
        <div class="fm-table-export-fields">
          <div
            class="field-item"
            v-for="(column, i) in columns.filter(v => !search || v.config.title.indexOf(search) > -1)"
            :key="i + column.order + column.config.title"
            :data-index="i"
            :data-field="column.config.field">
            <fm-checkbox v-model="column.hidden" :true-value="false" :false-value="true">{{column.config.title}}</fm-checkbox>
          </div>
        </div>
      </div>
      <div class="fm-table-export-picked">
        <div class="fm-table-export-action-title">
          <span>已选字段</span>
          <fm-btn @click="clearAll">全部清除</fm-btn>
        </div>
        <div class="fm-table-export-config" :data-moving="activeIndex !== null" @mouseenter="(e) => e.target.classList.remove('leave')" @mouseleave="(e) => e.target.classList.add('leave')">
          <div class="fm-table-export-column" ref="columns">
            <div
              class="column-item"
              v-for="(column, i) in columns.filter(v => !v.hidden)"
              :key="i + column.order + column.config.title"
              :data-index="i"
              :data-field="column.config.field">
              <span>{{ column.config.title }}</span>
              <fm-btn text @click="column.hidden = true">清除</fm-btn>
              <i class="fmico fmico-row-drage" @mousedown="onMousedown"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <fm-btn @click="confirm" type="primary">导出</fm-btn>
      <fm-btn @click="show = false;$emit('cancel')">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
import FmCheckbox from '../../FmCheckbox.vue'
import XLSX from 'xlsx'

let startY = 0
let timer = null

function getParentByClass (el, cls, max = 5) {
  if (el.classList.contains(cls)) {
    return el
  } else if (el.parentElement && max > 0) {
    return getParentByClass(el.parentElement, cls, max - 1)
  } else {
    return null
  }
}

export default {
  components: { FmCheckbox },
  props: {
    table: { type: Object }
  },
  data () {
    return {
      show: false,
      columns: (JSON.parse(JSON.stringify(this.table.columnConfig.columns))).filter(v => v.config.export !== false),
      activeIndex: null,
      search: null
    }
  },
  computed: {
    tableColumns () {
      return this.table.columnConfig.columns
    }
  },
  watch: {
    tableColumns: {
      deep: true,
      handler (v) {
        this.columns = (JSON.parse(JSON.stringify(v))).filter(v => v.config.export !== false)
      }
    }
  },
  methods: {
    reverseAll () {
      this.columns.forEach(v => v.hidden = !v.hidden)
    },
    pickAll () {
      this.columns.forEach(v => v.hidden = false)
    },
    clearAll () {
      this.columns.forEach(v => v.hidden = true)
    },
    confirm () {
      let columns = Array.from(this.$refs.columns.children).map(el => {
        return this.columns.find(v => v.config.field === el.dataset.field)
      }).filter(column => column && !column.hidden && column.config.export !== false).map(column => {
        if (typeof column.config.export !== 'function') {
          column.config.export = (data, column) => {
            return data[column.config.field]
          }
        }
        return column
      })

      let exportData = this.table.sortData.map(data => {
        return columns.map(column => {
          return column.config.export(data, column)
        })
      })

      exportData.unshift(columns.map(column => column.config.title))

      const workbook = XLSX.utils.book_new()
      let fileName = typeof this.table.exportFileName === 'function' ? this.table.exportFileName() : this.table.exportFileName
      XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(exportData), 'sheet1')
      XLSX.writeFile(workbook, `${fileName}.xlsx`)

      this.$emit('done')
    },
    reset () {
      this.$nextTick(() => {
        for (let i = 0, l = this.$refs.columns.children.length; i < l; i++) {
          let el = this.$refs.columns.children.item(i)
          el.dataset.index = i
          el.style.top = ''
          el.style.opacity = ''
          el.classList.remove('active')
        }
      })
    },
    update (target, current) {
      let currentElement = this.$refs.columns.children[current]
      let targetElement = this.$refs.columns.children[target]

      if (targetElement === this.$refs.columns.lastElementChild) {
        this.$refs.columns.append(currentElement)
      } else if (targetElement === this.$refs.columns.firstElementChild || target < current) {
        targetElement.parentElement.insertBefore(currentElement, targetElement)
      } else if (target > current) {
        if (targetElement.nextElementSibling) {
          targetElement.parentElement.insertBefore(currentElement, targetElement.nextElementSibling)
        } else {
          this.$refs.columns.append(currentElement)
        }
      }
      this.reset()
    },
    onMousedown (e) {
      startY = e.y
      e.target.parentElement.classList.add('active')
      this.activeIndex = parseInt(e.target.parentElement.dataset.index)
      document.body.addEventListener('mousemove', this.onMousemove)
      document.body.addEventListener('mouseup', this.onMouseup)
    },
    onMousemove (e) {
      let activeElement = this.$refs.columns.children[this.activeIndex]
      activeElement.style.top = (e.y - startY) + 'px'
    },
    onMouseup (e) {
      clearTimeout(timer)

      let target = getParentByClass(e.target, 'column-item')

      document.body.removeEventListener('mousemove', this.onMousemove)
      document.body.removeEventListener('mouseup', this.onMouseup)
      
      this.$refs.columns.children[this.activeIndex].style.opacity = 0
      
      if (target) {
        timer = setTimeout((targetIndex, activeIndex) => this.update(targetIndex, activeIndex), 300, parseInt(target.dataset.index), this.activeIndex)
      } else {
        this.reset()
      }

      this.activeIndex = null
      startY = 0
    }
  }
}
</script>
