const stockUnitFuns = {
  unitManage: {
    name: '单元管理',
    funKey: 'unitManage',
    apis: [
      { path: '/scm/storehouse', method: 'GET' },
      { path: '/scm/storehouse/unit', method: 'GET' },
      { path: '/scm/storehouse/unit', method: 'POST' },
      { path: '/scm/storehouse/unit/{id}', method: 'PUT' },
      { path: '/scm/storehouse/unit/{id}', method: 'DELETE' },
    ]
  }
}

const stockHouseFuns = {
  get: {
    name: '获取',
    funKey: 'get',
    apis: [
      { path: '/scm/storehouse', method: 'GET' }
    ]
  },
  add: {
    name: '新增',
    funKey: 'add',
    apis: [
      { path: '/scm/storehouse', method: 'POST' }
    ]
  },
  update: {
    name: '修改',
    funKey: 'update',
    apis: [
      { path: '/scm/storehouse/{id}', method: 'PUT' }
    ]
  },
  del: {
    name: '删除',
    funKey: 'del',
    apis: [
      { path: '/scm/storehouse/{id}', method: 'DELETE' }
    ]
  },
  ...stockUnitFuns
}

const stockIndexFuns = {
  get: {
    name: '获取',
    funKey: 'get',
    apis: [
      { path: '/scm/storehouse', method: 'GET' },
      { path: '/scm/goods_stock', method: 'GET' },
      { path: '/scm/goods_stock/count', method: 'GET' },
      { path: '/scm/goods_stock/detail', method: 'GET' },
    ]
  },
  out: {
    name: '出库',
    funKey: 'out',
    apis: [
      { path: '/scm/goods_stock/direct_out', method: 'POST' }
    ]
  },
  in: {
    name: '入库',
    funKey: 'in',
    apis: [
      { path: '/scm/goods', method: 'GET' },
      { path: '/scm/goods_stock/direct_in', method: 'POST' },
      { path: '/scm/storehouse', method: 'GET' },
      { path: '/scm/storehouse/unit', method: 'GET' }
    ]
  }
}

export default {
  clientType: 'web',
  router: {
    name: 'stock',
    path: 'stock',
    component: () => import('../../../views/stock/router.vue')
  },
  children: [
    {
      icon: 'icon-shuju',
      name: '仓库',
      clientType: 'web',
      router: {
        name: 'stock.house1',
        path: 'house1',
        component: () => import('../../../views/stock/house.vue'),
        props: { type: 'store' }
      },
      funs: stockHouseFuns
    },
    {
      icon: 'icon-shuju',
      name: '车间',
      clientType: 'web',
      router: {
        name: 'stock.house2',
        path: 'house2',
        component: () => import('../../../views/stock/house.vue'),
        props: { type: 'workshop' }
      },
      funs: stockHouseFuns
    },
    {
      clientType: 'web',
      router: {
        name: 'stock.unit',
        path: 'unit',
        component: () => import('../../../views/stock/unit.vue')
      },
      funs: stockUnitFuns
    },
    {
      icon: 'icon-shuju',
      name: '当前库存',
      clientType: 'web',
      router: {
        name: 'stock.index',
        path: 'index',
        component: () => import('../../../views/stock/index.vue')
      },
      funs: stockIndexFuns
    },
    {
      icon: 'icon-shuju',
      name: '出入库明细',
      clientType: 'web',
      router: {
        name: 'stock.detail',
        path: 'detail',
        component: () => import('../../../views/stock/detail.vue')
      },
      funs: {
        get: {
          name: '查看',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_stock/detail', method: 'GET' }
          ]
        }
      }
    },
    {
      icon: 'icon-shuju',
      name: '库存管理',
      clientType: 'web',
      router: {
        name: 'stock.manage',
        path: 'manage',
        component: () => import('../../../views/stock/stock.vue')
      },
      funs: stockIndexFuns
    },
    {
      icon: 'icon-shuju',
      name: '出库单执行',
      clientType: 'web',
      router: {
        name: 'stock.outorder',
        path: 'outorder',
        component: () => import('../../../views/stock/inout/outorder/exec.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_stock_out_order/group', method: 'GET' },
            { path: '/scm/goods_stock_out_order', method: 'GET' },
            { path: '/scm/storehouse', method: 'GET' }
          ]
        },
        manage: {
          name: '执行',
          funKey: 'manage',
          apis: [
            { path: '/scm/goods_stock/detail', method: 'GET' },
            { path: '/scm/goods_stock', method: 'GET' },
            
            { path: '/scm/goods_stock_out_order/exe_out/{id}', method: 'POST' },
            { path: '/scm/goods_stock_out_order/exe_out/{id}', method: 'DELETE' }
          ]
        }
      }
    },
    {
      icon: 'icon-shuju',
      name: '入库单执行',
      clientType: 'web',
      router: {
        name: 'stock.inorder',
        path: 'inorder',
        component: () => import('../../../views/stock/inout/inorder/exec.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_stock_in_order/group', method: 'GET' },
            { path: '/scm/goods_stock_in_order', method: 'GET' },
            { path: '/scm/storehouse', method: 'GET' }
          ]
        },
        manage: {
          name: '执行',
          funKey: 'manage',
          apis: [
            { path: '/scm/goods_stock/detail', method: 'GET' },
            { path: '/scm/storehouse/unit', method: 'GET' },
            
            { path: '/scm/goods_stock_in_order/check_and_exe/{id}', method: 'POST' },
            { path: '/scm/goods_stock_in_order/back_check_and_exe/{id}', method: 'POST' }
          ]
        }
      }
    },
    {
      icon: 'icon-shuju',
      name: '盘点计划',
      clientType: 'web',
      router: {
        name: 'stock.check.plan',
        path: 'check/plan',
        component: () => import('../../../views/stock/check/plan.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_stock_check/batch', method: 'GET' },
            { path: '/scm/goods_stock_check', method: 'GET' }
          ]
        },
        add: {
          name: '新增',
          funKey: 'add',
          apis: [
            { path: '/scm/goods_stock_check/batch', method: 'POST' },
          ]
        },
        update: {
          name: '修改',
          funKey: 'update',
          apis: [
            { path: '/scm/goods_stock_check/batch/{id}', method: 'PUT' },
            { path: '/scm/goods_stock_check/batch/status/{id}', method: 'PUT' }
          ]
        },
        del: {
          name: '删除',
          funKey: 'del',
          apis: [
            { path: '/scm/goods_stock_check/batch/{id}', method: 'DELETE' }
          ]
        },
        deal: {
          name: '处理',
          funKey: 'deal',
          apis: [
            { path: '/scm/goods_stock_check/{id}', method: 'DELETE' },
            { path: '/scm/goods_stock_check/no_stock', method: 'POST' },
            { path: '/scm/goods_stock_check', method: 'POST' },
            { path: '/scm/goods_stock_check/checking', method: 'GET' },
            { path: '/scm/storehouse/unit', method: 'GET' },
            { path: '/scm/storehouse', method: 'GET' },
            { path: '/scm/goods', method: 'GET' },
          ]
        }
      }
    }
  ]
}