<template>
  <div class="fm-input-number" :class="wrapCls">
    <input :value="formatValue" @keydown="onKeydown" @input="onInput" :readonly="disabled !== false || readonly !== false" :disabled="disabled !== false" :placeholder="placeholder" @focus="focusIn = true" @blur="focusIn = false"/>
    <div class="fm-input-number-handler">
      <button class="fm-input-number-up" type="button" @click="handler(1)">
        <i class="fmico fmico-top-arrow"></i>
      </button>
      <button class="fm-input-number-down" type="button" @click="handler(-1)">
        <i class="fmico fmico-top-arrow"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmInputNumber',
  data () {
    return {
      focusIn: false,
      data: this.value
    }
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: undefined
    },
    min: {
      type: Number,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'norm',
      validator: function(size) {
        return ['norm', 'large', 'small', 'mini'].includes(size)
      }
    },
    value: {
      type: Number,
      default: null
    },
    parser: {
      type: Function,
      default: (val) => val
    },
    formatter: {
      type: Function,
      default: (val) => val
    },
    precision: {
      type: Number,
      default: 2
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  computed: {
    wrapCls () {
      return {
        'fm-input-number-focus': this.focusIn,
        [`fm-input-number-${this.size}`]: true,
        'fm-input-number-disabled': this.disabled !== false,
        'fm-input-number-readonly': this.readonly !== false
      }
    },
    formatValue () {
      return this.data === null ? '' : this.formatter(this.data)
    }
  },
  methods: {
    onKeydown (event) {
      if (this.focusIn) {
        if (event.keyCode === 38) {
          this.handler(1)
        } else if (event.keyCode === 40) {
          this.handler(-1)
        }
      }
    },
    rangeFix (data) {
      if (this.max !== undefined && this.max < data) {
        return this.max
      }
      if (this.min !== undefined && this.min > data) {
        return this.min
      }
      return data
    },
    onInput (event) {
      if (this.readonly !== false || this.disabled !== false) return
      let data = this.parser(event.target.value.trim())

      data = data === '' ? null : Number(data)

      if (isNaN(data)) {
        data = null
      }

      this.data = data !== null ? this.rangeFix(data) : null
      this.$emit('input', this.data)
    },
    handler (operator) {
      if (this.readonly !== false || this.disabled !== false) return
      this.data = this.rangeFix(Number((Number(this.data === undefined ? 0 : this.data) + Number(this.step) * operator).toFixed(this.precision)))
      this.$emit('input', this.data)
    }
  },
  watch: {
    value (value) {
      this.data = value
    }
  }
}
</script>

<style lang="less">
  @import './styles/values.less';
  .fm-input-number {
    position: relative;
    display: inline-flex;
    border: 1px solid @color-border;
    border-radius: 5px;
    overflow: hidden;
    transition: all .3s;
    box-shadow: 0 0 0px 3px transparent;
    &.fm-input-number-focus, &:hover {
      border-color: @color-primary;
    }
    &.fm-input-number-focus {
      box-shadow: 0 0 0px 3px @color-primary-shadow;
    }

    &:hover, &.fm-input-number-focus {
      .fm-input-number-handler {
        opacity: 1;
      }
    }
    &.fm-input-number-disabled {
      &:hover {
        border-color: @color-border;
      }
    }
    &.fm-input-number-readonly.fm-input-number-focus {
      box-shadow: 0 0 0px 3px transparent;
    }
    &.fm-input-number-readonly, &.fm-input-number-disabled {
      &:hover .fm-input-number-handler, .fm-input-number-handler {
        opacity: .3;
        button {
          cursor: not-allowed;
          &:hover .fmico-top-arrow {color: @color-border;}
          &.fm-input-number-up {
            &:active {
              .fmico-top-arrow {
                transform: scale(1);
              }
            }
          }
          &.fm-input-number-down {
            &:active {
              .fmico-top-arrow {
                transform: scale(1) rotate(180deg);
              }
            }
          }
        }
      }
    }

    &.fm-input-number-disabled {
      background-color: @color-disabled-background;
      input, input::placeholder {
        cursor: not-allowed;
        color: @color-disabled-text;
      }
    }

    input {
      flex: 1;
      width: 100%;
      border: none;
      outline: none;
      padding: 0 8px;
      color: @color-component-text;
      &::placeholder {
        color: @color-placeholder-font;
      }
    }

    .fm-input-number-handler {
      width: 40px;
      display: flex;
      flex-direction: column;
      border-left: 1px solid @color-border;
      opacity: .3;
      transition: all .3s;
      button {
        cursor: pointer;
        border: none;
        background-color: #FFF;
        outline: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .fmico-top-arrow {
          display: inline-block;
          color: @color-border;
          transform: scale(1);
          transition: color .3s, transform 0.1s;
        }
        &:hover {
          .fmico-top-arrow {
            color: @color-primary;
          }
        }
        
        &.fm-input-number-up {
          border-radius: 5px;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: @color-border;
            display: block;
          }
          &:active {
            .fmico-top-arrow {
              transform: scale(0.8);
            }
          }
        }
        &.fm-input-number-down {
          .fmico-top-arrow {
            transform: rotate(180deg);
          }
          &:active {
            .fmico-top-arrow {
              transform: scale(0.8) rotate(180deg);
            }
          }
        }
      }
    }

    // 大小设置
    &.fm-input-number-norm {
      button {
        height: @size-height-norm / 2;
        .fmico-top-arrow {
          font-size: @size-height-norm / 2;
        }
      }
      input {
        line-height: @size-height-norm;
        height: @size-height-norm;
      }
    }
    &.fm-input-number-large {
      button {
        height: @size-height-large / 2;
        .fmico-top-arrow {
          font-size: @size-height-large / 2;
        }
      }
      input {
        line-height: @size-height-large;
        height: @size-height-large;
      }
    }
    &.fm-input-number-small {
      button {
        height: @size-height-small / 2;
        .fmico-top-arrow {
          font-size: @size-height-small / 2;
        }
      }
      input {
        line-height: @size-height-small;
        height: @size-height-small;
      }
    }
    &.fm-input-number-mini {
      button {
        height: @size-height-mini / 2;
        .fmico-top-arrow {
          font-size: @size-height-mini / 2;
        }
      }
      input {
        line-height: @size-height-mini;
        height: @size-height-mini;
      }
    }
  }
</style>