export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/count_group',
      method: 'GET'
    },
    {
      path: '/basis/count_worker',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'update',
    name: '修改',
    apis: [{
      path: '/basis/count_worker/{id}',
      method: 'PUT'
    }]
  },
  batch: {
    funKey: 'batch',
    name: '批量生成',
    apis: [{
      path: '/basis/count_group',
      method: 'POST'
    },
    {
      path: '/basis/count_group/detail/{id}',
      method: 'POST'
    }]
  },
  del_all: {
    funKey: 'del_all',
    name: '清空数据',
    apis: [{
      path: '/basis/count_group/detail/{id}',
      method: 'DELETE'
    }]
  }
}
