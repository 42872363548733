/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Thu Dec 05 2019
 */
import Vue from 'vue'
import FmModal from '../components/FmModal'
import FmInputNew from '../components/FmInputNew'
import FmBtn from '../components/FmBtn'

const prefixCls = 'fm-modal-dialog'

function getInstance(options) {

  let resolve, promise = new Promise(r => {
    resolve = r
  })
  const instance =  new Vue({
    data: Object.assign({
      value: false,
      title: '',
      content: '',
      promptValue: '',
      theme: '',
      okText: '确定',
      showCancel: false,
      showInput: false,
      inputType: 'text',
      placeholder: '请输入',
      cancelText: '取消',
      esc: false,
      wait: false,
      waiting: false
    }, options),
    render: function (h) {
      let head_render, content_render, footer_render, footerNodes = []

      const okBtn = h(FmBtn, {
        props: {
          disabled: this.waiting
        },
        on: {
          click: this.ok
        }
      }, this.waiting ? (this.okText + '(' + parseInt(this.wait) + ')') : this.okText)
      footerNodes.push(okBtn)

      if (this.showCancel) {
        footerNodes.push(h(FmBtn, {
          props: {},
          on: {
            click: this.cancel
          }
        }, this.cancelText))
      }

      if (this.title) {
        head_render = h('div', {
          attrs: {
            class: `${prefixCls}-header`
          }
        }, [
          h('div', {
            domProps: {
              innerHTML: this.title
            }
          })
        ])
      }

      if (this.showInput) {
        content_render = h('div', {
          attrs: {
            class: `${prefixCls}-body`
          }
        }, [
          h(FmInputNew, {
            attrs: {
              // class: `${prefixCls}-input`
            },
            props: {
              value: this.promptValue,
              placeholder: this.placeholder || '请输入',
              type: this.inputType || 'text'
            },
            style: {
              width: '100%'
            },
            domProps: {},
            on: {
              change: (v) => {
                this.promptValue = v
              }
            }
          })
        ])
      } else {
        content_render = h('div', {
          attrs: {
            class: `${prefixCls}-body`
          }
        }, [
          h('div', {
            domProps: {
              innerHTML: this.content
            }
          })
        ])
      }

      footer_render = h('div', {
        attrs: {
          class: `${prefixCls}-footer`
        }
      }, footerNodes)
      return h(FmModal, {
        props: {
          value: this.value,
          theme: this.theme,
          width: window.innerWidth < 500 ? '80vw' : 500
        },
        on: {
          input: (status) => {
            this.value = status
          },
          cancel: this.cancel
        }
      }, [
        head_render,
        content_render,
        footer_render
      ])
    },
    methods: {
      ok () {
        this.value = false
        if (this.showInput !== false) {
          resolve(this.promptValue)
        } else {
          resolve(true)
        }
      },
      cancel () {
        this.value = false
        resolve(false)
      }
    }
  }).$mount()

  document.body.appendChild(instance.$el)
  const modal = instance.$children[0]

  return {
    show () {
      modal.$parent.value = true
      return promise
    },
    remove () {
      modal.$parent.value = false
    },
    component: modal,
    instance: instance
  }
}

function configFix (c) {
  if (typeof c === 'string') {
    return {
      content: c
    }
  } else {
    return c
  }
}

export default {
  name: '$dialog',
  methods: {
    info (config = {}) {
      config = configFix(config)
      config.showCancel = false
      const dialog = getInstance(config)
      return dialog.show()
    },
    confirm (config = {}) {
      config = configFix(config)
      config.showCancel = true
      config.waiting = config.wait && config.wait > 0 ? true : false
      const dialog = getInstance(config)
      let timer = setInterval(() => {
        dialog.instance.wait = dialog.instance.wait - 1
        if (dialog.instance.wait <= 0) {
          clearInterval(timer)
          dialog.instance.waiting = false
        }
      }, 1000)
      return dialog.show()
    },
    prompt (config = {}) {
      config = configFix(config)
      config.showCancel = true
      config.showInput = true
      const dialog = getInstance(config)
      // dialog.$children.find(item => item.)
      setTimeout(() => {
        dialog.component.$children[0].focus()
      }, 200)
      return dialog.show()
    }
  }
}
