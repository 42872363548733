export default {
  icon: 'icon-shuju',
  name: '销售',
  clientType: 'web',
  router: {
    name: 'sale',
    path: 'sale',
    component: () => import('../../../views/sale/router.vue'),
    redirect: { name: 'scm.sale.index' }
  },
  children: [
    {
      icon: 'icon-shuju',
      name: '销售计划',
      clientType: 'web',
      router: {
        name: 'sale.index',
        path: 'index',
        component: () => import('../../../views/sale/index.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_sale', method: 'GET' },
            { path: '/scm/goods_sale/detail/count', method: 'GET' },
            { path: '/scm/goods_sale/detail', method: 'GET' }
          ]
        },
        manage: {
          name: '维护',
          funKey: 'manage',
          apis: [
            { path: '/scm/goods_sale/{id}', method: 'PUT' },
            { path: '/scm/goods_sale/exe_status/{id}', method: 'PUT' },
            { path: '/scm/goods_sale/{id}', method: 'DELETE' },
            { path: '/scm/goods_sale/detail', method: 'GET' },
            { path: '/scm/goods_sale', method: 'POST' },
            { path: '/scm/goods_sale', method: 'GET' },
            { path: '/scm/goods', method: 'GET' },
            { path: '/scm/goods_group', method: 'GET' },
            { path: '/scm/goods_group/detail', method: 'GET' },

            { path: '/scm/goods_sale/detail/{id}', method: 'DELETE' },
            { path: '/scm/goods_sale/detail/{id}', method: 'PUT' },
            { path: '/scm/goods_sale/detail', method: 'POST' },
            { path: '/scm/goods_sale/detail', method: 'GET' },
          ]
        }
      }
    },
    {
      icon: 'icon-shuju',
      name: '销售出库',
      clientType: 'web',
      router: {
        name: 'sale.outbound',
        path: 'outbound',
        component: () => import('../../../views/stock/inout/sale.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_sale', method: 'GET' },
            { path: '/scm/goods_sale/detail', method: 'GET' },
            { path: '/scm/storehouse', method: 'GET' }
          ]
        },
        manage: {
          name: '执行',
          funKey: 'manage',
          apis: [
            { path: '/scm/goods_stock/detail', method: 'GET' },
            { path: '/scm/goods_sale/detail', method: 'GET' },
            { path: '/scm/goods_stock', method: 'GET' },
            
            { path: '/scm/goods_sale/detail/out_send/{id}', method: 'POST' },
            { path: '/scm/goods_sale/detail/out_send/{id}', method: 'DELETE' }
          ]
        }
      }
    },
  ]
}