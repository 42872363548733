<template>
  <div class="fm-echarts-map" v-loadingx="loading"></div>
</template>

<script>
import { getEchartsMapInstance } from './FmEchartsMap/index'

export default {
  name: "FmEchartsMap",
  components: {},
  data() {
    return {
      loading: false,
      mapInstance: null
    }
  },
  props: {},
  computed: {},
  methods: {
    seriesInViewPort (series, option = {}) {
      this.mapInstance.seriesInViewPort(series, option)
    },
    setSeriesItem (newSeries) {
      this.mapInstance.setSeriesItem(newSeries)
    },
    async initInstance () {
      this.loading = true
      this.mapInstance = await getEchartsMapInstance(this.$el, { ak: 'RI9riBHWhBS9udjXeMVcdlFEVuH9QFCp' })
      this.loading = false
      this.$emit('instance')
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted () {
    this.initInstance()
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  .fm-echarts-map {
    width: 100%;
    height: 100%;
  }
</style>