<template>
  <div>
    <h2>开关组件(fm-switch) - {{status}}</h2>
    <div class="row">
      <fm-switch size="mini" v-model="status"></fm-switch>
      <fm-btn @click="status = true">true</fm-btn>
      <fm-btn @click="status = false">false</fm-btn>
    </div>

    <h2>宽度自适应</h2>
    <div class="row">
      <fm-switch size="small">
        <span slot="on">宽度自适应</span>
        <span slot="off">宽度自适应</span>
      </fm-switch>
      <fm-switch size="small">
        <span slot="on">开</span>
        <span slot="off">关</span>
      </fm-switch>
    </div>

    <h2>状态(<fm-tag>loaidng</fm-tag><fm-tag>disabled</fm-tag>)</h2>
    <div class="row">
      <fm-switch size="mini" :value="true" :loading="true">
        <span slot="on">loading</span>
        <span slot="off">loading</span>
      </fm-switch>
      <fm-switch size="mini" :value="true" disabled>
        <span slot="on">disabled</span>
        <span slot="off">disabled</span>
      </fm-switch>
    </div>

    <h2>自定义颜色(<fm-tag>on-color</fm-tag><fm-tag>off-color</fm-tag>)</h2>
    <div class="row">
      <fm-switch size="mini" :value="true" on-color="green" off-color="red"></fm-switch>
    </div>

    <h2>尺寸(size: <fm-tag>large</fm-tag><fm-tag>norm</fm-tag><fm-tag>small</fm-tag><fm-tag>mini</fm-tag>)</h2>
    <div class="row">
      <fm-switch size="large"></fm-switch>
      <fm-switch size="norm"></fm-switch>
      <fm-switch size="small"></fm-switch>
      <fm-switch size="mini"></fm-switch>
    </div>

    <h2>事件(<fm-tag>change</fm-tag>)</h2>
    <div class="row">
      <fm-switch size="small" v-model="status2" @change="(v) => $notice.info('开关状态变化', String(v))"></fm-switch>
      <fm-btn @click="status2 = true">true</fm-btn>
      <fm-btn @click="status2 = false">false</fm-btn>
    </div>

    <h2>自定义选中值(<fm-tag>on-value</fm-tag><fm-tag>off-value</fm-tag>)</h2>
    <div class="row">
      <fm-switch size="small" :on-value="1" :off-value="0" @change="(v) => $notice.info('开关状态变化', String(v))"></fm-switch>
    </div>

    <h2>异步控制(<fm-tag>confirm</fm-tag>)</h2>
    <div class="row">
      <fm-switch size="small" :loading="loading1" :confirm="promiseConfirm"></fm-switch>
    </div>

    <h2>弹框确认(<fm-tag>confirm</fm-tag>)</h2>
    <div class="row">
      <fm-switch size="small" :loading="loading2" :confirm="dialogConfirm" on-value="打开" off-value="关闭"></fm-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: "FmSwitchDemo",
  components: {},
  data () {
    return {
      loading1: false,
      loading2: false,
      status: true,
      status2: true
    }
  },
  props: {},
  computed: {},
  methods: {
    async dialogConfirm (status) {
      this.loading2 = true;
      const accept = await this.$dialog.confirm('确认要切换状态到【' + status + '】吗？')
      this.loading2 = false;
      return accept;
    },
    promiseConfirm () {
      this.loading1 = true;
      return new Promise(resolve => {
        setTimeout(() => {
          this.loading1 = false;
          resolve(true);
        }, 1000);
      });
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  .row {
    display: flex;
    align-items: center;
    & + & {
      margin-top: 10px;
    }
  }
  h2 {margin: 10px 0;}
</style>