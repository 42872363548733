<template>
  <div class="fm-tree" @click="showMenu = false">
    <div class="fm-tree-search" v-if="search === undefined || search === true">
      <input v-model="searchKey" class="fm-tree-input" :placeholder="placeholder"/>
      <i class="fmico fmico-search"></i>
    </div>
    <fm-tree-node v-for="(node, i) in nodesData" :key="String(i)"
      :node="node"
      :node-render="nodeRender"
      :isChecked="isChecked"
      :isSelected="isSelected"
      :edit="isEdit"
      :multiple="multiple"
      :index="i"
      :searchKey="searchKey"></fm-tree-node>
			<div class="fm-tree-contextmenu" ref="menu" v-show="showMenu">
				<div class="fm-tree-menu" v-for="menu in contextmenu" @click="menuClick(menu)" :key="menu">{{menu}}</div>
			</div>
  </div>
</template>

<script>
function getNode (nodes, keys, getChild = true, reserve = 0) {
  const key = keys.shift()

  if (keys.length > reserve) {
    return getNode.call(this, nodes[key].children, keys, getChild, reserve)
  } else {
    if (!nodes[key].children) {
      this.$set(nodes[key], 'children', [])
    }
    return  getChild ? nodes[key].children : nodes[key]
  }
}

export default {
  name: 'FmTree',
  data() {
    return {
      searchKey: null,
      selectedList: new Map(),
      checkedList: new Map(),
      nodesData: null,
      showMenu: false,
      contextmenuData: null
    }
  },
  provide () {
    return {
      root: this
    }
  },
  props: {
    contextmenu: {
      type: Array,
      default () {
        return []
      }
    },
    nodes: {
      type: Array,
      default () {
        return []
      }
    },
    placeholder: {
      type: String,
      default: '搜索'
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      deefault: false
    },
    nodeAdd: {
      type: Function,
      default: function ({eventData}) {
        return eventData
      }
    },
    nodeRender: {
      type: Function
    },
    nodeTitleChange: {
      type: Function,
      default: function ({eventData, data}) {
        return Object.assign({}, data, {title: eventData.new})
      }
    },
    nodeRemove: {
      type: Function,
      default: function ({eventData, data}) {
        return Object.assign({}, data, {title: eventData.new})
      }
    }
  },
  computed: {
    isMultiple () {
      return this.multiple === undefined || this.multiple === true
    },
    isEdit () {
      return this.edit === undefined || this.edit === true
    }
  },
  methods: {
    menuClick (menu) {
      this.showMenu = false
      this.$emit('contextmenu', menu, this.contextmenuData)
    },
    report (type, data) {
      if (type === 'selected') {
        this.onSelectedChange(data)
      } else if (type === 'checked') {
        this.onCheckedChange(data)
      } else if (type === 'nodeAdd') {
        this.onNodeAddDeal(data)
      } else if (type === 'nodeTitleChange') {
        this.onNodeTitleChangeDeal(data)
      } else if (type === 'nodeRemove') {
        this.onNodeRemoveDeal(data)
      } else if (type === 'contextmenu') {
        this.onContextmenu(data)
      } else {
        this.$emit(type, data)
      }
    },
    async onContextmenu (data) {
      if (Array.isArray(this.contextmenu) && this.contextmenu.length > 0) {
        this.showMenu = !this.showMenu
        if (this.showMenu) {
          this.$refs.menu.style.top = data.eventData.y + 'px'
          this.$refs.menu.style.left = data.eventData.x + 'px'
        }
        this.contextmenuData = data
      } else {
        this.showMenu = false
      }
    },
    async onNodeRemoveDeal (data) {
      data.node.loading = true
      data.node.loadType = '删除中...'
      let result = this.nodeRemove(data)
      if (result instanceof Promise) {
        result = await result
      }

      if (result) {
        if (data.level === 0) {
          this.nodesData.splice(data.index, 1)
        } else {
          const nodeBody = getNode.call(this, this.nodesData, data.key.split('-'), false, 1)
          nodeBody.children.splice(data.index, 1)
        }
        this.$emit(data.eventType, data)
      }
      data.node.loading = false
    },
    async onNodeTitleChangeDeal (data) {
      data.node.loading = true
      data.node.loadType = '修改中...'
      let node = this.nodeTitleChange(data)
      if (node instanceof Promise) {
        node = await node
      }
      if (node) {
        const nodeBody = getNode.call(this, this.nodesData, data.key.split('-'), false, 1)
        nodeBody.children.splice(data.index, 1, node)
        this.$emit(data.eventType, data)
      }
      data.node.loading = false
    },
    async onNodeAddDeal (data) {
      data.node.loading = true
      data.node.loadType = '添加中...'
      let node = this.nodeAdd(data)
      if (node instanceof Promise) {
        node = await node
      }
      if (node) {
        const nodeBody = getNode.call(this, this.nodesData, data.key.split('-'))
        nodeBody.push(node)
        this.$emit(data.eventType, data)
      }
      data.node.loading = false
      data.node.open = true
    },
    onCheckedChange (data) {
      if (this.isMultiple) {
        if (!data.checked) {
            this.checkedList.delete(data.key)
          } else {
            // data.paths.forEach(node => {
            //   this.checkedList.set(node.$vnode.key, data)
            // })
            this.checkedList.set(data.key, data)
          }
          this.$emit('checked', data, [...this.checkedList.values()])
        } else {
          this.checkedList.clear()
          this.checkedList.set(data.key, data)
          data.checked && this.$emit('checked', data, [data])
        }

        if (data.checked && !data.parent.open) {
          data.paths.forEach(node => {
            node.open = true
          })
        }
    },
    onSelectedChange (data) {
      if (this.isMultiple) {
        if (!data.selected) {
          this.selectedList.delete(data.key)
        } else {
          this.selectedList.set(data.key, data)
        }
        this.$emit('selected', data, [...this.selectedList.values()])
      } else {
        this.selectedList.clear()
        this.selectedList.set(data.key, data)
        data.selected && this.$emit('selected', data, [data])
      }

      if (data.selected && !data.parent.open) {
        data.paths.forEach(node => {
          node.open = true
        })
      }
    }
  },
  watch: {
    nodes: {
      immediate: true,
      deep: true,
      handler (nodes) {
        this.nodesData = JSON.parse(JSON.stringify(nodes))
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
  .fm-tree-search {
    padding-bottom: 5px;
    position: relative;
    .fmico-search {
      position: absolute;
      left: 5px;
      top: 5px;
      transition: all .2s;
      color: #657180;
    }
    .fm-tree-input {
      &:focus {
        & ~ .fmico-search {
          color: rgba(87, 163, 243, 1);
        }
      }
    }
  }
  .fm-tree-input {
    border: 1px solid rgba(87, 163, 243, .2);
    transition: all .2s;
    outline: none;
    border-radius: 4px;
    padding: 5px 10px 5px 23px;
    color: #657180;
    &:focus {
      outline: none;
      border: 1px solid rgba(87, 163, 243, 1);
      & ~ .fmico-search {
        color: rgba(87, 163, 243, 1);
      }
    }
	}
	.fm-tree-contextmenu {
		position: fixed;
		background-color: #FFF;
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0 1px 6px rgb(87, 163, 243);
		.fm-tree-menu {
			color: #657180;
			padding: 5px 10px;
			cursor: pointer;
			&:hover {
				background-color: #EEE;
			}
		}
	}
</style>