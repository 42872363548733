export default {
  functional: true,
  props: {
    render: Function,
    column: {
      type: Object, default () {
        return {}
      }
    },
    index: {
      type: Number, default: undefined
    },
    row: {
      type: Object, default () {
        return {}
      }
    }
  },
  render: (h, ctx) => {
    return ctx.props.render ? ctx.props.render(h, ctx.props.row, ctx.props.column.config, ctx.props.index) : 'no render'
  }
}
  