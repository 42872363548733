import pda from './pda'
import web from './web'
import app from './app'

let menus = [...web, ...pda, ...app]

function treeGet (list) {
  let rows = []
  for (let item of list) {
    if (!item.icon && !item.name) {
      if (item.children) {
        rows.push(...treeGet(item.children))
      }
      continue
    }
    let row = {
      icon: item.icon,
      name: item.name,
      clientType: item.clientType,
      code: item.code || undefined,
      url: item.url || (item.router ? item.router.name: undefined),
      funs: item.funs || undefined
    }
    if (item.children && item.children.length) {
      row.children = treeGet(item.children)
    }
    rows.push(row)
  }
  return rows
}

export default treeGet(menus)