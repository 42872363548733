export default {
  selectData: [],
  sysConfig: [{
    key: 'repair_config',
    label: '维修申请相关配置',
    remark: '维修申请相关配置',
    defaultValue: null
  },
  {
    key: 'scrap_config',
    label: '报废申请相关配置',
    remark: '报废申请相关配置',
    defaultValue: null
  }]
}
