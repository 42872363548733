<style lang="less">
  .fm-table-head-cells {
    display: flex;
    background-color: #FAFAFA;
  }
  .fm-table-head-item {
    flex: 1;
    overflow-x: auto;
    &::-webkit-scrollbar {display:none}
    table {
      width: 100%;
    }
    &.fm-table-head-fixed-left, &.fm-table-head-fixed-right {
      overflow-x: unset;
      flex: 0;
      flex-shrink: 0;
    }
  }
  .fm-table-head-tools-bar {
    position: relative;
    display: flex;
    justify-content: flex-end;
    & > * + * {
      margin-left: 10px;
    }
  }
</style>

<template>
  <div class="fm-table-head">
    <div class="fm-table-head-tools-bar">
      <slot name="head-tools-bar"></slot>
    </div>
    <div class="fm-table-head-cells">
      <template v-for="(columns, columnsIndex) in columnConfig">
        <div class="fm-table-head-item" :ref="'item' + columnsIndex" v-if="columns.length > 0" :key="columnsIndex" :style="{
          width: columnsIndex === 1 ? (needResize ? '10000px' : '0px') : 'unset',
          flex: columnsIndex === 1 ? '1' : ('0 0 ' + columnWidth[columnsIndex].reduce((a, b) => a + b, 0) + 'px')
        }" :class="{
          'fm-table-head-fixed-left': columnsIndex === 0,
          'fm-table-head-fixed-right': columnsIndex === 2
        }" @scroll="onScrollX">
          <table border="0" cellspacing="0" cellpadding="0" :style="{
            width: columnsIndex === 1 && !needResize ? (tableWidth + 'px') : 'unset'
          }" :class="{'fm-table-layout-fixed': !needResize, 'fm-table-layout-ready': needResize}">
            <colgroup :key="columnWidthNeedUpdate">
              <col v-for="(width, index) in (columnWidth[columnsIndex] || columns.map(v => v.width))" :key="index" :width="width" />
            </colgroup>
            <thead>
              <tr :ref="'trs' + columnsIndex">
                <table-head-cell v-for="(column, columnIndex) in columns" :key="columnIndex" :column="column" :group-index="columnsIndex" :column-index="columnIndex"></table-head-cell>
              </tr>
              <tr class="fm-table-simple-filter" v-if="simpleFilter === 'head'">
                <td v-for="(columnItem, columnIndex) in columns" :key="columnIndex">
                  <simple-filter :column="columnItem" :groupIndex="columnsIndex" :columnIndex="columnIndex"></simple-filter>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TableHeadCell from './head-cell'
import ColMixins from './col-mixins'
import SimpleFilter from './simple-filter'

export default {
  inject: ['table'],
  mixins: [ColMixins],
  data () {
    return {
      height: 0
    }
  },
  components: {TableHeadCell, SimpleFilter},
  watch: {
    '$slots': {
      handler () {
        this.height = this.$el.offsetHeight
      }
    },
    columnConfig: {
      handler () {
        this.columnWidth = this.columnConfig.map(columns => columns.map(column => column.width))
        this.$nextTick(this.updateCellWidth)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    simpleFilter () {
      return this.table.simpleFilter
    }
  },
  methods: {
    updateCellWidth () {
      this.columnWidth = this.columnConfig.map(columns => columns.map(column => column.width))
      this.needResize = true
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.table.$emit('update-head-cell-width', [this.$refs.trs0 || null, this.$refs.trs1 || null, this.$refs.trs2 || null].map(tr => tr && tr.length ? Array.from(tr[0].cells).map(th => th.offsetWidth) : []))
        })
      })
    },
    onScrollX (event) {
      this.table.$emit('scroll-x', { index: 1, x: event.target.scrollLeft, type: 'head' })
    },
    scrollX ({index, x, type}) {
      if (type !== 'head' && this.$refs['item' + index]) {
        this.$refs['item' + index][0].scrollLeft = x
      }
    },
    onSizeChange () {
      this.height = this.$el.offsetHeight
      this.updateCellWidth()
    }
  },
  mounted () {
    this.table.$on('set-cell-width', this.handleResize)
    this.table.$on('scroll-x', this.scrollX)
    this.table.$on('size-change', () => {
      this.$nextTick(this.onSizeChange)
    })
    this.height = this.$el.offsetHeight
  }
}
</script>
