import {
  getFUn
} from '../auth_lib'

export default {
  ...getFUn('wage'),
  input: {
    funKey: 'input',
    name: '导入',
    apis: [{
      path: '/basis/import',
      method: 'POST'
    }]
  },
  outPut: {
    funKey: 'outPut',
    name: '导出'
  },
  outPutP: {
    funKey: 'outPutP',
    name: '导出模版'
  },
  clear: {
    funKey: 'clear',
    name: '清空',
    apis: [{
      path: '/basis/wage/clear',
      method: 'POST'
    }]
  }
}