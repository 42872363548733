import {
  getFUn
} from '../auth_lib'

export default {
  ...getFUn('worker_auth'),
  add_all: {
    funKey: 'add_all',
    name: '新增',
    apis: [{
      path: '/basis/worker_auth',
      method: 'POST'
    }]
  },
  edit_all: {
    funKey: 'edit_all',
    name: '修改',
    apis: [{
      path: '/basis/worker_auth/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/worker_auth/{id}',
      method: 'DELETE'
    }]
  }
}