function getComponents() {
  const files = require.context('./', false, /\.vue$/)
  return files.keys().map(key => files(key).default)
}

export default {
  install(Vue) {
    getComponents().forEach(component => {
      Vue.component(component.name, component)
    })
  }
}