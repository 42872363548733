export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/appraisal',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/basis/appraisal',
      method: 'POST'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/basis/appraisal/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/appraisal/{id}',
      method: 'DELETE'
    }]
  },
  result: {
    funKey: 'result',
    name: '结果',
    apis: [{
      path: '/basis/appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_result',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_result/detail',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_result/calculate/{id}',
      method: 'POST'
    }]
  },
  otherAction: {
    funKey: 'otherAction',
    name: '其他操作',
    apis: [{
      path: '/basis/appraisal/status/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/appraisal/re_start/{id}',
      method: 'PUT'
    }]
  },
  ready: {
    funKey: 'ready',
    name: '设置',
    apis: [{
      path: '/basis/appraisal',
      method: 'GET'
    },
    {
      path: '/basis/appraisal/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/appraisal/file/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/appraisal_item',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_item',
      method: 'POST'
    },
    {
      path: '/basis/appraisal_item/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_item/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/appraisal_group',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_group',
      method: 'POST'
    },
    {
      path: '/basis/appraisal_group/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/appraisal_group/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/appraisal_group_detail',
      method: 'GET'
    },
    {
      path: '/basis/appraisal_group_detail',
      method: 'POST'
    },
    {
      path: '/basis/appraisal_group_detail/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/appraisal_group_detail/{id}',
      method: 'DELETE'
    }]
  }
}