export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/worker/detail',
      method: 'GET'
    }]
  },
  getMy: {
    funKey: 'getMy',
    name: '查看我的',
    apis: [{
      path: '/basis/worker/detail/my',
      method: 'GET'
    }]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '查看本机构下的',
    apis: [{
      path: '/basis/worker/detail/my_org',
      method: 'GET'
    }]
  },
  getMyOrgContainChild: {
    funKey: 'getMyOrgContainChild',
    name: '查看本机构及其子机构下的',
    apis: [{
      path: '/basis/worker/detail/my_org_contain_child',
      method: 'GET'
    }]
  },
  idcard_file: {
    funKey: 'idcard_file',
    name: '查看扫描件'
  },
  file_update: {
    funKey: 'file_update',
    name: '修改扫描件',
    apis: [{
      path: '/basis/worker/{id}',
      method: 'PUT'
    }]
  }
}