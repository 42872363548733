/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Thu Apr 09 2020
 */
import { XDate } from '../components/FmDatePicker/lib'
export default {
  name: '$datetime',
  methods: {
    format (time, str) {
      return new XDate(time).format(str)
    },
    strToDate (str) {
      return new XDate(str)
    },
    Date: XDate
  }
}