export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/assets_repair',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/internalcontrol/assets_repair',
      method: 'POST'
    },
    {
      path: '/internalcontrol/assets',
      method: 'GET'
    }]
  },
  edit: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/internalcontrol/assets_repair/{id}',
      method: 'PUT'
    },
    {
      path: '/internalcontrol/assets',
      method: 'GET'
    }]
  },
  apply: {
    funKey: 'apply',
    name: '相关申请',
    apis: [{
      path: '/internalcontrol/status_manage/config',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_repair_apply',
      method: 'GET'
    }]
  },
  start: {
    funKey: 'start',
    name: '开始维修',
    apis: [{
      path: '/internalcontrol/assets_repair/{id}',
      method: 'PUT'
    }]
  },
  over: {
    funKey: 'over',
    name: '完成维修',
    apis: [{
      path: '/internalcontrol/assets_repair/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/internalcontrol/assets_repair/{id}',
      method: 'DELETE'
    }]
  }
}