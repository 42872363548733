export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/activity',
      method: 'GET'
    },
    {
      path: '/basis/activity_type',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    }]
  },
  getMy: {
    funKey: 'getMy',
    name: '查看我的',
    apis: [{
      path: '/basis/activity/by_auth',
      method: 'GET'
    },
    {
      path: '/basis/activity_type',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    }]
  },
  new: {
    funKey: 'new',
    name: '开展新活动',
    apis: [{
      path: '/basis/activity',
      method: 'POST'
    }]
  },
  start: {
    funKey: 'start',
    name: '开始',
    apis: [{
      path: '/basis/activity/{id}',
      method: 'PUT'
    }]
  },
  end: {
    funKey: 'end',
    name: '结束',
    apis: [{
      path: '/basis/activity/{id}',
      method: 'PUT'
    }]
  },
  restart: {
    funKey: 'restart',
    name: '重新开展',
    apis: [{
      path: '/basis/activity/{id}',
      method: 'PUT'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '活动明细',
    apis: [{
      path: '/basis/activity',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'update',
    name: '修改',
    apis: [{
      path: '/basis/activity/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/activity/{id}',
      method: 'DELETE'
    }]
  }
}