<style lang="less">
  .fm-tab-pane {
    width: 100%;
    flex-shrink: 0;
  }
</style>

<template>
  <div class="fm-tab-pane">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FmTabPane',
  data () {
    return {
      index: this.name
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: [String, Number],
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  inject: ['FmTabs']
}
</script>
