var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fm-tabs",class:{
  [`fm-tabs-${_vm.size}`]: true,
  'fm-tabs-full': _vm.full !== false
}},[_c('div',{staticClass:"fm-tabs-nav"},_vm._l((_vm.navs),function(nav,i){return _c('div',{key:_vm.navUpdateKey + '-' + i,staticClass:"fm-tabs-nav-item",class:{
      'fm-tabs-nav-active': nav.index === _vm.currentIndex,
      'fm-tabs-nav-disabled': nav.disabled !== false
    },on:{"click":function($event){return _vm.updateIndex(nav.disabled !== false ? _vm.currentIndex : nav.index)}}},[_vm._v(_vm._s(nav.title))])}),0),_c('div',{staticClass:"fm-tabs-content",style:(_vm.contentStyle)},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }