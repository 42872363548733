<style scoped></style>

<template>
  <div>
    <h2>级联动选择(<fm-tag>fm-cascader</fm-tag>)</h2>
    <div class="flex">
      <fm-cascader :data-list="data" clearable placeholder="可清空"></fm-cascader>
      <fm-cascader :data-list="data" disabled clearable placeholder="不可用"></fm-cascader>
    </div>
    <h2>单节点选择(<fm-tag>single</fm-tag>)</h2>
    <div class="flex">
      <fm-cascader :data-list="data" single placeholder="单选"></fm-cascader>
    </div>
    <h2>单选任意节点(<fm-tag>anyitem</fm-tag>)</h2>
    <div class="flex">
      <fm-cascader :data-list="data" single anyitem placeholder="单选任意层级"></fm-cascader>
    </div>
    <h2>异步加载子节点(<fm-tag>data-load</fm-tag>)</h2>
    <div class="flex">
      <fm-cascader :data-list="[{label: '更多', value: 1}]" :load-data="loadData" placeholder="异步加载子节点"></fm-cascader>
    </div>
    <h2>双向绑定</h2>
    <div class="flex">
      <fm-cascader :data-list="data" single v-model="value1" placeholder="单选"></fm-cascader>
      <fm-cascader :data-list="data" v-model="value2" placeholder="层级选"></fm-cascader>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmCascaderDemo',
  data() {
    return {
      value1: 'tiantan',
      value2: ['jiangsu', 'suzhou', 'zhuozhengyuan'],
      data: [{
        value: 'beijing',
        label: '北京',
        children: [
          {
            value: 'gugong',
            label: '故宫'
          },
          {
            value: 'tiantan',
            label: '天坛'
          },
          {
            value: 'wangfujing',
            label: '王府井'
          },
          {
            value: 'qita',
            label: '其他',
            disabled: true
          }
        ]
      }, {
        value: 'jiangsu',
        label: '江苏',
        children: [
          {
            value: 'nanjing',
            label: '南京',
            children: [
              {
                value: 'fuzimiao',
                label: '夫子庙',
              }
            ]
          },
          {
            value: 'suzhou',
            label: '苏州',
            children: [
              {
                value: 'zhuozhengyuan',
                label: '拙政园'
              },
              {
                value: 'shizilin',
                label: '狮子林',
              }
            ]
          }
        ],
      }]
    }
  },
  methods: {
    loadData (item) {
      console.log(item)
      return new Promise(resolve => {
        setTimeout(() => {
          resolve([...new Array(3)].map((v, index) => {
            return {
              label: item.label + '-' + index,
              value: item.label + '-' + index
            }
          }))
        }, 1000 * 3)
      })
    }
  }
}
</script>
