export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [
      { method: 'GET', path: '/internalcontrol/budget' },
      { method: 'GET', path: '/internalcontrol/budget/year/my' },
      { method: 'GET', path: '/internalcontrol/budget/year' },
      { method: 'GET', path: '/internalcontrol/goods' },
      { method: 'GET', path: '/internalcontrol/status_manage/switch_config' },
      { method: 'GET', path: '/ucenter/org/all' },
      { method: 'GET', path: '/ucenter/user' },
      { method: 'GET', path: '/internalcontrol/assets_type/tree' },
      { method: 'GET', path: '/internalcontrol/status_manage/config' }
    ]
  },
  viewFile: {
    funKey: 'viewFile',
    name: '查看会议材料',
    apis: [
      { method: 'GET', path: '/fms/file' },
      { method: 'GET', path: '/fms/download' }
    ]
  },
  detail: {
    funKey: 'detial',
    name: '明细',
    apis: [
      { method: 'GET', path: '/internalcontrol/budget/detail/by_auth' },
      { method: 'GET', path: '/internalcontrol/budget/detail' },
      { method: 'POST', path: '/internalcontrol/budget/detail' },
      { method: 'PUT', path: '/internalcontrol/budget/detail/{id}' },
      { method: 'DELETE', path: '/internalcontrol/budget/detail/{id}' },
      { method: 'POST', path: '/internalcontrol/budget/detail/switch/{id}' },
      
      { method: 'GET', path: '/internalcontrol/goods' },
      { method: 'POST', path: '/internalcontrol/goods' },
      { method: 'PUT', path: '/internalcontrol/goods/{id}' },

      { method: 'GET', path: '/internalcontrol/budget' },
      { method: 'GET', path: '/internalcontrol/budget/my_org' },
      { method: 'GET', path: '/internalcontrol/budget/all' },

      { method: 'GET', path: '/internalcontrol/budget/year' },
      { method: 'GET', path: '/internalcontrol/assets_in' },
      { method: 'GET', path: '/internalcontrol/purchase/year_count' },
    ]
  }
}