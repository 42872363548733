<template>
	<div class="fm-table-search-his">
		<a href="javascript:;"
			class="fm-btn-s"
			:class="{'fm-btn_active': item.active, 'fm-btn_disabled': !item.active}"
			:key="index"
			v-for="(item, index) in hisSearchData"
			@click="changeActive(item)">
			{{item.label + '：' + item.valueText}}
			<i class="iconfont icon-guanbi" @click.stop="delData(item.field)"></i>
			</a>
	</div>
</template>

<script>
const asd = 'wtf'

export default {
	name: 'fm-table-search-his',
	props: {
		searchData: {
			type: Object,
			default: () => {
				return {}
			}
		},
		searchParm: {
			type: Array,
			default: () => {
				return []
			},
			validator: (data) => {
				let pass = true
				if (pass) {
					data.forEach((item) => {
						pass = pass && typeof item.field === 'string' && typeof item.title === 'string'
					})
				}
				return pass
			}
		}
	},
	data () {
		return {
			hisSearchData: [],
			wtd: asd
		}
	},
	created () {
		this.$watch('searchData', () => {
			this.updateHisSearchData()
		}, {
			deep: true
		})
	},
	methods: {
		changeActive (data) {
			this.hisSearchData.forEach((item) => {
				if (item.field === data.field) {
					item.active = !item.active
				}
			})
			this.emitChange()
		},
		emitChange () {
			let newSearchData = {}
			this.hisSearchData.forEach((item) => {
				if (item.active) {
					newSearchData[item.field] = item.value
				}
			})
			this.$emit('searchDataChange', newSearchData)
		},
		delData (field) {
			let newData = []
			this.hisSearchData.forEach((item) => {
				if (item.field !== field) {
					newData.push(item)
				}
			})
			this.hisSearchData = newData
			this.emitChange()
		},
		updateHisSearchData () {
			let data = []
			this.searchParm.forEach((item) => {
				let his = false
				let searchItem = this.searchData[item.field]
				let searchHisItem = {
					field: item.field,
					label: item.title,
					value: searchItem,
					valueText: ''
				}
				if (searchItem && typeof searchItem === 'object') {
					if (searchItem[0] !== null) {
						searchHisItem.valueText += searchItem[0]
						his = true
					}
					if (searchItem[1] !== null) {
						searchHisItem.valueText += ('~' + searchItem[1])
						his = true
					}
				} else if (searchItem) {
					searchHisItem.valueText = searchItem === 'fmNull' ? '为空' : searchItem
					his = true
				}
				if (his) {
					data.push(searchHisItem)
				}
			})
			this.hisSearchData.forEach((item) => {
				let inNew = false
				data.forEach((itemData) => {
					if (item.field === itemData.field) {
						item.active = true
						item.value = itemData.value
						item.valueText = itemData.valueText
						itemData.have = true
						inNew = true
					}
				})
				if (!inNew) {
					item.active = false
				}
			})
			data.forEach((itemData) => {
				if (!itemData.have) {
					this.hisSearchData.push({
						field: itemData.field,
						value: itemData.value,
						active: true,
						valueText: itemData.valueText,
						label: itemData.label
					})
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
@import './styles/values.less';
.fm-table-search-his {
	flex: 1;
	display: flex;
	align-items: center;
	.iconfont {
		margin-top: 0.2rem;
		margin-left: 0.3rem;
		line-height: 1;
		font-size: 0.8rem;
	}
	a {
		margin-right: 0.5rem;
	}
}
.fm-btn {
	white-space:nowrap;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: @size-border-radius;
}

.fm-btn-s {
	.fm-btn;
	height: 1.3rem;
	padding: 0 0.2rem;
	font-size: @size-font-mini;
	line-height: 1;
}

.fm-btn_active {
	background-color: #feb37f;
	color: #fff;
}

.fm-btn_active:hover {
	background: #fe954c;
}

.fm-btn_disabled {
	background-color: red;
	color: @color-disabled-text;
}
</style>
