<style lang="less">
  @import './demo.less';
</style>

<template>
  <div>
    <h2>面板分割fm-split</h2>
    <div style="width: 200px;height: 100px;position: relative;">
      <fm-split>
        <div slot="left">AAA</div>
        <div slot="right">BBB</div>
      </fm-split>
    </div>
    <h2>上下分割（<fm-tag>mode="vertical"</fm-tag>）</h2>
    <div style="width: 200px;height: 100px;position: relative;">
      <fm-split mode="vertical">
        <div slot="left">AAA</div>
        <div slot="right">BBB</div>
      </fm-split>
    </div>
    <h2>嵌套</h2>
    <div style="width: 200px;height: 100px;position: relative;">
      <fm-split>
        <fm-split mode="vertical" slot="left">
          <div slot="left">AAA</div>
          <div slot="right">BBB</div>
        </fm-split>
        <div slot="right">CCC</div>
      </fm-split>
    </div>
    <h2>设置最大与最小值（<fm-tag>:max="0.7"</fm-tag><fm-tag>:min="0.1"</fm-tag>） - {{v}}</h2>
    <div style="width: 200px;height: 100px;position: relative;">
      <fm-split mode="vertical" :max="0.7" :min="0.1" v-model="v">
        <div slot="left">AAA</div>
        <div slot="right">BBB</div>
      </fm-split>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmSplitDemo',
  data () {
    return {
      v: 0.5
    }
  }
}
</script>
