/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Sat Feb 08 2020
 */

/**
 * 数据验证的场景
 * 规则验证
 * 多规则验证
 * 非空验证和非空规则验证
 * 值可选且有值时验证
 * 多种错误提示
 */

const verifyDefaultRegular = {
  date: /^\d{4}\-\d{1,2}\-\d{1,2}$/,
  datetime: /^\d{4}\-\d{1,2}\-\d{1,2}\s\d{1,2}:\d{1,2}:\d{1,2}$/,
  time: /^\d{1,2}:\d{1,2}:\d{1,2}$/,
  phone: /^1\d{10}$/,
  email: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
  number: /(^[\-0-9][0-9]*(.[0-9]+)?)$/,
  integer: /^\d*$/
}

class VerifyError {
  constructor (msg) {
    this.message = msg
  }
}

const ruleCheck = function (data, verify) {
  if (typeof verify === 'object') {
    if (verify.type === String) {
      if (typeof data !== 'string') {
        throw new VerifyError(verify.message || '只能输入文字')
      }
      if (verify.max && data.length > verify.max) {
        throw new VerifyError(verify.message || `最少输入${verify.max}个字`)
      }
      if (verify.min && data.length < verify.min) {
        throw new VerifyError(verify.message || `最多输入${verify.min}个字`)
      }
    } else if (verify.type === Array) {
      if (verify.max && data.length > verify.max) {
        throw new VerifyError(verify.message || `最多选择${verify.max}个`)
      }
      if (verify.min && data.length < verify.min) {
        throw new VerifyError(verify.message || `最少选择${verify.min}个`)
      }
    } else if (verify.type === Number || verify.type === 'number') {
      if (verify.type === Number && typeof data !== 'number') {
        throw new VerifyError(verify.message || '只能输入数字')
      }
      if (verify.type === 'number' && isNaN(data)) {
        throw new VerifyError(verify.message || '只能输入数字')
      }
      if (verify.max && data > verify.max) {
        throw new VerifyError(verify.message || `不能大于${verify.max}`)
      }
      if (verify.min && data > verify.min) {
        throw new VerifyError(verify.message || `不能小于${verify.max}`)
      }
    } else if (verify.type === 'integer') {
      if (verify.type === Number && typeof data !== 'number') {
        throw new VerifyError(verify.message || '只能输入自然数')
      }
      if (!verifyDefaultRegular.integer.test(data)) {
        throw new VerifyError(verify.message || '只能输入自然数')
      }
      if (verify.max && data > verify.max) {
        throw new VerifyError(verify.message || `不能大于${verify.max}`)
      }
      if (verify.min && data > verify.min) {
        throw new VerifyError(verify.message || `不能小于${verify.max}`)
      }
    } else if (verify.type === Date) {
      if (!(data instanceof Date)) {
        throw new VerifyError(verify.message || '日期格式错误')
      }
    } else if (verify.type === 'date') {
      if (!verifyDefaultRegular.date.test(data)) {
        throw new VerifyError(verify.message || '日期格式错误')
      }
    } else if (verify.type === 'datetime') {
      if (!verifyDefaultRegular.datetime.test(data)) {
        throw new VerifyError(verify.message || '日期格式错误')
      }
    } else if (verify.type === 'time') {
      if (!verifyDefaultRegular.time.test(data)) {
        throw new VerifyError(verify.message || '时间格式错误')
      }
    } else if (verify.type === 'phone') {
      if (!verifyDefaultRegular.phone.test(data)) {
        throw new VerifyError(verify.message || '手机号错误')
      }
    } else if (verify.type === 'email') {
      if (!verifyDefaultRegular.email.test(data)) {
        throw new VerifyError(verify.message || '邮箱格式错误')
      }
    } else if (verify.type === 'regular') {
      if (!verify.regular.test(data)) {
        throw new VerifyError(verify.message || '验证未通过')
      }
    }
  }
}

export default {
  name: '$verifier',
  methods: {
    check (data, required, verify) {
      try {
        if (required === true || required === undefined) {
          if (data === null || data === undefined || data === '' || (Array.isArray(data) && data.length === 0)) {
            throw new VerifyError('不能为空')
          }
        } else if (!data) {
          return ''
        }
    
        if (Array.isArray(verify)) {
          verify.forEach(v => {
            ruleCheck(data, v)
          })
        } else if (verify) {
          ruleCheck(data, verify)
        }
        return ''
      } catch (error) {
        return error.message
      }
    },
    get regular () {
      return verifyDefaultRegular
    }
  }
}
