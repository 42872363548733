import '../components/styles/verifier.less'
import Verifier from '../methods/verifier'

/**
 * 设计原则
 * 1. 便于单条表单验证
 * 2. 便于统一验证
 * 3. 有错误效果
 * 4. 验证触发机制
 * 5. 能获取错误消息
 * 6. 多种验证
 */

/**
  * 验证方式
  * 1. 验证规则指定方式(prop)
  * 2. 错误触发机制(事件|回调|状态)
  * 3. 验证结果获取
  */

/**
 * 实现方式
 * 指令实现错误效果的展示 (需要验证指令是否能够读取到验证规则等)
 */

function resultWrapDispose (result) {
  result ? this.classList.add('verifier-error') : this.classList.remove('verifier-error')
  let msg = this.querySelector('.verifier-message')
  if (!result) {
    msg && msg.remove()
  } else {
    if (this.style.position === '' && getComputedStyle(this).position === 'static') {
      this.style.position = 'relative'
    }
    if (!msg) {
      msg = document.createElement('div')
      msg.classList.add('verifier-message')
      this.appendChild(msg)
    }
    msg.innerText = result
  }
}

function dispatchCheckEvent (sourceEvent, result) {
  let event = document.createEvent('Event')
  event.initEvent('verifier')
  event.result = result
  event.trigger = sourceEvent ? sourceEvent.type : 'call'
  this.dispatchEvent(event)
}

function triggerBinding (trigger) {
  if (Array.isArray(trigger)) {
    trigger.forEach(v => {
      triggerBinding.call(this, v)
    })
  } else if (typeof trigger === 'string') {
    this.addEventListener(trigger, this.verifier.check)
  }
}

export default {
  name: 'verifier',
  inserted: function (el, {value}, vnode) {
    const tagName = vnode.tag.toLowerCase()
    if (tagName === 'input') {
      el.verifier = {
        rules: value ? value.rules : undefined,
        check: (function (event) {
          const result = Verifier.methods.check(this.value, this.required, this.verifier.rules)
          resultWrapDispose.call(this.parentElement, result)
          dispatchCheckEvent.call(this, event, result)
          return result
        }).bind(el)
      }
      triggerBinding.call(el, value ? (value.trigger || 'input') : 'input')
    } else {
      const pvnode = vnode.componentInstance.$parent.$el
      if (vnode.componentInstance.required && pvnode.classList.contains('fm-form-item')) {
        vnode.componentInstance.$parent.componentRequired = true
      }
      vnode.componentInstance.$on('verifier', (result) => {
        resultWrapDispose.call(el, result)
      })
    }
    if (['input'].includes(tagName)) {
      let event = document.createEvent('Event')
      event.initEvent('v-inserted')
      el.dispatchEvent(event)
    }
  },
  update (el, {value}, vnode, oldVnode) {
    if (el.value !== oldVnode.elm.value) {
      const tagName = vnode.tag.toLowerCase()
      if (['input'].includes(tagName)) {
        el.verifier.check()
      }
    }
  }
}