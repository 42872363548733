export default {
  routerNames: ['wages.config.default'],
  funs: {
    manage: {
      funKey: 'manage',
      name: '管理',
      apis: [
        { method: 'GET', path: '/performance/wage_month_worker_detail_default' },
        { method: 'PUT', path: '/performance/wage_month_worker_detail_default/{id}' },
        { method: 'DELETE', path: '/performance/wage_month_worker_detail_default/{id}' },
        { method: 'POST', path: '/performance/wage_month_worker_detail_default' }
      ]
    }
  }
}