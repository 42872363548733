<style scoped></style>

<template>
  <div class="fm-badge"></div>
</template>

<script>
export default {
  name: 'FmBadge'
}
</script>
