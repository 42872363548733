export default {
  base: {
    funKey: 'base',
    name: '基础',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    },
    {
      path: '/basis/status_manage/config',
      method: 'GET'
    },
    {
      path: '/basis/ask_leave_apply',
      method: 'GET'
    },
    {
      path: '/basis/ask_leave_apply/by_auth',
      method: 'GET'
    },
    {
      path: '/basis/ask_leave_apply/{id}',
      method: 'DELETE'
    },
    {
      path: '/basis/ask_leave_apply/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/ask_leave_apply/assign/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/ask_leave_apply/switch/{id}',
      method: 'POST'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增申请',
    apis: [{
      path: '/basis/ask_leave_apply',
      method: 'POST'
    }]
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取扫描件'
  },
  updateDataFile: {
    funKey: 'updateDataFile',
    name: '上传扫描件',
    apis: [{
      path: '/basis/ask_leave_apply/file_ids/{id}',
      method: 'PUT'
    }]
  },
  delDataFile: {
    funKey: 'delDataFile',
    name: '删除扫描件',
    apis: [{
      path: '/basis/ask_leave_apply/file_ids/{id}',
      method: 'PUT'
    }]
  }
}