<template>
  <div class="fm-cascader" :class="{
    [`fm-cascader-${size}`]: true,
    'fm-cascader-open': isOpen,
    'fm-cascader-clearable': clearable !== false,
    'fm-cascader-disabled': disabled !== false
  }">
    <div class="fm-cascader-wrap" @click="toogle">
      <input class="fm-cascader-input" :placeholder="placeholder" readonly type="text" v-model="currentValue"/>
      <i class="fmico fmico-top-arrow fm-cascader-right-icon"></i>
      <i class="fmico fmico-error-solid fm-cascader-right-icon" @click.stop.self="clearData"></i>
    </div>
    <div class="fm-cascader-section" :tabindex="0" ref="section" @blur="onBlur">
      <ul v-for="(items, i) in sectionList" :key="i + '-' + sectionKey">
        <li v-for="item in items" :class="{
          'fm-cascader-nochild': !item.children || item.children.length < 1,
          'fm-cascader-selected': single !== false ? (data && data.value === item.value) : (data && data[i] && data[i].value === item.value),
          'fm-cascader-item-disabled': item.disabled !== undefined && item.disabled !== false,
          'fm-cascader-item-loading': item.loading
        }" :key="item.value" @click="setSublist(i, item)"
        >{{item.label}}</li>
      </ul>
    </div>
  </div>
</template>

<script>

// 逐层匹配
function layerMatch (args, list) {
  let result = []
  for (let item of list) {
    if (item.value === args[0]) {
      result = [item]
    }
    if (item.children && item.children.length) {
      result = [...result, ...layerMatch(args.slice(1), item.children)]
    }
  }
  return result
}

// 单条路径匹配
function singlePathMatch (value, list) {
  let result = []
  for (let item of list) {
    if (item.value === value) {
      result = [item]
    }
    if (item.children && item.children.length) {
      let res = singlePathMatch(value, item.children)
      if (res && res.length) {
        result = [item, ...res]
      }
    }
  }
  return result
}

function getData (value, list) {
  if (Array.isArray(value)) {
    const res = layerMatch(value, list)
    if (res && res.length) {
      return res.map(v => {
        return { label: v.label, value: v.value }
      })
    } else {
      return value.map(v => {
        return { label: v, value: v }
      })
    }
  } else {
    let res = singlePathMatch(value, list)
    if (res && res.length) {
      return res.pop()
    } else {
      return {label: value, value: value}
    }
  }
}

function wrapItemList (list) {
  return JSON.parse(JSON.stringify(list)).map(v => {
    return {
      label: v.label,
      value: v.value,
      children: v.children || [],
      disabled: v.disabled,
      loading: false
    }
  })
}

export default {
  name: 'FmCascader',
  data () {
    return {
      isOpen: false,
      sectionFocus: false,
      sectionKey: 0,
      itemList: wrapItemList(this.dataList),
      data: getData(this.value, wrapItemList(this.dataList)),
      currentValue: null,
      sectionList: []
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    anyitem: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      default: function () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'norm',
      validator: function(size) {
        return ['norm', 'mini', 'small', 'large'].includes(size)
      }
    },
    clearable: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Number, String],
      default () {
        return this.single !== false ? null : []
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    formater: {
      type: Function,
      default: function (value) {
        if (this.single !== false) {
          return value ? (value.label || '') : ''
        } else {
          return value ? value.map(v => v.label || '').join(' / ') : ''
        }
      }
    },
    loadData: {
      type: Function,
      default: undefined
    }
  },
  watch: {
    dataList: {
      handler () {
        this.itemList = wrapItemList(this.dataList)
        this.sectionList = [this.itemList]
        if (this.value) {
          this.data = getData(this.value, this.itemList)
        } else {
          this.data = getData(this.single !== false ? this.data.value : this.data.map(v => v.value), this.itemList)
        }
      },
      deep: true,
      immediate: true
    },
    value: {
      deep: true,
      handelr (val) {
        this.data = val
      }
    },
    data (data) {
      this.currentValue = this.formater(data)

      let res = null
      if (this.single) {
        res = this.label ? this.data : this.data.value
      } else {
        res = this.label ? this.data : this.data.map(v => v.value)
      }

      this.$emit('change', typeof res === 'object' ? JSON.parse(JSON.stringify(res)) : res)
    },
    isOpen () {
      if (this.value) {
        this.updateDefaultSublist()
      }
    }
  },
  methods: {
    updateDefaultSublist () {
      let res = []
      if (this.single !== false) {
        res = singlePathMatch(this.data.value, this.itemList)
      } else {
        res = layerMatch(this.data.map(v => v.value), this.itemList)
      }
      if (res.length > 1) {
        this.sectionList.splice(1, this.sectionList.length - 1, ...res.slice(0, res.length - 1).map(item => {
          return item.children ? wrapItemList(item.children) : []
        }))
      }
    },
    onBlur () {
      this.sectionFocus = this.isOpen = false
    },
    toogle () {
      if (this.disabled === false) {
        this.isOpen = this.sectionFocus ? false : !this.isOpen
        this.sectionFocus = this.isOpen
        if (this.isOpen) {
          this.$nextTick(() => this.$refs.section.focus())
        }
      }
    },
    async setSublist (index, item) {
      if (disabled) {
        return
      }

      let {children, value, label, disabled} = item
      let hasChild = children && children.length

      if ((!children || !children.length) && typeof this.loadData === 'function') {
        item.loading = true
        let loadRes = this.loadData(item)
        if (loadRes instanceof Promise) {
          loadRes = await loadRes
        }

        if (Array.isArray(loadRes)) {
          // eslint-disable-next-line require-atomic-updates
          item.children = children = loadRes
          hasChild = true
        }
        // eslint-disable-next-line require-atomic-updates
        item.loading = false
      }

      if (hasChild) {
        this.sectionList.splice(index + 1, this.sectionList.length - 1, wrapItemList(children))
      } else {
        this.sectionList.splice(index + 1, this.sectionList.length - 1)
      }

      this.sectionKey += 1

      if (this.single !== false) {
        if (this.anyitem !== false) {
          this.data = {label, value}
          if (!hasChild) {
            this.$refs.section.blur()
          }
        } else if (!hasChild) {
          this.data = {label, value}
          this.$refs.section.blur()
        }
        this.$emit('input', this.data.value)
      } else {
        if (!Array.isArray(this.data)) {
          this.data = []
        }
        this.data.splice(index, 1, {label, value})
        this.data.splice(index + 1)
        if (!children || !children.length) {
          this.$refs.section.blur()
        }
        this.$emit('input', this.data.map(v => v.value))
      }
    },
    clearData () {
      this.data = this.single !== false ? null : []
      this.sectionList.splice(1)
      this.$emit('input', this.single !== false ? null : [])
    }
  }
}
</script>

<style lang="less">
  @import './styles/values.less';
  @keyframes ani-section {
    from {transform: translateY(0);opacity: 0;}
    to {transform: translateY(10px);opacity: 1;}
  }
  @keyframes ani-load-loop {
    from { transform: scale(1) rotate(0deg);}
    50%  { transform: scale(1) rotate(180deg);}
    to   { transform: scale(1) rotate(360deg);}
  }
  .fm-cascader {
    display: inline-flex;
    border-radius: 4px;
    position: relative;
    border: 1px solid @color-border;
    box-shadow: 0 0 0px 3px transparent;
    transition: all .3s;
    box-sizing: border-box;
    &:hover {
      border-color: @color-primary;
    }
    &.fm-cascader-disabled {
      background-color: @color-disabled-background;
      cursor: not-allowed;
      .fm-cascader-wrap {
        .fm-cascader-input {
          color: @color-disabled-text;
        }
      }
      &.fm-cascader-clearable {
        &:hover {
          .fmico.fmico-top-arrow {
            opacity: 1;
            z-index: 1;
          }
          .fmico.fmico-error-solid {
            opacity: 0;
            z-index: 0;
          }
        }
      }
    }
  }
  .fm-cascader-right-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    color: @color-component-text;
    transform: rotateX(180deg) translateY(-50%);
    transform-origin: top;
    cursor: pointer;
    opacity: 0;
    z-index: 0;
    transition: all .3s;
  }
  .fm-cascader-wrap {
    border: 1px solid transparent;
    .fm-cascader-input {
      color: @color-component-text;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .fmico.fmico-top-arrow {
      opacity: 1;
      z-index: 1;
    }
  }
  .fm-cascader-clearable {
    &:hover {
      .fmico.fmico-error-solid {
        opacity: 1;
        z-index: 1;
      }
      .fmico.fmico-top-arrow {
        opacity: 0;
        z-index: 0;
      }
    }
  }
  .fm-cascader-section {
    background-color: #FFF;
    position: absolute;
    top: 100%;
    left: 0;
    flex-direction: row;
    display: none;
    outline: none;
    border: 1px solid @color-border;
    border-radius: 5px;
    z-index: 2;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);

    ul {
      padding: 5px;
      margin: 0;
      & + ul {
        border-left: 1px solid @color-border;
        padding-left: 5px;
      }
      li {
        color: @color-component-text;
        transition: all .3s;
        cursor: pointer;
        padding: 2px 13px 2px 5px;
        min-width: 100px;
        word-break: keep-all;
        white-space: nowrap;
        list-style-type: none;
        display: flex;
        align-items: center;
        position: relative;
        
        &::after {
          content: '';
          width: 4px;
          height: 4px;
          border: 2px solid @color-component-text;
          border-left: 0;
          border-top: 0;
          display: inline-block;
          transform: rotate(-45deg) scale(0);
          transition: all .3s;
          opacity: 0;
          position: relative;
        }

        &.fm-cascader-selected {
            color: @color-primary;
            &::after {
              left: 5px;
              transform: rotate(-45deg) scale(1);
              opacity: 1;
            }
        }

        &.fm-cascader-nochild, &.fm-cascader-nochild.fm-cascader-selected {
          &:hover, & {
            &::after {
              opacity: 0;
            }
          }
        }

        &.fm-cascader-item-disabled {
          color: @color-disabled-text;
          cursor: not-allowed
        }

        &.fm-cascader-item-loading {
          &.fm-cascader-nochild::after, &.fm-cascader-nochild:hover::after, &::after {
            width: 10px; height: 10px;
            border: none;
            background: conic-gradient(@color-border, @color-primary);
            border-radius: 50%;
            left: 5px;
            opacity: 1;
            animation: ani-load-loop 1s linear 0s infinite;
            mask: radial-gradient(transparent 3px, #000 3px);
          }
        }
      }
    }
  }
  .fm-cascader.fm-cascader-open {
    .fm-cascader-wrap {
      box-shadow: 0 0 0px 3px @color-primary-shadow;
      .fmico.fmico-top-arrow {
        transform: rotateX(0deg) translateY(-50%);
      }
    }
    .fm-cascader-section {
      display: flex;
      animation: ani-section .3s linear 0s 1 forwards;
    }
  }

  .fm-cascader-input {
    border: none;
    padding: 0;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 30px;
    outline: none;
    background-color: transparent;
  }

  // 尺寸设置
  .fm-cascader.fm-cascader-norm {
    .fm-cascader-input { line-height: @size-height-norm - 2px; }
    .fm-cascader-section {
      ul {
        li {
          line-height: @size-height-norm;
        }
      }
    }
  }
  .fm-cascader.fm-cascader-mini {
    .fm-cascader-input { line-height: @size-height-mini - 2px; }
    .fm-cascader-section {
      ul {
        li {
          line-height: @size-height-mini;
        }
      }
    }
  }
  .fm-cascader.fm-cascader-small {
    .fm-cascader-input { line-height: @size-height-small - 2px; }
    .fm-cascader-section {
      ul {
        li {
          line-height: @size-height-small;
        }
      }
    }
  }
  .fm-cascader.fm-cascader-large {
    .fm-cascader-input { line-height: @size-height-large - 2px; }
    .fm-cascader-section {
      ul {
        li {
          line-height: @size-height-large;
        }
      }
    }
  }
</style>