/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Link: https://www.slippersclown.com
 * Date: Tue Dec 17 2019
 */
export function getCellsDates (date, type) {
  if (type === 'year') {
    return getYearCells(date)
  } else if (type === 'month') {
    return getMonthCells(date)
  } else {
    return getDateCells(date)
  }
}

export function getMonthCells (date) {
  let month = []
  for (let i = 0; i <= 11; i++) {
    month.push({
      date: new XDate(date.getFullYear(), i, 1)
    })
  }
  return month
}

export function getYearCells (date) {
  let year = []
  for (let i = date.getFullYear() - 4; i <= date.getFullYear() + 4; i++) {
    year.push({
      date: new XDate(i, 0, 1)
    })
  }
  return year
}

export function getDateCells (date) {
  let result = [], year = date.getFullYear(), month = date.getMonth()
  let monthStartDay = new Date(year, month, 1).getDay()
  let monthEndDay = new Date(year, month + 1, 0).getDay()

  for (let i = monthStartDay - 1; i >= 0; i--) {
    result.push({
      date: new XDate(date.getFullYear(), date.getMonth(), -i),
      pre: true
    })
  }

  const monthDays = new Date(year, date.getMonth() + 1, 0).getDate()
  for (let i = 1; i <= monthDays; i++) {
    result.push({
      date: new XDate(date.getFullYear(), date.getMonth(), i)
    })
  }

  for (let i = monthEndDay + 1, l = 1; i <= 6; i++, l++) {
    result.push({
      date: new XDate(date.getFullYear(), date.getMonth() + 1, l),
      next: true
    })
  }
  
  return result
}

export class XDate extends Date {
  get frame () {
    let month = this.getMonth() + 1
    let date = this.getDate()
    let hours = this.getHours()
    let minutes = this.getMinutes()
    let seconds = this.getSeconds()

    return {
      Y: this.getFullYear(),
      m: month,
      M: month < 10 ? '0' + month : month,
      d: date,
      D: date < 10 ? '0' + date : date,
      h: hours,
      H: hours < 10 ? '0' + hours : hours,
      i: minutes,
      I: minutes < 10 ? '0' + minutes : minutes,
      s: seconds,
      S: seconds < 10 ? '0' + seconds : seconds
    }
  }

  constructor() {
    super(...arguments)
  }

  sameDate (date) {
    return date && this.sameYear(date) && this.sameMonth(date) && date.getDate() === this.getDate()
  }

  sameMonth (date) {
    return date && this.sameYear(date) && date.getMonth() === this.getMonth()
  }

  sameYear (date) {
    return date && date.getFullYear() === this.getFullYear()
  }

  copy () {
    return new XDate(this.getTime())
  }

  compare (date, tag) {
    if (!date || !(date instanceof Date)) {
      return undefined
    }

    let index = 'ymdhis'.indexOf(tag.toLowerCase())

    if (!(date instanceof XDate)) {
      date = new XDate(date.getTime())
    }

    if (index !== 0) {
      index = 4 + index * 2
    } else {
      index = 4
    }

    let left = parseInt(this.format().substr(0, index))
    let right = parseInt(date.format().substr(0, index))

    if (left < right) {
      return -1
    } else if (left === right) {
      return 0
    } else {
      return 1
    }
  }

  compute (tag, quantity) {
    if (isNaN(tag) === false) {
      return new XDate(this.getTime() + tag)
    } else {
      const tags = ['Y', 'M', 'D', 'H', 'I', 'S']
      let index = tags.findIndex(v => tag.toUpperCase() === v)
      if (index === undefined) {
        return null
      }
      let d = this.format(tags.join(',')).split(',').map((v, i) => {
        return i === index ? (parseInt(v) + quantity) : parseInt(v)
      })
      d[1] -= 1
      return new XDate(...d)
    }
  }

  format (str = 'YMDHIS') {
    const frame = this.frame
    return str.split('').map(char => frame[char] !== undefined ? frame[char] : char).join('')
  }

  toString() {
    return this.format('Y-M-D H:I:S')
  }
}