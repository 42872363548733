<template>
  <div class="the-breadcrumb" v-if="breadcrumb && breadcrumb.length > 0">
    <div class="breadcrumb-item" :class="{'active-breadcrumb-item': index === breadcrumb.length - 1}" :key="item.id" v-for="(item, index) in breadcrumb">
      <a href="javascript:;" @click="goMenu(item)">{{item.name}}</a><span>/</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheBreadcrumb',
  computed: {
    currentRouterName: {
      get () {
        return this.$store.state.route.name
      }
    },
    availableMenus: {
      get () {
        return this.$store.getters.availableMenus
      }
    },
    breadcrumb: {
      get () {
        return this.inThisMenu(this.availableMenus)
      }
    }
  },
  methods: {
    goMenu (item) {
      if (item.url === this.currentRouterName) {
        return
      }
      if (item.type === 'sys_page') {
        this.$router.push({name: item.url})
      } else if (item.type === 'out_page') {
        this.$store.dispatch('setOutPageUrl', item.url)
        this.$router.push({name: 'business.out_page'})
      } else if (item.type === 'menu' && item.url) {
        this.$router.push({name: item.url})
      }
    },
    inThisMenu (menus) {
      let findMenu = []
      menus.forEach((item) => {
        if (item.url === this.currentRouterName) {
          findMenu = [item]
        } else if (item.children && item.children.length > 0) {
          let isFind = this.inThisMenu(item.children)
          if (isFind.length > 0) {
            findMenu = [item].concat(isFind)
          }
        }
      })
      return findMenu
    }
  },
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
.the-breadcrumb {
  line-height: 1;
  display: flex;
  font-size: @size-font-s;
}
.breadcrumb-item {
  a {
    color: @color-font-sub;
    font-weight: 400;
  }
  span {
    color: @color-font-sub;
    padding: 0 0.5rem;
  }
}
.active-breadcrumb-item {
  a {
    color: @color-font-content;
  }
  span {
    display: none;
  }
}
</style>
