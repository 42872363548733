import { request } from '@/api'

export function getPfmMonthStatusText (status) {
  switch (status) {
    case 'end':
      return '已发布'
    case 'divide':
      return '分配中'
    case 'compute':
      return '计算中'
    case 'plan':
      return '准备中'
    default:
      return '未知状态'
  }
}

const state = {
  pfmUnitList: [],
  pfmOrgList: [],
  pfmPositionList: [],
  pfmProjectTypeList: [],
  pfmMonthList: [],
}

const getters = {
  pfmUnitList: state => state.pfmUnitList,
  pfmOrgList: state => state.pfmOrgList,
  pfmPositionList: state => state.pfmPositionList,
  pfmProjectTypeList: state => state.pfmProjectTypeList,
  pfmMonthList: state => state.pfmMonthList,
  pfmProjectBillType: () => [
    { label: '公卫', value: 1 },
    { label: 'his系统项目', value: 2 }
  ],
  pfmConsumeType: () => [
    { label: '办公用品', value: 'bgyp' },
    { label: '耗材', value: 'hc' }
  ],
  pfmPositionType: () => [
    { label: '行政人员', value: 'xz' },
    { label: '业务人员', value: 'yw' }
  ],
  pfmUnitType: () => [
    { label: '业务', value: 'yw', color: 'blue' },
    { label: '其他', value: 'other', color: 'purple' }
  ],
  pfmSingleType: () => [
    { label: '开单医生', value: 'kdys' },
    { label: '执行人', value: 'zxr' },
    { label: '手术主刀医生', value: 'zdys' },
    { label: '第一助手', value: 'dyzs' }, 
    { label: '麻醉医生', value: 'mzys' },
    { label: '巡回护士', value: 'xhhs' },
    { label: '器械护士', value: 'qxhs' }
  ],
  pfmProjectTypeCategory: () => [
    { label: '收费类别', value: 'sflb' },
    { label: '发票项目', value: 'fpxm' }
  ],
  pfmAwardType: () => [
    { label: '指定值', value: 'value' },
    { label: '收入系数', value: 'income_rate' }
  ]
}

const actions = {
  async loadPfmMonthList ({ commit, getters }, reLoad) {
    const monthList = await request('/performance/pfm_month', 'get')
    if (!getters.pfmMonthList.length || reLoad) {
      commit('setPfmMonthList', monthList.map(v => {
        v.statusText = getPfmMonthStatusText(v.status)
        return v
      }))
    }
    return getters.pfmMonthList
  },
  async loadPfmUnitList ({ commit, getters }, reLoad) {
    if (!getters.pfmUnitList.length || reLoad) {
      const data = await request('/performance/pfm_unit', 'get')
      data.forEach(item => {
        const type = (getters.pfmUnitType.find(v => v.value === item.type) || { label: '未知', color: 'default' })
        item.typeText = type.label
        item.typeColor = type.color
      })
      commit('setPfmUnitList', data)
    }
    return getters.pfmUnitList
  },
  async loadPfmOrgList ({ commit, getters }, reLoad) {
    if (!getters.pfmOrgList.length || reLoad) {
      const data = await request('/performance/pfm_org', 'get')
      commit('setPfmOrgList', data)
    }
    return getters.pfmOrgList
  },
  async loadPfmPositionList ({ commit, getters }, reLoad) {
    if (!getters.pfmPositionList.length || reLoad) {
      const data = await request('/performance/pfm_position', 'get')
      commit('setPfmPositionList', data)
    }
    return getters.pfmPositionList
  },
  async loadPfmProjectTypeList ({ commit, getters }, reLoad) {
    if (!getters.pfmProjectTypeList.length || reLoad) {
      const data = await request('/performance/pfm_project_type', 'get')
      commit('setPfmProjectTypeList', data)
    }
    return getters.pfmProjectTypeList
  }
}

const mutations = {
  setPfmUnitList (state, data) {
    state.pfmUnitList = data
  },
  setPfmOrgList (state, data) {
    state.pfmOrgList = data
  },
  setPfmPositionList (state, data) {
    state.pfmPositionList = data
  },
  setPfmProjectTypeList (state, data) {
    state.pfmProjectTypeList = data
  },
  setPfmProjectSingleList (state, data) {
    state.pfmProjectSingleList = data
  },
  setPfmMonthList (state, data) {
    state.pfmMonthList = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
