<template>
  <div :class="wrapClass">
    <template v-if="type !== 'textarea'">
      <div class="fm-input-new-prefix-prepend" v-if="$slots.prefix || $slots.prepend">
        <div v-if="$slots.prepend" class="fm-input-new-prepend">
          <slot name="prepend"></slot>
        </div>
        <slot v-else name="prefix"></slot>
      </div>
      <input :maxlength="maxlength" :disabled="disabled !== false" :readonly="readonly !== false" ref="input" :value="data" :type="showType" @focus="focus($event)" @blur="onBlur" :placeholder="placeholder" @input="onInput" @change="onChange" @keyup.enter="onEnten"/>
      <div class="fm-input-new-suffix-append" v-if="type === 'password' || clearable !== false || $slots.suffix || $slots.append">
        <div class="fm-input-new-hover-append" v-if="type === 'password' || clearable !== false">
          <i v-if="type === 'password'" :class="passwordCls" @click.stop.self="handlerPwdChange"></i>
          <i v-else-if="clearable !== false" class="fmico fmico-error-solid" @click.stop.self="clearData"></i>
        </div>
        <div v-if="$slots.append" class="fm-input-new-append">
          <slot name="append"></slot>
        </div>
        <slot v-else name="suffix"></slot>
      </div>
    </template>
    <textarea :maxlength="maxlength" :disabled="disabled !== false" :readonly="readonly !== false" v-else :value="data" @focus="focus($event)" @blur="onBlur" :placeholder="placeholder" @input="onInput" @change="onChange"></textarea>
    <div v-if="type === 'textarea' && counts" class="fm-input-counts-wrap">
      <span>{{data ? data.length : 0}}</span><span v-if="maxlength !== undefined">/{{maxlength}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmInputNew',
  data () {
    return {
      data: this.value,
      focusIn: false,
      showPassword: false,
      onError: false
    }
  },
  props: {
    counts: {
      type: Boolean,
      default: undefined
    },
    maxlength: {
      type: Number,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'norm',
      validator: function(size) {
        return ['norm', 'large', 'small', 'mini'].includes(size)
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: [String, Number]
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  computed: {
    passwordCls () {
      return 'fmico ' + (this.showPassword ? 'fmico-eye-close' : 'fmico-eye')
    },
    showType () {
      return this.type === 'password' && this.showPassword ? 'text' : this.type
    },
    wrapClass () {
      return {
        'fm-input-empty': this.data ? false : true,
        'fm-input-new': true,
        ['fm-input-new-' + this.size]: true,
        'fm-textarea': this.type === 'textarea',
        'fm-input-password': this.type === 'password',
        'fm-input-focus': this.focusIn,
        'fm-input-disabled': this.disabled !== false,
        'fm-input-readonly': this.readonly !== false,
        'fm-input-counts': this.counts !== false,
        'verifier-error': this.onError
      }
    }
  },
  methods: {
    focus (event) {
      this.focusIn = true
      this.$emit('focus', event)
    },
    clearData () {
      this.$emit('input', null)
      this.$emit('clear')
      this.updateValue(null)
      this.onChange()
    },
    verifier () {
      const msg = this.$verifier.check(this.data, this.required, this.rules)
      this.$emit('verifier', msg)
      this.onError = msg !== ''
      return msg
    },
    handlerPwdChange () {
      this.showPassword = !this.showPassword
      this.$refs.input.focus()
      const length = (this.data || this.value).length
      setTimeout(() => this.$refs.input.setSelectionRange(length, length), 0)
    },
    onBlur (event) {
      this.$nextTick(this.verifier)
      this.focusIn = false
      this.$emit('blur', event.target.value)
    },
    onInput (event) {
      this.$emit('input', event.target.value)
      this.updateValue(event.target.value)
    },
    onChange () {
      this.$emit('change', this.data)
    },
    onEnten () {
      this.$emit('enten', this.data)
    },
    updateValue (value) {
      if (this.data !== value) {
        this.$nextTick(this.verifier)
        this.data = value
      }
    }
  },
  watch: {
    value (val) {
      this.updateValue(val)
    }
  },
  mounted () {
    this.registerVerifier()
  }
}
</script>

<style lang="less">
  @import "./styles/values.less";
  @input-border-radius: 4px;
  .fm-input-new {
    position: relative;
    border-radius: @input-border-radius;
    transition: all .2s;
    display: inline-flex;
    box-sizing: border-box;
    padding: 0;
    border: 1px solid @color-border;
    align-items: center;
    box-shadow: 0 0 0px 3px transparent;

    textarea, input {
      border: none;
      flex: 1;
      overflow: auto;
      background-color: transparent;
      color: @color-component-text;
      padding: 0 8px;
      &:focus,
      &:active {
        outline: none;
      }
      &::placeholder {
        color: @color-placeholder-font;
      }
    }

    textarea {
      padding: 8px;
      min-height: 60px;
      max-height: 80px;
    }

    .fm-input-new-prefix-prepend, .fm-input-new-suffix-append {
      .fm-btn {
        margin: 0 -5px;
        border: none;
        &:hover {
          border: none;
        }
      }
      .fm-select {
        margin: 0 -5px;
        .fm-select-box {
          border: none;
          padding: 0 4px;
          .fm-select-value {
            padding-right: 20px;
          }
          &:hover {
            border: none;
          }
        }
      }
      .fm-input-new-prepend, .fm-input-new-append {
        background-color: @color-background-base;
        overflow: hidden;
        color: @color-component-text;
        padding: 0 4px;
        position: relative;
      }
      .fm-input-new-prepend {
        border-right: 1px solid @color-border;
        border-top-left-radius: @input-border-radius;
        border-bottom-left-radius: @input-border-radius;
      }
      .fm-input-new-append {
        border-left: 1px solid @color-border;
        border-top-right-radius: @input-border-radius;
        border-bottom-right-radius: @input-border-radius;
      }
      i.fmico {
        color: @color-placeholder-font;
      }
      & > i.fmico {
        &:first-child {
          margin-left: 4px;
        }
        &:last-child {
          margin-right: 4px;
        }
      }
    }
    .fm-input-new-prefix-prepend + input {
      padding-left: 4px;
    }

    &.fm-input-counts {
      textarea {
        padding-bottom: 20px;
      }
    }

    .fm-input-new-hover-append {
      opacity: 0;
      z-index: 0;
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      i.fmico-eye, i.fmico-eye-close, i.fmico-error-solid {
        cursor: pointer;
      }
    }

    &.fm-input-focus, &:hover {
      border-color: @color-primary;
      .fm-input-new-hover-append {
        opacity: 1;
        z-index: 1;
        & ~ * {
          z-index: 0;
          opacity: 0;
        }
      }
    }

    &.fm-input-empty {
      &.fm-input-focus, &:hover {
        .fm-input-new-hover-append {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &.fm-input-focus {
      box-shadow: 0 0 0px 3px @color-primary-shadow;
    }

    &.fm-input-disabled {
      color: @color-disabled-text;
      background-color: @color-disabled-background;
      &:hover {
        border-color: @color-border;
      }
    }

    .fm-input-counts-wrap {
      position: absolute;
      bottom: 1px;
      font-size: @size-font-small;
      color: @color-placeholder-font;
      right: 5px;
      z-index: 1;
    }

    // 大小设置
    &.fm-input-new-large {
      .fm-input-new-prepend, .fm-input-new-append, input {
        height: @size-height-large - 2px;
        line-height: @size-height-large - 2px;
      }
      textarea {
        min-height: @size-height-large - 2px;
      }
      textarea, input {
        font-size: @size-font-large;
      }
    }
    &.fm-input-new-norm {
      .fm-input-new-prepend, .fm-input-new-append, input {
        height: @size-height-norm - 2px;
        line-height: @size-height-norm - 2px;
      }
      textarea {
        min-height: @size-height-norm - 2px;
      }
      textarea, input {
        font-size: @size-font-norm;
      }
    }
    &.fm-input-new-small {
      .fm-input-new-prepend, .fm-input-new-append, input {
        height: @size-height-small - 2px;
        line-height: @size-height-small - 2px;
      }
      textarea {
        min-height: @size-height-small - 2px;
      }
      textarea, input {
        font-size: @size-font-small;
      }
    }
    &.fm-input-new-mini {
      .fm-input-new-prepend, .fm-input-new-append, input {
        height: @size-height-mini - 2px;
        line-height: @size-height-mini - 2px;
      }
      textarea {
        min-height: @size-height-mini - 2px;
      }
      textarea, input {
        font-size: @size-font-mini;
      }
    }

    // 报错设置
    &.verifier-error {
      border-color: @color-fail;
      &.fm-input-focus {
        box-shadow: 0 0 0px 3px @color-fail-shadow;
      }
    }
  }
</style>