<template>
  <div class="fm-radio-group" :class="{
    'fm-radio-group-vertical': vertical === undefined || vertical === true
  }">
    <slot></slot>
  </div>
</template>

<script>
let radioSeek = 0
const radioGroupUuid = () => `fm-radio-name-${Date.now()}-${radioSeek++}`

export default {
  name: 'FmRadioGroup',
  components: {},
  data() {
    return {
      currentValue: this.value
    }
  },
  props: {
    vertical: {
      type: Boolean,
      default: false
    },
    name: {type: String, default: radioGroupUuid},
    value: {type: [Boolean, Number, String], default: null}
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  computed: {},
  methods: {
    change ({value}) {
      this.$emit('input', value)
      this.currentValue = value
      this.$nextTick(this.updateValue)
      this.$emit('change', value)
    },
    updateValue () {
      const radios = this.$children.filter(v => v._isVue && v.$el.classList.contains('fm-radio'))
      radios.forEach(radio => {
        radio.currentValue = radio.value === this.currentValue
      })
    }
  },
  watch: {
    value: {
      handler () {
        if (this.currentValue !== this.value) {
          this.currentValue = this.value
          this.$nextTick(this.updateValue)
          this.$emit('change', this.value)
        }
      },
      immediate: true
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.updateValue()
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
  .fm-radio-group.fm-radio-group-vertical {
    .fm-radio + .fm-radio {
      margin-left: unset;
      margin-top: 10px;
    }
  }
</style>

<style lang="less" scoped>
  .fm-radio-group {
    display: flex;
    &.fm-radio-group-vertical {
      display: inline-flex;
      flex-direction: column;
    }
  }
</style>