export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [
      { method: 'GET', path: '/internalcontrol/budget/detail/info' }
    ]
  },
  getByAuth: {
    funKey: 'getByAuth',
    name: '查看权限内的',
    apis: [
      { method: 'GET', path: '/internalcontrol/budget/detail/info/by_auth' }
    ]
  }
}