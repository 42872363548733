export default {
  get: {
    funKey: 'base',
    name: '基础功能',
    apis: [{
      path: '/basis/worker/edit',
      method: 'GET'
    },
    {
      path: '/basis/worker_edu/edit',
      method: 'GET'
    },
    {
      path: '/basis/worker_edu_continue/edit',
      method: 'GET'
    },
    {
      path: '/basis/his_work/edit',
      method: 'GET'
    },
    {
      path: '/basis/rew_pun/edit',
      method: 'GET'
    },
    {
      path: '/basis/train/edit',
      method: 'GET'
    },
    {
      path: '/basis/worker_certificate/edit',
      method: 'GET'
    },
    {
      path: '/basis/worker_job_title/edit',
      method: 'GET'
    },
    {
      path: '/basis/worker/edit',
      method: 'POST'
    },
    {
      path: '/basis/worker_edu/edit',
      method: 'POST'
    },
    {
      path: '/basis/worker_edu_continue/edit',
      method: 'POST'
    },
    {
      path: '/basis/his_work/edit',
      method: 'POST'
    },
    {
      path: '/basis/rew_pun/edit',
      method: 'POST'
    },
    {
      path: '/basis/train/edit',
      method: 'POST'
    },
    {
      path: '/basis/worker_certificate/edit',
      method: 'POST'
    },
    {
      path: '/basis/worker_job_title/edit',
      method: 'POST'
    },
    {
      path: '/basis/worker/edit',
      method: 'PUT'
    },
    {
      path: '/basis/worker_edu/edit',
      method: 'PUT'
    },
    {
      path: '/basis/worker_edu_continue/edit',
      method: 'PUT'
    },
    {
      path: '/basis/his_work/edit',
      method: 'PUT'
    },
    {
      path: '/basis/rew_pun/edit',
      method: 'PUT'
    },
    {
      path: '/basis/train/edit',
      method: 'PUT'
    },
    {
      path: '/basis/worker_certificate/edit',
      method: 'PUT'
    },
    {
      path: '/basis/worker_job_title/edit',
      method: 'PUT'
    },
    {
      path: '/basis/modify_apply/wait',
      method: 'GET'
    },
    {
      path: '/basis/modify_apply/submit/worker/{id}',
      method: 'POST'
    },
    {
      path: '/ucenter/placard',
      method: 'GET'
    },
    {
      path: '/basis/worker_job_title',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/basis/his_work',
      method: 'GET'
    },
    {
      path: '/basis/worker_edu',
      method: 'GET'
    },
    {
      path: '/basis/worker_edu_continue',
      method: 'GET'
    },
    {
      path: '/basis/worker_certificate/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/agreement',
      method: 'GET'
    },
    {
      path: '/basis/job_title',
      method: 'GET'
    },
    {
      path: '/basis/worker_certificate',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all_tree',
      method: 'GET'
    },
    {
      path: '/basis/worker',
      method: 'GET'
    },
    {
      path: '/basis/certificate',
      method: 'GET'
    }]
  },
  getMyResume: {
    funKey: 'getMyResume',
    name: '查看我的简历',
    apis: [{
      path: '/basis/worker/my',
      method: 'GET'
    }]
  },
  getMyOrgResume: {
    funKey: 'getMyOrgResume',
    name: '查看本机构下的简历',
    apis: [{
      path: '/basis/worker/my_org',
      method: 'GET'
    }]
  },
  getMyOrgContainChildResume: {
    funKey: 'getMyOrgContainChildResume',
    name: '查看本机构及其子机构下的简历',
    apis: [{
      path: '/basis/worker/my_org_contain_child',
      method: 'GET'
    }]
  },
  getAllResume: {
    funKey: 'getAllResume',
    name: '查看全部简历',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    }]
  }
}