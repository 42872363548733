<template>
  <div class="fm-table-menu">
    <fm-btn
      v-for="item in showTableActions"
      @click="clickFmTableMenu(item.key)"
      text
      :key="item.key"
      size="small">
      {{item.label || item.getLabel(rowData)}}
    </fm-btn>
  </div>
</template>

<script>
import FmBtn from '../FmBtn'

export default {
  components: {
    FmBtn
  },
  name: 'fm-table-menu',
  props: {
    rowData: {
      type: Object
    },
    tableActions: {
      type: Array,
      default: () => {
        return []
      },
      validator: (data) => {
        let pass = true
        if (pass) {
          data.forEach((item) => {
            pass = pass && typeof item.key === 'string'
          })
        }
        return pass
      }
    }
  },
  computed: {
    showTableActions: {
      get () {
        let data = []
        let allAction = this.tableActions
        allAction.forEach((item) => {
          if (!item.show || item.show(this.rowData)) {
            data.push(item)
          }
        })
        return data
      }
    }
  },
  methods: {
    clickFmTableMenu (key) {
      this.$emit('click_table_action', {action: key, data: this.rowData})
    }
  }
}
</script>

<style lang="less" scoped>
.fm-table-menu {
  display: flex;
  height: 100%;
  margin-left: 10px;
  align-items: center;
  .fm-btn {
    padding: 0;
  }
}
</style>