export const base = [
  {
    path: 'bill',
    name: 'base.bill',
    component: () => import('../views/base/bill')
  },
  {
    path: 'bill_check_times',
    name: 'base.bill_check_times',
    component: () => import('../views/base/billCheckTimes')
  },
  {
    path: 'assets_type',
    name: 'base.assets_type',
    component: () => import('../views/base/assetsType')
  },
  {
    path: 'goods',
    name: 'base.goods',
    component: () => import('../views/base/goods')
  },
  {
    path: 'status',
    name: 'base.status',
    component: () => import('../views/status/index')
  },
  {
    path: 'config',
    name: 'base.config',
    component: () => import('../views/base/config')
  }
]

export const budget = [
  {
    path: 'home',
    name: 'budget.home',
    component: () => import('../views/budget/home')
  },
  {
    path: 'batch_detail',
    name: 'budget.batch_detail',
    component: () => import('../views/budget/batchDetail')
  },
  {
    path: 'detail_list',
    name: 'budget.detail_list',
    component: () => import('../views/budget/detailList')
  },
  {
    path: 'manage',
    name: 'budget.manage',
    component: () => import('../views/budget/index'),
    props: { type: 'manage' }
  },
  {
    path: 'handle',
    name: 'budget.handle',
    component: () => import('../views/budget/handle'),
    props: { type: 'handle' }
  },
  {
    path: 'details',
    name: 'budget.details',
    component: () => import('../views/budget/details'),
  }
]

export const purchase = [
  {
    path: 'home',
    name: 'purchase.home',
    component: () => import('../views/purchase/home')
  },
  {
    path: 'batch_detail',
    name: 'purchase.batch_detail',
    component: () => import('../views/purchase/batchDetail')
  },
  {
    path: 'detail_list',
    name: 'purchase.detail_list',
    component: () => import('../views/purchase/detailList')
  },
  {
    path: 'handle',
    name: 'purchase.handle',
    component: () => import('../views/purchase/handle'),
    props: { type: 'handle' }
  },
  {
    path: 'index',
    name: 'purchase.index',
    component: () => import('../views/purchase/index'),
    props: { type: 'index' }
  },
  {
    path: 'details',
    name: 'purchase.details',
    component: () => import('../views/purchase/details'),
  },
  {
    path: 'contract',
    name: 'purchase.contract',
    component: () => import('../views/purchase/contract')
  },
  {
    path: 'contract_end_warning',
    name: 'purchase.contract_end_warning',
    component: () => import('../views/purchase/contractEndWarning')
  },
  {
    path: 'contract_warranty_warning',
    name: 'purchase.contract_warranty_warning',
    component: () => import('../views/purchase/contractWarrantyWarning')
  },
  {
    path: 'implement',
    name: 'purchase.implement',
    component: () => import('../views/purchase/implement')
  }
]

export const purchaseMethod = [
  {
    path: 'home',
    name: 'purchase_method.home',
    component: () => import('../views/purchase_method/home')
  },
  {
    path: 'batch_detail',
    name: 'purchase_method.batch_detail',
    component: () => import('../views/purchase_method/batchDetail')
  },
  {
    path: 'detail_list',
    name: 'purchase_method.detail_list',
    component: () => import('../views/purchase_method/detailList')
  },
  {
    path: 'handle',
    name: 'purchase_method.handle',
    component: () => import('../views/purchase_method/handle'),
    props: { type: 'handle' }
  },
  {
    path: 'index',
    name: 'purchase_method.index',
    component: () => import('../views/purchase_method/index'),
    props: { type: 'index' }
  },
  {
    path: 'handle',
    name: 'purchase_method.handle',
    component: () => import('../views/purchase_method/index'),
    props: { type: 'handle' }
  },
  {
    path: 'details',
    name: 'purchase_method.details',
    component: () => import('../views/purchase_method/details'),
  }
]

export const repair = [
  {
    path: 'manage',
    name: 'repair.manage',
    component: () => import('../views/repair/manage')
  },
  {
    path: 'apply',
    name: 'repair.apply',
    component: () => import('../views/repair/apply')
  },
  {
    path: 'apply_record',
    name: 'repair.apply_record',
    component: () => import('../views/repair/applyRecord')
  }
]

export const scrap = [
  {
    path: 'apply',
    name: 'scrap.apply',
    component: () => import('../views/scrap/apply')
  },
  {
    path: 'record',
    name: 'scrap.record',
    component: () => import('../views/scrap/record')
  }
]

export const accept = [
  {
    path: 'manage',
    name: 'accept.manage',
    component: () => import('../views/accept/manage')
  },
  {
    path: 'my',
    name: 'accept.my',
    component: () => import('../views/accept/my')
  }
]

export const assets = [
  {
    path: 'manage',
    name: 'assets.manage',
    component: () => import('../views/assets/manage')
  },
  {
    path: 'manage_gy',
    name: 'assets.manage_gy',
    component: () => import('../views/assets/manageGy')
  },
  {
    path: 'manage_dw',
    name: 'assets.manage_dw',
    component: () => import('../views/assets/manageDw')
  },
  {
    path: 'check',
    name: 'assets.check',
    component: () => import('../views/assets/check')
  },
  {
    path: 'repair',
    name: 'assets.repair',
    component: () => import('../views/assets/repair')
  },
  {
    path: 'in',
    name: 'assets.in',
    component: () => import('../views/assets/in')
  },
  {
    path: 'out',
    name: 'assets.out',
    component: () => import('../views/assets/out')
  },
  {
    path: 'ledger',
    name: 'assets.ledger',
    component: () => import('../views/assets/ledger')
  }
]