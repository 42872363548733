import { request } from '@/api'

export default new Proxy({}, {
  get (obj, prop) {
    if (prop === 'download') {
      return (data) => request('/fms/download', 'get', data, {
        responseType: 'blob'
      })
    } else if (prop === 'file') {
      return (data) => request('/fms/file', 'get', data)
    }
    return (url, data) => request('/scm' + url, prop || 'get', data)
  }
})
