// 数据校验类型
const verifyTypes = {
  STRING: 'string',
  POSITIVE_INTEGER: 'positive_integer',
  INTEGER: 'integer',
  FLOAT: 'float',
  BOOLEAN: 'boolean',
  PHONE_NUMBER: 'phone_number',
  ID_CARD: 'id_card'
}

// 校验结果
const verifyResults = {
  // 校验通过
  PASS: 'pass',
  // 数据类型无效
  INVALID_TYPE: 'invalid_type',
  // 电话号码无效
  INVALID_PHONE_NUMBER: 'invalid_phone_number',
  // 身份证号无效
  INVALID_ID_CARD: 'invalid_id_card',
  // 数据为空
  REQUIRED_FAIL: 'required_fail',
  // 长度不够
  NOT_ENOUGH_LENGTH: 'not_enough_length',
  // 长度过长
  TOO_LONG_LENGTH: 'too_long_length',
  // 长度过长
  INVALID_FLOAT: 'invalid_float'
}

const verifyResultsDefaultErrorText = {
  invalid_type: '数据类型无效',
  required_fail: '不能为空',
  not_enough_length: '长度不够',
  too_long_length: '长度过长',
  invalid_phone_number: '无效电话号码',
  invalid_id_card: '无效身份证号',
  invalid_float: '无效的小数'
}

const verify = (data, {needDataType, minLength, maxLength, required}) => {
  let pass = verifyResults.PASS
  if (typeof data === 'undefined' || data === null) {
    // 数据源为 undefined 或 null
    if (required) {
      pass = verifyResults.REQUIRED_FAIL
    }
  } else if (['string', 'number', 'boolean', 'object'].includes(typeof data)) {
    // 数据源为 字符、数字、布尔
    if (data === '') {
      if (required) {
        pass = verifyResults.REQUIRED_FAIL
      }
    } else {
      if (needDataType === verifyTypes.STRING) {
        pass = verifyDataString(data, {minLength, maxLength})
      } else if (needDataType === verifyTypes.FLOAT) {
        pass = verifyDataFloat(data)
      }
    }
  } else {
    // 其他数据
    pass = verifyResults.INVALID_TYPE
  }
  return pass
}

const verifyDataString = (data, {minLength, maxLength}) => {
  let pass = verifyResults.PASS
  if (minLength && data.length < minLength) {
    pass = verifyResults.NOT_ENOUGH_LENGTH
  } else if (maxLength && data.length > maxLength) {
    pass = verifyResults.TOO_LONG_LENGTH
  }
  return pass
}

const verifyDataFloat = (data) => {
  let re = /^\d+(?=\.{0,1}\d+$|$)/
  return re.test(data) ? verifyResults.PASS : verifyResults.INVALID_FLOAT
}

const verifyDataPositiveInteger = (data, {minLength}) => {
  let pass = verifyResults.PASS
  if (data.length < minLength) {
    pass = verifyResults.NOT_ENOUGH_LENGTH
  }
  return pass
}

export default {
  verifyTypes,
  verifyResults,
  verifyResultsDefaultErrorText,
  verifyDataString,
  verifyDataFloat,
  verifyDataPositiveInteger,
  verify
}