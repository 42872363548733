export default {
  routerNames: ['org'],
  funs: {
    get: {
      funKey: 'get',
      name: '科室查看',
      apis: [
        { method: 'GET', path: '/performance/pfm_org' },
        { method: 'GET', path: '/performance/pfm_unit' },
        { method: 'GET', path: '/ucenter/org/all' }
      ]
    },
    manage: {
      funKey: 'manage',
      name: '科室管理',
      apis: [
        { method: 'PUT', path: '/performance/pfm_org/{id}' },
        { method: 'POST', path: '/performance/pfm_org' },
        { method: 'DELETE', path: '/performance/pfm_org/{id}' }
      ]
    },
    unitGet: {
      funKey: 'unitGet',
      name: '考核单元查看',
      apis: [
        { method: 'GET', path: '/performance/pfm_unit' }
      ]
    },
    unitManage: {
      funKey: 'unitManage',
      name: '考核单元管理',
      apis: [
        { method: 'PUT', path: '/performance/pfm_unit/{id}' },
        { method: 'POST', path: '/performance/pfm_unit' },
        { method: 'DELETE', path: '/performance/pfm_unit/{id}' }
      ]
    },
    unitConfig: {
      funKey: 'unitConfig',
      name: '考核单元明细配置',
      apis: [
        { method: 'GET', path: '/performance/pfm_unit_month_detail_default' },
        { method: 'PUT', path: '/performance/pfm_unit_month_detail_default/{id}' },
        { method: 'POST', path: '/performance/pfm_unit_month_detail_default' },
        { method: 'DELETE', path: '/performance/pfm_unit_month_detail_default/{id}' }
      ]
    }
  }
}