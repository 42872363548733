export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/job_title/promotion/queue',
      method: 'GET'
    }]
  },
  queue: {
    funKey: 'queue',
    name: '排队情况'
  }
}