var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fm-form-item",class:{
  'fm-label-alone': _vm.labelAlone,
  'fm-form-item-required': this.isRequired !== false
}},[_c('div',{staticClass:"fm-form-item-label",style:({
    width: _vm.labelWidth ? _vm.labelWidth : '',
    textAlign: _vm.labelAlign
  })},[_vm._v(_vm._s(_vm.label)),_vm._t("labelInfo")],2),_c('div',{staticClass:"fm-form-item-content"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }