export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/article_type',
      method: 'GET'
    }]
  },
  new: {
    funKey: 'new',
    name: '新增',
    apis: [{
      path: '/basis/article_type',
      method: 'POST'
    }]
  },
  update: {
    funKey: 'update',
    name: '修改',
    apis: [{
      path: '/basis/article_type/{id}',
      method: 'PUT'
    }]
  },
  deleter: {
    funKey: 'deleter',
    name: '删除',
    apis: [{
      path: '/basis/article_type/{id}',
      method: 'DELETE'
    }]
  }
}