import { request, baseUrl } from '@/api'

export function queryStr (params) {
  return Object.keys(params).filter(key => {
    return params[key] !== undefined
  }).map(key => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&')
}

const state = {
  token: null
}

const getters = {
  fileToken: state => state.token,
  fileUrl: state => {
    const token = state.token
    return (opt) => {
      if (!token || !opt.id) return undefined
      return baseUrl + '/fms/download/agent?' + queryStr({
        token,
        ...opt
      })
    }
  }
}

let time = null
let loading = null

const actions = {
  async loadFileToken ({ commit, state, dispatch }) {
    if (loading) {
      return await loading
    }
    let fileToken = null
    if (!state.token || !time || new Date().getTime() - time > 86400) {
      loading = new Promise(async resolve => {
        try {
          time = new Date().getTime()
          fileToken = await request('/ucenter/oauth/jwt_token', 'get')
          setTimeout(() => dispatch('loadFileToken'), 80000)
        } catch (e) {
          time = fileToken = null
          console.error(e)
        }
        commit('setFileToken', fileToken)
        resolve(fileToken)
      })
    }
    return state.token
  },
}

const mutations = {
  setFileToken (state, data) {
    state.token = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}