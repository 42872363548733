<template>
  <router-view />
</template>

<script>
export default {}
</script>

<style lang="less">
@import './styles/global.less';
@import './styles/css-components.less';
</style>
