<template>
  <div class="fm-input" :class="inputCls">
    <i class="fmico icon-label" v-if="icon" :class="icon"></i>
    <i class="icon-label" v-else-if="customIcon" :class="customIcon"></i>
    <input
      ref="el-input"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      @blur="focusIn = false, verifier(), blur()"
      @focus="focusIn = true"
      @input="inputText($event.target.value)"
      @keyup.enter="keyUp"
    />
    <i class="fmico icon-warning fmico-warning" v-if="warningParm.pass === -1">
      <span class="info_text">{{warningParm.error}}</span>
    </i>
    <!-- <i class="iconfont icon_success icon-checked" v-if="showWarning === 1"></i> -->
  </div>
</template>

<script>
export default {
  name: 'FmInput',
  data () {
    return {
      focusIn: false
    }
  },
  props: {
    size: {
      type: String,
      default: 'norm'
    },
    placeholder: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    customIcon: {
      type: String,
      default: null
    },
    warningParm: {
      type: Object,
      default: () => {
        return {
          pass: 0,
          error: ''
        }
      },
      validator: data => {
        return !data || [0, 1, -1].includes(data.pass)
      }
    },
    type: {
      type: String,
      default: 'text'
    },
    value: [String, Number]
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    inputCls() {
      return {
        'fm-input-norm': this.size === 'norm' || this.size === undefined,
        'fm-input-large': this.size === 'large',
        'fm-input-small': this.size === 'small',
        'fm-input-mini': this.size === 'mini',
        'fm-input-focus': this.focusIn
      }
    }
  },
  watch: {
    value: {
      handler () {
        // this.$nextTick(this.verifier)
      },
      immediate: true
    }
  },
  mounted () {
    // this.$nextTick(this.verifier)
    this.registerVerifier()
  },
  methods: {
    blur () {
      this.$emit('elBlur')
    },
    focus () {
      this.$refs['el-input'].focus()
    },
    keyUp() {
      this.$emit('keyUp')
    },
    inputText(text) {
      this.$emit('change', text)
    }
  }
}
</script>

<style lang="less">
@import "./styles/values.less";
.fm-input {
  height: 3.75rem;
  border: 1px solid @color-input-border;
  position: relative;
  display: flex;
  align-items: center;
  transition: @time-transition;
  font-size: @size-font-large;
  border-radius: 4px;
  transition: all .2s;
  box-shadow: 0 0 0px 3px transparent;
  // overflow: hidden;
  &:hover, &.fm-input-focus {
    border-color: @color-primary;
  }
  &.fm-input-focus {
    box-shadow: 0 0 0px 3px @color-primary-shadow;
  }
  input {
    border-style: none;
    flex: 1;
    color: #515a6e;
    margin: 0 8px;
    background-color: @color-tm;
    font-size: @size-font-large;
    &:focus,
    &:active {
      outline: none;
    }
  }
  input::-webkit-input-placeholder {
    color: @color-placeholder-font;
  }
  .icon-label {
    color: @color-input-label;
    padding: 0 0.7rem 0 0.7rem;
    font-size: @size-font-b;
    border-right: 1px solid @color-input-label;
  }
  .icon-warning {
    position: relative;
    color: @color-warning;
    margin: 0 1rem;
    font-size: @size-font-b;
    .info_text {
      line-height: 1.5rem;
      white-space: nowrap;
      font-size: @size-font-mini;
      position: absolute;
      bottom: 0rem;
      top: 0;
      left: 2rem;
      padding: 0 0.3rem;
      border-radius: @size-border-radius;
      background-color: @color-input-note-bak;
      box-shadow: @size-shadow-n @color-input-note-shadow;
    }
  }
  .icon_success {
    position: relative;
    color: @color-success;
    margin: 0 1rem;
  }
  &.verifier-error {
    border-color: red;
    // input {
    // }
    // input:focus, input:hover {
    //   border-color: red;
    // }
    // input:focus {
    //   box-shadow: 0 1px 6px red;
    // }
  }
}
.fm-input.fm-input-mini {
  height: @size-height-mini;
  line-height: @size-height-mini;
  font-size: @size-font-mini;
  .icon-label {
    font-size: @size-font-mini;
  }
  input {
    font-size: @size-font-mini;
  }
}
.fm-input.fm-input-small {
  height: @size-height-small;
  line-height: @size-height-small;
  font-size: @size-font-small;
  .icon-label {
    font-size: @size-font-small;
  }
  input {
    font-size: @size-font-small;
  }
}

.fm-input.fm-input-norm {
  height: @size-height-norm;
  line-height: @size-height-norm;
  font-size: @size-font-norm;
  .icon-label {
    font-size: @size-font-norm;
  }
  input {
    font-size: @size-font-norm;
  }
}

.fm-input.fm-input-large {
  height: @size-height-large;
  line-height: @size-height-large;
  font-size: @size-font-large;
  .icon-label {
    font-size: @size-font-large;
  }
  input {
    font-size: @size-font-large;
  }
}
</style>