export default {
  base: {
    funKey: 'base',
    name: '基础'
  },
  current: {
    funKey: 'current',
    name: '当前请假情况',
    apis: [{
      path: '/basis/ask_leave',
      method: 'GET'
    },
    {
      path: '/basis/ask_leave_apply',
      method: 'GET'
    }]
  }
}
  