<style lang="less">
  @import url('../styles/values.less');
  .fm-table-custom-sizer {
    .fmico.fmico-list-size {
      opacity: 0.5;
      font-weight: 800;
      cursor: pointer;
      color: @color-primary;
      transition: all .3s;
      font-size: 12px;
      &:hover {
        opacity: 1;
      }
    }
  }
  .fm-table-custom-sizer-title {
    color: @color-component-text;
    line-height: 3;
    margin-bottom: 10px;
    border-bottom: 1px solid @color-divider;
    display: flex;
    justify-content: space-between;
    .fmico.fmico-cha {
      font-size: 12px;
      opacity: .5;
      transition: all .3s;
      cursor: pointer;
      color: @color-danger;
      &:hover {
        opacity: 1;
      }
    }
  }
  .fm-table-custom-sizer-container {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid @color-border;
    padding: 10px;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 3;
  }
  // 动画
  .fm-table-custom-sizer-transition-enter-active, .fm-table-custom-sizer-transition-leave-active {
    transition: all .5s;
    opacity: 1;
    transform: scale(1);
  }
  .fm-table-custom-sizer-transition-enter, .fm-table-custom-sizer-transition-leave-to {
    opacity: 0;
    transform: scale(0.7);
  }
</style>

<template>
  <div class="fm-table-custom-sizer">
    <span class="fmico fmico-list-size" @click="show = !show"></span>
    <transition name="fm-table-custom-sizer-transition" mode="in-out">
      <div class="fm-table-custom-sizer-container" v-show="show">
        <div class="fm-table-custom-sizer-title">表格大小设置<i class="fmico fmico-cha" @click="show = false"></i></div>
        <div>
          <fm-radio-group @change="onSizeChange" :value="sizeSet">
            <fm-radio value="large" label="超大"></fm-radio>
            <fm-radio value="norm" label="正常"></fm-radio>
            <fm-radio value="small" label="中等"></fm-radio>
            <fm-radio value="mini" label="迷你"></fm-radio>
          </fm-radio-group>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  inject: ['table'],
  data () {
    return {
      show: false
    }
  },
  computed: {
    sizeSet () {
      return this.table.sizeSet
    }
  },
  methods: {
    onSizeChange (size) {
      this.table.$emit('size-change', size)
    }
  }
}
</script>
