export default {
  functional: true,
  inject: ['table'],
  props: {
    row: Object,
    index: Number,
    column: {
      type: Object,
      default: null
    }
  },
  render: (h, ctx) => {
    return h('div', ctx.injections.table.$scopedSlots[ctx.props.column.config.slot]({
      row: ctx.props.row,
      column: ctx.props.column,
      index: ctx.props.index
    }))
  }
}