<style lang="less"></style>

<template>
  <div>
    <fm-title badge></fm-title>
  </div>
</template>

<script>
export default {
  name: 'FmTitleDemo'
}
</script>
