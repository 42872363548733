let localStorage = window.localStorage

export default {
  updateData: function (key, value) {
    let reData = 0
    try {
      if (localStorage.getItem(key) !== null) {
        this.deleteData(key)
      }
      localStorage.setItem(key, window.JSON.stringify(value))
      reData = 1
    } catch (error) {
      let SaveError = function (message, code) {
        this.message = message
        this.code = code
      }
      // 数据存储失败还没进行回滚处理
      throw new SaveError('数据存储失败' + error, -100)
    }
    return reData
  },
  deleteData: function (key) {
    localStorage.removeItem(key)
  },
  getData: function (key) {
    let data = localStorage.getItem(key)
    return typeof data === 'undefined' || data === null ? data : JSON.parse(data)
  }
}
