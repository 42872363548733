<template>
  <div class="fm-search-group" :class="{'fm-search-group-no-bk': noBk}">
    <div class="search-one" v-for="item in searchParms" :key="item.key">
      <fm-select v-model="searchData[item.key]" :placeholder="item.placeholder || '请选择'" v-if="item.type === 'select'" clearable style="width: 8rem;" filterable>
        <fm-option v-for="itemO in (item.selectDatas || item.getSelectDatas(searchData))"
          :key="itemO.key"
          :label="itemO.label"
          :value="itemO.key">
        </fm-option>
      </fm-select>
      <fm-input style="width: 8rem;" v-model="searchData[item.key]" :placeholder="item.placeholder || '请输入'" v-if="item.type === 'input'"></fm-input>
      <el-date-picker
        style="width: 12rem;"
        v-model="searchData[item.key]"
        type="date"
        :placeholder="item.placeholder"
        v-if="item.type === 'datePicker'" size="mini">
      </el-date-picker>
      <el-date-picker
        style="width: 12rem;"
        v-model="searchData[item.key]"
        type="datetime"
        :placeholder="item.placeholder"
        v-if="item.type === 'dateTimePicker'" size="mini">
      </el-date-picker>
      <el-date-picker
        style="width: 10rem;"
        v-model="searchData[item.key]"
        type="month"
        :placeholder="item.placeholder"
        v-if="item.type === 'monthPicker'" size="mini">
      </el-date-picker>
      <el-date-picker
        style="width: 10rem;"
        v-model="searchData[item.key]"
        type="year"
        :placeholder="item.placeholder"
        v-if="item.type === 'yearPicker'" size="mini">
      </el-date-picker>
      <el-date-picker
        v-model="searchData[item.key]"
        type="daterange"
        range-separator="至"
        :start-placeholder="item.placeholder"
        end-placeholder="结束日期"
        v-if="item.type === 'dateRange'" size="mini" style="width: 25rem;">
      </el-date-picker>
      <fm-select style="min-width: 18rem;" v-model="searchData[item.key]" multiple :placeholder="item.placeholder || '请选择'" v-if="item.type === 'selectMultiple'">
        <fm-option v-for="itemO in (item.selectDatas || item.getSelectDatas(searchData))"
          :key="itemO.key"
          :label="itemO.label"
          :value="itemO.key">
        </fm-option>
      </fm-select>
    </div>
    <div class="search-one">
      <fm-btn @click="clickSearch">搜 索</fm-btn>
    </div>
    <div class="search-sort">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import FmBtn from './FmBtn'

export default {
  name: 'FmSearchGroup',
  components: {
    FmBtn
  },
  props: {
    noBk: {
      type: Boolean,
      default: false
    },
    searchParms: {
      type: Array,
      default: () => {
        return []
      },
      validator: (data) => {
        let pass = true
        if (pass) {
          data.forEach((item) => {
            pass = pass && typeof item.key === 'string' && typeof item.type === 'string'
          })
        }
        return pass
      }
    }
  },
  data () {
    return {
      searchData: {}
    }
  },
  watch: {
    searchParms: {
      handler () {
        this.setSearchData()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    clickSearch () {
      this.$emit('clickSearch', this.searchData)
    },
    setSearchData () {
      this.searchParms.forEach((item) => {
        this.$set(this.searchData, item.key, item.default || null)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './styles/values.less';
.fm-search-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #fff;
  padding: 0.6rem 1rem;
  width: 100%;
}
.fm-search-group-no-bk {
  background: rgba(0, 0, 0, 0);
}
.search-one {
  height: 2.5rem;
  min-width: 8rem;
  min-height: 2.5rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
.search-sort {
  flex: 1;
  height: 100%;
  display: flex;
}
</style>
