export default [
  {
    icon: 'icon-shuju',
    name: '绩效',
    clientType: 'web',
    children: [
      {
        icon: 'icon-shuju',
        name: '基础数据',
        clientType: 'web',
        children: [
          {
            icon: 'icon-Award',
            name: '项目',
            clientType: 'web',
            url: 'project',
          },
          {
            icon: 'icon-renwufenpei',
            name: '科室',
            clientType: 'web',
            url: 'org',
          },
          {
            icon: 'icon-ry-list',
            name: '人员',
            clientType: 'web',
            url: 'worker',
          }
        ]
      },
      {
        icon: 'icon-shuju',
        name: '基础数据',
        clientType: 'web',
        url: 'calculate.index',
      }
    ]
  }
]