export default {
  get: {
    funKey: 'get',
    name: '获取',
    apis: [{
      path: '/internalcontrol/acceptance/my_org',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '查看',
    apis: [{
      path: '/internalcontrol/acceptance/accept/{id}',
      method: 'POST'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '扫描件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取扫描件'
  },
  updateDataFile: {
    funKey: 'updateDataFile',
    name: '上传扫描件',
    apis: [{
      path: '/internalcontrol/acceptance/{id}',
      method: 'PUT'
    }]
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载扫描件'
  },
  delDataFile: {
    funKey: 'delDataFile',
    name: '删除扫描件',
    apis: [{
      path: '/internalcontrol/acceptance/{id}',
      method: 'PUT'
    }]
  }
}