<template>
  <div class="fm-date-picker-cell-follow"></div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {}
  },
  props: {},
  computed: {},
  methods: {
    me (vnode) {
      if (vnode) {
        let node = vnode._isVue ? vnode.$el : vnode
        let {top, left, width, height} = node.getBoundingClientRect()

        this.$el.style.top = top + 'px'
        this.$el.style.left = left + 'px'
        this.$el.style.width = width + 'px'
        this.$el.style.height = height + 'px'
        this.$el.style.opacity = 1

        node = null
      } else {
        this.$el.style.width = 0
        this.$el.style.height = 0
        this.$el.style.opacity = 0
      }
    },
    onScroll () {
      this.$el.style.opacity = 0
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    window.addEventListener('mousewheel', this.onScroll)
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {
    window.removeEventListener('mousewheel', this.onScroll)
  },
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
@import '../styles/datapicker.less';
.fm-date-picker-cell-follow {
  position: fixed;
  width: 0;
  height: 0;
  opacity: 0;
  transition: all .3s;
  background-color: @color-candidate;
  border-radius: @size-border-radius;
}
</style>