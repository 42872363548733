export default {
  get: {
    funKey: 'get',
    name: '查看全部',
    apis: [
      { method: 'GET', path: '/basis/nucleic_report' },
      { method: 'GET', path: '/basis/nucleic_report/cydd' },
      { method: 'GET', path: '/ucenter/org' },
    ]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '查看本机构下的',
    apis: [
      { method: 'GET', path: '/basis/nucleic_report/my_org' },
      { method: 'GET', path: '/basis/nucleic_report/cydd' },
      { method: 'GET', path: '/ucenter/org' },
    ]
  },
  result: {
    funKey: 'result',
    name: '结果填报',
    apis: [
      { method: 'PUT', path: '/basis/nucleic_report/{id}' },
      { method: 'POST', path: '/basis/nucleic_report' },
      { method: 'POST', path: '/basis/nucleic_report/result' },
      { method: 'POST', path: '/basis/nucleic_report/jsrq' }
    ]
  },
  update: {
    funKey: 'update',
    name: '数据填报',
    apis: [
      { method: 'PUT', path: '/basis/nucleic_report/no_result/{id}' },
      { method: 'POST', path: '/basis/nucleic_report/clear_result' }
    ]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [
      { method: 'DELETE', path: '/basis/nucleic_report' }
    ]
  },
  print: {
    funKey: 'print',
    name: '打印',
    apis: [
      { method: 'GET', path: '/fms/download' }
    ]
  },
  count: {
    funKey: 'count',
    name: '统计汇总全部',
    apis: [
      { method: 'GET', path: '/basis/nucleic_report/count' }
    ]
  },
  countMyOrg: {
    funKey: 'countMyOrg',
    name: '统计汇总本机构下的',
    apis: [
      { method: 'GET', path: '/basis/nucleic_report/count/my_org' }
    ]
  }
}