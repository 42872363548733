<style lang="less">
@import './styles/values.less';
.fm-title {
  height: @the-title-height;
  min-height: @the-title-height;
  display: flex;
  align-items: center;
  background-color: @color-background-title;
}
.fm-title-small {
  height: @the-title-height-small;
  min-height: @the-title-height-small;
}
.fm-title_div-border {
  border-bottom: @size-border solid @color-border;
}
.fm-title_span-note {
  font-size: 0.9rem;
  color: @color-sub-text;
  margin-left: 1rem;
}
.fm-title_span {
  white-space:nowrap;
  letter-spacing: @size-letter-spacing;
  font-size: @size-font-norm;
  padding-left: 0.5rem;
}
.fm-title-left-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fm-title-menus {
  display: flex;
  padding: 0 1rem;
}
.fm-title_slot {
  height: 100%;
  margin: 0 1rem;
  flex: 1;
  display: flex;
}
.fm-title-badeg {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  display: inline-block;
}
.fm-title-alone {
  flex-wrap: wrap;
  height: unset;
  max-height: unset;
  padding: 8px 0;
  .fm-title_span {
    margin-bottom: 5px;
    width: 100%;
  }
  .fm-title_slot {
    margin-left: 0.5rem;
  }
}
</style>


<template>
  <div class="fm-title" :class="{'fm-title_div-border': needBorder, 'fm-title-small': titleSize === 'small', 'fm-title-alone': alone}">
    <span class="fm-title_span">
      <i v-if="badge !== false" class="fm-title-badeg" :style="badegStyle"></i>
      {{titleText}}
      <span class="fm-title_span-note">{{noteText}}</span>
    </span>
    <div class="fm-title_slot">
      <slot></slot>
    </div>
    <div class="fm-title-left-content">
      <div class="fm-title-search_input" v-if="showSearch">
        <input type="text" :value="inputValue" :placeholder="placeholderText" @input="inputText($event.target.value)">
      </div>
      <div class="fm-title-menus" v-if="titleMenus && titleMenus.length > 0">
        <fm-btn
          tint
          type="info"
          v-for="item in titleMenus"
          @click="clickTitleMenu(item)"
          :text="item.btnStatus === 'table'"
          :disabled="item.btnStatus === 'disabled'"
          :key="item.key"
          :size="titleSize">
          {{item.label}}
        </fm-btn>
      </div>
    </div>
  </div>
</template>

<script>
import FmBtn from './FmBtn'

export default {
  name: 'FmTitle',
  components: {
    FmBtn
  },
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  props: {
    alone: { type: Boolean, default: false },
    badge: {
      type: [Boolean, String],
      default: false
    },
    titleSize: {
      type: String,
      default: 'norm'
    },
    inputValue: [String, Number],
    needBorder: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    titleText: {
      type: String,
      default: '标题'
    },
    noteText: {
      type: String,
      default: null
    },
    placeholderText: {
      type: String,
      default: ''
    },
    titleMenus: {
      type: Array,
      default: () => {
        return []
      },
      validator: (data) => {
        let pass = true
        if (pass) {
          data.forEach((item) => {
            pass = pass && typeof item.key === 'string' && typeof item.label === 'string'
          })
        }
        return pass
      }
    }
  },
  computed: {
    badegStyle () {
      if (this.badge && typeof this.badge === 'boolean') {
        return {backgroundColor: '#1cb5e0'}
      } else {
        return {backgroundColor: this.badge}
      }
    }
  },
  methods: {
    inputText (text) {
      this.$emit('change', text)
    },
    clickTitleMenu (menu) {
      this.$emit('clickTitleMenu', menu.key, menu)
    }
  }
}
</script>
