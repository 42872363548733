var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fm-table-summary"},[_c('div',{staticClass:"fm-table-summary-cells"},[_vm._l((_vm.columnConfig),function(columns,columnsIndex){return [(columns.length > 0)?_c('div',{key:columnsIndex,ref:'item' + columnsIndex,refInFor:true,staticClass:"fm-table-summary-item",class:{
        'fm-table-summary-fixed-left': columnsIndex === 0,
        'fm-table-summary-fixed-right': columnsIndex === 2
      },style:({
        width: columnsIndex === 1 ? ('0px') : 'unset',
        flex: columnsIndex === 1 ? '1' : ('0 0 ' + _vm.columnWidth[columnsIndex].reduce((a, b) => a + b, 0) + 'px')
      })},[_c('table',{class:{'fm-table-layout-fixed': !_vm.needResize, 'fm-table-layout-ready': _vm.needResize},style:({
          width: columnsIndex === 1 ? (_vm.tableWidth + 'px') : 'unset'
        }),attrs:{"border":"0","cellspacing":"0","cellpadding":"0"}},[_c('colgroup',{key:_vm.columnWidthNeedUpdate},_vm._l(((_vm.columnWidth[columnsIndex] || columns.map(v => v.width))),function(width,index){return _c('col',{key:index,attrs:{"width":width}})}),0),_c('tbody',[_c('tr',{ref:'trs' + columnsIndex,refInFor:true,style:({height: _vm.height ? (_vm.height + 'px') : ''})},_vm._l((columns),function(column){return _c('td',{key:column.key,staticClass:"fm-table-summary-cell"},[_c('div',{staticClass:"fm-table-summary-cell-wrap",class:{
                  ['fm-table-cell-align-' + (column.config.valign || column.config.align || 'left')]: true
                },domProps:{"innerHTML":_vm._s(_vm.summaryMethod({column: column.config, data: _vm.dataList, summaryField: _vm.summaryField, summaryDecimal: _vm.summaryDecimal}))}})])}),0)])])]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }