import {
  axiosInit
} from '@/fmlib'

import sysEnv from '@/env/env'

import store from '@/store'

import router from '@/router'

import Vue from 'vue'

export const baseUrl = sysEnv.apiAdr

let showMessageFun = (msg) => {
  Vue.prototype.$notice.error({
    title: '系统提示',
    desc: msg
  })
  // 快捷处理 不太严谨
  if (msg === '无权限，请确定是否已登录，若已登录请确定是否有权限。') {
    router.push({name: 'login'})
  }
}

export const getHeaderParm = () => {
  return {
    token: store.getters.token,
    orgId: store.getters.currentOrgId || '',
    roleId: store.getters.currentRoleId || ''
  }
}

let axiosInitOne = new axiosInit.axiosInit({baseUrl, showMessageFun, getHeaderParm})

export const request = axiosInitOne.request

export const httpRequestMethods = axiosInitOne.httpRequestMethods

export const fileRequest = {
  mkdir: function (parm) {
    return request('/fms/file/mkdir', httpRequestMethods.POST, parm)
  },
  getFileData: function (parm) {
    return request('/fms/file', httpRequestMethods.GET, parm)
  },
  upload: function (parm) {
    return request('/fms/upload', httpRequestMethods.POST, parm, {
      contentType: 'multipart/form-data'
    })
  },
  get: function (parm) {
    return request('/fms/download', httpRequestMethods.GET, parm)
  },
  download: function (parm) {
    return request('/fms/download', httpRequestMethods.GET, parm, {
      responseType: 'blob'
    })
  },
  del: function (parm) {
    return request('/fms/file', httpRequestMethods.DELETE, parm)
  },
  deal: function () {
    return request('/fms/file/deal', httpRequestMethods.GET, {})
  }
}

// auth
export const authRequest = {
  getLoginPubKey (parm) {
    return request('/ucenter/oauth/pre/token', httpRequestMethods.POST, parm)
  },
  login (parm) {
    return request('/ucenter/oauth/token', httpRequestMethods.POST, parm)
  },
  agentLogin (parm) {
    return request('/ucenter/oauth/agent_token', httpRequestMethods.POST, parm)
  },
  updatePwd (parm) {
    return request('/ucenter/user/myPassword', httpRequestMethods.PUT, parm)
  },
  updateSignFile (parm) {
    return request('/ucenter/user/sign_file/my', httpRequestMethods.PUT, parm)
  },
  loginUpdatePwd (parm, token) {
    let get = () => {
      return {
        token
      }
    }
    return new axiosInit.axiosInit({baseUrl, showMessageFun, getHeaderParm: get}).request('/ucenter/user/myPassword', httpRequestMethods.PUT, parm)
  },
  logOut () {
    return request('/ucenter/oauth/token', httpRequestMethods.DELETE)
  },
  getUserInfo () {
    return request('/ucenter/user/current', httpRequestMethods.GET)
  },
  dealUser () {
    return request('/ucenter/deal_data/worker_user', httpRequestMethods.GET)
  },
  dealPosition () {
    return request('/ucenter/deal_data/position', httpRequestMethods.GET)
  }
}

// systemApis
export function systemApisGet () {
  return request('/ucenter/system/apis', httpRequestMethods.GET)
}

export const userRequest = {
  get (parm) {
    return request('/ucenter/user', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request('/ucenter/user', httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request('/ucenter/user/' + id, httpRequestMethods.PUT, parm)
  },
  updateAuth (id, parm) {
    return request('/ucenter/user/auth/' + id, httpRequestMethods.PUT, parm)
  },
  updateSignFile (id, parm) {
    return request('/ucenter/user/sign_file/' + id, httpRequestMethods.PUT, parm)
  },
  updateSortNum (id, parm) {
    return request('/ucenter/user/sort/' + id, httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request('/ucenter/user/' + id, httpRequestMethods.DELETE)
  },
  resetPassword (id) {
    return request(`/ucenter/user/${id}/password/default`, httpRequestMethods.PUT)
  },
  updateAuthorities (id, parm) {
    return request(`/ucenter/user/${id}/authorities`, httpRequestMethods.POST, parm)
  }
}

export const orgTypeRequest = {
  get: function (parm) {
    return request('/ucenter/org_type', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/ucenter/org_type', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/ucenter/org_type/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/ucenter/org_type/' + id, httpRequestMethods.DELETE)
  }
}

export const placardRequest = {
  get: function (parm) {
    return request('/ucenter/placard', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/ucenter/placard', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/ucenter/placard/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/ucenter/placard/' + id, httpRequestMethods.DELETE)
  }
}

export const sysConfigRequest = {
  get: function (parm) {
    return request('/ucenter/sys_config', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/ucenter/sys_config', httpRequestMethods.POST, parm)
  },
  del: function (id) {
    return request('/ucenter/sys_config/' + id, httpRequestMethods.DELETE)
  }
}

export const orgRequest = {
  get (parm) {
    return request('/ucenter/org', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request('/ucenter/org', httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request('/ucenter/org/' + id, httpRequestMethods.PUT, parm)
  },
  updateRole: function (id, parm) {
    return request('/ucenter/org/role/' + id, httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request('/ucenter/org/' + id, httpRequestMethods.DELETE)
  },
  getAll (parm) {
    return request('/ucenter/org/all', httpRequestMethods.GET, parm)
  },
  getTree (parm) {
    return request('/ucenter/org/tree', httpRequestMethods.GET, parm)
  },
  getAllTree (parm) {
    return request('/ucenter/org/all_tree', httpRequestMethods.GET, parm)
  }
}

export const roleRequest = {
  get (parm) {
    return request('/ucenter/system/roles', httpRequestMethods.GET, parm)
  },
  getBase (parm) {
    return request('/ucenter/system/roles/base', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request('/ucenter/system/roles', httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request('/ucenter/system/roles/' + id, httpRequestMethods.PUT, parm)
  },
  updateAuth (id, parm) {
    return request('/ucenter/system/roles/auth/' + id, httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request('/ucenter/system/roles/' + id, httpRequestMethods.DELETE)
  }
}

export const menuRequest = {
  get (parm) {
    return request('/ucenter/system/menus', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request('/ucenter/system/menus', httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request('/ucenter/system/menus/' + id, httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request('/ucenter/system/menus/' + id, httpRequestMethods.DELETE)
  }
}

export const userOrgRoleRequest = {
  get (parm) {
    return request('/ucenter/user_org_role', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request('/ucenter/user_org_role', httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request('/ucenter/user_org_role/' + id, httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request('/ucenter/user_org_role/' + id, httpRequestMethods.DELETE)
  }
}

export const funRequest = {
  get (parm) {
    return request('/ucenter/system/funs', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request('/ucenter/system/funs', httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request('/ucenter/system/funs/' + id, httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request('/ucenter/system/funs/' + id, httpRequestMethods.DELETE)
  },
  updateApi (id, parm) {
    return request(`/ucenter/system/funs/${id}/apis`, httpRequestMethods.POST, parm)
  }
}
