/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Link: https://www.slippersclown.com
 * Date: Sat Sep 05 2020
 */

export default {
  data () {
    return {
      columnWidth: [],
      columnWidthNeedUpdate: 0,
      needResize: true
    }
  },
  methods: {
    handleResize (columnWidth) {
      this.needResize = false
      this.columnWidth = columnWidth
      this.columnWidthNeedUpdate += 1
    }
  },
  computed: {
    tableHeight () {
      return this.table.autoHeight !== false ? this.table.$el.parentElement.offsetHeight : this.table.height
    },
    tableWidth () {
      return this.table.width
    },
    columnConfig () {
      return this.table.columnConfig
    }
  }
}
