<style lang="less">
  @import url('../styles/values.less');
  .fm-table-head-cell {
    position: relative;
    box-sizing: border-box;
  }
  .fm-table-head-cell-drag {
    border-right: 1px solid @color-border;
  }
  .fm-table-head-cell-wrap {
    display: flex;
    align-items: center;
    word-break: break-all;
  }
  .fm-table-drag-area {
    position: absolute;
    height: 100%;
    width: 10px;
    top: 0;
    right: 0;
    cursor: col-resize;
    &:hover {
      background-color: @color-divider;
    }
  }
  .fm-table-layout-ready {
    .fm-table-head-cell-wrap {
      word-break: keep-all;
    }
  }
</style>

<template>
  <th class="fm-table-head-cell" :class="{
    'fm-table-head-cell-drag': column.config.resizable
  }" @dblclick="onDblclick" @contextmenu.stop.prevent="(e) => table.activeContextmenu(e, {area: 'head', row: null, column: column.config})">
    <fm-checkbox :size="sizeSet" class="fm-table-section" v-if="column.type === 'selection'" @change="allSelect"></fm-checkbox>
    <div v-else class="fm-table-head-cell-wrap" :class="cellCls">
      <template>{{column.config.title}}</template>
      <cell-sort :column="column" v-if="column.config.sort"></cell-sort>
      <cell-filter :column="column" :group-index="groupIndex" :column-index="columnIndex" v-if="!simpleFilter && column.config.filter && column.config.field"></cell-filter>
      <span v-if="column.config.resizable === true" class="fm-table-drag-area" @mousedown="onMouseDown"></span>
    </div>
  </th>
</template>

<script>
import CellSort from './sort'
import CellFilter from './filter'
export default {
  inject: ['table'],
  props: {
    column: {
      type: Object, default () {
        return {}
      }
    },
    groupIndex: {
      type: Number, default: -1
    },
    columnIndex: {
      type: Number, default: -1
    }
  },
  components: {CellSort, CellFilter},
  methods: {
    allSelect (v) {
      this.table.$emit('cell-all-select', v)
    },
    onDblclick () {
      // this.table.$emit('cell-data-wrap', {groupIndex: this.groupIndex, columnIndex: this.columnIndex})
    },
    onMouseDown (event) {
      this.table.$emit('cell-drag-start', {event, groupIndex: this.groupIndex, columnIndex: this.columnIndex})
    }
  },
  computed: {
    simpleFilter () {
      return this.table.simpleFilter
    },
    sizeSet () {
      return this.table.sizeSet
    },
    cellCls () {
      let align = this.column.config.align || this.column.config.halign || 'left'
      if (this.column.type === 'selection') {
        align = 'center'
      }
      return {
        [`fm-table-cell-align-${align}`]: true
      }
    }
  }
}
</script>
