let jse = require('jsencrypt')

export const genterRsaKey = async function () {
  let rsaKey = await window.crypto.subtle.generateKey({
    name: 'RSA-OAEP',
    modulusLength: 2048,
    publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
    hash: {name: 'SHA-512'},
  },
  true,
  ['encrypt', 'decrypt'])
  let prikey = await window.crypto.subtle.exportKey(
    'pkcs8', 
    rsaKey.privateKey
  )
  let pubkey = await window.crypto.subtle.exportKey(
    'spki', 
    rsaKey.publicKey 
  )
  return {prikey: RSA2text(prikey), pubkey: RSA2text(pubkey)}
}

const RSA2text = (buffer) => {
  let binary = ''
  let bytes = new Uint8Array(buffer)
  let i = 0
  while (i < bytes.byteLength) {
    binary += String.fromCharCode(bytes[i])
    i += 1
  }
  return window.btoa(binary)
}

export const rsaEncrypt = (pubkey, text) => {
  let jseJm = new jse.JSEncrypt()
  jseJm.setPublicKey(pubkey)
  return jseJm.encrypt(text)
}

export const rsaDecrypt = (privkey, text) => {
  let jseJm = new jse.JSEncrypt()
  jseJm.setPrivateKey(privkey)
  return jseJm.decrypt(text)
}

export default {
  genterRsaKey,
  rsaEncrypt,
  rsaDecrypt
}
