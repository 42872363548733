export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/budget/year/upcoming',
      method: 'POST'
    },
    {
      path: '/internalcontrol/purchase_batch/upcoming',
      method: 'POST'
    },
    {
      path: '/internalcontrol/purchase_method_batch/upcoming',
      method: 'POST'
    },
    {
      path: '/internalcontrol/assets_scrap_apply/upcoming',
      method: 'POST'
    },
    {
      path: '/internalcontrol/assets_repair_apply/upcoming',
      method: 'POST'
    },
    {
      path: '/internalcontrol/acceptance/upcoming',
      method: 'POST'
    }]
  }
}