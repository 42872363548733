<style scoped lang="less">
  @import url('./styles/values.less');
  .fm-breadcrumb-item:last-child {
    .fm-breadcrumb-link, .fm-breadcrumb-text {
      &, &:hover {
        color: @color-content-text;
        font-weight: bold;
      }
    }
    .fm-breadcrumb-separator {
      display: none;
    }
  }
  .fm-breadcrumb-separator {
    padding: 0 8px;
    color: @color-border;
    cursor: default;
  }
  .fm-breadcrumb-link, .fm-breadcrumb-text {
    user-select: none;
    transition: all .3s;
    color: @color-component-text;
    font-size: @size-font-norm;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
  .fm-breadcrumb-link {
    cursor: pointer;
    &:hover {
      color: @color-primary;
    }
  }
  .fm-breadcrumb-text {
    cursor: default;
  }
</style>

<template>
  <div class="fm-breadcrumb-item">
    <a v-if="isLink" :target="target" :href="to" class="fm-breadcrumb-link"><slot></slot></a>
    <router-link v-else-if="to" :to="to" tag="a" class="fm-breadcrumb-link"><slot></slot></router-link>
    <span v-else class="fm-breadcrumb-text"><slot></slot></span>
    <span class="fm-breadcrumb-separator" v-html="separator"></span>
  </div>
</template>

<script>
export default {
  name: 'FmBreadcrumbItem',
  inject: ['separator'],
  props: {
    to: {
      type: [Object, String],
      default: null
    },
    target: {
      type: String,
      default: '_self',
      validator (value) {
        return ['_blank', '_self', '_parent', '_top'].includes(value)
      }
    }
  },
  computed: {
    isLink () {
      return typeof this.to === 'string'
    }
  }
}
</script>
