<template>
  <div>
    <h2>Label标签宽度和右对其（<fm-tag>label-align="right"</fm-tag>）</h2>
    <FmForm label-width="100px" label-align="right">
      <FmFormItem label="标签">
        <FmInput placeholder="标签"/>
      </FmFormItem>
      <FmFormItem label="标签">
        <FmInput placeholder="标签"/>
      </FmFormItem>
    </FmForm>
    <h2>Label标签宽度和左对其（<fm-tag>label-align="left"</fm-tag>）</h2>
    <FmForm label-width="100px" label-align="left">
      <FmFormItem label="标签">
        <FmInput placeholder="标签"/>
      </FmFormItem>
      <FmFormItem label="标签">
        <FmInput placeholder="标签"/>
      </FmFormItem>
    </FmForm>

    <h2>Label标签单独一行（<fm-tag>label-alone</fm-tag>）</h2>
    <FmForm label-alone label-align="left">
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
    </FmForm>

    <h2>内联表单（<fm-tag>inline</fm-tag>）</h2>
    <FmForm label-align="right" inline>
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
    </FmForm>

    <h2>内联表单 - 将表单划分为4列（<fm-tag>:inline="4"</fm-tag>）</h2>
    <FmForm label-align="right" :inline="4" label-width="80px">
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
      <FmFormItem label="Select">
        <FmSelect placeholder="Select">
          <FmOption value="1">1</FmOption>
          <FmOption value="2">2</FmOption>
          <FmOption value="3">3</FmOption>
        </FmSelect>
      </FmFormItem>
      <FmFormItem label="时间">
        <FmDatePicker placeholder="时间" type="datetime"></FmDatePicker>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInput placeholder="标签1"/>
      </FmFormItem>
    </FmForm>

    <h2>表单验证</h2>
    <FmForm label-align="right" ref="verifier1">
      <FmFormItem label="标签1" required>
        <FmInputNew placeholder="fm-form-item required 非空验证"/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInputNew placeholder="验证指令required 非空验证" v-model="input.required" v-verifier required/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInputNew placeholder="数值型验证" v-model="input.number" v-verifier :rules="{type: 'number'}"/>
      </FmFormItem>
      <FmFormItem label="标签1">
        <fm-select required v-verifier placeholder="非空验证" v-model="input.select" filterable>
          <fm-option value="1" label="很好喝"></fm-option>
          <fm-option value="2" label="哈哈哈"></fm-option>
          <fm-option value="3" label="hhhh"></fm-option>
          <fm-option :value="0" label="0"></fm-option>
        </fm-select>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmDatePicker placeholder="时间日期选择 带确认 和 非空验证" confirm required v-verifier></FmDatePicker>
      </FmFormItem>
      <FmFormItem label="标签1">
        <FmInputNew placeholder="无验证" v-model="input.empty"/>
      </FmFormItem>
      <fm-btn @click="formVerifier">提交</fm-btn>
    </FmForm>
  </div>
</template>

<script>
export default {
  name: 'FmFormDemo',
  components: {},
  data() {
    return {
      select: null,
      input: {
        select: null,
        number: null,
        required: null,
        empty: null
      }
    }
  },
  props: {},
  computed: {},
  methods: {
    formVerifier () {
      const pass = this.$refs.verifier1.verifier()
      this.$notice[pass ? 'success' : 'warning']('验证' + (pass ? '通过' : '失败'))
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  @import './demo.less';
</style>