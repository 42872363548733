<template>
  <div>
    <h2>对话框(fm-modal)</h2>
    <div class="flex">
      <fm-btn @click="open1 = true">v-model</fm-btn>
      <fm-btn @click="open2 = true">自定义标题</fm-btn>
      <fm-btn @click="open3 = true">自定义关闭</fm-btn>
      <fm-btn @click="open4 = true">无标题栏</fm-btn>
      <fm-btn @click="open5 = true">点击遮罩不可关闭</fm-btn>
      <fm-btn @click="open6 = true">自定义页脚</fm-btn>
    </div>

    <FmModal v-model="open1" title="v-model">双向绑定的对话框</FmModal>

    <FmModal v-model="open2">
      <div style="color: red; text-align: center;" slot="header">标题自定义</div>标题自定义
    </FmModal>

    <FmModal v-model="open3" width="200" title="对话框">
      <div slot="close">关闭</div>slot.close插槽自定义关闭
    </FmModal>

    <FmModal v-model="open4">无标题栏</FmModal>

    <FmModal v-model="open5" :mask-closable="false">点击遮罩不可关闭</FmModal>

    <FmModal :value="open6" :mask-closable="false" :closable="false">
      <p>通过closable属性设置为false即可关闭右上角关闭按钮</p>
      <p>value属性也可以控制打开关闭</p>
      <div slot="footer" style="display:flex;justify-content: space-between;">
        <fm-btn @click="open6 = false">取消</fm-btn>
        <fm-btn @click="open6 = false">确定</fm-btn>
      </div>
    </FmModal>

    <div class="inner-box">
      <fm-btn @click="open7 = true">打开</fm-btn>
      <FmModal v-model="open7" inner title="内部对话框">内部对话框</FmModal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmModalDemo',
  components: {},
  data() {
    return {
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      open6: false,
      open7: false,
    }
  },
  props: {},
  computed: {},
  methods: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  .inner-box {
    width: 300px;
    height: 300px;
    position: relative;
  }
</style>