<style scoped lang="less">
  @import url('../../styles/theme.less');
  .table-actions {
    display: flex;
    word-break: keep-all;
    span {
      color: rgba(0, 0, 0, .65);
      font-size: 14px;
      display: flex;
      word-break: keep-all;
      cursor: pointer;
    }
    span + span {
      margin-left: 5px;
      &::before {
        margin-right: 5px;
        content: '';
        width: 1px;
        background-color: rgba(0, 0, 0, .65);
      }
    }
  }
  .pink-theme {
    .table-actions {
      span {
        color: @color-bright-pink;
      }
      span + span {
        &::before {
          background-color: @color-bright-pink;
        }
      }
    }
  }
</style>

<template>
  <div class="table-actions">
    <span v-for="item in data.filter(v => !v.show || v.show(row))" @click="$emit('table-action', {action: item.key, data: row, index: index})" :key="item.key">{{item.label}}</span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    row: { type: Object },
    index: {
      type: Number, default: undefined
    }
  }
}
</script>
