<template>
  <div>
    <h2>单选框(fm-radio) - {{gender}}</h2>
    <div class="flex">
      <fm-radio v-model="gender" name="gender" value="男" label="男"></fm-radio>
      <fm-radio v-model="gender" name="gender" value="女" label="女"></fm-radio>
      <fm-btn @click="gender = '男'">男</fm-btn>
      <fm-btn @click="gender = '女'">女</fm-btn>
    </div>

    <h2>不可用(disabled)</h2>
    <div class="flex">
      <fm-radio v-model="gender" name="gender" disabled value="未知" label="未知"></fm-radio>
    </div>
    
    <h2>组合(fm-radio-group) - {{fruit}}</h2>
    <div class="flex">
      <fm-radio-group v-model="fruit" name="fruit">
        <fm-radio value="桃子" label="桃子"></fm-radio>
        <fm-radio value="芒果" label="芒果"></fm-radio>
      </fm-radio-group>
    </div>

    <h2>组合垂直排列(vertical) {{fruit1}}</h2>
    <div class="flex">
      <fm-radio-group v-model="fruit1" name="fruit1" vertical>
        <fm-radio value="桃子" label="桃子"></fm-radio>
        <fm-radio value="芒果" label="芒果"></fm-radio>
      </fm-radio-group>
    </div>

    <h2>事件(<fm-tag>change</fm-tag>) - {{fruit2}}</h2>
    <div class="flex">
      <fm-radio-group name="event" v-model="fruit2" vertical @change="(value) => $notice.info(value)">
        <fm-radio value="桃子" label="桃子"></fm-radio>
        <fm-radio value="芒果" label="芒果"></fm-radio>
        <fm-radio value="过期" label="过期" disabled></fm-radio>
      </fm-radio-group>
      <fm-btn @click="fruit2 = '桃子'">桃子</fm-btn>
      <fm-btn @click="fruit2 = '芒果'">芒果</fm-btn>
      <fm-btn @click="fruit2 = '过期'">过期</fm-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FmRadioDemo",
  components: {},
  data() {
    return {
      gender: '',
      gender1: '男',
      fruit: '',
      fruit1: '芒果',
      fruit2: '芒果'
    }
  },
  props: {},
  computed: {},
  methods: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  h2 {margin: 20px 0;}
</style>