<style scoped></style>

<template>
  <div>
    <h2>分页(<fm-tag>fm-page</fm-tag>)</h2>
    <div class="flex">
      <fm-page :total="30" :current="1"></fm-page>
      <fm-page :total="300" :current="7"></fm-page>
    </div>
    <h2>自定义上下页(<fm-tag>prev-text</fm-tag><fm-tag>next-text</fm-tag>)</h2>
    <fm-page :total="300" prev-text="上一页" next-text="下一页" :current="15"></fm-page>
    <h2>显示总条数(<fm-tag>show-total</fm-tag>)</h2>
    <fm-page :total="300" :current="28" show-total></fm-page>
    <h2>页数设置(<fm-tag>show-sizer</fm-tag>)</h2>
    <fm-page :total="300" :current="28" show-sizer></fm-page>
    <h2>电梯(<fm-tag>show-elevator</fm-tag>)</h2>
    <fm-page :total="300" :current="28" show-elevator></fm-page>
    <h2>简洁模式(<fm-tag>simple</fm-tag>)</h2>
    <fm-page :total="300" :current="28" simple></fm-page>
  </div>
</template>

<script>
export default {
  name: 'FmPageDemo'
}
</script>
