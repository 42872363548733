import {
  workerRequest
} from '../api'

export const delUser = async (vm, userId) => {
  let allWorkers = await vm.$store.dispatch('loadAllWorkerList')
  let findWorker = allWorkers.find(v => v.data.userId === userId)
  if (findWorker) {
    findWorker = findWorker.data
    const result = await vm.$dialog.confirm({title: '系统提示', content: '确定删除用户及员工 ' + findWorker.name + ' 吗?'})
    if (result) {
      await workerRequest.del(findWorker.id)
      return 'done'
    }
    return 'cancel'
  }
  return false
}

export const updateUser = async (vm, user) => {
  let allWorkers = await vm.$store.dispatch('loadAllWorkerList')
  let findWorker = allWorkers.find(v => v.data.userId === user.id)
  if (findWorker) {
    findWorker = findWorker.data
    await workerRequest.update(findWorker.id, {
      name: user.name,
      phone: user.phone,
      idNo: user.idNo,
      sex: user.sex
    })
    return 'done'
  }
  return false
}