<template>
  <div class="fm-checkbox" :class="checkboxClass" @click="toggle">
    <i></i>
    <template v-if="$slots.default"><slot></slot></template>
    <template v-else>{{label}}</template>
    <input type="hidden" :name="label" :disabled="disabled !== false" :value="value" />
  </div>
</template>

<script>
import {findParentComponent} from '../basic/index'
export default {
  name: 'FmCheckbox',
  props: {
    border: { type: Boolean, default: false },
    label: { type: [Boolean, Number, String], default: '' },
    disabled: { type: Boolean, default: false },
    value: { type: [Boolean, Number, String], default: null},
    trueValue: {type: [Boolean, Number, String], default: true},
    falseValue: {type: [Boolean, Number, String], default: false},
    size: {
      type: String,
      default: 'norm',
      validator: function(size) {
        return ['norm', 'large', 'small', 'mini'].includes(size)
      }
    },
  },
  data () {
    return {
      data: this.value === this.trueValue,
      isGroup: false,
      parent: null
    }
  },
  watch: {
    value () {
      if (this.data !== (this.value === this.trueValue)) {
        this.data = this.value === this.trueValue
        this.$emit('change', this.data)
      }
    }
  },
  computed: {
    isDisabled () {
      return [undefined, true, ''].includes(this.disabled)
    },
    isChecked () {
      return this.data
    },
    checkboxClass () {
      return {
        'fm-checkbox-checked': this.isChecked,
        'fm-checkbox-border': this.border !== false,
        'fm-checkbox-disabled': this.isDisabled,
        [`fm-checkbox-${this.size}`]: true,
      }
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  methods: {
    toggle () {
      if (!this.isDisabled) {
        this.data = !this.isChecked
        const value = this.isChecked ? this.trueValue : this.falseValue
        this.$emit('input', value)
        this.$emit('change', value)
        if (this.isGroup) {
          this.isChecked ? this.parent.appendValue(this.label) : this.parent.rmeoveValue(this.label)
        }
      }
    }
  },
  mounted () {
    let parent = null
    if (this.$parent._isVue && this.$parent.$options.name === 'FmCheckboxGroup') {
      parent = this.$parent
    } else {
      parent = findParentComponent(this, 'FmCheckboxGroup')
    }
    this.parent = parent
    this.isGroup = parent ? true : false

    if (this.isGroup) {
      this.data = this.parent.data && Array.isArray(this.parent.data) && this.parent.data.includes(this.label)
    }
  }
}
</script>

<style lang="less">
@import './styles/values.less';

@keyframes ani-checkbox-in {
  from {transform: scale(0) rotate(45deg); opacity: 0;}
  to {transform: scale(1) rotate(45deg); opacity: 1;}
}

@keyframes ani-checkbox-out {
  from {transform: scale(1) rotate(45deg); opacity: 1;}
  to {transform: scale(0) rotate(45deg); opacity: 0;}
}

.fm-checkbox {
  user-select: none;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: @color-component-text;
  border: 1px solid transparent;
  border-radius: 5px;

  input {display: none;}

  &.fm-checkbox-border {
    transition: all .3s;
    border-color: @color-border;
    padding: 0 10px;
  }

  &.fm-checkbox-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  & > i:first-child {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid @color-component-text;
    transition: all .3s;
    border-radius: 2px;
    &::after {
      content: '';
      animation: ani-checkbox-out 0.2s linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  &:hover {
    & > i:first-child {
      border-color: @color-primary;
      background-color: rgba(28, 181, 224, .1);
    }
    &.fm-checkbox-border {
      border-color: @color-primary;
    }
  }

  &.fm-checkbox-checked {
    & > i:first-child {
      border-color: @color-primary;
      background-color: @color-primary;
      color: @color-primary;
      &::after {
        animation: ani-checkbox-in 0.2s linear;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }

  // 大小设置
  &.fm-checkbox-large {
    height: @size-height-large;
    font-size: @size-font-large;
    & + & {margin-left: 10px;}
    & > i:first-child {
      padding: 4px 8px;
      &::after {
        height: 12px;
        width: 6px;
        border: 4px solid #FFF;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  &.fm-checkbox-norm {
    height: @size-height-norm;
    font-size: @size-font-norm;
    & + & {margin-left: 8px;}
    &.fm-checkbox-norm {
      height: @size-height-norm;
      font-size: @size-font-norm;
      & > i:first-child {
        padding: 2px 4px;
        &::after {
          height: 8px;
          width: 4px;
          border: 2px solid #FFF;
          border-top: 0;
          border-left: 0;
        }
      }
    }
  }

  &.fm-checkbox-small {
    height: @size-height-small;
    font-size: @size-font-small;
    & + & {margin-left: 6px;}
    &.fm-checkbox-small {
      height: @size-height-small;
      font-size: @size-font-small;
      & > i:first-child {
        padding: 2px 4px;
        margin-right: 6px;
        &::after {
          height: 6px;
          width: 3px;
          border: 2px solid #FFF;
          border-top: 0;
          border-left: 0;
        }
      }
    }
  }

  &.fm-checkbox-mini {
    height: @size-height-mini;
    font-size: @size-font-mini;
    & + & {margin-left: 4px;}
    &.fm-checkbox-mini {
      height: @size-height-mini;
      font-size: @size-font-mini;
      & > i:first-child {
        margin-right: 4px;
        padding: 1px 2px;
        &::after {
          height: 6px;
          width: 3px;
          border: 1px solid #FFF;
          border-top: 0;
          border-left: 0;
        }
      }
    }
  }
}
</style>