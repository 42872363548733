<template>
  <div class="fm-radio" :class="{
    'fm-radio-checked': currentValue,
    'fm-radio-disabled': this.disabled === undefined || this.disabled === true
  }">
    <label>
      <input type="radio" @change="change" :disabled="this.disabled === undefined || this.disabled === true" :checked="currentValue" :value="value" :name="groupName" ref="radio" />
      <i class="fmico fmico-radio-checked"></i>
      <i class="fmico fmico-radio"></i>
      <span class="fm-radio-label">{{label}}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FmRadio',
  components: {},
  data() {
    return {
      isGroup: false,
      groupName: this.name,
      currentValue: false
    }
  },
  model: {
    prop: 'checked',
    event: 'input'
  },
  props: {
    name: {type: String, default: ''},
    label: {type: String, default: ''},
    value: {type: [Boolean, Number, String], default: null},
    checked: {type: [Boolean, Number, String], default: null},
    disabled: {type: Boolean, default: false},
  },
  computed: {},
  methods: {
    change () {
      this.$emit('input', this.value)
      this.currentValue = this.$refs.radio.checked

      if (this.isGroup) {
        this.$parent.change({
          label: this.label,
          value: this.value
        })
      } else {
        this.$emit('change', this.value)
      }
    },
    updateValue () {
      this.currentValue = this.checked === this.value
    }
  },
  watch: {
    checked () {
      this.currentValue = this.checked === this.value
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    if (this.$parent._isVue && this.$parent.$el.classList.contains('fm-radio-group')) {
      this.isGroup = true
      this.groupName = this.$parent.name
      this.$parent.updateValue()
    } else {
      this.updateValue()
    }
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
  @import './styles/values.less';
  .fm-radio-label {
    padding-left: 5px;
  }
  .fm-radio {
    display: inline-block;
    i.fmico, .fm-radio-label {
      color: #657180;
    }
    &.fm-radio-checked {
      .fmico, .fm-radio-label {
        color: @color-primary;
      }
    }
    &.fm-radio-disabled {
      .fmico, .fm-radio-label {
        color: @color-disabled-text !important;
      }
    }
    label {
      .fm-radio-label, i.fmico {
        transition: all .3s;
      }
      &:hover {
        .fmico, .fm-radio-label {
          color: @color-primary;
        }
      }
      input[type="radio"] {
        & ~ i.fmico.fmico-radio {
          display: block;
        }
        & ~ i.fmico.fmico-radio-checked {
          display: none;
        }
        &:checked {
          & ~ i.fmico.fmico-radio-checked {
            display: block;
          }
          & ~ i.fmico.fmico-radio {
            display: none;
          }
        }
      }
    }
    & + & {margin-left: 10px;}
  }
</style>

<style lang="less" scoped>
  .fm-radio {
    label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      position: relative;
    }
  }
  input[type="radio"] {
    display: none;
  }
</style>