<template>
  <div class="fm-multiple-choose">
    <!-- <div class="dc-gas-one" :key="item[dataKey]" v-for="item in chooseData.choosedData">{{showLabelFormat(item[dataLabel])}}
      <div class="icon-guanbi-div">
        <i class="icon-guanbi iconfont" @click="clearGas(item[dataKey])"></i>
      </div>
    </div> -->
    <fm-select style="width:8rem;" multiple @change="chooseOne" :size="size" v-model="selectData" :placeholder="dataPlaceholder">
      <fm-option v-for="item in chooseData.canChooseData" :label="item[dataLabel]" :value="item[dataKey]" :key="item[dataKey]"></fm-option>
    </fm-select>
  </div>
</template>

<script>
export default {
  name: 'FmMultipleChoose',
  props: {
    size: {
      type: String,
      default: 'norm'
    },
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    showLabelFormat: {
      type: Function,
      default: (data) => {
        return data
      }
    },
    chooseDataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    dataKey: {
      type: String,
      default: 'key'
    },
    dataLabel: {
      type: String,
      default: 'label'
    },
    dataPlaceholder: {
      type: String,
      default: '请选择'
    }
  },
  data () {
    return {
      selectData: null
    }
  },
  computed: {
    chooseData: {
      get () {
        let data = []
        let canChooseData = []
        this.dataList.forEach((item) => {
          let dataIndex = this.chooseDataList.indexOf(item[this.dataKey])
          if (dataIndex > -1) {
            data[dataIndex] = item
          } else {
            canChooseData.push(item)
          }
        })
        return {
          choosedData: data,
          canChooseData: canChooseData
        }
      }
    }
  },
  model: {
    prop: 'chooseDataList',
    event: 'change'
  },
  methods: {
    clearGas (dataId) {
      let data = []
      let dataIndex = this.chooseDataList.indexOf(dataId)
      if (dataIndex > -1) {
        data = this.chooseDataList.slice(0, dataIndex).concat(this.chooseDataList.slice(dataIndex + 1))
      }
      this.$emit('change', data)
    },
    chooseOne (data) {
      let newData = []
      if (data) {
        newData = this.chooseDataList.concat([data])
        this.selectData = null
      }
      this.$emit('change', newData)
    }
  }
}
</script>

<style lang="less" scoped>
@import './styles/values.less';
.fm-multiple-choose {
  display: flex;
  flex-wrap: wrap;
}
.dc-gas-one {
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  height: 2rem;
  border: 1px solid @color-gray;
  margin-right: 0.8rem;
  padding: 0.1rem 0.5rem;
  display: flex;
  align-items: center;
  .icon-guanbi-div {
    background: @color-btn-bak;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
  }
  .icon-guanbi {
    font-size: 1rem;
    color: @color-btn-font;
  }
}
</style>
