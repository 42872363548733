<template>
  <div class="inventory-chart" v-loadingx="loading">
    <fm-title v-if="!noTitle" :title-text="title" title-size="small" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
      <div class="pi-stock-tanks" v-if="inventoryCell && inventoryCell.inventoryCellList && inventoryCell.inventoryCellList.length > 1">
        <div class="choose-tank-btn" :class="{'active-tank-btn': item.id === chooseInventoryCellId}" :key="item.id" v-for="item in inventoryCell.inventoryCellList" @click="clickInventoryCell(item.id)">
          {{item.tankMark}}
        </div>
      </div>
      <div v-if="hisTime" class="pi-stock-tanks">{{hisTime}}</div>
    </fm-title>
    <div class="all-order-table" v-if="chartOption && !errorMsg">
      <fm-echart :chart-option="chartOption"></fm-echart>
      <div class="pi-in-order" v-if="config.showOrder">
        <div class="block-data_div">
          <fm-table
            :column-list="orderTable.columnList"
            :data-list="orderTable.orderList"
            @checkDataChange="checkDataChange"
            :show-checkbox="true">
          </fm-table>
        </div>
      </div>
    </div>
    <fm-modal
      :title="'日销量 ' + title"
      v-model="config.showCalendar"
      width="60%"
      theme="mh-withe"
      v-if="config.showCalendar">
      <div class="pi-day-sale-div">
        <fm-inventory-calendar
          :load-tag="invenyoryCalendarRefreshTag"
          :inventory-cell-id="chooseInventoryCellId"
          :data-request="calendarDataRequest"
          @reloadData="loadData()">
        </fm-inventory-calendar>
      </div>
    </fm-modal>
    <div class="all-order-table pi-chart-error" v-if="!chooseInventoryCellId">
      <span>请选择油罐</span>
    </div>
    <div class="all-order-table pi-chart-error" v-if="chooseInventoryCellId && errorMsg">
      <span>{{errorMsg}}</span>
    </div>
  </div>
</template>

<script>
import {
  dealData,
  dealMark,
  reCalculationHourStatus,
  reCalculationHourStatusByReal
} from './fm_inventory_chart/lib'

import {
  updateOption
} from './fm_inventory_chart/chart_option'

import {XDate} from './FmDatePicker/lib'

let dcopy = require('deep-copy')

export default {
  name: 'FmInventoryChart',
  props: {
    lineTypes: {
      type: Array
    },
    theme: {
      // 暂时只定义 简介版 或正常版
      type: String,
      default: 'norm'
    },
    hisTime: {
      type: String,
      default: null
    },
    currentTime: {
      type: Date
    },
    notUpdate: {
      type: Boolean,
      default: false
    },
    planDate: {
      type: String
    },
    // gourp_id || tank_id
    inventoryCellId: {
      type: String
    },
    tools: {
      // 包括 日历销量、存货仓切换、配送单、走势修正
      type: String,
      default: ''
    },
    dataRequest: {
      type: Function
    },
    chartData: {
      type: Object
    },
    calendarDataRequest: {
      type: Function
    },
    isWarnTime: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    needInTimeCount: {
      type: Boolean,
      default: false
    },
    refreshTag: {
      type: Number,
      default: 0
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    }
  },
  watch: {
    refreshTag: {
      immediate: true,
      handler () {
        let hisChooseInventoryCellId = this.chooseInventoryCellId
        this.chooseInventoryCellId = this.inventoryCellId
        if (!this.notUpdate || hisChooseInventoryCellId === null) {
          this.loadData()
        }
      }
    }
  },
  computed: {
    useLineTypes: {
      get () {
        let data = this.lineTypes || []
        return data
      }
    },
    titleMenus: {
      get () {
        let data = []
        let dataMap = {
          'order': {
            label: '订单'
          },
          'calendar_sale': {
            label: '日销量'
          // },
          // 'hour_sale': {
          //   label: '小时销量走势'
          // },
          // 'revise': {
          //   label: this.reviseByReal ? '还原' : '修正'
          // },
          // 'in_time_count': {
          //   label: '入库时间分布'
          }
        }
        let keyList = this.tools.split(',')
        for (let key in dataMap) {
          if (keyList.includes(key)) {
            data.push({
              key,
              label: dataMap[key].label
            })
          }
        }
        return this.inventoryCell.id ? data : []
      }
    },
    title: {
      get () {
        let title = '库存走势'
        if (this.inventoryCell.id) {
          title = this.inventoryCell.name
        }
        return title
      }
    }
  },
  data () {
    return {
      loading: false,
      errorMsg: null,
      inventoryCell: {},
      originHourStatus: [],
      hourStatus: [],
      arrivalTimeRange: {},
      maxValue: null,
      minValue: null,
      chartOption: null,
      invenyoryCalendarRefreshTag: 0,
      reviseByReal: false,
      chooseInventoryCellId: null,
      config: {
        showOrder: false,
        showCalendar: false,
        showInTimeCount: false,
        showHourSale: false
      },
      orderTable: {
        checkedData: [],
        orderList: [],
        columnList: [{
          field: 'carNo',
          title: '车辆'
        },
        {
          field: 'status',
          title: '状态'
        },
        {
          field: 'volume',
          title: '配送量'
        },
        {
          field: 'time',
          title: '入库时间'
        }]
      }
    }
  },
  methods: {
    checkDataChange (data) {
      let newData = []
      data.forEach((item) => {
        newData.push(item.id)
      })
      if (newData.join(',') !== this.orderTable.checkedData.join(',')) {
        this.orderTable.checkedData = newData
        this.updateHourStatus()
      }
    },
    clickInventoryCell (data) {
      let needLoad = this.chooseInventoryCellId !== data
      if (needLoad) {
        this.chooseInventoryCellId = data
        this.loadData()
      }
    },
    updateHourStatus () {
      if (this.reviseByReal) {
        this.hourStatus[0] = reCalculationHourStatusByReal(dcopy(this.originHourStatus[0]), this.orderTable.checkedData, this.inventoryCell.stopPumpCapacity, this.inventoryCell.validCapacity)
      } else {
        if (this.orderTable.checkedData && this.orderTable.checkedData.length > 0) {
          this.hourStatus[0] = reCalculationHourStatus(dcopy(this.originHourStatus[0]), this.orderTable.checkedData, this.inventoryCell.stopPumpCapacity, this.inventoryCell.validCapacity)
        } else {
          this.hourStatus[0] = dcopy(this.originHourStatus[0])
        }
      }
      this.buildChartOPtion()
    },
    loadData () {
      if (!this.chooseInventoryCellId) {
        return
      }
      this.loading = true
      this.errorMsg = null
      this.config.showCalendar = false
      this.orderTable.checkedData = []
      this.orderTable.orderList = []
      this.inventoryCell = {}
      this.originHourStatus = []
      this.hourStatus = []
      this.arrivalTimeRange = {}
      this.chartOption = null
      let parm = {
        id: this.chooseInventoryCellId,
      }
      if (this.chartData) {
        this.originHourStatus = this.chartData.hourStatus
        this.hourStatus = dcopy(this.originHourStatus)
        this.arrivalTimeRange = this.chartData.arrivalTimeRange
        this.maxValue = this.chartData.maxValue
        this.minValue = this.chartData.minValue
        this.inventoryCell = this.chartData.inventoryCell
        this.orderTable.orderList = this.chartData.orderList
        this.errorMsg = this.chartData.errorMsg
        this.updateHourStatus()
        this.loading = false
      } else {
        this.dataRequest(parm).then((data) => {
          if (!data.inventoryCell || !data.inventoryCell.id || data.inventoryCell.id !== this.chooseInventoryCellId) {
            this.loading = false
            return
          }
          this.originHourStatus = data.hourStatus
          this.hourStatus = dcopy(this.originHourStatus)
          this.arrivalTimeRange = data.arrivalTimeRange
          this.maxValue = data.maxValue
          this.minValue = data.minValue
          this.inventoryCell = data.inventoryCell
          this.orderTable.orderList = data.orderList
          this.errorMsg = data.errorMsg
          this.updateHourStatus()
          this.loading = false
        }).catch((e) => {
          console.log(e)
          this.loading = false
        })
      }
    },
    buildChartOPtion () {
      let markData = dealMark(this.minValue, this.maxValue, this.arrivalTimeRange.start, this.arrivalTimeRange.end)
      let lineList = []
      let maxLineValue = this.maxValue || 0
      this.hourStatus.forEach((item) => {
        let data =  dealData(item, this.maxValue)
        lineList = lineList.concat(data.lineList)
        maxLineValue = maxLineValue < data.maxLineValue ? data.maxLineValue : maxLineValue
      })
      let option = updateOption({lineList, btmValue: this.minValue, topValue: this.maxValue, maxValue: maxLineValue, simple: this.theme === 'simple', markData})
      this.chartOption = option
    },
    clickTitleMenu (key) {
      if (key === 'order') {
        this.config.showOrder = !this.config.showOrder
      } else if (key === 'calendar_sale') {
        this.config.showCalendar = !this.config.showCalendar
        this.$nextTick(() => {
          this.invenyoryCalendarRefreshTag += 1
        })
      } else if (key === 'hour_sale') {
        this.config.showHourSale = !this.config.showHourSale
        this.buildChartOPtion()
      } else if (key === 'revise') {
        this.reviseByReal = !this.reviseByReal
        this.updateHourStatus()
      } else if (key === 'in_time_count') {
        this.config.showInTimeCount = !this.config.showInTimeCount
        this.updateHourStatus()
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './styles/values.less';
.inventory-chart {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.all-order-table {
  flex: 1;
  max-width: 100%;
  position: relative;
}
.pi-in-order {
  position: absolute;
  max-width: 100%;
  top: 0;
  right: 0;
  width: 30rem;
  bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  box-shadow: @size-shadow @color-shadow;
}
.block-data_div {
  flex: 1;
}
.pi-chart-error {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2;
  color: @color-warning;
  span {
    font-size: @size-font-small;
  }
  div {
    display: flex;
    flex-direction: column;
  }
  .pi-chart-error-title {
    font-size: @size-font-large;
  }
}
.pi-stock-tanks {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose-tank-btn {
  background-color: @color-gray-e5;
  color: #999;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  width: 2rem;
}
.active-tank-btn {
  background-color: @color-orange;
  color: #fff;
}
.pi-day-sale-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="less">
.inventory-chart {
  .v-table-header, .v-table-toolbar, .v-table-pager, .v-table-footer-inner {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .v-table-title-class {
    background-color: rgba(255, 255, 255, 0.8) !important;
  }
  .mr-content-block {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .v-table-views {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .v-table-class  {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .v-table-row {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
</style>
