export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/ask_leave_apply',
      method: 'GET'
    },
    {
      path: '/basis/ask_leave_apply/type',
      method: 'GET'
    },
    {
      path: '/basis/status_manage/config',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '明细'
  },
  back: {
    funKey: 'back',
    name: '状态切换',
    apis: [{
      path: '/basis/ask_leave_apply/switch/{id}',
      method: 'POST'
    }]
  }
}