export default {
  functional: true,
  inject: ['table'],
  props: {
    menu: { type: Object, default: () => ({}) }
  },
  render: function (h, ctx) {
    const { row, cell, area } = ctx.injections.table.contextMenuData
    return h('div', {
      class: {'fm-table-new-menu': true},
      on: {
        click () {
          ctx.injections.table.$emit('on-context-menu', ctx.props.menu.key, ctx.injections.table.contextMenuData)
        }
      }
    }, ctx.props.menu.render ? [ctx.props.menu.render(h, row, cell, area)] : ctx.props.menu.label)
  }
}
    