<template>
<div class="sign-page-content content-block">
  <div class="content-top">
    <fm-title :title-text="titleText" :note-text="noteText" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
      <slot name="title"></slot>
    </fm-title>
  </div>
  <div class="content-body">
    <fm-table-new
      :simple-filter="simpleFilter"
      :columns="columns"
      v-loadingx="loading"
      border="row"
      ref="table"
      :toolbox="toolbox"
      :export-file-name="exportFileName"
      :data-list="dataList"
      :selection="showCheckbox"
      :stripe="false"
      size="large"
      emptyPlaceholder="-"
      @on-select-change="checkDataChange"
      @counterChange="countDataChange"
      :handler-counter="countFun">
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
      <div slot="empty" style="margin: 30px 0;">
        <img src="/static/images/null.png" style="width: 40px;"/>
        <div>暂无数据</div>
      </div>
    </fm-table-new>
  </div>
  <fm-form-dialog
    :form-title="titleText"
    :open-dialog.sync="openDialog"
    :form-parms="formParms"
    :old-data="hisData"
    :mask-closable="false"
    :on-change="onFormChange"
    label-alone
    label-align="left"
    form-width="800px"
    @formSubmit="formSubmit"
    @handleClose="formHandleClose">
  </fm-form-dialog>
  <fm-modal :title="dataFileTitle" :mask-closable="false" :funs="dataFilefuns" :value="openDialogDataFile" @cancel="dataFileHandleClose" width="600px">
    <file-manage
      v-if="openDialogDataFile"
      :upload-check="dataFileUploadCheck"
      :file-data-ids="fileDataIds"
      :funs="dataFilefuns"></file-manage>
  </fm-modal>
</div>
</template>

<script>
import TableActions from './TableActions'
import FileManage from './FileManage'

export default {
  name: 'BaseSignBlock',
  components: {
    TableActions,
    FileManage
  },
  props: {
    fileDataIds: {
      type: Array
    },
    fileParm: {
      type: Object
    },
    onFormChange: {
      type: Function
    },
    dataFileUploadCheck: {
      type: Function
    },
    dataFilefuns: {
      type: Object
    },
    dataFileTitle: {
      type: String
    },
    dataFileDirPath: {
      type: String
    },
    countParm: {
      type: Object
    },
    formParms: {
      type: Array
    },
    hisData: {
      type: Object
    },
    pageParm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    openDialogDataFile: {
      type: Boolean
    },
    titleText: {
      type: String,
      default: '页面标题'
    },
    openDialog: {
      type: Boolean
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    breadcrumb: {
      type: Boolean,
      default: false
    },
    titleMenus: {
      type: Array
    },
    tableActions: {
      type: Array
    },
    countFun: {
      type: Function
    },
    searchParms: {
      type: Array
    },
    noteText: {
      type: String,
      default: '副标题'
    },
    dataList: {
      type: Array
    },
    columnList: {
      type: Array
    },
    loading: { type: Boolean, default: false },
    tableActionsWidth: { type: Number, default: null },
    tableActionsFixed: { type: Boolean, default: false },
    simpleFilter: { type: Boolean, default: true },
    tableSize: { type: String, default: 'small' },
    toolbox: {
      type: Array, default () {
        return []
      }
    },
    exportFileName: { default: '导出数据' },
    tableKey: { type: String, default: 'main' }
  },
  computed: {
    columns () {
      return this.$getTableConfig(this.tableKey, this.baseColumns)
    },
    baseColumns () {
      if (!this.tableActions || !this.tableActions.length || this.columnList.find(v => v.slot === 'actions')) {
        return this.columnList
      } else {
        return [...this.columnList, {title: '操作', slot: 'actions', width: this.tableActionsWidth, fixed: this.tableActionsFixed ? 'right' : false, search: false, export: false, configurable: false}]
      }
    }
  },
  methods: {
    dataFileHandleClose () {
      this.$emit('dataFileHandleClose')
    },
    dataFileUpdate (parm) {
      this.$emit('dataFileUpdate', parm)
    },
    getTableData () {
      return this.$refs.table.sortData
    },
    formSubmit (data, resolve) {
      this.$emit('formSubmit', data, resolve)
    },
    formHandleClose () {
      this.$emit('formHandleClose')
    },
    countDataChange (parm) {
      this.$emit('countDataChange', parm)
    },
    clickSearch (parm) {
      this.$emit('clickSearch', parm)
    },
    clickTitleMenu (key, parm) {
      this.$emit('clickTitleMenu', key, parm)
    },
    tableAction (parm) {
      this.$emit('tableAction', parm)
    },
    currentPageChange (parm) {
      this.$emit('currentPageChange', parm)
    },
    checkDataChange (parm) {
      this.$emit('checkDataChange', parm)
    }
  },
  mounted () {
    this.$tableColumnUpdate(this.$refs.table, this.tableKey)
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
.content-bottom {
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-page-div {
  border-radius: 8px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sign-page-div-no-padding {
  margin: 0;
}
.sign-page {
  width: 100%;
  height: 100%;
  display: flex;
  .fm-table-head-cells {
    background-color: #FFF;
  }
}
.sign-page-top-1 {
  margin-bottom: 1rem;
}
.sign-page-top-2 {
  margin-bottom: 1.25rem;
}
.sign-page-content {
  flex: 1;
}
.content-body {
  flex: 1;
}
</style>
