<style scoped>
  @import url('./demo.less');
</style>

<template>
  <div>
    <h2>评分(<fm-tag>fm-rate</fm-tag>)</h2>
    <div><fm-rate></fm-rate></div>
    <h2>半星(<fm-tag>half</fm-tag>)</h2>
    <div><fm-rate half></fm-rate></div>
    <h2>自定义字符(<fm-tag>char="好"</fm-tag>)</h2>
    <div><fm-rate char="好"></fm-rate></div>
    <h2>自定义字符+半星</h2>
    <div><fm-rate half char="好"></fm-rate></div>
    <h2>双向绑定</h2>
    <div>
      <fm-rate v-model="v1" half></fm-rate>{{v1}}
      <fm-rate v-model="v2"></fm-rate>{{v2}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmRateDemo',
  data () {
    return {
      v1: 2.5,
      v2: 2
    }
  }
}
</script>
