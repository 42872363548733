export default {
  routerNames: ['worker'],
  funs: {
    get: {
      funKey: 'get',
      name: '人员查看',
      apis: [
        { method: 'GET', path: '/performance/pfm_worker' },
        { method: 'GET', path: '/performance/pfm_position' },
        { method: 'GET', path: '/ucenter/user' }
      ]
    },
    manage: {
      funKey: 'manage',
      name: '人员管理',
      apis: [
        { method: 'PUT', path: '/performance/pfm_worker/{id}' },
        { method: 'POST', path: '/performance/pfm_worker' },
        { method: 'DELETE', path: '/performance/pfm_worker/{id}' }
      ]
    },
    positionGet: {
      funKey: 'positionGet',
      name: '绩效职务查看',
      apis: [
        { method: 'GET', path: '/performance/pfm_position' },
      ]
    },
    positionManage: {
      funKey: 'positionManage',
      name: '绩效职务管理',
      apis: [
        { method: 'PUT', path: '/performance/pfm_position/{id}' },
        { method: 'POST', path: '/performance/pfm_position' },
        { method: 'DELETE', path: '/performance/pfm_position/{id}' }
      ]
    },
    config: {
      funKey: 'config',
      name: '员工月度明细默认配置',
      apis: [
        { method: 'GET', path: '/performance/pfm_worker_month_detail_default' },
        { method: 'PUT', path: '/performance/pfm_worker_month_detail_default/{id}' },
        { method: 'POST', path: '/performance/pfm_worker_month_detail_default' },
        { method: 'DELETE', path: '/performance/pfm_worker_month_detail_default/{id}' }
      ]
    }
  }
}