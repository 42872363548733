<style lang="less">
.fm-poptip {
  position: relative;
}
.fm-poptip-content {
  z-index: 0;
}
.fm-poptip-wrap {
  z-index: 1;
  position: absolute;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0 0 8px 0 #BBB;
}
.fm-poptip-arrow {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  position: absolute;
  z-index: 1;
}
.fm-poptip-transition-enter-active, .fm-poptip-transition-leave-active {
  transition: all .3s;
  opacity: 1;
}
.fm-poptip-transition-enter, .fm-poptip-transition-leave-to {
  opacity: 0;
}
.fm-poptip-transform {
  .fm-poptip-transition-enter-active, .fm-poptip-transition-leave-active {
    transform: translateY(0);
  }
  .fm-poptip-transition-enter, .fm-poptip-transition-leave-to {
    transform: translateY(-10px);
  }
}
</style>

<template>
  <div class="fm-poptip" :class="{'fm-poptip-show': show, 'fm-poptip-transform': transform}" :style="style" @mouseenter="toogle" @mouseleave="toogle">
    <div class="fm-poptip-content" :style="{display: display || targetDisplay}" ref="content" @click.capture="toogle"><slot></slot></div>
    <transition name="fm-poptip-transition">
      <div class="fm-poptip-wrap" ref="wrap" :style="wrapStyle" v-show="show">
        <span class="fm-poptip-arrow" v-if="arrow" :style="arrowStyle"></span>
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FmPoptip',
  data () {
    return {
      targetDisplay: 'none',
      show: false,
      top: 'unset',
      left: 'unset',
      right: 'unset',
      bottom: 'unset'
    }
  },
  props: {
    trigger: { type: String, default: 'click' },
    arrow: { type: Boolean, default: false },
    outclose: { type: Boolean, default: true },
    display: { type: String, default: null },
    position: {
      type: String, default: 'bottom', validator: function(pst) {
        return ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end'].includes(pst)
      }
    },
    margin: { type: Number, default: 10 },
    transform: { type: Boolean, default: true }
  },
  watch: {
    show (v) {
      if (v) {
        if (this.outclose) {
          document.body.addEventListener('mouseup', this.onMouseUp)
        }
        this.$nextTick(this.put)
      } else {
        document.body.removeEventListener('mouseup', this.onMouseUp)
      }
      this.$emit('change', v)
    }
  },
  computed: {
    el () {
      if (this.$slots.default) {
        return Array.isArray(this.$slots.default) ? this.$slots.default[0].elm : this.$slots.default.elm
      } else {
        return this.$refs.content
      }
    },
    content () {
      return this.$refs.content
    },
    wrap () {
      return this.$refs.wrap
    },
    style () {
      return {
        display: this.display || this.targetDisplay
      }
    },
    wrapStyle () {
      return {
        top: this.top,
        left: this.left,
        right: this.right,
        bottom: this.bottom
      }
    },
    arrowStyle () {
      const [type, pst] = this.position.split('-')
      let top, bottom, left, right, transform, border
      if (['top', 'bottom'].includes(type)) {
        if (pst === 'start') {
          left = 0
          right = 'unset'
        } else if (pst === 'end') {
          left = 'unset'
          right = 0
        } else {
          left = '50%'
          transform = 'translateX(-50%)'
        }
        if (type === 'top') {
          top = 'unset'
          bottom = -(this.margin + 10) + 'px'
          border = 'borderTopColor'
        } else {
          top = -(this.margin + 10) + 'px'
          bottom = 'unset'
          border = 'borderBottomColor'
        }
      } else {
        if (pst === 'start') {
          top = 0
          bottom = 'unset'
        } else if (pst === 'end') {
          top = 'unset'
          bottom = 0
        } else {
          top = '50%'
          bottom = 'unset'
          transform = 'translateY(-50%)'
        }
        if (type === 'left') {
          left = 'unset'
          right = -(this.margin + 10) + 'px'
          border = 'borderLeftColor'
        } else {
          left = -(this.margin + 10) + 'px'
          right = 'unset'
          border = 'borderRightColor'
        }
      }

      return {
        top, bottom, left, right, transform,
        [border]: 'rgba(233,233,233,1)'
      }
    }
  },
  methods: {
    toogle (e) {
      if (e) {
        if (this.trigger === 'hover' && e.type === 'mouseleave') {
          this.show = false
        } else if (this.trigger === 'hover' && e.type === 'mouseenter') {
          this.show = true
        } else if (this.trigger === 'click' && e.type === 'click') {
          this.show = !this.show
        }
      }
    },
    put () {
      const { width, height } = this.content.getBoundingClientRect()
      const offsetWidth = this.wrap.offsetWidth
      const offsetHeight = this.wrap.offsetHeight

      const [type, pst] = this.position.split('-')

      if (['top', 'bottom'].includes(type)) {
        if (pst === 'start') {
          this.left = 0
          this.right = 'unset'
        } else if (pst === 'end') {
          this.left = 'unset'
          this.right = 0
        } else {
          this.left = (offsetWidth > width ? -((offsetWidth - width) / 2) : ((width - offsetWidth) / 2)) + 'px'
          this.right = 'unset'
        }
        if (type === 'top') {
          this.bottom = 'calc(100% + ' + this.margin + 'px)'
          this.top = 'unset'
        } else {
          this.bottom = 'unset'
          this.top = 'calc(100% + ' + this.margin + 'px)'
        }
      } else {
        if (pst === 'start') {
          this.top = 0
          this.bottom = 'unset'
        } else if (pst === 'end') {
          this.top = 'unset'
          this.bottom = 0
        } else {
          this.top = (offsetHeight > height ? -((offsetHeight - height) / 2) : ((height - offsetHeight) / 2)) + 'px'
          this.bottom = 'unset'
        }
        if (type === 'left') {
          this.right = 'calc(100% + ' + this.margin + 'px)'
          this.left = 'unset'
        } else {
          this.right = 'unset'
          this.left = 'calc(100% + ' + this.margin + 'px)'
        }
      }
    },
    onMouseUp (e) {
      if (e.target !== this.$el && (e.target.contains(this.$el) || !e.path.includes(this.$el))) {
        this.show = false
      }
    }
  },
  mounted () {
    let styles = getComputedStyle(this.el)
    this.targetDisplay = styles.display
  },
  destroyed () {
    document.body.removeEventListener('mouseup', this.onMouseUp)
  }
}
</script>
