import Vue from 'vue'

import router from '@/router'

import {
  localstorageHelper
} from '@/fmlib'

import {
  authRequest
} from '@/api'

const state = {
  token: null,
  wxCode: null,
  isWxUse: false,
  authPass: false,
  authIng: false,
}

const getters = {
  token: state => state.token,
  authPass: state => state.authPass,
  authIng: state => state.authIng
}

const actions = {
  setAuthing ({ commit }, data) {
    commit('setAuthing', data)
  },
  async authInit ({ commit, dispatch, getters }) {
    // 获取token
    // 1.本地存储获取  3.跳转登录页面登录
    dispatch('setAuthing', true)
    let token = localstorageHelper.getData('token')
    commit('setToken', token)
    let authPass = true
    if (getters.token) {
      // 获取用户信息
      try {
        let data = await authRequest.getUserInfo()
        dispatch('setUserData', data)
      } catch (error) {
        console.log(error)
        commit('setToken', '')
        authPass = false
      }
    } else {
      authPass = false
    }
    commit('setAuthPass', authPass)
    if (getters.authPass) {
      await Vue.prototype.$loadTableConfigs('table')
      await dispatch('loadSysConfig')
      await dispatch('loadSelectDatas')
      dispatch('goFirstPage')
    } else {
      dispatch('userOAuth')
    }
    dispatch('setAuthing', false)
  },
  userOAuth () {
    // 用户赋权
    router.push({name: 'login'})
  },
  logOut ({ dispatch, commit }) {
    authRequest.logOut()
    commit('setToken', '')
    dispatch('setUserData', {})
    let routerData = {
      name: 'login',
      params: {
        logOut: 1
      }
    }
    router.push(routerData)
    setTimeout(() => {
      window.location.reload()
    }, 200)
  }
}

const mutations = {
  setAuthPass (state, data) {
    state.authPass = data
  },
  setAuthing (state, data) {
    state.authing = data
  },
  setToken (state, data) {
    localstorageHelper.updateData('token', data)
    state.token = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
