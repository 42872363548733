<template>
  <div class="fm-tabs" :class="{
    [`fm-tabs-${size}`]: true,
    'fm-tabs-full': full !== false
  }">
    <div class="fm-tabs-nav">
      <div class="fm-tabs-nav-item" :class="{
        'fm-tabs-nav-active': nav.index === currentIndex,
        'fm-tabs-nav-disabled': nav.disabled !== false
      }" @click="updateIndex(nav.disabled !== false ? currentIndex : nav.index)" v-for="(nav, i) in navs" :key="navUpdateKey + '-' + i">{{nav.title}}</div>
    </div>
    <div class="fm-tabs-content" :style="contentStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmTabs',
  data () {
    return {
      tabPanes: [],
      navUpdateKey: 0,
      currentIndex: this.value
    }
  },
  props: {
    full: { type: Boolean, default: false },
    size: {
      type: String,
      default: 'norm',
      validator: function(size) {
        return ['norm', 'mini'].includes(size)
      }
    },
    value: {
      type: [String, Number],
      value: null
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  provide() {
    return {
      FmTabs: this
    }
  },
  computed: {
    contentStyle () {
      let index = this.navs.findIndex(v => this.currentIndex === v.index)
      index = index === -1 ? 0 : index
      return {
        transform: `translateX(-${index * 100 + '%'})`
      }
    },
    navs () {
      return this.tabPanes.map(v => {
        return {title: v.title, index: v.index, disabled: v.disabled}
      })
    }
  },
  watch: {
    navs () {
      this.navUpdateKey++
      if (this.currentIndex === undefined && this.navs.length) {
        this.currentIndex = this.navs[0].index
      }
    },
    value (val) {
      this.updateIndex(val)
    }
  },
  methods: {
    updateIndex (index) {
      if (index !== this.currentIndex) {
        this.currentIndex = index
        this.$emit('change', index)
      }
    },
    getTabPanes () {
      if (this.$slots.default && Array.isArray(this.$slots.default)) {
        return this.$slots.default.map(({componentInstance}, index) => {
          componentInstance.index = componentInstance.index === undefined ? index : componentInstance.index
          return componentInstance
        })
      } else {
        return []
      }
    },
    updateTabsNav () {
      this.tabPanes = this.getTabPanes()
    }
  },
  mounted () {
    this.updateTabsNav()
  }
}
</script>

<style lang="less">
  @import './styles/values.less';
  .fm-tabs {
    position: relative;
    overflow: hidden;
  }
  .fm-tabs-nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid @color-border;
  }
  .fm-tabs-nav-item {
    color: @color-component-text;
    transition: all .3s;
    cursor: pointer;
    position: relative;
    bottom: -1px;
    border-bottom: 2px solid transparent;
    &.fm-tabs-nav-active {
      color: @color-primary;
      border-bottom: 2px solid @color-primary;
    }
    &.fm-tabs-nav-disabled {
      cursor: not-allowed;
      color: @color-disabled-text;
    }
    & + & {
      margin-left: 16px;
    }
  }
  .fm-tabs-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all .3s;
    margin-top: 16px;
  }

  .fm-tabs.fm-tabs-full {
    height: 100%;
    display: flex;
    flex-direction: column;
    .fm-tabs-content {
      flex: 1;
      height: 0;
      .fm-tab-pane {
        height: 100%;
      }
    }
  }

  // 大小设置
  .fm-tabs-norm {
    .fm-tabs-nav-item {
      font-size: @size-font-norm;
      padding: 8px 16px;
    }
  }
  .fm-tabs-mini {
    .fm-tabs-nav-item {
      font-size: @size-font-mini;
      padding: 2px 5px;
    }
  }
</style>