<style lang="less">
  .fm-table-summary-cells {
    display: flex;
    background-color: #FAFAFA;
  }
  .fm-table-summary-item {
    flex: 1;
    overflow-x: auto;
    &::-webkit-scrollbar {display:none}
    table {
      width: 100%;
    }
    &.fm-table-summary-fixed-left, &.fm-table-summary-fixed-right {
      overflow-x: unset;
      flex: 0;
      flex-shrink: 0;
    }
  }
  .fm-table-summary-cell-wrap {
    word-break: break-all;
  }
  .fm-table-layout-ready {
    .fm-table-summary-cell-wrap {
      word-break: keep-all;
    }
  }
</style>

<template>
  <div class="fm-table-summary">
    <div class="fm-table-summary-cells">
      <template v-for="(columns, columnsIndex) in columnConfig">
        <div class="fm-table-summary-item" :ref="'item' + columnsIndex" v-if="columns.length > 0" :key="columnsIndex" :style="{
          width: columnsIndex === 1 ? ('0px') : 'unset',
          flex: columnsIndex === 1 ? '1' : ('0 0 ' + columnWidth[columnsIndex].reduce((a, b) => a + b, 0) + 'px')
        }" :class="{
          'fm-table-summary-fixed-left': columnsIndex === 0,
          'fm-table-summary-fixed-right': columnsIndex === 2
        }">
          <table border="0" cellspacing="0" cellpadding="0" :style="{
            width: columnsIndex === 1 ? (tableWidth + 'px') : 'unset'
          }" :class="{'fm-table-layout-fixed': !needResize, 'fm-table-layout-ready': needResize}">
            <colgroup :key="columnWidthNeedUpdate">
              <col v-for="(width, index) in (columnWidth[columnsIndex] || columns.map(v => v.width))" :key="index" :width="width" />
            </colgroup>
            <tbody>
              <tr :ref="'trs' + columnsIndex" :style="{height: height ? (height + 'px') : ''}">
                <td class="fm-table-summary-cell" v-for="column in columns" :key="column.key">
                  <div class="fm-table-summary-cell-wrap" :class="{
                    ['fm-table-cell-align-' + (column.config.valign || column.config.align || 'left')]: true
                  }" v-html="summaryMethod({column: column.config, data: dataList, summaryField: summaryField, summaryDecimal: summaryDecimal})"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ColMixins from './col-mixins'

export default {
  inject: ['table'],
  mixins: [ColMixins],
  data () {
    return {
      height: 0
    }
  },
  computed: {
    summaryExclude () {
      return this.table.summaryExclude
    },
    summaryMethod () {
      return this.table.summaryMethod
    },
    summaryField () {
      return this.table.summaryField
    },
    summaryDecimal () {
      return this.table.summaryDecimal
    },
    dataList () {
      return this.summaryExclude ? this.table.dataList : this.table.tableData
    }
  },
  watch: {
    dataList () {
      this.$nextTick(this.updateHeight)
    },
    columnConfig: {
      handler () {
        this.columnWidth = this.columnConfig.map(columns => columns.map(column => column.width))
        this.$nextTick(this.updateHeight)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateHeight () {
      this.height = this.$el.offsetHeight
      this.table.$emit('update-summary-height', this.height)
      return this.height
    },
    scrollX ({index, x}) {
      if (this.$refs['item' + index] && this.$refs['item' + index][0]) {
        this.$refs['item' + index][0].scrollLeft = x
      }
    }
  },
  mounted () {
    this.table.$on('set-cell-width', this.handleResize)
    this.table.$on('scroll-x', this.scrollX)
    this.updateHeight()
  }
}
</script>
