export default {
  icon: 'icon-shuju',
  name: '采购',
  clientType: 'web',
  router: {
    name: 'purchase',
    path: 'purchase',
    component: () => import('../../../views/purchase/router.vue'),
    redirect: { name: 'scm.purchase.index' }
  },
  children: [
    {
      icon: 'icon-shuju',
      name: '采购计划',
      clientType: 'web',
      router: {
        name: 'purchase.index',
        path: 'index',
        component: () => import('../../../views/purchase/index.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_purchase', method: 'GET' },
            { path: '/scm/goods_purchase/detail', method: 'GET' },
            { path: '/scm/goods_purchase/detail/count', method: 'GET' },
          ]
        },
        manage: {
          name: '维护',
          funKey: 'manage',
          apis: [
            { path: '/scm/goods_purchase/{id}', method: 'PUT' },
            { path: '/scm/goods_purchase/exe_status/{id}', method: 'PUT' },
            { path: '/scm/goods_purchase/{id}', method: 'DELETE' },
            { path: '/scm/goods_purchase', method: 'POST' },
            { path: '/scm/goods_purchase', method: 'GET' },
            { path: '/scm/goods', method: 'GET' },
            { path: '/scm/goods_group', method: 'GET' },
            { path: '/scm/goods_group/detail', method: 'GET' },

            { path: '/scm/goods_purchase/detail/{id}', method: 'DELETE' },
            { path: '/scm/goods_purchase/detail/{id}', method: 'PUT' },
            { path: '/scm/goods_purchase/detail', method: 'POST' },
            { path: '/scm/goods_purchase/detail', method: 'GET' },
          ]
        }
      }
    },
    {
      icon: 'icon-shuju',
      name: '采购入库',
      clientType: 'web',
      router: {
        name: 'purchase.inbound',
        path: 'inbound',
        component: () => import('../../../views/stock/inout/purchase.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_purchase', method: 'GET' },
            { path: '/scm/goods_purchase/detail', method: 'GET' },
            { path: '/scm/storehouse', method: 'GET' },
          ]
        },
        manage: {
          name: '执行',
          funKey: 'manage',
          apis: [
            { path: '/scm/storehouse/unit', method: 'GET' },
            { path: '/scm/goods_not_stock', method: 'GET' },
            { path: '/scm/goods_purchase/detail/check_in/{id}', method: 'POST' },
            { path: '/scm/goods_purchase/detail/check_in/{id}', method: 'DELETE' },
          ]
        }
      }
    },
  ]
}