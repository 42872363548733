export default {
  handler: {
    funKey: 'handler',
    name: '处理',
    apis: [
      { method: 'GET', path: '/internalcontrol/purchase_method_batch/my' },
      { method: 'GET', path: '/internalcontrol/purchase_method_batch/detail/by_auth' },
      { method: 'POST', path: '/internalcontrol/purchase_method_batch/detail/switch/{id}' },

      { method: 'GET', path: '/internalcontrol/purchase_batch/detail' },
      { method: 'GET', path: '/internalcontrol/purchase_method_batch/detail' },
      { method: 'POST', path: '/internalcontrol/purchase_method_batch/detail' },
      { method: 'PUT', path: '/internalcontrol/purchase_method_batch/detail/{id}' },
      { method: 'DELETE', path: '/internalcontrol/purchase_method_batch/detail/{id}' },

      { method: 'GET', path: '/internalcontrol/purchase_method_batch' },
    ]
  },
  manage: {
    funKey: 'manage',
    name: '管理',
    apis: [
      { method: 'GET', path: '/internalcontrol/purchase_method_batch' },
      { method: 'POST', path: '/internalcontrol/purchase_method_batch' },
      { method: 'PUT', path: '/internalcontrol/purchase_method_batch/{id}' },
      { method: 'DELETE', path: '/internalcontrol/purchase_method_batch/{id}' },

      { method: 'POST', path: '/internalcontrol/purchase_method_batch/switch/{id}' },

      { method: 'GET', path: '/internalcontrol/purchase_batch/detail' },
      { method: 'GET', path: '/internalcontrol/purchase_method_batch/detail' },
      { method: 'GET', path: '/internalcontrol/purchase_method_batch/detail/by_auth' }
    ]
  },
  viewFile: {
    funKey: 'viewFile',
    name: '文件查看',
    apis: [
      { method: 'GET', path: '/fms/file' },
      { method: 'GET', path: '/fms/download' }
    ]
  },
  delFile: {
    funKey: 'delFile',
    name: '文件删除',
    apis: [
      { method: 'DELETE', path: '/fms/file' },
      { method: 'PUT', path: '/internalcontrol/purchase_method_batch/file_ids/{id}' }
    ]
  },
  uploadFile: {
    funKey: 'uploadFile',
    name: '文件上传',
    apis: [
      { method: 'DELETE', path: '/fms/file' },
      { method: 'PUT', path: '/internalcontrol/purchase_method_batch/file_ids/{id}' },
    ]
  },
  detailsFile: {
    funKey: 'detailsFile',
    name: '采购方式申请文件管理',
    apis: [
      { method: 'DELETE', path: '/fms/file' },
      { method: 'PUT', path: '/internalcontrol/purchase_method_batch/detail/file_ids/{id}' }
    ]
  }
}