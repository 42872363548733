<style lang="less">
  @import '../styles/values.less';
  .fm-table-body {
    display: flex;
    position: relative;
  }
  .fm-table-body-item {
    flex: 1;
    overflow-x: auto;
    &::-webkit-scrollbar {
      background: rgba(0, 0, 0, 0);
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d5d5d5;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }
    table {
      width: 100%;
    }
    &.fm-table-body-fixed-left, &.fm-table-body-fixed-right {
      overflow-x: unset;
      flex: 0;
      flex-shrink: 0;
    }
  }
  .fm-table-fixed-height, .fm-table-full, .fm-table-column-fixed {
    .fm-table-body-item {
      &::-webkit-scrollbar, &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
        -webkit-appearance: none;
        height: 0;width: 0;display: none; background: none;
      }
    }
  }
  .fm-table-body-scroll {
    overflow-y: auto;
    align-items: flex-start;
  }
  .fm-table-empty {
    position: relative;
    width: 100%;
    color: @color-disabled-text;
    text-align: center;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fm-table-auto-height.fm-table-full {
    .fm-table-empty {
      top: calc(50% + 16px);
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }
</style>

<template>
  <div class="fm-table-body" @scroll="onYScroll" :class="{
    'fm-table-body-scroll': tableHeight !== null
  }" :style="styles">
    <template v-for="(columns, columnsIndex) in columnConfig">
      <div class="fm-table-body-item" :ref="'body-item-' + columnsIndex" v-if="columns.length > 0" :key="columnsIndex" :style="{
        width: columnsIndex === 1 ? (needResize ? '100000px' : '0px') : 'unset',
        flex: columnsIndex === 1 ? '1' : ('0 0 ' + (columnWidth[columnsIndex] || []).reduce((a, b) => a + b, 0) + 'px')
      }" :class="{
        'fm-table-body-fixed-left': columnsIndex === 0,
        'fm-table-body-fixed-center': columnsIndex === 1,
        'fm-table-body-fixed-right': columnsIndex === 2
      }" @scroll="(e) => onXScroll(e, columnsIndex)">
        <table border="0" cellspacing="0" cellpadding="0" :style="{
          width: columnsIndex === 1 && !needResize ? (tableWidth + 'px') : 'unset'
        }" :class="{'fm-table-layout-fixed': !needResize, 'fm-table-layout-ready': needResize}">
          <colgroup :key="columnWidthNeedUpdate">
            <col v-for="(width, index) in (columnWidth[columnsIndex] || columns.map(v => v.width))" :key="index" :width="width || columns[index].width" />
          </colgroup>
          <tbody :ref="'tbodys' + columnsIndex">
            <tr class="fm-table-simple-filter" v-if="simpleFilter === true || simpleFilter === 'body'" v-show="!needResize">
              <td v-for="(columnItem, columnIndex) in columns" :key="columnIndex">
                <simple-filter :column="columnItem" :groupIndex="columnsIndex" :columnIndex="columnIndex"></simple-filter>
              </td>
            </tr>
            <template v-for="(dataItem, dataIndex) in dataList">
              <table-tr :key="'tbody-tr' + dataIndex" :row-index="dataIndex" :column-index="columnsIndex">
                <table-body-cell v-for="(columnItem, columnIndex) in columns" :column="columnItem" :row="dataItem" :index="dataIndex" :key="columnIndex"></table-body-cell>
              </table-tr>
              <tr class="fm-table-expand-row" v-if="columnsIndex === 1 && currentExpand && currentExpand[dataIndex]" :key="'tbody-expand' + dataIndex">
                <td :colspan="columns.length" class="fm-table-expand-cell">
                  <table-expand :render="currentExpand[dataIndex].reader" :row="dataItem" :index="dataIndex"></table-expand>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import TableTr from './tr'
import TableBodyCell from './body-cell'
import TableExpand from './expand'
import SimpleFilter from './simple-filter'
import ColMixins from './col-mixins'

export default {
  inject: ['table'],
  components: {TableTr, TableBodyCell, TableExpand, SimpleFilter},
  mixins: [ColMixins],
  data () {
    return {
      rows: {}
    }
  },
  methods: {
    updateCellWidth () {
      this.columnWidth = this.columnConfig.map(columns => columns.map(column => column.width))
      this.needResize = true
      this.$nextTick(() => {
        [this.$refs.tbodys0 || null, this.$refs.tbodys1 || null, this.$refs.tbodys2 || null].map(tbody => {
          return tbody && tbody.length ? Array.from(tbody[0].rows[this.table.simpleFilter ? 1 : 0].cells).map(td => td.offsetWidth) : []
        })
        this.$nextTick(() => {
          this.table.$emit('update-body-cell-width', [this.$refs.tbodys0 || null, this.$refs.tbodys1 || null, this.$refs.tbodys2 || null].map(tbody => tbody && tbody.length ? Array.from(tbody[0].rows[this.table.simpleFilter ? 1 : 0].cells).map(td => td.offsetWidth) : [] ))
        })
      })
    },
    onXScroll (event, index) {
      // fm-table-body-item fm-table-body-fixed-center
      // fm-table-layout-fixed 与 fm-table-layout-ready
      // 之间的状态切换后，以及table内容的变化 造成 内容宽度的缩减
      // 缩减后 多余的滚动 父级scrollLeft被修正
      this.table.$emit('scroll-x', {index, x: event.target.scrollLeft})
    },
    onYScroll (event) {
      this.table.$emit('scroll-y', event.target.scrollTop, event.target.scrollHeight, event.target.offsetHeight)
    },
    onSizeChange () {
      this.updateCellWidth()
    },
    onRowResize (columnIndex, rowIndex, height) {
      if (!this.rows[rowIndex]) {
        this.rows[rowIndex] = []
      }
      this.rows[rowIndex][columnIndex] = height
      if (this.rows[rowIndex].filter(v => v).length === this.columnSize) {
        let row = this.rows[rowIndex].filter(v => v)
        delete this.rows[rowIndex]
        this.table.$emit('set-row-height', rowIndex, Math.max(...row))
      }
    },
    onScrollAt ({x, index, type}) {
      if (type !== 'body') {
        this.$refs['body-item-' + index][0].scrollLeft = x
      }
    }
  },
  watch: {
    baseDataList: {
      immediate: true,
      handler (val, old) {
        if (val && val.length && (!old || !old.length)) {
          this.$nextTick(this.updateCellWidth)
        }
      }
    },
    dataList: {
      handler () {
        if (this.table.isFixed) {
          this.table.$emit('re-row-height')
        }
      }
    }
  },
  computed: {
    simpleFilter () {
      return this.table.simpleFilter
    },
    columnSize () {
      return this.columnConfig.map(v => v.length).reduce((a, b) => a + (b > 0 ? 1 : 0), 0)
    },
    isFull () {
      return this.table.full
    },
    tableHeadHeight () {
      return this.table.$refs.head ? this.table.$refs.head.height : 0
    },
    tableBodyHeight () {
      return this.tableHeight !== null ? (parseFloat(this.tableHeight) - this.tableHeadHeight - this.summaryHeight) : null
    },
    styles () {
      const height = this.tableBodyHeight === null ? '' : (this.tableBodyHeight + 'px')
      return this.isFull ? {
        height: height
      } : {
        maxHeight: height
      }
    },
    summaryHeight () {
      return this.table.summaryHeight
    },
    currentExpand () {
      return this.table.currentExpand
    },
    dataList () {
      return this.table.tableData
    },
    baseDataList () {
      return this.table.dataList
    }
  },
  mounted () {
    this.table.$on('set-cell-width', this.handleResize)
    this.table.$on('size-change', () => {
      this.$nextTick(this.onSizeChange)
    })
    this.table.$on('on-row-resize', this.onRowResize)
    this.table.$on('scroll-x', this.onScrollAt)
  }
}
</script>
