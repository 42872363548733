// 发票管理
export default {
  get: {
    funKey: 'get',
    name: '获取',
    apis: [
      { path: '/internalcontrol/bill', method: 'GET' },
      { path: '/internalcontrol/bill/last_status_time', method: 'GET' },
      { path: '/internalcontrol/assets', method: 'GET' },
      { path: '/internalcontrol/bill/check', method: 'GET' }
    ]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [
      { path: '/internalcontrol/bill/{id}', method: 'DELETE' }
    ]
  },
  update: {
    funKey: 'update',
    name: '修改',
    apis: [
      { path: '/internalcontrol/bill/{id}', method: 'PUT' }
    ]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [
      { path: '/internalcontrol/bill', method: 'POST' },
      { path: '/internalcontrol/bill/qkl_qr', method: 'POST' }
    ]
  },
  upload: {
    funKey: 'upload',
    name: '附件上传',
    apis: [
      { path: '/fms/upload', method: 'POST' },
    ]
  },
  remove: {
    funKey: 'remove',
    name: '附件删除'
  },
  apicheck: {
    funKey: 'apicheck',
    name: '发票核验接口'
  }
}