import './components/iconfont/iconfont.css'
import './components/styles/common.less'

function getComponents() {
  const files = require.context('./components/', false, /\.vue$/)
  return files.keys().map(key => files(key).default)
}

function getDirectives() {
  const files = require.context('./directives/', false, /\.js$/)
  return files.keys().map(key => files(key).default)
}

function getMethods() {
  const files = require.context('./methods/', false, /\.js$/)
  return files.keys().map(key => files(key).default)
}

export default {
  install(Vue) {
    getComponents().forEach(component => {
      if (['FmFormInput', 'FmInput', 'FmInputNew', 'FmSelect', 'FmDatePicker'].includes(component.name)) {
        if (!component.props) {
          component.props = {}
        }
        if (!component.props.rules) {
          component.props.rules = {
            type: [Array, Object],
            default () {
              return []
            }
          }
        }
        if (!component.props.required) {
          component.props.required = {
            type: Boolean,
            default: false
          }
        }
        if (!component.methods) {
          component.methods = {}
        }
        if (!component.methods.verifier) {
          component.methods.verifier = function () {
            const msg = this.$verifier.check(this.value, this.required, this.rules)
            this.$emit('verifier', msg)
            return msg
          }
        }
        if (!component.methods.registerVerifier) {
          component.methods.registerVerifier = function () {
            if (this.form) {
              this.form.$emit('form-register-verifier', this)
            }
          }
        }
        if (!component.inject) {
          component.inject = {}
        }
        if (!component.inject.form) {
          component.inject.form = { default: null }
        }
      }
      Vue.component(component.name, component)
    })

    getDirectives().forEach(directive => {
      if (directive.default) {
        Vue.directive(directive.name, directive.default)
      } else {
        Vue.directive(directive.name, {
          bind: directive.bind || undefined,
          inserted: directive.inserted || undefined,
          update: directive.update || undefined,
          componentUpdated: directive.componentUpdated || undefined,
          unbind: directive.unbind || undefined
        })
      }
    })

    getMethods().forEach(method => {
      if (typeof method.methods === 'function') {
        Vue.prototype[method.name] = method.methods
      } else {
        Vue.prototype[method.name] = {}
        Object.assign(Vue.prototype[method.name], method.methods)
      }
    })
  },
  getDemos () {
    return require('./demos').default
  }
}