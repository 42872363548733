<template>
  <div>
    <h2>消息弹框($dialog)</h2>
    <div class="flex">
      <fm-btn @click="$dialog.info('info')">info</fm-btn>
      <fm-btn @click="$dialog.confirm({title: '确认要删除么?', content: 'confirm'})">confirm</fm-btn>
      <fm-btn @click="getconfirm">获取点击结果</fm-btn>
      <fm-btn @click="getprompt">prompt</fm-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FmDialogDemo",
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {
    async getconfirm() {
      const result = await this.$dialog.confirm({
        title: '确认要删除么?',
        content: '<pre>async getconfirm () {\n  const result = await $dialog.confirm({title: \'确认要删除么?\', content: \'confirm\'})\n  console.log(result)\n}</pre>'
      })
      console.log(result)
    },
    async getprompt () {
      const result = await this.$dialog.prompt({
        title: '确认要删除么?',
        promptValue: '哈哈',
        placeholder: '请输入哈哈哈'
      })
      console.log(result)
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
};
</script>

<style lang="less" scoped></style>