// 手机端浏览器: wap
export default [{
  'clientType': 'wap',
  'roles': ['worker'],
  'name': '工资明细',
  'url': 'wap.salary'
},
{
  'clientType': 'wap',
  'name': '我的考核',
  'roles': ['worker'],
  'url': 'wap.my'
},
{
  'clientType': 'wap',
  'name': '手机端首页',
  'url': 'wap.menu'
}]