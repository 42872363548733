export default {
  functional: true,
  props: {
    render: Function,
    node: {
      type: Object, default () {
        return {}
      }
    },
    data: {
      type: Object, default () {
        return {}
      }
    }
  },
  render: (h, ctx) => {
    return ctx.props.render ? ctx.props.render(h, ctx.props.data, ctx.parent) : 'no render'
  }
}
  