export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/ask_leave',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '假条明细',
    apis: [{
      path: '/basis/ask_leave_apply',
      method: 'GET'
    },
    {
      path: '/basis/ask_leave_apply/type',
      method: 'GET'
    },
    {
      path: '/basis/status_manage/config',
      method: 'GET'
    }]
  },
  edit: {
    funKey: 'edit',
    name: '销假',
    apis: [{
      path: '/basis/ask_leave/off/{id}',
      method: 'POST'
    }]
  },
  back_off: {
    funKey: 'back_off',
    name: '撤回销假',
    apis: [{
      path: '/basis/ask_leave/back_off/{id}',
      method: 'POST'
    }]
  },
  invalid: {
    funKey: 'invalid',
    name: '作废',
    apis: [{
      path: '/basis/ask_leave/{id}',
      method: 'PUT'
    }]
  },
  back_invalid: {
    funKey: 'back_invalid',
    name: '撤回作废',
    apis: [{
      path: '/basis/ask_leave/{id}',
      method: 'PUT'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '附件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取附件'
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载附件'
  }
}
  