<style scoped lang="less">
  .preview-doc {
    padding: 10px 0px 10px 0px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(1px);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  iframe {
    background-color: #FFF;
    margin: 0 auto;
    display: block;
    width: 22cm;
    flex: 1;
    height: 0;
  }
  .fmico.fmico-cha {
    cursor: pointer;
    position: fixed;
    right: 10px;
    top: 10px;
    color: #FFF;
    font-size: 20px;
  }
  .sheets {
    background-color: #FFF;
    border-top: 1px solid #EEE;
    span {
      padding: 0 10px;
      line-height: 40px;
      cursor: pointer;
      color: #000;
      &.active {
        cursor: default;
        color: #657180;
      }
      & + span {
        border-left: 1px solid #EEE;
      }
    }
  }
</style>

<template>
  <div class="preview-doc">
    <span class="fmico fmico-cha" @click="$emit('close')"></span>
    <iframe ref="iframe" :style="{width: type === 'excel' ? '80%' : '22cm'}" frameborder="0" />
    <div :style="{width: type === 'excel' ? '80%' : '22cm'}" class="sheets" v-if="sheetNames.length > 1">
      <span v-for="(name, i) in sheetNames" :class="{active: activeSheetName === name}" :key="i + name" @click="showSheet(name)">{{name}}</span>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'

function getSheetHtml (sheet) {
  let style = "<style>table{border-collapse: collapse;}td {border: 1px solid #EEE;padding: 5px 8px;white-space: nowrap;}</style>"
  return style + XLSX.utils.sheet_to_html(sheet)
}

export default {
  props: {
    src: { type: String, default: null },
    type: { type: String, default: null },
    data: { type: Object, default: null }
  },
  data () {
    return {
      activeSheetName: ''
    }
  },
  computed: {
    sheetNames () {
      if (this.type === 'excel' && this.data && this.data.SheetNames) {
        return this.data.SheetNames
      } else {
        return []
      }
    }
  },
  methods: {
    loadDoc () {
      if (/^blob:/.test(this.src) || /^https?:\/\//.test(this.src)) {
        this.$refs.iframe.src = this.src
      } else if (this.type === 'excel') {
        this.activeSheetName = this.data.SheetNames[0]
        this.$refs.iframe.srcdoc = getSheetHtml(this.data.Sheets[this.activeSheetName])
      } else {
        this.$refs.iframe.srcdoc = this.src
      }
    },
    showSheet (name) {
      if (name && this.type === 'excel' && this.data.Sheets[name]) {
        this.activeSheetName = name
        this.$refs.iframe.srcdoc = getSheetHtml(this.data.Sheets[this.activeSheetName])
      }
    }
  },
  mounted () {
    this.loadDoc()
  },
  destroyed () {
    if (/^blob:/.test(this.src)) {
      window.URL.revokeObjectURL(this.src)
    }
  }
}
</script>
