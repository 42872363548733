export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/ask_leave_apply/type',
      method: 'GET'
    },
    {
      path: '/basis/status_manage',
      method: 'GET'
    },
    {
      path: '/ucenter/system/roles',
      method: 'GET'
    }]
  },
  flow: {
    funKey: 'flow',
    name: '流程',
    apis: [{
      path: '/basis/ask_leave_apply/type/flow/{id}',
      method: 'PUT'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/basis/ask_leave_apply/type',
      method: 'POST'
    }]
  },
  edit: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/basis/ask_leave_apply/type/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/ask_leave_apply/type/{id}',
      method: 'DELETE'
    }]
  }
}
  