<style scoped>
  @import './demo.less';
</style>

<template>
  <div>
    <h2>Tab标签fm-tabs</h2>
    <div class="flex">
      <FmTabs>
        <FmTabPane title="标签1">
          标签1
        </FmTabPane>
        <FmTabPane title="不可用" disabled>
          不可用
        </FmTabPane>
        <FmTabPane title="标签3">
          标签3
        </FmTabPane>
      </FmTabs>
    </div>
    <h2>尺寸设置（<fm-tag>size="mini"</fm-tag>）</h2>
    <div class="flex">
      <FmTabs size="mini">
        <FmTabPane title="标签1">
          标签1
        </FmTabPane>
        <FmTabPane title="不可用" disabled>
          不可用
        </FmTabPane>
        <FmTabPane title="标签3">
          标签3
        </FmTabPane>
      </FmTabs>
    </div>
    <h2>默认显示项（<fm-tag>value="标签3"</fm-tag>）支持双向绑定</h2>
    <div class="flex">
      <FmTabs size="mini" value="标签3">
        <FmTabPane title="标签1" name="标签1">
          标签1
        </FmTabPane>
        <FmTabPane title="标签2" name="标签2">
          标签2
        </FmTabPane>
        <FmTabPane title="标签3" name="标签3">
          标签3
        </FmTabPane>
      </FmTabs>
    </div>
    <h2>未指定name时，value对应的是位置索引index（<fm-tag>:value="1"</fm-tag>）</h2>
    <div class="flex">
      <FmTabs size="mini" :value="1">
        <FmTabPane title="标签1">
          标签1
        </FmTabPane>
        <FmTabPane title="标签2">
          标签2
        </FmTabPane>
        <FmTabPane title="标签3">
          标签3
        </FmTabPane>
      </FmTabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmTabsDemo'
}
</script>
