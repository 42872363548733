export default [
  {
    routerNames: ['salary.cop.worker'],
    funs: {
      worker: {
        funKey: 'worker',
        name: '人员管理',
        apis: [
          { path: '/ucenter/user', method: 'GET' },
          { path: '/basis/hrms_wage_worker', method: 'GET' },
          { path: '/basis/hrms_wage_worker/{id}', method: 'DELETE' },
          { path: '/basis/hrms_wage_worker/{id}', method: 'PUT' },
          { path: '/basis/hrms_wage_worker', method: 'POST' },
        ]
      }
    }
  },
  {
    routerNames: ['salary.cop.official', 'salary.cop.auxiliary', 'salary.cop.soldier'],
    funs: {
      manage: {
        funKey: 'manage',
        name: '工资管理',
        apis: [
          { path: '/ucenter/sys_config', method: 'GET' },
          { path: '/basis/hrms_wage', method: 'POST' },
          { path: '/basis/hrms_wage', method: 'GET' },
          { path: '/basis/hrms_wage/{id}', method: 'DELETE' },
          { path: '/basis/hrms_wage/{id}', method: 'PUT' },
          { path: '/basis/hrms_wage_worker', method: 'GET' },
          { path: '/basis/hrms_wage/batch', method: 'DELETE' },
        ]
      }
    }
  },
  {
    routerNames: ['salary.cop.my'],
    funs: {
      my: {
        funKey: 'my',
        name: '查看我的',
        apis: [
          { path: '/ucenter/sys_config', method: 'GET' },
          { path: '/basis/hrms_wage/my', method: 'GET' }
        ]
      }
    }
  },
  {
    routerNames: ['salary.cop.config'],
    funs: {
      my: {
        funKey: 'my',
        name: '管理扣减项目',
        apis: [
          { path: '/ucenter/sys_config', method: 'GET' },
          { path: '/ucenter/sys_config', method: 'POST' }
        ]
      }
    }
  }
]