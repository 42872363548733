<style scoped lang="less">
  @import url('./styles/values.less');
  .fm-page {
    display: flex;
    align-items: center;
    font-size: @size-font-norm;
    color: @color-component-text;
    line-height: @size-height-norm;
  }
  .fm-page-items {
    display: flex;
    padding-left: 10px;
    align-items: center;
    li, .fm-page-item {
      & + li { margin-left: 5px; }
      list-style: none;
      display: flex;
      min-width: @size-height-norm;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      cursor: pointer;
      transition: all .3s;
      border: 1px solid @color-border;
      border-radius: 5px;
      &.fm-page-current {
        border-color: @color-primary;
        color: @color-primary;
        cursor: default;
      }
      &:hover {
        border-color: @color-primary;
      }
      &.fm-page-nomore {
        cursor: not-allowed;
        border-color: @color-disabled-text;
        i, span {
          border-color: @color-disabled-text;
          opacity: 0.5;
          color: @color-disabled-text;
        }
      }
    }
  }
  .fm-page-prev-icon, .fm-page-next-icon {
    border: 2px solid @color-component-text;
    width: 8px;
    height: 8px;
    display: inline-block;
    transition: all .3s;
  }
  .fm-page-prev-icon {
    border-right: 0;
    border-top: 0;
    transform: rotate(45deg);
  }
  .fm-page-next-icon {
    border-left: 0;
    border-top: 0;
    transform: rotate(-45deg);
  }
  .fm-page-next, .fm-page-prev {
    height: @size-height-norm;
    width: @size-height-norm;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    user-select: none;
    &:hover {
      i {
        border-color: @color-primary;
      }
    }
    &.fm-page-custom-txt {
      width: unset;
    }
  }
  .fm-page-elevator {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .fm-page-sizer {
    margin-left: 10px;
  }
  .fm-page.fm-page-simple {
    .fm-page-items {
      li, .fm-page-item {
        border-color: transparent;
      }
    }
  }
</style>

<template>
  <div class="fm-page" :class="{
    'fm-page-simple': simple !== false
  }">
    <span class="fm-page-total" v-if="showTotal">共{{total}}条, {{pageCount}}页</span>
    <ul class="fm-page-items" :key="pageSizer + '-' + currentPage">
      <li class="fm-page-prev" @click="pageChange(currentPage - 1)" :class="{
        'fm-page-nomore': currentPage <= 1,
        'fm-page-custom-txt': Boolean(prevText)
      }">
        <span class="fm-page-prev-text" v-if="prevText" v-html="prevText"></span>
        <i class="fm-page-prev-icon" v-else></i>
      </li>
      
      <li v-if="currentPage - 6 > 0 && pageCount - currentPage < 1" @click="pageChange(currentPage - 6)" class="fm-page-item" :a="6">{{currentPage - 6}}</li>
      <li v-if="currentPage - 5 > 0 && pageCount - currentPage < 2" @click="pageChange(currentPage - 5)" class="fm-page-item" :a="5">{{currentPage - 5}}</li>
      <li v-if="currentPage - 4 > 0 && pageCount - currentPage < 3" @click="pageChange(currentPage - 4)" class="fm-page-item" :a="4">{{currentPage - 4}}</li>

      <li v-if="currentPage - 3 > 0" @click="pageChange(currentPage - 3)" class="fm-page-item" :a="3">{{currentPage - 3}}</li>
      <li v-if="currentPage - 2 > 0" @click="pageChange(currentPage - 2)" class="fm-page-item" :a="2">{{currentPage - 2}}</li>
      <li v-if="currentPage - 1 > 0" @click="pageChange(currentPage - 1)" class="fm-page-item" :a="1">{{currentPage - 1}}</li>

      <li class="fm-page-item fm-page-current">{{currentPage}}</li>

      <li a="1" v-if="currentPage + 1 <= pageCount" @click="pageChange(currentPage + 1)" class="fm-page-item">{{currentPage + 1}}</li>
      <li a="2" v-if="currentPage + 2 <= pageCount" @click="pageChange(currentPage + 2)" class="fm-page-item">{{currentPage + 2}}</li>
      <li a="3" v-if="currentPage + 3 <= pageCount" @click="pageChange(currentPage + 3)" class="fm-page-item">{{currentPage + 3}}</li>

      <li a="4" v-if="currentPage + 4 <= pageCount && currentPage - 2 < 0" @click="pageChange(currentPage + 4)" class="fm-page-item">{{currentPage + 4}}</li>
      <li a="5" v-if="currentPage + 5 <= pageCount && currentPage - 3 < 0" @click="pageChange(currentPage + 5)" class="fm-page-item">{{currentPage + 5}}</li>
      <li a="6" v-if="currentPage + 6 <= pageCount && currentPage - 4 < 0" @click="pageChange(currentPage + 6)" class="fm-page-item">{{currentPage + 6}}</li>

      <li class="fm-page-next" @click="pageChange(currentPage + 1)" :class="{
        'fm-page-nomore': currentPage >= pageCount,
        'fm-page-custom-txt': Boolean(nextText)
      }">
        <span class="fm-page-next-text" v-if="nextText" v-html="nextText"></span>
        <i class="fm-page-next-icon" v-else></i>
      </li>
    </ul>
    <div class="fm-page-sizer" v-if="showSizer !== false">
      <fm-select v-model="pageSizer">
        <fm-option v-if="![10, 20, 30, 40].includes(pageSizer)" :value="pageSizer" :label="pageSizer + '条/页'"></fm-option>
        <fm-option :value="10" label="10条/页"></fm-option>
        <fm-option :value="20" label="20条/页"></fm-option>
        <fm-option :value="30" label="30条/页"></fm-option>
        <fm-option :value="40" label="40条/页"></fm-option>
      </fm-select>
    </div>
    <div class="fm-page-elevator" v-if="showElevator !== false">
      跳至
      <fm-input-new style="width: 60px;margin: 0 10px;" size="small" :value="currentPage" @enten="(page) => pageChange(page)"/>
      页
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmPage',
  data () {
    return {
      pageSizer: this.pageSize,
      currentPage: this.current
    }
  },
  model: {
    prop: 'current',
    event: 'change'
  },
  props: {
    total: { type: Number, default: 0 },
    current: { type: Number, default: 1 },
    pageSize: { type: Number, default: 10 },
    prevText: { type: String, default: null },
    nextText: { type: String, default: null },
    showTotal: { type: Boolean, default: false },
    showSizer: { type: Boolean, default: false },
    showElevator: { type: Boolean, default: false },
    simple: { type: Boolean, default: false }
  },
  watch: {
    pageSize (size) {
      this.pageSizer = size
    },
    pageSizer () {
      this.$emit('page-size-change', this.pageSizer)
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.total / this.pageSizer) || 1
    }
  },
  methods: {
    pageChange (page) {
      if (isNaN(page)) {
        this.currentPage = 1
      } else {
        if (page <= 0) {
          page = 1
        } else if (page > this.pageCount) {
          page = this.pageCount
        }
        this.currentPage = parseInt(page)
        this.$emit('change', this.currentPage)
      }
    }
  }
}
</script>
