// 维修
export default {
    get: {
        funKey: 'get',
        name: '获取',
        apis: [
            { path: '/internalcontrol/assets_repair', method: 'GET' }
        ]
    },
    add: {
        funKey: 'add',
        name: '添加',
        apis: [
            { path: '/internalcontrol/assets_repair', method: 'POST' },
            { path: '/internalcontrol/assets', method: 'GET' }
        ]
    },
    update: {
        funKey: 'update',
        name: '修改',
        apis: [
            { path: '/internalcontrol/assets_repair/{id}', method: 'PUT' },
            { path: '/internalcontrol/assets', method: 'GET' }
        ]
    },
    del: {
        funKey: 'del',
        name: '删除',
        apis: [
            { path: '/internalcontrol/assets_repair/{id}', method: 'DELETE' }
        ]
    }
}