export default {
  icon: 'icon-shuju',
  name: '物品组',
  clientType: 'web',
  router: {
    name: 'base.group',
    path: 'base/group',
    component: () => import('../../../../views/base/group/index.vue')
  },
  funs: {
    get: {
      name: '获取',
      funKey: 'get',
      apis: [
        { path: '/scm/goods_group', method: 'GET' }
      ]
    },
    add: {
      name: '新增',
      funKey: 'add',
      apis: [
        { path: '/scm/goods_group', method: 'POST' }
      ]
    },
    update: {
      name: '修改',
      funKey: 'update',
      apis: [
        { path: '/scm/goods_group/{id}', method: 'PUT' }
      ]
    },
    del: {
      name: '删除',
      funKey: 'del',
      apis: [
        { path: '/scm/goods_group/{id}', method: 'DELETE' }
      ]
    },
    detail: {
      name: '明细',
      funKey: 'detail',
      apis: [
        { path: '/scm/goods', method: 'GET' },
        { path: '/scm/goods_group/detail', method: 'GET' }
      ]
    },
    addDetail: {
      name: '新增明细',
      funKey: 'addDetail',
      apis: [
        { path: '/scm/goods_group/detail', method: 'POST' }
      ]
    },
    updateDetail: {
      name: '修改明细',
      funKey: 'updateDetail',
      apis: [
        { path: '/scm/goods_group/detail/{id}', method: 'PUT' }
      ]
    },
    delDetail: {
      name: '删除明细',
      funKey: 'delDetail',
      apis: [
        { path: '/scm/goods_group/detail/{id}', method: 'DELETE' }
      ]
    }
  }
}