export default {
  routerNames: ['unit.index', 'unit.config'],
  funs: {
    get: {
      funKey: 'get',
      name: '考核单元查看',
      apis: [
        { method: 'GET', path: '/performance/pfm_unit' }
      ]
    },
    manage: {
      funKey: 'manage',
      name: '考核单元管理',
      apis: [
        { method: 'PUT', path: '/performance/pfm_unit/{id}' },
        { method: 'POST', path: '/performance/pfm_unit' },
        { method: 'DELETE', path: '/performance/pfm_unit/{id}' }
      ]
    },
    config: {
      funKey: 'config',
      name: '考核单元明细配置',
      apis: [
        { method: 'GET', path: '/performance/pfm_unit_month_detail_default' },
        { method: 'PUT', path: '/performance/pfm_unit_month_detail_default/{id}' },
        { method: 'POST', path: '/performance/pfm_unit_month_detail_default' },
        { method: 'DELETE', path: '/performance/pfm_unit_month_detail_default/{id}' }
      ]
    }
  }
}