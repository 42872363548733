import menus from '../config/menus/web'

function treeGet (list) {
  let rows = []
  for (let item of list) {
    if (item.router && item.clientType === 'web') {
      let row = {
        ...item.router
      }
      if (item.children && item.children.length) {
        row.children = treeGet(item.children)
      }
      rows.push(row)
    } else {
      rows.push(...treeGet(item.children))
    }
  }
  return rows
}

export default treeGet(menus)