export const findDataFormTree = ({data, dataKey, tree, childKey}) => {
  let findItem = null
  tree.forEach((item) => {
    if (item[dataKey] === data) {
      findItem = item
    } else if (!findItem && item[childKey] && item[childKey].length > 0) {
      findItem = findDataFormTree({data, dataKey, tree: item[childKey], childKey})
    }
  })
  return findItem
}

export const sleep = async function (time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const treeToList = (tree, childKey, data) => {
  data = data || []
  tree.forEach((item) => {
    data.push(item)
    if (item[childKey] && item[childKey].length > 0) {
      data = treeToList(item[childKey], childKey, data)
    }
    item[childKey] = null
  })
  return data
}

export const getLabelFromList = (dataList, value, key = 'key', label = 'label') => {
  let data = ''
  dataList.forEach((item) => {
    if (item[key] === value) {
      data = item[label]
    }
  })
  return data
}

export const getUrlParm = () => {
  let data = {}
  let url = window.location.href
  let urlList = url.split('?')
  let parmStr = urlList && urlList.length === 2 ? urlList[1] : null
  if (parmStr) {
    let parmStrList = parmStr.split('&')
    parmStrList.forEach((item) => {
      let parmItemList = item.split('=')
      if (parmItemList && parmItemList.length === 2) {
        data[parmItemList[0]] = parmItemList[1]
      }
    })
  }
  return data
}

export const testPassword = (password) => {
  let reData = 'ok'
  if (typeof password !== 'string') {
    reData = 'noString'
  } else if (password.length < 12) {
    reData = 'noLong'
  } else if (!password.match(/[a-z]/g) && !password.match(/[A-Z]/g)) {
    reData = 'noAbc'
  } else if (!password.match(/[0-9]/g)) {
    reData = 'noNum'
  } else {
    reData = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【_】‘；：”“'。，、？]").test(password) ? 'ok' : 'noChart'
  }
  return reData
}

export const downFile = (content, filename) => {
  let eleLink = document.createElement('a')
  eleLink.download = filename
  eleLink.style.display = 'none'
  // 字符内容转变成blob地址
  eleLink.href = URL.createObjectURL(new Blob([content]))
  // 触发点击
  document.body.appendChild(eleLink)
  eleLink.click()
  // 然后移除
  document.body.removeChild(eleLink)
}

export const copyText = (text) => {
  let elInput = document.createElement('input')
  elInput.value = text
  document.body.appendChild(elInput)
  elInput.focus()
  elInput.select()
  document.execCommand('copy', false, null)
  document.body.removeChild(elInput)
}

export default {
  sleep,
  copyText,
  downFile,
  testPassword,
  getUrlParm,
  findDataFormTree,
  treeToList,
  getLabelFromList
}