import funs from './funs'
export default {
  icon: 'icon-shuju',
  name: '物品',
  clientType: 'web',
  router: {
    name: 'base.goods',
    path: 'base/goods',
    component: () => import('../../../../views/base/goods/index.vue')
  },
  funs
}