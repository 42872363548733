export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    },
    {
      path: '/basis/worker_job_title/current',
      method: 'GET'
    },
    {
      path: '/basis/worker_certificate',
      method: 'GET'
    },
    {
      path: '/basis/job_title',
      method: 'GET'
    }]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '查看本机构下的',
    apis: [{
      path: '/basis/worker/detail/my_org',
      method: 'GET'
    },
    {
      path: '/basis/worker_job_title/current',
      method: 'GET'
    },
    {
      path: '/basis/worker_certificate',
      method: 'GET'
    },
    {
      path: '/basis/job_title',
      method: 'GET'
    }]
  },
  getMyOrgContainChild: {
    funKey: 'getMyOrgContainChild',
    name: '查看本机构及其子机构下的',
    apis: [{
      path: '/basis/worker/detail/my_org_contain_child',
      method: 'GET'
    },
    {
      path: '/basis/worker_job_title/current',
      method: 'GET'
    },
    {
      path: '/basis/worker_certificate',
      method: 'GET'
    },
    {
      path: '/basis/job_title',
      method: 'GET'
    }]
  }
}