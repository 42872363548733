let dcopy = require('deep-copy')

export const getStrShowLength = (str) => {
  return (str ? String(str) : '').replace(/[\u0391-\uFFE5]/g, 'aa').length / 2
}

export const getDomFatherLevel = (dom, domF, level = 1) => {
  let DomFatherLevel = 0
  if (!dom || !domF) {
    return false
  }
  if (dom === domF) {
    return DomFatherLevel
  }
  let getEd = false
  let testDom = dom
  while (level > 0 && testDom.parentElement) {
    if (!getEd) {
      getEd = testDom.parentElement === domF
    }
    DomFatherLevel += 1
    level = level - 1
    testDom = testDom.parentElement
    if (getEd) {
      break
    }
  }
  return getEd ? DomFatherLevel : -1
}

export const fmMergeColumn = (dataList, mergeKeyList) => {
  if (mergeKeyList.length === 0) {
    return dataList
  }
  let mergeKey = mergeKeyList[0]
  let reList = []
  let dataMap = {}
  dataList.forEach((itemData) => {
    if (!dataMap[itemData[mergeKey]]) {
      dataMap[itemData[mergeKey]] = []
    }
    dataMap[itemData[mergeKey]].push(itemData)
  })
  for (let mergeData in dataMap) {
    dataMap[mergeData] = fmMergeColumn(dataMap[mergeData], mergeKeyList.slice(1))
  }
  for (let mergeData in dataMap) {
    dataMap[mergeData].forEach((item, index) => {
      let newDataMap = dcopy(item)
      newDataMap['__' + mergeKey + '_span'] = {
        'rowspan': index === 0 ? dataMap[mergeData].length : 0
      }
      reList.push(newDataMap)
    })
  }
  return reList
}

export const dataListFilter = function (dataList, searchMap, searchConfig) {
  let showData = []
  searchMap = searchMap || {}
  searchConfig = searchConfig || {}
  dataList.forEach((item) => {
    let show = true
    for (let key in searchMap) {
      if (searchMap[key] === undefined || searchMap[key] === null) {
        continue
      }
      let cellData = String(item[key])
      if (searchConfig[key].searchFun) {
        if (!searchConfig[key].searchFun(item, searchMap[key])) {
          show = false
        }
      } else if (searchConfig[key].searchType === 'text' && !(cellData.toUpperCase()).includes(searchMap[key].toUpperCase())) {
        show = false
      } else if (searchConfig[key].searchType === 'select') {
        if (['fmNull', 'fmNotNull'].includes(searchMap[key])) {
          if (searchMap[key] === 'fmNull' && cellData !== 'null') {
            show = false
          } else if (searchMap[key] === 'fmNotNull' && cellData === 'null') {
            show = false
          }
        } else if (cellData !== String(searchMap[key])) {
          show = false
        }
      } else if (searchConfig[key].searchType === 'mu_select') {
        if (searchMap[key] && searchMap[key].length > 0 && !searchMap[key].includes(cellData)) {
          show = false
        }
      } else if (searchConfig[key].searchType === 'interval_number') {
        let cellDataNumber = Number(cellData)
        if (![null, ''].includes(searchMap[key][0]) && (cellDataNumber < searchMap[key][0] || isNaN(cellDataNumber))) {
          show = false
        }
        if (![null, ''].includes(searchMap[key][1]) && (cellDataNumber > searchMap[key][1] || isNaN(cellDataNumber))) {
          show = false
        }
      }
    }
    if (show) {
      showData.push(item)
    }
  })
  return showData
}