<template>
  <div>
    <h2>输入框(fm-input)</h2>
    <div class="flex">
      <fm-input v-model="text1" placeholder="mini" size="mini"></fm-input>
      <fm-input v-model="text1" placeholder="small" size="small"></fm-input>
      <fm-input v-model="text1" placeholder="norm" size="norm"></fm-input>
      <fm-input v-model="text1" placeholder="large" size="large"></fm-input>
    </div>
    <h2>验证(v-verifier)</h2>
    <div class="flex">
      <fm-input v-model="text2" v-verifier required placeholder="必填"></fm-input>
      <fm-input v-model="text3" v-verifier placeholder="可空"></fm-input>
      <fm-input v-model="text4" v-verifier :rules="[{type: 'phone'}]" placeholder="非空时验证"></fm-input>
      <fm-input v-model="text5" v-verifier :rules="rules" placeholder="正则+多条件+自定错误提示"></fm-input>
      <fm-input v-model="text6" v-verifier :rules="[{type: 'email'}]" placeholder="邮箱"></fm-input>
      <fm-input v-model="text7" @verifier="verifier" v-verifier :rules="[{type: 'email'}]" placeholder="邮箱"></fm-input>
    </div>

    <br />
    <hr />
    <br />
    
    <h2>新版输入框 (fm-input-new)</h2>
    <h2>类型</h2>
    <div class="flex">
      <fm-input-new placeholder="普通文本"></fm-input-new>
      <fm-input-new placeholder="密码" type="password">
        <i class="fmico fmico-password" slot="suffix"></i>
      </fm-input-new>
      <fm-input-new placeholder="可清空" clearable></fm-input-new>
      <fm-input-new placeholder="文本框" type="textarea"></fm-input-new>
      <fm-input-new placeholder="不可用" disabled></fm-input-new>
      <fm-input-new placeholder="仅读" readonly></fm-input-new>
    </div>
    <h2>插槽</h2>
    <div class="flex">
      <fm-input-new placeholder="前置图标">
        <i class="fmico fmico-edit" slot="prefix"></i>
      </fm-input-new>
      <fm-input-new placeholder="后置图标">
        <i class="fmico fmico-edit" slot="suffix"></i>
      </fm-input-new>
      <fm-input-new placeholder="前置自定义内容">
        <span slot="prepend">姓名</span>
      </fm-input-new>
      <fm-input-new placeholder="前置自定义内容+图标">
        <span slot="prepend">
          <i class="fmico fmico-edit"></i>
          编辑
        </span>
      </fm-input-new>
      <fm-input-new placeholder="后置自定义内容+图标+可清空" clearable>
        <span slot="append">
          <i class="fmico fmico-verify"></i>
          验证码
        </span>
      </fm-input-new>
    </div>
    <h2>字数统计</h2>
    <div class="flex">
      <fm-input-new placeholder="字数统计" type="textarea" counts :maxlength="100"></fm-input-new>
      <fm-input-new placeholder="字数统计" type="textarea" counts></fm-input-new>
    </div>
    <h2>其他插槽</h2>
    <div class="flex">
      <fm-input-new placeholder="插入按钮">
        <fm-btn slot="append" text>
          <i class="fmico fmico-search"></i>
        </fm-btn>
      </fm-input-new>
      <fm-input-new placeholder="插入下拉选择">
        <fm-select slot="prepend" style="width: 80px;">
          <fm-option value="http:" label="http:"></fm-option>
          <fm-option value="https:" label="https:"></fm-option>
        </fm-select>
        <fm-select slot="append" style="width: 70px;">
          <fm-option value=".cn" label=".cn"></fm-option>
          <fm-option value=".com" label=".com"></fm-option>
          <fm-option value=".org" label=".org"></fm-option>
        </fm-select>
      </fm-input-new>
    </div>
    <h2>可用性测试</h2>
    <div class="flex">
      <fm-input-new v-model="test1" placeholder="双向绑定"></fm-input-new>
      <fm-input-new v-model="test2" placeholder="无初始值的双向绑定"></fm-input-new>
      <fm-input-new :value="test3" @input="(v) => test3 = v" placeholder="value绑定"></fm-input-new>
      <fm-btn @click="test3 = 'aaa'">set to aaa</fm-btn>
      <fm-btn @click="test3 = 'bbb'">set to bbb</fm-btn>
    </div>

    
    <br />
    <hr />
    <br />

    <h2>原生input(带验证, 支持多事件多规则验证,和触发器识别)</h2>
    <div class="flex">
      <!-- 
        错误消息会以position: absolute的形式被添加
        包裹input的元素没有postion属性时
        verifier会自动将包裹input的容器设置为relative定位
      -->
      <div>
        <input v-verifier="{
          trigger: 'input',
          rules: {type: 'number'}
        }" required placeholder="输入时验证, 数字+非空"/>
      </div>
      <div>
        <input v-verifier="{
          trigger: ['input', 'focus', 'blur'],
          rules: {type: 'number'}
        }" required placeholder="输入获取和失去焦点时验证"/>
      </div>
      <div>
        <input v-verifier="{
          trigger: ['input', 'focus', 'blur'],
          rules: {type: 'number'}
        }" @verifier="({trigger, result}) => $notice.warning(`由${trigger}触发的验证结果: ${result}`)" required placeholder="验证事件"/>
      </div>
      <div>
        <input v-verifier required placeholder="输入时验证, 非空"/>
      </div>
      <div>
        <input v-verifier="{trigger: 'focus'}" autofocus required placeholder="获取焦点时验证 非空" />
      </div>
      <div>
        <input v-verifier="{trigger: 'v-inserted'}" autofocus required placeholder="指令加载时立即验证" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmInputDemo',
  components: {},
  data() {
    return {
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      text5: '',
      text6: '',
      text7: 'aaa',
      rules: [
        {type: 'regular', regular: /^[a-zA-Z]/, message: '只能字母开头'},
        {type: 'regular', regular: /^[a-zA-Z]\d/, message: '第二位必须是数字'}
      ],
      test1: 'hello',
      test2: null,
      test3: null,
    }
  },
  props: {},
  computed: {},
  methods: {
    verifier (msg) {
      console.log(msg)
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  h2 {
    margin-bottom: 10px;
  }
  h2 ~ h2 {
    margin-top: 20px;
  }
</style>