import menus from '../config/menus'

const funs = []

;(function treeGet (list, result) {
  for (let item of list) {
    if (item.funs && item.url) {
      result.push({
        routerNames: [item.url],
        funs: item.funs
      })
    }
    if (item.children && item.children.length) {
      treeGet(item.children, result)
    }
  }
})(menus, funs)

export default funs