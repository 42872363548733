<style lang="less">
  @import url('../styles/values.less');
  .fm-table-cell-width-resize {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    user-select: none;
    border-left: 2px dashed @color-divider;
  }
</style>

<template>
  <div class="fm-table-cell-width-resize" v-show="sizeing" :style="{
    left: left + 'px'
  }">

  </div>
</template>

<script>
export default {
  inject: ['table'],
  data () {
    return {
      left: 0,
      startX: 0,
      endX: 0,
      groupIndex: -1,
      columnIndex: -1,
      sizeing: false
    }
  },
  methods: {
    start ({event, columnIndex, groupIndex}) {
      this.sizeing = true
      this.startX = event.x
      this.left = event.x - this.table.$el.getBoundingClientRect().x
      this.columnIndex = columnIndex
      this.groupIndex = groupIndex
      this.table.$el.style.userSelect = 'none'
      window.addEventListener('mousemove', this.onMousemove)
      window.addEventListener('mouseup', this.onMouseup)
      document.body.addEventListener('mouseleave', this.end)
    },
    onMousemove (event) {
      this.left = event.x - this.table.$el.getBoundingClientRect().x
    },
    onMouseup (event) {
      if (this.sizeing) {
        this.endX = event.x
        this.table.$emit('cell-drag-end', {x: this.endX - this.startX, groupIndex: this.groupIndex, columnIndex: this.columnIndex})
        this.end()
      }
    },
    end () {
      this.sizeing = false
      this.endX = 0
      this.startX = 0
      window.removeEventListener('mousemove', this.onMousemove)
      window.removeEventListener('mouseup', this.onMouseup)
    }
  },
  mounted () {
    this.table.$on('cell-drag-start', this.start)
  },
  destroyed () {
    this.end() 
  }
}
</script>
