export default {
  get: {
    funKey: 'get',
    name: '查看审核列表',
    apis: [
      { method: 'GET', path: '/basis/appraisal_year' }
    ]
  },
  del: {
    funKey: 'del',
    name: '删除审核',
    apis: [
      { method: 'DELETE', path: '/basis/appraisal_year/{id}' }
    ]
  },
  update: {
    funKey: 'update',
    name: '修改审核',
    apis: [
      { method: 'PUT', path: '/basis/appraisal_year/{id}' }
    ]
  },
  add: {
    funKey: 'add',
    name: '添加审核',
    apis: [
      { method: 'POST', path: '/basis/appraisal_year' }
    ]
  },
  status: {
    funKey: 'status',
    name: '审核状态切换',
    apis: [
      { method: 'PUT', path: '/basis/appraisal_year/status/{id}' }
    ]
  },
  getDetails: {
    funKey: 'getDetails',
    name: '查看审核明细',
    apis: [
      { method: 'GET', path: '/basis/appraisal_year/detail' }
    ]
  },
  my: {
    funKey: 'my',
    name: '个人总结填报',
    apis: [
      { method: 'GET', path: '/basis/appraisal_year/detail' },
      { method: 'GET', path: '/basis/appraisal_year/detail/my' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/{id}' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/status/{id}' }
    ]
  },
  mycheck: {
    funKey: 'mycheck',
    name: '个人意见填报',
    apis: [
      { method: 'GET', path: '/basis/appraisal_year/detail' },
      { method: 'GET', path: '/basis/appraisal_year/detail/my' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/{id}' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/status/{id}' },
      { method: 'POST', path: '/basis/appraisal_year/check' },
    ]
  },
  orgcheck: {
    funKey: 'orgcheck',
    name: '单位审核意见填报',
    apis: [
      { method: 'GET', path: '/basis/appraisal_year/detail' },
      { method: 'GET', path: '/basis/appraisal_year/detail/my' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/{id}' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/status/{id}' },
      { method: 'POST', path: '/basis/appraisal_year/check' },
    ]
  },
  leadcheck: {
    funKey: 'leadcheck',
    name: '领导审核意见填报',
    apis: [
      { method: 'GET', path: '/basis/appraisal_year/detail' },
      { method: 'GET', path: '/basis/appraisal_year/detail/my' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/{id}' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/status/{id}' },
      { method: 'POST', path: '/basis/appraisal_year/check' },
    ]
  },
  othercheck: {
    funKey: 'othercheck',
    name: '未确定等次或不参加考核情况说明填报',
    apis: [
      { method: 'GET', path: '/basis/appraisal_year/detail' },
      { method: 'GET', path: '/basis/appraisal_year/detail/my' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/{id}' },
      { method: 'PUT', path: '/basis/appraisal_year/detail/status/{id}' },
      { method: 'POST', path: '/basis/appraisal_year/check' },
    ]
  },
  myindex: {
    funKey: 'myindex',
    name: '仅查看自己的完成情况',
    apis: []
  }
}