import roles from '../roles'
import { tmpAssetsRepair, applyRecordTmp } from './web'

// pad端: pda
export default [{
  'name': '资产管理',
  'icon': 'tags-fill',
  'url': '/pages/assets/index',
  'roles': ['assets', 'nk'],
  'clientType': 'pda'
},
{
  'name': '资产盘点',
  'icon': 'bookmark-fill',
  'url': '/pages/pandian/index',
  'roles': ['assets'],
  'clientType': 'pda'
},
{
  'name': '出入库',
  'icon': 'checkmark-circle-fill',
  'url': '/pages/inventory/index',
  'roles': ['assets'],
  'clientType': 'pda'
},
{
  'icon': 'setting-fill',
  'clientType': 'pda',
  'roles': roles.map(v => v.code).concat('assets'),
  'name': '维修申请',
  'url': '/pages/repair/apply/index'
},
{
  'icon': 'setting-fill',
  'clientType': 'pda',
  'roles': applyRecordTmp,
  'name': '维修申请记录',
  'url': '/pages/repair/apply/record'
},
{
  'name': '维修管理',
  'icon': 'setting-fill',
  'url': '/pages/repair/index',
  'roles': tmpAssetsRepair.concat('assets'),
  'clientType': 'pda'
},
{
  'icon': 'minus',
  'clientType': 'app',
  'roles': [...roles.map(v => v.code), 'assets'],
  'name': '报废申请',
  'url': '/pages/scrap/index'
},
{
  'icon': 'minus',
  'clientType': 'app',
  'roles': applyRecordTmp,
  'name': '报废申请记录',
  'url': '/pages/scrap/record'
},
{
  'name': '发票管理',
  'icon': 'order',
  'url': '/pages/invoice/index',
  'roles': ['bill', 'nk'],
  'clientType': 'pda'
}]