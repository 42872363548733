export default {
  icon: 'icon-shuju',
  name: '售后',
  clientType: 'web',
  router: {
    name: 'saleafter',
    path: 'saleafter',
    component: () => import('../../../views/saleafter/router.vue'),
    redirect: { name: 'scm.saleafter.index' }
  },
  children: [
    {
      icon: 'icon-shuju',
      name: '售后计划',
      clientType: 'web',
      router: {
        name: 'saleafter.index',
        path: 'index',
        component: () => import('../../../views/saleafter/index.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_sale_after', method: 'GET' },
            { path: '/scm/goods_sale_after/detail/count', method: 'GET' },
            { path: '/scm/goods_sale_after/detail', method: 'GET' }
          ]
        },
        manage: {
          name: '维护',
          funKey: 'manage',
          apis: [
            { path: '/scm/goods_sale_after/{id}', method: 'PUT' },
            { path: '/scm/goods_sale_after/exe_status/{id}', method: 'PUT' },
            { path: '/scm/goods_sale_after/{id}', method: 'DELETE' },
            { path: '/scm/goods_sale_after/detail', method: 'GET' },
            { path: '/scm/goods_sale_after', method: 'POST' },
            { path: '/scm/goods_sale_after', method: 'GET' },
            { path: '/scm/goods', method: 'GET' },
            { path: '/scm/goods_group', method: 'GET' },
            { path: '/scm/goods_group/detail', method: 'GET' },

            { path: '/scm/goods_sale_after/detail/{id}', method: 'DELETE' },
            { path: '/scm/goods_sale_after/detail/{id}', method: 'PUT' },
            { path: '/scm/goods_sale_after/detail', method: 'POST' },
            { path: '/scm/goods_sale_after/detail', method: 'GET' },
          ]
        }
      }
    },
    {
      icon: 'icon-shuju',
      name: '销售出库',
      clientType: 'web',
      router: {
        name: 'saleafter.outbound',
        path: 'outbound',
        component: () => import('../../../views/stock/inout/saleafter.vue')
      },
      funs: {
        get: {
          name: '获取',
          funKey: 'get',
          apis: [
            { path: '/scm/goods_sale_after', method: 'GET' },
            { path: '/scm/goods_sale_after/detail', method: 'GET' },
            { path: '/scm/storehouse', method: 'GET' }
          ]
        },
        manage: {
          name: '执行',
          funKey: 'manage',
          apis: [
            { path: '/scm/goods_stock/detail', method: 'GET' },
            { path: '/scm/goods_sale_after/detail', method: 'GET' },
            { path: '/scm/goods_stock', method: 'GET' },
            
            { path: '/scm/goods_sale_after/detail/out_send/{id}', method: 'POST' },
            { path: '/scm/goods_sale_after/detail/out_send/{id}', method: 'DELETE' }
          ]
        }
      }
    },
  ]
}