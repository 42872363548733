<template>
  <div class="echarts-block">
    <div class="echarts-contant" ref="echart" :id="chartId">
    </div>
  </div>
</template>

<script>
let echarts = require('echarts')
export default {
  name: 'FmEchart',
  props: {
    chartId: {
      type: String,
      default: () => {
        window.FmChartId = window.FmChartId ? window.FmChartId + 1 : 1
        return 'fm-chart-' + window.FmChartId
      }
    },
    chartOption: {
      type: Object,
      default: () => {
        return null
      }
    },
    chartEvent: {
      type: Array,
      default: () => {
        return []
      }
    },
    needClear: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chart: null,
      updateTime: 0
    }
  },
  mounted () {
    let elStyle = getComputedStyle(this.$el.parentNode)
    this.$refs['echart'].style.width = elStyle.width
    this.$refs['echart'].style.height = elStyle.height
    this.chart = echarts.init(document.getElementById(this.chartId))
    this.setOption(this.updateTime)
    let vm = this
    this.chartEvent.forEach((item) => {
      vm.chart.on(item.type, function (params) {
        item.dealFun(params)
      })
    })
    vm.$watch('chartOption', function () {
      vm.updateTime += 1
      setTimeout(vm.setOption(vm.updateTime), 200)
    }, {deep: true})
  },
  methods: {
    setOption (updateTime) {
      if (updateTime !== this.updateTime) {
        return
      }
      this.chart.clear()
      let vm = this
      this.$nextTick(() => {
        if (vm.chartOption) {
          if (vm.needClear) {
            vm.chart.clear()
          }
          vm.chart.setOption(vm.chartOption)
        }
      })
    }
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  }
}
</script>

<style scoped lang="less">
  .echarts-block {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>
