<template>
  <span class="fm-switch" :style="switchStyle" :class="switchClass" tabindex="0" @click="toggle">
    <input type="hidden" v-model="data"/>
    <span class="fm-switch-inner">
        <slot name="on" v-if="data === onValue"></slot>
        <slot name="off" v-if="data === offValue"></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'FmSwitch',
  components: {},
  data () {
    return {
      data: this.value
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    onColor: {type: String, default: undefined},
    offColor: {type: String, default: undefined},
    loading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    value: {type: [Boolean, Number, String], default: false},
    size: {
      type: String,
      default: 'norm',
      validator: function(size) {
        return ['norm', 'large', 'small', 'mini'].includes(size)
      }
    },
    onValue: {type: [Boolean, Number, String], default: true},
    offValue: {type: [Boolean, Number, String], default: false},
    confirm: {type: Function, default: undefined}
  },
  computed: {
    isDisabled () { return [undefined, true].includes(this.disabled) },
    isChecked () { return this.data === this.onValue },
    switchStyle () {
      let styles = {}
      if (this.onColor && this.isChecked) {
        Object.assign(styles, { backgroundColor: this.onColor })
      } else if (this.offColor) {
        Object.assign(styles, { backgroundColor: this.offColor })
      }
      return styles
    },
    switchClass () {
      return {
        [`fm-switch-${this.size}`]: true,
        'fm-switch-checked': this.data === this.onValue,
        'fm-switch-loading': this.loading,
        'fm-switch-disabled': this.isDisabled
      }
    }
  },
  methods: {
    async toggle () {
      if (!this.isDisabled && !this.loading) {
        let accept = true
        if (typeof this.confirm === 'function') {
          accept = await this.confirm(this.isChecked ? this.offValue : this.onValue)
        }
        if (accept !== false) {
          let data = this.isChecked ? this.offValue : this.onValue
          this.$emit('input', data)
        }
      }
    }
  },
  watch: {
    value () {
      this.data = this.value
      this.$emit('change', this.data)
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less">
  @import "./styles/values.less";
  @padding: 3px;
  .fm-switch {
    background-color: #CCC;
    transition: all .3s;
    cursor: pointer;
    padding: @padding;
    user-select: none;
    display: inline-block;
    position: relative;
    box-shadow: 0 0 5px 0 transparent;
    &:focus, &:active {box-shadow: 0 0 0 2px rgba(28, 181, 224, .4);outline: none;}
    & + & {margin-left: 10px;}
    &.fm-switch-disabled {opacity: .4;cursor: not-allowed;}
    &.fm-switch-loading {
      opacity: .7;
      .fm-switch-inner {
        &::before {
          content: '';
          z-index: 1;
          animation: ani-switch-loop 1s linear infinite;
          transform-origin: center;
          border: 1px solid @color-primary;
          box-sizing: border-box;
          border-color: transparent transparent transparent @color-primary;
        }
      }
    }
  }

  @keyframes ani-switch-loop {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
  }

  @keyframes ani-switch-checked-loop {
    from { transform: translateX(-100%) rotate(0deg);}
    50%  { transform: translateX(-100%) rotate(180deg);}
    to   { transform: translateX(-100%) rotate(360deg);}
  }

  .fm-switch-inner {
    color: #FFF;
    font-size: @size-font-norm;
    transition: all .3s;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &::after {
      content: '';
      display: inline-block;
      background-color: #FFF;
    }
    &::before, &::after {top: 0; left: 0;transition: all .3s;position: absolute;}
  }

  .fm-switch.fm-switch-checked {
    background-color: @color-primary;
    .fm-switch-inner {
      justify-content: flex-start;
      &::before, &::after {
        transform: translateX(-100%);
        left: 100%;
      }
    }
    &.fm-switch-loading {
      .fm-switch-inner {
        &::before {
          animation-name: ani-switch-checked-loop;
        }
      }
    }
  }

  // 大小设置
  .fm-switch {
    &.fm-switch-large {
      min-width: 100px;
      height: @size-height-large;
      border-radius: @size-height-large;
      .fm-switch-inner {
        padding-left: @size-height-large + @padding;
        padding-right: @size-height-large / 5;
        &::before, &::after {
          width: @size-height-large - (@padding * 2);
          height: @size-height-large - (@padding * 2);
          border-radius: (@size-height-large - (@padding * 2)) / 2;
        }
      }
      &:active {
        .fm-switch-inner {
          &::after {
            width: @size-height-large + 5px;
          }
        }
      }
      &.fm-switch-checked {
        .fm-switch-inner {
          padding-right: @size-height-large + @padding;
          padding-left: @size-height-large / 5;
        }
      }
    }
    &.fm-switch-norm {
      min-width: 64px;
      height: @size-height-norm;
      border-radius: @size-height-norm;
      .fm-switch-inner {
        padding-left: @size-height-norm + @padding;
        padding-right: @size-height-norm / 5;
        &::before, &::after {
          width: @size-height-norm - (@padding * 2);
          height: @size-height-norm - (@padding * 2);
          border-radius: (@size-height-norm - (@padding * 2)) / 2;
        }
      }
      &:active {
        .fm-switch-inner {
          &::after {
            width: @size-height-norm + 5px;
          }
        }
      }
      &.fm-switch-checked {
        .fm-switch-inner {
          padding-right: @size-height-norm + @padding;
          padding-left: @size-height-norm / 5;
        }
      }
    }
    &.fm-switch-small {
      min-width: 48px;
      height: @size-height-small;
      border-radius: @size-height-small;
      .fm-switch-inner {
        padding-left: @size-height-small + @padding;
        padding-right: @size-height-small / 5;
        &::before, &::after {
          width: @size-height-small - (@padding * 2);
          height: @size-height-small - (@padding * 2);
          border-radius: (@size-height-small - (@padding * 2)) / 2;
        }
      }
      &:active {
        .fm-switch-inner {
          &::after {
            width: @size-height-small + 5px;
          }
        }
      }
      &.fm-switch-checked {
        .fm-switch-inner {
          padding-right: @size-height-small + @padding;
          padding-left: @size-height-small / 5;
        }
      }
    }
    &.fm-switch-mini {
      min-width: 50px;
      height: @size-height-mini;
      border-radius: @size-height-mini;
      .fm-switch-inner {
        padding-left: @size-height-mini + @padding;
        padding-right: @size-height-mini / 5;
        &::before, &::after {
          width: @size-height-mini - (@padding * 2);
          height: @size-height-mini - (@padding * 2);
          border-radius: (@size-height-mini - (@padding * 2)) / 2;
        }
      }
      &:active {
        .fm-switch-inner {
          &::after {
            width: @size-height-mini + 5px;
          }
        }
      }
      &.fm-switch-checked {
        .fm-switch-inner {
          padding-right: @size-height-mini + @padding;
          padding-left: @size-height-mini / 5;
        }
      }
    }
  }
</style>