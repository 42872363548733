<template>
  <div>
    <h2>按钮(fm-btn)</h2>
    <div class="btn-list">
      <fm-btn v-loadingx="status2" @click="load">{{status2 ? '加载中' : '按钮'}}</fm-btn>
      <fm-btn v-loadingx="true">加载中...</fm-btn>
      <fm-btn disabled>不可用</fm-btn>
      <fm-btn text>文本</fm-btn>
    </div>
    <h2>按钮样式(type: <fm-tag>norm</fm-tag><fm-tag>primary</fm-tag><fm-tag>info</fm-tag><fm-tag>success</fm-tag><fm-tag>warning</fm-tag><fm-tag>danger</fm-tag>)</h2>
    <div class="btn-list">
      <fm-btn type="norm">默认样式</fm-btn>
      <fm-btn type="primary">主要按钮</fm-btn>
      <fm-btn type="info">信息按钮</fm-btn>
      <fm-btn type="success">成功按钮</fm-btn>
      <fm-btn type="warning">警告按钮</fm-btn>
      <fm-btn type="danger">失败按钮</fm-btn>
    </div>
    <h2>浅色模式(<fm-tag>tint</fm-tag>)</h2>
    <div class="btn-list">
      <fm-btn tint type="primary">主要按钮</fm-btn>
      <fm-btn tint type="info">信息按钮</fm-btn>
      <fm-btn tint type="success">成功按钮</fm-btn>
      <fm-btn tint type="warning">警告按钮</fm-btn>
      <fm-btn tint type="danger">失败按钮</fm-btn>
    </div>
    <h2>按钮尺寸(size: <fm-tag>large</fm-tag><fm-tag>norm</fm-tag><fm-tag>small</fm-tag><fm-tag>mini</fm-tag>)</h2>
    <div class="btn-list">
      <fm-btn size="large" type="primary">大按钮</fm-btn>
      <fm-btn size="norm" type="primary">默认按钮</fm-btn>
      <fm-btn size="small" type="primary">小按钮</fm-btn>
      <fm-btn size="mini" type="primary">迷你按钮</fm-btn>
      <br />
      <br />
      <fm-btn type="norm" size="large" icon="search"></fm-btn>
      <fm-btn type="norm" icon="search"></fm-btn>
      <fm-btn type="primary" size="small" icon="info"></fm-btn>
      <fm-btn type="info" size="mini" icon="cha"></fm-btn>
    </div>
    <h2>块状按钮(block)</h2>
    <div class="btn-list">
      <fm-btn block type="primary">块状独占一行按钮</fm-btn>
      <fm-btn block type="primary">块状独占一行按钮</fm-btn>
    </div>
    <h2>长按钮(long)</h2>
    <div class="btn-list">
      <fm-btn long type="norm">长按钮</fm-btn>
      <fm-btn long="100" type="primary">指定长</fm-btn>
      <fm-btn long="10vw" type="info">指定长</fm-btn>
    </div>
    <h2>图标(icon)</h2>
    <div class="btn-list">
      <fm-btn type="norm" icon="search"></fm-btn>
      <fm-btn type="primary" icon="info"></fm-btn>
      <fm-btn type="info" icon="cha"></fm-btn>
      <fm-btn type="primary">
        <i class="fmico fmico-search"></i>搜索
      </fm-btn>
      <fm-btn type="primary">
        搜索<i class="fmico fmico-search"></i>
      </fm-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FmBtnDemo',
  components: {},
  data() {
    return {
      status2: false
    }
  },
  props: {},
  computed: {},
  methods: {
    load () {
      this.status2 = true
      setTimeout(() => {
        this.status2 = false
      }, 1000)
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
  .btn-list {
    margin: 20px 0;
  }
</style>