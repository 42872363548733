export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/modify_apply/wait',
      method: 'GET'
    },
    {
      path: '/basis/modify_apply/wait/detail',
      method: 'GET'
    }]
  },
  pass: {
    funKey: 'detail',
    name: '明细',
    apis: [{
      path: '/basis/modify_apply/check/{id}',
      method: 'POST'
    },
    {
      path: '/basis/modify_apply/check/{id}',
      method: 'POST'
    }]
  }
}