export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/ask_leave/my',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '假条明细',
    apis: [{
      path: '/basis/ask_leave_apply',
      method: 'GET'
    },
    {
      path: '/basis/ask_leave_apply/type',
      method: 'GET'
    },
    {
      path: '/basis/status_manage/config',
      method: 'GET'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '附件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取附件'
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载附件'
  }
}
