<style lang="less">
  .fm-table-new-contextmenu {
    position: fixed;
    background-color: #FFF;
    border-radius: 5px;
    z-index: 1;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    max-height: 200px;
    .fm-table-new-menu {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      transition: all .3s;
      color: #657180;
      font-size: 15px;
      .fmico {
        font-size: 20px;
        margin-right: 5px;
      }
      &:hover {
        background-color: #EEE;
      }
    }
  }
  .fm-table-new-contextmenu-transition-enter-active, .fm-table-new-contextmenu-transition-leave-active {
    transition: all .3s;
    opacity: 1;
    transform: scale(1);
  }
  .fm-table-new-contextmenu-transition-enter, .fm-table-new-contextmenu-transition-leave-to {
    opacity: 0;
    transform: scale(0.7);
  }
</style>

<template>
  <transition name="fm-table-new-contextmenu-transition" mode="in-out">
    <div class="fm-table-new-contextmenu" v-show="show">
      <div class="fm-table-new-menu" v-if="toolbox.includes('export')" @click="action('export')"><i class="fmico fmico-export-excel"></i>导出</div>
      <div class="fm-table-new-menu" v-if="toolbox.includes('config')" @click="action('config')"><i class="fmico fmico-edit"></i>设置</div>
      <render
        v-for="menu in contextMenus"
        :key="menu.key"
        :menu="menu"
      />
    </div>
  </transition>
</template>

<script>
import ExportConfig from '../export/config'
import TableConfig from '../config/index'
import Render from './render'
import Vue from 'vue'

function VueCmpRender (cmp, table) {
  const instance = new Vue({
    data: {},
    render: function (h) {
      return h(cmp, {
        props: {
          table: table
        },
        on: {
          done: this.close,
          cancel: this.close
        }
      })
    },
    methods: {
      close: function () {
        setTimeout(() => {
          this.$destroy()
          document.body.removeChild(this.$el)
        }, 300)
      }
    }
  }).$mount()
  document.body.appendChild(instance.$el)
  instance.$children[0].show = true
}

export default {
  inject: ['table'],
  components: { Render },
  props: {
    outclose: { type: Boolean, default: true }
  },
  data () {
    return {
      show: false,
      init: false
    }
  },
  watch: {
    show (v) {
      if (v) {
        if (this.outclose) {
          document.body.addEventListener('mouseup', this.onMouseUp)
        }
      } else {
        document.body.removeEventListener('mouseup', this.onMouseUp)
      }
    }
  },
  computed: {
    toolbox () {
      return this.table.toolbox
    },
    contextMenus () {
      return this.table.contextMenus
    }
  },
  methods: {
    action (type) {
      this.show = false
      switch (type) {
      case 'export':
        this.openExport()
        break
      case 'config':
        this.openConfig()
        break
      }
    },
    openExport () {
      VueCmpRender(ExportConfig, this.table)
    },
    openConfig () {
      VueCmpRender(TableConfig, this.table)
    },
    onMouseUp (e) {
      if (!this.init && e.target !== this.$el && (e.target.contains(this.$el) || !e.path.includes(this.$el))) {
        this.show = false
      } else {
        this.init = false
      }
    },
    active (e) {
      this.$el.style.top = e.y + 'px'
      this.$el.style.left = e.x + 'px'
      this.init = true
      this.show = true
    }
  },
  mounted () {
    this.table.$on('on-context-menu', () => {
      this.show = false
    })
  }
}
</script>
