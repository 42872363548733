<style lang="less">
@import url('../drag/row.less');
@import url('../../styles/values.less');
.fm-table-export-config {
  &[data-moving] {
    .field-item {
      opacity: 0;
    }
  }
  .field-item {
    margin-top: 5px;
    padding-right: 20px;
    transition: all .3s;
    flex-wrap: wrap;
    justify-content: flex-start;
    display: flex;
    .fixed {
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #EEE;
      display: inline-block;
      color: @color-content-text;
      span {
        transition: all .3s;
        cursor: pointer;
        padding: 5px 10px;
        &:hover {
          color: @color-primary;
        }
        &.active {
          color: #FFF;
          background-color: @color-primary;
          border-left: 1px solid @color-primary;
          & + span {
            border-left-color: @color-primary;
          }
        }
      }
      span + span {
        border-left: 1px solid #EEE;
      }
    }
    .field-set {
      display: flex;
      align-items: center;
      padding-right: 10px;
      .field-label {
        margin-right: 10px;
      }
    }
  }
  .column-item {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }
  .column-item + .column-item {
    border-top: 1px solid #EEE;
  }
}
</style>

<template>
  <fm-modal v-model="show" @cancel="$emit('cancel')" :mask-closable="false" title="表格设置" width="800px">
    <div class="fm-table-export-config" :data-moving="activeIndex !== null" @mouseenter="(e) => e.target.classList.remove('leave')" @mouseleave="(e) => e.target.classList.add('leave')">
      <div class="fm-table-export-column" ref="columns">
        <div
          class="column-item"
          v-for="(column, i) in columns"
          :key="i + column.order + column.config.title"
          :data-index="i"
          :data-field="column.config.field">
          <fm-checkbox v-model="column.hidden" :true-value="false" :false-value="true">{{column.config.title}}</fm-checkbox>
          <div class="field-item">
            <div class="field-set">
              <span class="field-label">固定</span>
              <div class="fixed">
                <span :class="{active: column.config.fixed === 'left'}" @click="$set(column.config, 'fixed', 'left')">左</span>
                <span :class="{active: column.config.fixed === undefined}" @click="$set(column.config, 'fixed', undefined)">无</span>
                <span :class="{active: column.config.fixed === 'right'}" @click="$set(column.config, 'fixed', 'right')">右</span>
              </div>
            </div>
            <div class="field-set">
              <span class="field-label">类型</span>
              <fm-select size="small" v-model="column.config.dataType" clearable style="width: 100px;">
                <fm-option value="Number" label="数字"></fm-option>
                <fm-option value="String" label="文本"></fm-option>
                <fm-option value="Date" label="时间"></fm-option>
              </fm-select>
            </div>
            <div class="field-set">
              <span class="field-label">排序</span>
              <fm-switch size="mini" v-model="column.config.sort">
                <span slot="on">开</span>
                <span slot="off">关</span>
              </fm-switch>
            </div>
            <div class="field-set">
              <span class="field-label">宽度</span>
              <fm-input-number :precision="0" :min="0" v-model="column.config.width" style="width: 100px" placeholder="宽度" size="small"/>
            </div>
          </div>
          <i class="fmico fmico-row-drage" @mousedown="onMousedown"></i>
        </div>
      </div>
    </div>
    <div slot="footer">
      <fm-btn @click="confirm" type="primary">确定</fm-btn>
      <fm-btn @click="show = false;$emit('cancel')">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
import FmCheckbox from '../../FmCheckbox'
import FmOption from '../../FmOption.vue'
import FmSwitch from '../../FmSwitch'
import FmInputNumber from '../../FmInputNumber'

let startY = 0
let timer = null

function getParentByClass (el, cls, max = 5) {
  if (el.classList.contains(cls)) {
    return el
  } else if (el.parentElement && max > 0) {
    return getParentByClass(el.parentElement, cls, max - 1)
  } else {
    return null
  }
}

function stringifyReplacer (key, value) {
  if (key === 'dataType') {
    switch (value) {
    case Number:
      return 'Number'
    case Date:
      return 'Date'
    case String:
      return 'String'
    }
  }
  return value
}

function columnsDispose (columns) {
  return (JSON.parse(JSON.stringify([
    ...columns.filter(v => v.fixed === 'left').sort((a, b) => a.order - b.order),
    ...columns.filter(v => !v.fixed).sort((a, b) => a.order - b.order),
    ...columns.filter(v => v.fixed === 'right').sort((a, b) => a.order - b.order)
  ], stringifyReplacer))).filter(v => {
    if (v.config.configurable !== false && !v.config.field) {
      console.warn('fm-table', '未指定field参数，无法参与配置', v.config)
    }
    return v.config.configurable !== false && v.config.field
  })
}

export default {
  components: { FmCheckbox, FmSwitch, FmOption, FmInputNumber },
  props: {
    table: { type: Object }
  },
  data () {
    return {
      show: false,
      columns: columnsDispose(this.table.columnConfig.columns),
      activeIndex: null
    }
  },
  computed: {
    tableColumns () {
      return this.table.columnConfig.columns
    }
  },
  watch: {
    tableColumns: {
      deep: true,
      handler (v) {
        this.columns = columnsDispose(v)
      }
    }
  },
  methods: {
    confirm () {
      let configs = Array.from(this.$refs.columns.children).map(el => {
        return this.columns.find(v => v.config.field === el.dataset.field)
      }).map((column, order) => {
        column.config.hidden = column.hidden
        return {
          field: column.config.field,
          hidden: column.hidden,
          fixed: column.config.fixed,
          dataType: column.config.dataType ? (function (dataType) {
            switch (dataType) {
            case 'Number':
              return Number
            case 'Date':
              return Date
            case 'String':
              return String
            default:
              return undefined
            }
          })(column.config.dataType) : undefined,
          sort: column.config.sort,
          width: column.config.width,
          order: order
        }
      })

      this.table.$emit('column-settings', configs)
      this.$emit('done')
    },
    reset () {
      this.$refs.columns.children.forEach((el, index) => {
        el.dataset.index = index
        el.style.top = ''
        el.style.opacity = ''
        el.classList.remove('active')
      })
    },
    update (target, current) {
      let currentElement = this.$refs.columns.children[current]
      let targetElement = this.$refs.columns.children[target]

      if (targetElement === this.$refs.columns.lastElementChild) {
        this.$refs.columns.append(currentElement)
      } else if (targetElement === this.$refs.columns.firstElementChild || target < current) {
        targetElement.parentElement.insertBefore(currentElement, targetElement)
      } else if (target > current) {
        if (targetElement.nextElementSibling) {
          targetElement.parentElement.insertBefore(currentElement, targetElement.nextElementSibling)
        } else {
          this.$refs.columns.append(currentElement)
        }
      }
      this.reset()
    },
    onMousedown (e) {
      startY = e.y
      e.target.parentElement.classList.add('active')
      this.activeIndex = parseInt(e.target.parentElement.dataset.index)
      document.body.addEventListener('mousemove', this.onMousemove)
      document.body.addEventListener('mouseup', this.onMouseup)
    },
    onMousemove (e) {
      let activeElement = this.$refs.columns.children[this.activeIndex]
      activeElement.style.top = (e.y - startY) + 'px'
    },
    onMouseup (e) {
      clearTimeout(timer)

      let target = getParentByClass(e.target, 'column-item')

      document.body.removeEventListener('mousemove', this.onMousemove)
      document.body.removeEventListener('mouseup', this.onMouseup)
      
      this.$refs.columns.children[this.activeIndex].style.opacity = 0
      
      if (target) {
        timer = setTimeout((targetIndex, activeIndex) => this.update(targetIndex, activeIndex), 300, parseInt(target.dataset.index), this.activeIndex)
      } else {
        this.reset()
      }

      this.activeIndex = null
      startY = 0
    }
  }
}
</script>
