<style lang="less">
  @import url('../styles/values.less');
  .fm-scrollbar {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow-x: auto;
    opacity: .5;
    &:hover {opacity: 1;}
    &::-webkit-scrollbar {
      background: transparent;
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d5d5d5;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  .fm-scrollbar-wrap {
    height: 15px;
  }
  .fm-table-new:hover {
    .fm-scrollbar {
      display: block;
    }
  }
</style>

<template>
  <div class="fm-scrollbar" @scroll="onScroll" :style="style">
    <div class="fm-scrollbar-wrap" :style="wrapStyle"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      contentWidth: 0,
      width: 0,
      left: 0
    }
  },
  inject: ['table'],
  computed: {
    wrapStyle () {
      return {
        width: this.contentWidth + 'px'
      }
    },
    style () {
      return {
        display: this.contentWidth === this.width ? 'none' : 'block',
        width: this.width + 'px',
        left: this.left + 'px'
      }
    }
  },
  methods: {
    onScroll (event) {
      this.table.$emit('scroll-x', { index: 1, x: event.target.scrollLeft, type: 'scrollbar' })
    },
    onScrollAt ({x, type}) {
      if (type !== 'scrollbar') {
        this.$el.scrollLeft = x
      }
    },
    reload () {
      this.$nextTick(() => {
        let body = this.table.$refs.body.$el
        this.left = (body.querySelector('.fm-table-body-fixed-left') || {offsetWidth: 0}).offsetWidth
        this.width = (body.querySelector('.fm-table-body-fixed-center') || {offsetWidth: 0}).offsetWidth
        this.contentWidth = (body.querySelector('.fm-table-body-fixed-center table') || {offsetWidth: 0}).offsetWidth
      })
    }
  },
  mounted () {
    this.table.$on('set-cell-width', () => {
      this.$nextTick(this.reload)
    })
    this.table.$on('scroll-x', this.onScrollAt)
  }
}
</script>
