export default {
    upload: {
        funKey: 'upload',
        name: '附件上传',
        apis: [
            { method: 'POST', path: '/fms/upload' },
            { method: 'PUT', path: '/basis/ask_leave_apply/file_ids/{id}' }
        ]
    },
    delFile: {
        funKey: 'delFile',
        name: '删除附件',
        apis: [
            { method: 'PUT', path: '/basis/ask_leave_apply/file_ids/{id}' }
        ]
    },
    file: {
        funKey: 'file',
        name: '附件查看',
        apis: [
            { method: 'GET', path: '/fms/file' },
            { method: 'GET', path: '/fms/download' }
        ]
    },
    my: {
        funKey: 'my',
        name: '我的请假',
        apis: [
            { method: 'GET', path: '/basis/ask_leave/my' },
            { method: 'GET', path: '/basis/ask_leave_apply/by_auth' },
            { method: 'POST', path: '/basis/ask_leave_apply' },
            { method: 'PUT', path: '/basis/ask_leave_apply/{id}' },
            { method: 'GET', path: '/basis/status_manage/config' },
            { method: 'GET', path: '/basis/ask_leave_apply/type' }
        ]
    },
    terminate: {
        funKey: 'terminate',
        name: '销假',
        apis: [
            { method: 'GET', path: '/basis/ask_leave' },
            { method: 'POST', path: '/basis/ask_leave/off/{id}' },
            { method: 'POST', path: '/basis/ask_leave/back_off/{id}' },
            { method: 'PUT', path: '/basis/ask_leave/{id}' },
            { method: 'GET', path: '/basis/status_manage/config' },
            { method: 'GET', path: '/basis/ask_leave_apply/type' }
        ]
    },
    distribution: {
        funKey: 'distribution',
        name: '假条分配',
        apis: [
            { method: 'GET', path: '/basis/ask_leave_apply' },
            { method: 'PUT', path: '/basis/ask_leave_apply/assign/{id}' },
            { method: 'POST', path: '/basis/ask_leave_apply/switch/{id}' },
            { method: 'GET', path: '/basis/status_manage/config' },
            { method: 'GET', path: '/basis/ask_leave_apply/type' }
        ]
    },
    approval: {
        funKey: 'approval',
        name: '假条审批',
        apis: [
            { method: 'GET', path: '/basis/ask_leave_apply/by_auth' },
            { method: 'POST', path: '/basis/ask_leave_apply/switch/{id}' },
            { method: 'GET', path: '/basis/status_manage/config' },
            { method: 'GET', path: '/basis/ask_leave_apply/type' }
        ]
    }
}